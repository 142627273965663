<template>
  <div>
    <!-- <Header /> -->

    <div class="flex">
      <SideBar />

      <div
        class="w-[90%] mx-auto lg:w-full lg:mr-10 mt-10 rounded-3xl overflow-x-auto overflow-y-auto heightfix"
        style="box-shadow: 0px 2px 30px 0px #01b0f926"
      >
        <div class=" px-5 lg:px-10 pt-8 pb-4">
          <div class="flex items-center justify-between">
            <div>
              <h1 class="text-lg font-semibold">Assignments</h1>
            </div>
          </div>

          <div class="py-5 rounded flex gap-4 cursor-pointer">
            <p
              @click="this.submitted = 'assignment'"
              :class="{
                'border-b-2 border-b-blues': this.submitted == 'assignment',
              }"
            >
              Assignments
            </p>
            <p
              @click="this.submitted = 'submitted'"
              :class="{
                'border-b-2 border-b-blues': this.submitted == 'submitted',
              }"
            >
              Submitted
            </p>
          </div>
        <div v-if="loading" class="flex items-center justify-center " style=" height: calc(100vh - 350px) !important;"> 
      <img src="@/assets/newd/loading.gif" alt="">
      </div>
          <div class="h-full" v-if="this.submitted == 'assignment' && !loading">
            <div
              class="grid grid-cols-1 lg:grid-cols-3 gap-10 mt-5 overflow-y-auto"
              v-if="assignmentInfo"
            >
              <div
                v-for="assignment in assignmentInfo.data"
                :key="assignment"
                class="border rounded-2xl px-5 py-5 flex flex-col gap-2  md:w-full"
              >
                <div>
                  <div class="relative flex justify-center items-center">
                    <img
                      class="rounded-xl w-full"
                      src="@/assets/new/assignment.png"
                      alt=""
                    />
                  </div>
                </div>

                <div
                  class="text-center flex flex-col gap-1 justify-center"
                  
                >
                  <p
                    class="text-sm text-gray-600 font-semibold text-left truncate mb-0 mt-2"
                  >
                    {{ assignment.title }}
                  </p>
                  <p class="text-xs text-gray-600 text-left truncate mb-0">
                    Due Date :
                    <span
                      class="text-gray-600"
                      style="color: #adadad !important"
                    >
                      {{ assignment.display_date }}
                    </span>
                  </p>
                  <p class="text-xs text-gray-600 text-left truncate mb-0">
                    Description :
                    <span
                      class="text-gray-600"
                      style="color: #adadad !important"
                      >{{ assignment.description }}
                    </span>
                  </p>

                  <div
                    class="px-1 flex items-center gap-3"
                    style="width: 100% !important"
                  >
                    <a :href="assignment.file" target="_blank" download>
                      <img
                        class="p-1 mb-1 rounded-lg"
                        style="
                          border: 1px solid #01b0f9;
                          width: 35px;
                          height: 30px;
                        "
                        src="@/assets/new/download.png"
                      />
                    </a>

                    <p
                      @click="
                        this.assignmentPopup = !this.assignmentPopup;
                        this.assignmentId = assignment.id;
                      "
                      class="my-3 py-2 w-full text-xs rounded-lg border-2 text-black cursor-pointer"
                    >
                      <a>Submit</a>
                    </p>
                  </div>

                  <div></div>
                </div>
              </div>
            </div>
          </div>
          
          


          <div class="h-full" v-if="submitted == 'submitted'&& !loading">
            <div
              class="grid lg:grid-cols-3 gap-10 mt-5 overflow-y-auto"
              v-if="submitassignmentInfo"
            >
              <div
                v-for="assignment in submitassignmentInfo.data"
                :key="assignment"
                class="border rounded-2xl px-5 py-5 flex flex-col gap-2"
              >
                <div>
                  <div class="relative flex justify-center items-center">
                    <img
                      class="rounded-xl w-full"
                      src="@/assets/new/assignment.png"
                      alt=""
                    />
                  </div>
                </div>

                <p
                  class="text-sm text-gray-600 font-semibold text-left truncate mb-0 mt-2"
                >
                  {{ assignment.title }}
                </p>
                <p class="text-xs text-gray-600 text-left truncate mb-0">
                  Submited Date : {{ assignment.submit_date }}
                </p>

                <div
                  class="px-1 flex items-center gap-3"
                  style="width: 100% !important"
                >
                  <p
                    v-if="assignment.verify_status == 'submitted'"
                    class="my-3 py-2 px-2 w-full text-xs rounded-lg border text-center"
                    style="border: 1px solid #f87474; color: #f87474"
                  >
                    <a style="color: #f87474">Pending</a>
                  </p>

                  <p
                    v-else-if="assignment.verify_status == 'accepted'"
                    class="my-3 py-2 px-2 w-full text-xs rounded-lg border text-center"
                    style="border: 1px solid #4bd08b; color: #4bd08b"
                  >
                    <a style="color: #4bd08b">Accepted</a>
                  </p>

                  <p
                    v-else
                    class="my-3 py-2 px-2 w-full text-xs rounded-lg border text-center"
                    style="border: 1px solid #f87474; color: #f87474"
                  >
                    <a style="color: #f87474">Rejected</a>
                  </p>

                  <!-- <p
                      class="my-3 py-2 w-full text-xs rounded-lg border text-center text-black"
                    >
                      <a :href="assignment.file" target="_blank" download
                        >Download</a
                      >
                    </p>  -->

                  <div class="w-full">
                    <a :href="assignment.file" target="_blank" download
                      ><p
                        class="my-3 py-2 w-full text-xs rounded-lg border text-center text-black"
                      >
                        Download
                      </p></a
                    >
                  </div>
                </div>

                <!-- <p v-if="assignment.plagiarismcheck != null" class="rounded-xl bg-blue-400 text-white p-2 text-sm font-bold"> Plagiarism Score: {{ assignment.plagiarismcheck }}%</p> -->
              </div>
            </div>
          </div>
         

          <div
            v-if="this.assignmentPopup"
            class="fixed bg-primary bg-opacity-30 top-0 left-0 right-0 bottom-0 z-50 flex items-center justify-center"
          >
            <div
              class=" lg:w-[40%] bg-white rounded-xl p-10 overflow-y-auto max-h-[600px]"
            >
              <div class="flex items-center justify-between">
                <h3 class="text-xl font-bold text-blues">Submit Assignment</h3>
                <img
                  src="@/assets/newd/close.png"
                  class="cursor-pointer"
                  alt=""
                  @click="this.assignmentPopup = false"
                />
              </div>

              <div
                class="w-full mt-4 flex flex-col p-6 relative border border-gray-100 rounded-2xl"
              >
                <div class="w-full">
                  <form
                    @submit.prevent="scheduleAssignment"
                    class="flex flex-col gap-4 text-xs md:text-sm lg:text-base h-full"
                  >
                    <div
                      class="flex flex-col gap-2 w-full text-xs md:text-sm lg:text-base"
                    >
                      <div class="w-full flex flex-col gap-2">
                        <label for="title" class="text-left">Title</label>
                        <input
                          id="title"
                          type="text"
                          class="border border-gray-100 rounded text-xs md:text-sm lg:text-base py-2 px-2"
                        />
                      </div>
                      <!-- <div class="w-full flex flex-col gap-2">
                        <label for="duedate" class="text-left"
                          >Submit date</label
                        >
                        <input
                          id="duedate"
                          type="date"
                          class="border border-gray-100 rounded text-xs md:text-sm lg:text-base py-2 px-2"
                        />
                      </div> -->
                    </div>
                    <div class="flex flex-col w-full gap-2">
                      <label for="description" class="text-left"
                        >Description</label
                      >
                      <textarea
                        id="description"
                        rows="3"
                        cols="10"
                        class="border border-gray-100 h-full rounded text-xs md:text-sm lg:text-base py-2 px-2"
                      />
                    </div>
                    <div class="flex flex-col w-full">
                      <label for="attachments" class="text-left"
                        >Attachments *</label
                      >
                      <input
                        id="files"
                        type="file"
                        class="border bg-white border-gray-100 py-2 px-2 w-full rounded text-xs md:text-sm lg:text-base"
                        @change="inputchange"
                        required
                        accept=".txt,.doc,.docx"
                      />
                    </div>
                    <div class="flex justify-end pb-4 mt-3">
                      <button
                        type="submit"
                        class="text-black border border-black rounded-lg text-sm px-10 py-2"
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import SideBar from "@/components/Student/SideBar.vue";
// import FlashMessage from "@/components/flashMessage.vue";
// import confirmPopup from "@/components/confirmPopup.vue";
// import leftNavigationStudent from "@/views/studentDashboard/leftnav.vue";
// import VuePdfEmbed from "vue-pdf-embed";

export default {
  data() {
    return {
      assignmentInfo: "",
      messages: {
        text: "",
        type: "null",
      },
      popupwindow: false,
      titlepass: null,
      idpass: null,
      assignmentPopup: false,
      assignmentEditPopup: false,
      editvalues: null,
      assignmentId: null,
      submitassignmentInfo: null,
      submitted: "assignment",
      assignmentsNull: false,
      is_detailsPopup: false,
      assignmentDetails: null,
      is_preview: false,
      courseDetailInfo: "",
      loading:true,
    };
  },

  props: ["course_id"],

  components: {
    SideBar,
    // FlashMessage,
    // confirmPopup,
    // leftNavigationStudent,
    // VuePdfEmbed,
  },

  created() {},

  computed: {
    ...mapGetters(["userInfo"]),
  },

  mounted() {
    axios
      .get(`${process.env.VUE_APP_API}/coursesdata/${this.course_id}/`)
      .then((response) => {
        this.courseDetailInfo = response.data;
      });
    setTimeout(() => {
      this.getdetails();
    }, 200);
  },

  methods: {
    detailsPopup(value) {
      this.is_detailsPopup = true;
      this.assignmentDetails = value;
    },

    async getdetails() {
      await axios
        .get(
          `${process.env.VUE_APP_API}/Inst_Assignment/view/?instructor_id=${this.courseDetailInfo.instructor_id}&course_id=${this.course_id}&Key=${this.userInfo.token_key}`
        )
        .then((response) => {
          this.assignmentInfo = response.data;
          this.loading=false;
          if (Object.keys(this.assignmentInfo) == "error") {
            console.log("Null");
            this.assignmentsNull = false;
          } else {
            this.assignmentsNull = true;
            this.assignmentInfo.data.forEach((element) => {
              element["display_date"] = new Date(
                element.duedate
              ).toLocaleDateString("en-IN", {
                day: "numeric",
                month: "long",
                year: "numeric",
              });
            });
          }
        });

      await axios
        .get(
          `${process.env.VUE_APP_API}/student_assignment/view/?user=${this.userInfo.user.id}&Key=${this.userInfo.token_key}`
        )
        .then((response) => {
          this.submitassignmentInfo = response.data;
          if (Object.keys(this.submitassignmentInfo) == "error") {
            console.log("Null");
          } else {
            this.submitassignmentInfo.data.forEach((element) => {
              element.submit_date = new Date(
                element.submit_date
              ).toLocaleDateString("en-IN", {
                year: "numeric",
                month: "long",
                day: "numeric",
              });
            });
          }
        });
    },

    // student Submit Functions
    async scheduleAssignment(el) {
      let fileVal = el.target.elements.files.files[0];
      let urls = `${process.env.VUE_APP_API}/student_assignment/new/?Key=${this.userInfo.token_key}`;
      this.assignmentPopup = false;
      let formData = new FormData();
      formData.append("file", fileVal);
      formData.append("title", el.target.elements.title.value);
      formData.append(
        "student_description",
        el.target.elements.description.value
      );
      // formData.append("submit_date", el.target.elements.duedate.value);
      formData.append("verify_status", "submitted");
      formData.append("user", this.userInfo.user.id);
      formData.append("assignment_id", this.assignmentId);
      axios
        .post(urls, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response.data.message == "your Assignment added successfully") {
            this.messages = {
              text: "Assignment Submitted successfully",
              type: "success",
            };
          } else {
            this.messages = {
              text: " Event Faild",
              type: "error",
            };
          }
        });
      await this.getdetails();

      setTimeout(() => {
        this.messages = {
          text: "",
          type: "null",
        };
      }, 1500);
    },

    async deleteAssignment(data) {
      this.popupwindow = false;
      await axios
        .delete(
          `${process.env.VUE_APP_API}/assignment_deleted/${data}/?Key=${this.userInfo.token_key}`
        )
        .then((response) => {
          if (response.data.message == "Assignment deleted successfully") {
            this.messages = {
              text: "Assignment Deleted successfully",
              type: "success",
            };
          } else {
            this.messages = {
              text: " Event Faild",
              type: "error",
            };
          }
        });

      await this.getdetails();
      setTimeout(() => {
        this.messages = {
          text: "",
          type: "null",
        };
      }, 1500);
    },
  },
};
</script>

<style scoped>
.heightfix {
  height: calc(100vh - 170px) !important;
}
</style>
