<template>
  <div>
    <Header />

    <div class="py-10 max-h-screen overflow-y-auto">
      <div class="container mx-auto max-w-[1200px] flex flex-col gap-10 px-6">
        <div>
          <h1 class="text-2xl md:text-2xl text-start font-bold text-black">
            Blogs
          </h1>
        </div>

        <!-- Loading -->
        <div v-if="loading" class="flex items-center justify-center min-h-[300px] md:min-h-[400px]">
          <img src="@/assets/newd/loading.gif" alt="Loading..." />
        </div>

        <!-- Blog List -->
        <div v-else-if="this.bloglist.length > 0">
          <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 md:gap-10">
            <div class="rounded-3xl bg-white shadow-lg" v-for="blog in this.bloglist" :key="blog.id">
              <router-link :to="{
                name: 'InstructorBlogDetails',
                params: { blog_id: blog.id },
              }">
                <div class="flex flex-col min-h-[380px] md:min-h-[420px] justify-between gap-3 p-6 relative">
                  <!-- Thumbnail -->
                  <img v-if="blog.thumbnail" class="object-cover object-center h-[200px] md:h-[250px] w-full rounded-lg"
                    :src="blog.thumbnail" alt="Blog Thumbnail" />
                  <img v-else class="object-cover object-center h-[100px] md:h-[150px] w-auto rounded mx-auto"
                    src="@/assets/coding.svg" alt="Default Image" />

                  <!-- Edit Button -->
                  <router-link :to="{
                    name: 'InstructorBlogsEdit',
                    params: { blog_id: blog.id },
                  }" class="absolute top-6 right-6 text-sm bg-blues px-3 py-1 text-white font-bold rounded-md">
                    Edit
                  </router-link>

                  <!-- Blog Details -->
                  <h1 class="font-bold text-lg text-primary leading-6">
                    {{ blog.title }}
                  </h1>
                  <p class="text-blues text-sm font-bold">
                    {{ blog.bolger_id.display_name }}
                  </p>

                  <div class="text-sm text-gray-500 font-medium">
                    {{ formatDate(blog.updated_date) }}
                  </div>

                  <p class="text-black line-clamp-3 text-sm" v-html="blog.content"></p>
                </div>
              </router-link>
            </div>
          </div>

          <!-- Pagination Controls -->
          <div class="flex justify-between items-center flex-wrap mt-6 space-x-2 sm:space-x-3">
            <button
              class="sm:px-5 px-3 py-2 bg-gray-200 rounded hover:bg-gray-400 hover:text-white disabled:text-black disabled:bg-gray-100 disabled:cursor-not-allowed"
              :disabled="currentPage === 1" @click="prevPage">
              &laquo; Previous
            </button>

            <span class="px-4 py-2 bg-white border border-gray-400 text-gray-800 font-semibold rounded">
              {{ currentPage }} / {{ totalPages }}
            </span>

            <button
              class="sm:px-5 px-3 py-2 bg-gray-200 rounded hover:bg-gray-400 hover:text-white disabled:text-black disabled:bg-gray-100 disabled:cursor-not-allowed"
              :disabled="currentPage >= totalPages" @click="nextPage">
              Next &raquo;
            </button>
          </div>
        </div>

        <!-- No Data -->
        <div v-else class="text-lg font-normal text-center">No data found</div>
      </div>
    </div>

  </div>
</template>

<script>
import Header from "@/components/Instructor/Header.vue";

import axios from "axios";
import { mapGetters } from "vuex";

export default {
  components: {
    Header,
  },

  data() {
    return {
      bloglist: [],
      currentPage: 1,
      totalPages: 1,
      perPage: 10,
      loading: true,
    };
  },

  computed: {
    ...mapGetters(["userInfo"]),
  },

  mounted() {
    this.fetchBlogs();
  },

  methods: {
    fetchBlogs() {
      axios
        .get(
          `${process.env.VUE_APP_API}/blog-details/list/?page=${this.currentPage}&Key=${this.userInfo.token_key}`
        )
        .then((res) => {
          if (res.data.data) {
            this.bloglist = res.data.data.data;
            setTimeout(() => {
              this.loading = false;
            }, 1000);

            this.totalPages = Math.ceil(res.data.total / this.perPage);
          }
        })
        .catch((error) => {
          console.error("Error fetching blogs:", error);
        });
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
        this.fetchBlogs();
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.fetchBlogs();
      }
    },
    formatDate(date) {
      return new Date(date).toLocaleDateString("en-IN", {
        day: "numeric",
        month: "long",
        year: "numeric",
      });
    },
  },
};
</script>

<style></style>
