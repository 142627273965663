<template>
  <div>
    <div class="flex" v-if="!isLessonTab">
      <SideBar />

      <div
        class="w-[90%] mx-auto lg:w-full lg:mr-10 mt-10 rounded-3xl overflow-x-auto overflow-y-auto heightfix"
        style="box-shadow: 0px 2px 30px 0px #01b0f926"
      >
        <div class="p-5 lg:p-10 flex flex-col gap-10" v-if="courseDetailInfo">
          <div class="flex flex-col lg:flex-row items-start gap-10">
            <div class="w-full lg:w-[50%]">
              
              <img
                :src="courseDetailInfo[0].thumbnail"
                class="rounded-2xl h-[300px] object-cover w-full"
              />
              
            </div>

            <div class="flex flex-col gap-3 lg:w-[50%] mt-10  w-full">
              <h1 class="text-xl lg:text-3xl text-primary font-bold">
                {{ courseDetailInfo[0].title }}
              </h1>

              <div class="flex items-center gap-10">
                <div class="flex items-center rounded-2xl py-3 gap-2">
                  <img src="@/assets/new/rounded.png" alt="" />
                  <p class="text-xs">
                    {{ courseDetailInfo[0].sections.length }} Units
                  </p>
                </div>
                <div class="flex items-center rounded-2xl py-3 gap-2">
                  <img src="@/assets/new/time.png" alt="" />
                  <p class="text-xs">18h 20m Left</p>
                </div>
              </div>

              <div>
                <!-- {{ this.lessonInfo.results }} -->
                <button v-if="this.lessonInfo.results"
                  class="bg-blues text-white font-semibold rounded-full py-2 px-5 text-xs"
                  @click="
                    setUrlTap(
                      this.lessonInfo.results[0].video_url,
                      0,
                      this.lessonInfo.results[0]
                    )
                  "
                >
                  Continue Learning
                </button>

                <button v-else
                  class=" text-blues font-semibold rounded-full py-2 text-xs"
                >
                  No Lessons
                </button>

              </div>

              <div class="flex items-center justify-between w-full ">
                <div>
                  <p class="text-sm" v-if="percentage.completion_percentage < 100">In progress</p>
                  <p v-else class="text-sm">Complete</p>
                </div>
                <div>
                  <p class="text-sm">
                    {{ percentage.completion_percentage }}%
                  </p>
                </div>
              </div>

              <div class="relative w-full bg-gray-200 rounded-full h-4">
                <div
                  class="absolute left-0 top-0 h-4 bg-blues rounded-full transition-all duration-300"
                
                :style="{ width: percentage.completion_percentage + '%' }"
                ></div>
              </div>
            </div>
          </div>

          <div class="flex flex-col gap-4 px-2">
            <h2 class="text-2xl text-primary font-bold">Course Description</h2>
            <p>{{ this.courseDetailInfo[0].short_description }}</p>
          </div>

          <div class="flex flex-col gap-10 px-2">
            <h2 class="text-2xl text-primary font-bold">Course Content</h2>
            <div class="flex flex-col gap-4">
              <div v-for="section in sectionInfo.data" :key="section">
                <div
                  class="bg-blues flex items-center rounded-2xl px-10 py-1 gap-5 justify-between cursor-pointer"
                  @click="courseSectionToggle(section.id)"
                >
                  <p class="font-bold text-sm py-3 text-white">
                    {{ section.title }}
                  </p>
                  <img
                    src="@/assets/new/upw.png"
                    v-if="activeCourseMenu == section.id"
                    style="height: 8px !important"
                    alt=""
                  />
                  <img
                    src="@/assets/new/downw.png"
                    v-if="activeCourseMenu != section.id"
                    style="height: 8px !important"
                    alt=""
                  />
                </div>

                <div
                  v-for="(lesson, index) in lessonInfo.results"
                  :key="lesson"
                  v-if="activeCourseMenu == section.id"
                >
                  <div
                    class="my-5 flex flex-col gap-4"
                    v-if="section.id == lesson.section_id"
                  >
                    <div
                      v-if="lesson.section_participant.status == true"
                      class="bg-gray-200 flex items-center rounded-2xl px-10 py-2 gap-3 cursor-pointer"
                      @click="setUrlTap(lesson.video_url, index, lesson)"
                    >
                      <!-- <div
                        class="w-[10px] h-[10px] bg-black rounded-full"
                      ></div> -->
                      <div class="w-[16px]">
                        <img
                          src="@/assets/mdi_tick-circle-outline.png"
                          class="object-cover w-4 h-4 font-semibold"
                        />
                      </div>
                      <p
                        class="text-sm py-1"
                        :class="{
                          'text-blues font-bold': activeIndex === index,
                          'text-black': activeIndex !== index,
                        }"
                      >
                        {{ lesson.title }}
                      </p>
                    </div>
                    <div
                      v-else
                      class="bg-gray-200 flex items-center rounded-2xl px-10 py-2 gap-3 cursor-pointer"
                      @click="setUrlTap(lesson.video_url, index, lesson)"
                    >
                      <div class="w-[16px]">
                        <div
                          class="w-[12px] mx-auto h-[12px] rounded-full border-2"
                          :class="{
                            ' bg-blue-400 border-blue-500 border-none':
                              activeIndex === index,
                            'border-[#7c7d7f] ': activeIndex !== index,
                          }"
                        ></div>
                      </div>
                      <p
                        class="text-sm py-1"
                        :class="{
                          'text-blues font-bold': activeIndex === index,
                          'text-black': activeIndex !== index,
                        }"
                      >
                        {{ lesson.title }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="flex" v-if="isLessonTab">
      <!-- <SideBar /> -->

      <div
        class="lg:flex fixed w-[80%] bg-white lg:w-[35%] xl:w-[30%] 2xl:w-[25%] lg:relative z-50 m-10 p-10 rounded-3xl"
        :class="{ hidden: !isMobile }"
        style="box-shadow: 0px 2px 30px 0px #01b0f926"
      >
        <div
          v-if="courseDetailInfo"
          class="flex flex-col gap-5 relative w-full h-full overflow-y-auto heighttab"
        >
          <!-- <div v-for="section in sectionInfo.data" :key="section">
            <div
              class="activebg flex items-center rounded-2xl py-1 gap-5 justify-between cursor-pointer"
              @click="courseSectionToggle(section.id)"
            >
              <p class="font-bold text-sm pt-3 text-black">
                {{ section.title }}
              </p>
              <img
                src="@/assets/new/upw.png"
                v-if="activeCourseMenu == section.id"
                style="height: 8px !important"
                alt=""
              />
              <img
                src="@/assets/new/downw.png"
                v-if="activeCourseMenu != section.id"
                style="height: 8px !important"
                alt=""
              />
            </div>

            <div v-for="(lesson, index) in lessonInfo.results" :key="index">
              <div
                class="my-5 flex flex-col gap-4"
                v-if="section.id == lesson.section_id"
              >
                <div
                  class="flex items-start rounded-2xl gap-3 cursor-pointer"
                  @click="setUrlTap(lesson.video_url, index, lesson)"
                >

                  <div class="w-[10px] mt-2 h-[10px] bg-black rounded-full"> 
                  </div>

                  

                 
                  <p class="font-bold text-sm py-1 text-black">
                    {{ lesson.title }}
                  </p>
                </div>
              </div>
            </div>
          </div> -->

          <div v-for="section in sectionInfo.data" :key="section">
            <div
              class="text-black flex items-center rounded-2xl px-3 py-1 gap-5 justify-between cursor-pointer"
              @click="courseSectionToggle(section.id)"
            >
              <p class="font-bold text-sm py-3 text-black">
                {{ section.title }}
              </p>
              <img
                src="@/assets/new/up.png"
                v-if="activeCourseMenu == section.id"
                style="height: 6px !important"
                alt=""
              />
              <img
                src="@/assets/new/down.png"
                v-if="activeCourseMenu != section.id"
                style="height: 6px !important"
                alt=""
              />
            </div>

            <div
              v-for="(lesson, index) in lessonInfo.results"
              :key="lesson"
              v-if="activeCourseMenu == section.id"
            >
              <div
                class="my-5 flex flex-col gap-4"
                v-if="section.id == lesson.section_id"
              >
                <div
                  v-if="lesson.section_participant.status == true"
                  class="flex items-center rounded-2xl px-3 py-2 gap-3 cursor-pointer"
                  @click="setUrlTap(lesson.video_url, index, lesson)"
                >
                  <div class="w-[16px]">
                    <div class="w-[18px]">
                      <img
                        src="@/assets/mdi_tick-circle-outline.png"
                        class="object-cover w-4 h-4 font-semibold"
                      />
                    </div>
                  </div>
                  <p
                    class="text-sm py-1 pl-1"
                    :class="{ 'text-blues font-bold': activeIndex === index }"
                  >
                    {{ lesson.title }}
                  </p>
                </div>

                <div
                  v-else
                  class="flex items-center rounded-2xl px-3 py-2 gap-3 cursor-pointer"
                  @click="setUrlTap(lesson.video_url, index, lesson)"
                >
                  <div class="w-[18px]">
                    <div class="w-[16px]">
                      <div
                        class="w-[12px] mx-auto h-[12px] rounded-full border-2"
                        :class="{
                          ' bg-blue-400 border-blue-500 border-none':
                            activeIndex === index,
                          'border-gray-400 font-bold': activeIndex !== index,
                        }"
                      ></div>
                    </div>
                  </div>
                  <p
                    class="text-sm py-1 pl-1"
                    :class="{ 'text-blues font-bold': activeIndex === index }"
                  >
                    {{ lesson.title }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="w-[90%] mx-auto lg: lg:mr-10 mt-10 rounded-3xl overflow-x-auto overflow-y-auto heightfix"
        style="box-shadow: 0px 2px 30px 0px #01b0f926"
      >
       <!-- laptop -->
       <div class="hidden lg:block">
        <div class="flex items-center pt-10 px-10 justify-between ">
          <button
            class="bg-blues text-xs text-white px-6 py-1.5 rounded-2xl"
            @click="isLessonTab = !this.isLessonTab"
          >
            Back
          </button>

          <h1 class=" text-lg  2xl:text-2xl font-semibold w-[50%]  mx-auto text-center 2xl:w-[60%] line-clamp-3">
            {{ courseDetailInfo[0].title }}
          </h1>

          <div class="flex items-center gap-10">
            <div
              class="flex items-center gap-4 cursor-pointer"
              v-if="this.activeIndex != 0"
              @click="prevCourse()"
            >
              <img src="@/assets/new/left.png" class="h-3" />
              <p class="text-base">Previous</p>
            </div>
            <div
              class="flex items-center gap-4 cursor-pointer"
              v-if="this.activeIndex != this.lessonInfo.results.length - 1"
              @click="nextCourse()"
            >
              <p class="text-base">Next</p>
              <img src="@/assets/new/right.png" class="h-3" />
            </div>
          </div>
        </div>
        </div>
        <!-- mobile -->
        <div class="flex flex-col gap-4 items-center pt-10 px-5  justify-between block lg:hidden">
          <div class="flex flex-col gap-2 justify-between w-full ">
          <div class="flex justify-end">
          <button
            class="bg-blues text-xs text-white px-6 py-1.5 rounded-2xl"
            @click="isLessonTab = !this.isLessonTab"
          >
            Back
          </button>
          </div>
          <h1 class="text-xl font-semibold text-wrap">
            {{ courseDetailInfo[0].title }}
          </h1>
          
          </div>

          <div class="flex items-center gap-10">
            <div
              class="flex items-center gap-4 cursor-pointer"
              v-if="this.activeIndex != 0"
              @click="prevCourse()"
            >
              <img src="@/assets/new/left.png" class="h-3" />
              <p class="text-base">Previous</p>
            </div>
            <div
              class="flex items-center gap-4 cursor-pointer"
              v-if="this.activeIndex != this.lessonInfo.results.length - 1"
              @click="nextCourse()"
            >
              <p class="text-base">Next</p>
              <img src="@/assets/new/right.png" class="h-3" />
            </div>
          </div>
        </div>

        <div class="p-5 lg:p-10 flex flex-col gap-10" v-if="selectedlessonData">
          <div class="flex flex-col gap-4">
            <div class="flex items-center justify-between w-full">
              <h1 class="text-lg lg:text-2xl font-semibold">
                {{ selectedlessonData.title }}
              </h1>

              <div class="flex items-center justify-end gap-10">
                <div v-if="lessonCompletedData == null">
                  <button
                    v-if="showbutton"
                    class="bg-blues text-xs text-white block px-6 py-1.5 rounded-2xl"
                    @click="
                      completeUnit(
                        selectedlessonData.section_id,
                        selectedlessonData.id
                      )
                    "
                  >
                    Complete
                  </button>
                </div>

                <div v-else>
                  <button
                    class="bg-green-700 text-white text-xs px-4 py-2 rounded-2xl"
                  >
                    Lesson Completed
                  </button>
                </div>
              </div>
            </div>

            <div class="flex items-center gap-10">
              <div class="flex items-center rounded-2xl py-3 gap-2">
                <img src="@/assets/new/time.png" alt="" />
                <p class="text-xs pt-2">
                  {{ selectedlessonData.duration }} min
                </p>
              </div>
            </div>
          </div>

          <div v-if="selectedlessonData.video_url != null">
            <iframe
              v-if="selectedlessonData.video_url != ''"
              class="w-full h-[600px] mx-auto rounded p-3"
              :src="selectedlessonData.video_url"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            >
            </iframe>

            <!-- <video ref="videoPlayer" width="600" controls>
              <source :src="selectedlessonData.video_url" type="video/mp4">
              <source :src="selectedlessonData.video_url" type="video/mkv">
              Your browser does not support the video tag.
            </video> -->

            <!-- <video controls class="w-full h-[600px]">
              <source :src="selectedlessonData.video_url" type="video/mp4">
              <source :src="selectedlessonData.video_url" type="video/webm">
              <source :src="selectedlessonData.video_url" type="video/ogg">
              <source :src="selectedlessonData.video_url" type="video/ogg">
              <source :src="selectedlessonData.video_url" type="video/avi">
              <source :src="selectedlessonData.video_url" type="video/quicktime">
              <source :src="selectedlessonData.video_url" type="video/x-matroska">
              <source :src="selectedlessonData.video_url" type="video/x-flv">
            </video> -->
          </div>

          <div class="flex flex-col gap-4">
            <div
              v-html="selectedlessonData.content"
              class="w-full video-container"
            ></div>

            <iframe
              v-if="
                selectedlessonData.pdf_file != null &&
                selectedlessonData.pdf_file != ''
              "
              :src="convertHttpToHttps(selectedlessonData.pdf_file)"
              class="h-[400px]"
            ></iframe>

            <p class="text-sm">{{ selectedlessonData.keyworeds }}</p>

            <audio
              controls
              v-if="
                selectedlessonData.audio_file != null &&
                selectedlessonData.audio_file != ''
              "
            >
              <source :src="selectedlessonData.audio_file" type="audio/ogg" />
              <source :src="selectedlessonData.audio_file" type="audio/mpeg" />
              Your browser does not support the audio element.
            </audio>

            <!-- {{ selectedlessonData.quizzes[0].quiz.id }} -->

            <!-- <div v-if="lessonCompletedData == null"> -->

            <div>
              <div v-if="selectedlessonData.quizzes">
                <div v-if="selectedlessonData.quizzes != null">
                  <div v-if="selectedlessonData.quizzes[0]">
                    <div v-if="selectedlessonData.quizzes[0].quiz.id">

                      <!-- <keep-alive>
                        <QuizTest
                          v-if="selectedlessonData.quizzes[0].quiz.id"
                          :key="quizKey"
                          :course_id="this.course_id"
                          :quiz_id="selectedlessonData.quizzes[0].quiz.id"
                          :taker_id="selectedlessonData.course_quiz_takers_id"
                          @pushLessonComplete="receiveLessonComplete"
                        />
                      </keep-alive> -->

                      <QuizTest 
                          v-if="selectedlessonData.quizzes[0].quiz.id"
                          :key="quizKey"
                          :course_id="this.course_id"
                          :quiz_id="selectedlessonData.quizzes[0].quiz.id"
                          :taker_id="selectedlessonData.course_quiz_takers_id"
                          @pushLessonComplete="receiveLessonComplete"
                        />

                      <!-- <QuizTest v-once
                          v-if="selectedlessonData.quizzes[0].quiz.id"
                          :key="quizKey"
                          :course_id="this.course_id"
                          :quiz_id="selectedlessonData.quizzes[0].quiz.id"
                          :taker_id="selectedlessonData.course_quiz_takers_id"
                          @pushLessonComplete="receiveLessonComplete"
                        /> -->
                        
                        <!-- <KeepAlive>
                          <component :is="QuizTest"></component>
                        </KeepAlive> -->

                    </div>
                  </div>
                </div>
              </div>
            </div> 

            <!-- <div v-if="currentQuizDetails != null"> 
               <p>Data check :: {{ currentQuizDetails }}  
                <p>{{ currentQuizDetails.quiztakers_set }} {{ currentQuizDetails.qualify_certificate }} </p>             
            </div> -->

            <!-- <QuizTest
                        v-if="selectedlessonData.quizzes[0].quiz.id"
                        :key="quizKey"
                        :course_id="this.course_id"
                        :quiz_id="selectedlessonData.quizzes[0].quiz.id"
                        :taker_id="selectedlessonData.course_quiz_takers_id"
                      /> -->

            <!-- <div v-else class="flex flex-col gap-6 items-center justify-center">
              <div
                v-if="dataval != null"
                class="text-black font-bold p-10 rounded-2xl border text-lg"
              >
                This Lesson Quiz is Completed.
                <div class="flex gap-4"></div>
              </div>

      

              <div class="flex items-center gap-10">
                <div
                  class="flex items-center gap-4 cursor-pointer"
                  v-if="this.activeIndex != 0"
                  @click="prevCourse()"
                >
                  <img src="@/assets/new/left.png" class="h-3" />
                  <p class="text-base">Previous</p>
                </div>
                <div
                  class="flex items-center gap-4 cursor-pointer"
                  v-if="this.activeIndex != this.lessonInfo.results.length - 1"
                  @click="nextCourse()"
                >
                  <p class="text-base">Next</p>
                  <img src="@/assets/new/right.png" class="h-3" />
                </div>
              </div>
            </div> -->

            <!-- {{ selectedlessonData.course_quiz_takers_id }} Taker id:: -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SideBar from "@/components/Student/SideBar.vue";
import axios from "axios";
import { mapGetters } from "vuex";
import QuizTest from "@/views/Student/Dashboard/QuizTestComp.vue";

export default {
  components: {
    SideBar,
    QuizTest,
  },
  data() {
    return {
      messageVal: null,
      totalmessage: null,
      studentList: "",
      selectedStudent: null,
      ChatpopUp: false,
      instructorChat: [
        {
          id: "",
          display_name: "",
        },
      ],
      sectionInfo: "",
      lessonInfo: "",
      videoLink: "",
      activeIndex: null,
      isLessonTab: false,
      selectedlessonData: null,
      courseDetailInfo: null,
      progress: 90,
      activeCourseMenu: null,
      lessonCompletedData: null,
      showbutton: false,
      dataval: "",
      percentage: [],
      // new
      quiz_details: [],
      currentQuizDetails: null,
    };
  },

  props: ["course_id"],

  async created() {
    this.detailsgets();
    this.progressget();
    await axios
      .get(`${process.env.VUE_APP_API}/courses/?id=${this.course_id}`)
      .then((response) => {
        this.courseDetailInfo = response.data.data;
      });

    await axios
      .get(`${process.env.VUE_APP_API}/coursesdata/${this.course_id}/`)
      .then((response) => {
        this.instructorChat[0].id = response.data.user_id;
      });
    await axios
      .get(`${process.env.VUE_APP_API}/courses/?id=${parseInt(this.course_id)}`)
      .then((response) => {
        this.instructorChat[0].display_name = response.data.data[0].instructor;
      });

    // this.getCompletedLesson();
    // setTimeout(()=>{
    //   if(this.selectedlessonData.quizzes != null){
    //     if(this.selectedlessonData.quizzes[0]){
    //       if(this.selectedlessonData.quizzes[0].quiz.id){
    //         this.getQuiz(this.selectedlessonData.quizzes[0].quiz.id);
    //       }
    //     }
    //   }
    // }, 100)
    

  },

  mounted() {
    // setTimeout(()=>{
    //   if(this.selectedlessonData.quizzes[0]){
    //     if(this.selectedlessonData.quizzes[0].quiz){
    //       this.quizPreview(this.selectedlessonData.quizzes[0].quiz.id);
    //     }
    //   }
    // }, 1000)

    // fetch(videoAPIUrl)
    //   .then(response => response.blob())
    //   .then(blob => {
    //     const videoURL = URL.createObjectURL(blob);
    //     videoPlayer.src = videoURL;
    //   })
    //   .catch(error => {
    //     console.error("Error fetching the video:", error);
    //   });

    // setTimeout(() => {
    //   this.showbutton = true;
    // }, 3000);
  },

  computed: {
    ...mapGetters(["userInfo"]),
  },

  methods: {

    // async getQuiz(quiz_id) {
    //   if (this.userInfo) {
    //     let token = this.userInfo.token_key;
    //     console.log(
    //       `${process.env.VUE_APP_API}/course_quizzes_detail/${this.course_id}/?Key=${token}`
    //     );
    //     await axios
    //       .get(
    //         `${process.env.VUE_APP_API}/course_quizzes_detail/${this.course_id}/?Key=${token}`
    //       )
    //       .then((response) => {
    //         if (response.data.error == "No Quiz found for this course") {
    //           console.log("No quiz found for this course");
           
    //         } else {
    //           this.quiz_details = response.data.data;
    //           this.currentQuizDetails = this.quiz_details.find((x)=> x.quiz.id === quiz_id);
    //           console.log(this.quiz_details.find((x)=> console.log(x, "quizz :: ")));
    //         }
    //       });
    //   }
    // },

    // async ReturnquizDataResult(id) {
    //   let token = this.userInfo.token_key;
    //   await axios
    //     .get(
    //       `${process.env.VUE_APP_API}/quiztaker__quizzes_pk/${id}/?Key=${token}`
    //     )
    //     .then((response) => {
    //       this.dataval = response.data.data;
    //       if(this.dataval){
    //         return true;
    //       }
    //       else {
    //         return false;
    //       }
    //     });
    // },

    async quizPreview(id) {
      let token = this.userInfo.token_key;
      await axios
        .get(
          `${process.env.VUE_APP_API}/quiztaker__quizzes_pk/${id}/?Key=${token}`
        )
        .then((response) => {
          this.dataval = response.data.data;
          console.log("response::", response.data.data);
        });
    },

    convertHttpToHttps(url) {
      if (url.startsWith("http://")) {
        return url.replace("http://", "https://");
      }
      return url;
    },

    async getCompletedLesson(unit_id, lesson_id) {

      await axios
        .get(
          `${process.env.VUE_APP_API}/completed-lesson-tracking/${this.userInfo.user.id}/${lesson_id}/?Key=${this.userInfo.token_key}`
        )
        .then((response) => {
          if (response.data.status == 200) {
            // console.log(response.data.data, "data:::");
            this.lessonCompletedData = "";
          } else {
            console.log("Something went wrong::");
          }
          // this.lessonInfo = response.data;
          // if (Object.keys(this.lessonInfo) == "error") {
          //   this.lessonCount = 0;
          // } else {
          //   this.lessonCount = this.lessonInfo.count;
          // }
        });
    },

    // async getCompletedLesson(unit_id, lesson_id) {
    //   // http://127.0.0.1:8000/completed-lesson-tracking/1/1/

    //   await axios
    //     .get(
    //       `${process.env.VUE_APP_API}/lesson-tracking-get/${this.userInfo.user.id}/${unit_id}/${lesson_id}/?Key=${this.userInfo.token_key}`
    //     )
    //     .then((response) => {
    //       if (response.data.status == 200) {
    //         // console.log(response.data.data, "data:::");
    //         this.lessonCompletedData = "";
    //       } else {
    //         console.log("Something went wrong::");
    //       }
    //       // this.lessonInfo = response.data;
    //       // if (Object.keys(this.lessonInfo) == "error") {
    //       //   this.lessonCount = 0;
    //       // } else {
    //       //   this.lessonCount = this.lessonInfo.count;
    //       // }
    //     });
    // },

    receiveLessonComplete(){
      this.completeUnit(this.selectedlessonData.section_id, this.selectedlessonData.id);
    },

    async completeUnitStart(unit_id, lesson_id) {
      await axios
        .post(
          `${process.env.VUE_APP_API}/lesson-tracking/${this.userInfo.user.id}/${unit_id}/${lesson_id}/?Key=${this.userInfo.token_key}`,
          {}
        )
        .then((response) => {
          if (response) {
            console.log(response);
            this.getCompletedLesson(unit_id, lesson_id);
            this.detailsgets();
            this.progressget();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async completeUnit(unit_id, lesson_id) {
      await axios
        .post(
          `${process.env.VUE_APP_API}/lesson-tracking/${this.userInfo.user.id}/${unit_id}/${lesson_id}/?Key=${this.userInfo.token_key}&status=true`,
          {}
        )
        .then((response) => {
          if (response) {
            console.log(response);
            this.getCompletedLesson(unit_id, lesson_id);
            this.detailsgets();
            this.progressget();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    courseSectionToggle(id) {
      if (this.activeCourseMenu == id) {
        this.activeCourseMenu = null;
      } else {
        this.activeCourseMenu = id;
      }
    },

    prevCourse() {
      this.showbutton = false;
      this.lessonCompletedData = null;
      this.selectedlessonData = null;
      this.quizKey++;
      if (this.activeIndex > 0) {
        this.activeIndex = this.activeIndex - 1;
        this.selectedlessonData = this.lessonInfo.results[this.activeIndex];
        this.videoLink = this.lessonInfo.results[this.activeIndex].video_url;
        this.setActiveSection();
      }
      // this.getCompletedLesson(this.selectedlessonData.id);
       this.getCompletedLesson(this.selectedlessonData.section_id, this.selectedlessonData.id);
       if(this.selectedlessonData.section_participant.status == null){
        this.completeUnitStart(this.selectedlessonData.section_id,this.selectedlessonData.id);
      }
      setTimeout(() => {
        this.showbutton = true;
      }, 16000);
      this.getQuizDataList();
    },

    nextCourse() {
      this.showbutton = false;
      this.lessonCompletedData = null;
      this.selectedlessonData = null;
      this.quizKey++;
      if (this.activeIndex < this.lessonInfo.results.length - 1) {
        this.activeIndex = this.activeIndex + 1;
        this.selectedlessonData = this.lessonInfo.results[this.activeIndex];
        this.videoLink = this.lessonInfo.results[this.activeIndex].video_url;
        this.setActiveSection();
      }
      this.getCompletedLesson(this.selectedlessonData.section_id,this.selectedlessonData.id);
      if(this.selectedlessonData.section_participant.status == null){
        this.completeUnitStart(this.selectedlessonData.section_id,this.selectedlessonData.id);
      }
      setTimeout(() => {
        this.showbutton = true;
      }, 16000);
      this.getQuizDataList();
    },

    setUrlTap(url, index, lesson) {
      this.showbutton = false;
      this.selectedlessonData = null;
      this.lessonCompletedData = null;
      this.quizKey++;
      this.isLessonTab = true;
      this.videoLink = url;
      this.activeIndex = index;
      this.selectedlessonData = lesson;
      this.setActiveSection();
      if(this.selectedlessonData.section_participant.status == null){
        this.completeUnitStart(lesson.section_id, lesson.id);
      }
      // this.completeUnitStart(lesson.section_id, lesson.id);
      this.getCompletedLesson(lesson.section_id, lesson.id);

      setTimeout(() => {
        this.showbutton = true;
      }, 16000);
      this.getQuizDataList();
    },

    setUrl(url, index, lesson) {
      this.showbutton = false;
      this.selectedlessonData = null;
      this.lessonCompletedData = null;
      this.quizKey++;
      this.videoLink = url;
      this.activeIndex = index;
      this.selectedlessonData = lesson;
      this.setActiveSection();
      // this.completeUnitStart(lesson.section_id, lesson.id);
      if(this.selectedlessonData.section_participant.status == null){
        this.completeUnitStart(lesson.section_id, lesson.id);
      }
      this.getCompletedLesson(lesson.section_id, lesson.id);
      setTimeout(() => {
        this.showbutton = true;
      }, 16000);
      this.getQuizDataList();
    },

    getQuizDataList(){
      setTimeout(()=>{
        // if(this.selectedlessonData.quizzes != null){
        //   if(this.selectedlessonData.quizzes[0]){
        //     if(this.selectedlessonData.quizzes[0].quiz.id){
        //       this.getQuiz(this.selectedlessonData.quizzes[0].quiz.id);
        //     }
        //   }
        // }
      }, 300)
      
    },

    setActiveSection() {
      const activeSection =
        this.lessonInfo.results[this.activeIndex].section_id;
      this.activeCourseMenu = activeSection;
    },

    async detailsgets() {
      await axios
        .get(
          `${process.env.VUE_APP_API}/section/?course_name=${this.course_id}`
        )
        .then((response) => {
          this.sectionInfo = response.data;
        });

      await axios
        .get(
          `${process.env.VUE_APP_API}/lessons/?Key=${this.userInfo.token_key}&course_name=${this.course_id}`
        )
        .then((response) => {
          this.lessonInfo = response.data;
          if (Object.keys(this.lessonInfo) == "error") {
            this.lessonCount = 0;
          } else {
            this.lessonCount = this.lessonInfo.count;
          }
        });
    },
    async progressget() {
      await axios
        .get(
          `${process.env.VUE_APP_API}/user-course-list-lesson-percentage/?id=${this.course_id}&Key=${this.userInfo.token_key}`
        )
        .then((response) => {
          if (response.data.data) {
            this.percentage = response.data.data[0];
          }
        });
    },
  },
};
</script>

<style scoped>
.heighttab {
  height: calc(100vh - 250px) !important;
}

.heightfix {
  height: calc(100vh - 170px) !important;
}
:deep(.video-container iframe) {
  width: 100% !important;
  height: auto !important;
  aspect-ratio: 16 / 9;
  max-height: 500px;
}
</style>
