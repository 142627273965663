<template>
  <div>
    <!-- <Header /> -->

    <div class="flex">
      <SideBar />

      <div
        class="w-[90%] mx-auto lg:w-full lg:mr-10 mt-10 rounded-3xl overflow-x-auto overflow-y-auto heightfix"
        style="box-shadow: 0px 2px 30px 0px #01b0f926"
      >
        <table class="table-auto w-full overflow-x-auto">
          <thead>
            <tr>
              <th
                class="px-4 lg:px-7 py-8 border-b text-center border-r text-blues"
              >
                Sl.No
              </th>
              <th
                class="px-4 lg:px-7 py-8 border-b text-center border-r text-blues whitespace-nowrap"
              >
                Quiz Name
              </th>
              <th
                class="px-4 lg:px-7 py-8 border-b text-center border-r text-blues whitespace-nowrap"
              >
                Mark
              </th>
              <th
                class="px-4 lg:px-7 py-8 border-b text-center border-r text-blues whitespace-nowrap"
              >
                Date
              </th>
            </tr>
          </thead>
          <tbody v-if="loading">
            <tr class="w-full">
              <td colspan="4" class="text-center py-6">
                <div class="w-full flex justify-center items-center">
                  <img
                    src="@/assets/newd/loading.gif"
                    alt="Loading..."
                    class=""
                  />
                </div>
              </td>
            </tr>
          </tbody>
          <tbody v-else-if="reportlist.data">
            <tr v-for="(el, index) in this.reportlist.data" :key="el">
              <td class="px-4 lg:px-7 py-8 border-b text-center border-r">
                <div class="flex items-center justify-center">
                  <div class="ml-3">
                    <p class="text-gray-900 whitespace-no-wrap">
                      {{ index + 1 }}
                    </p>
                  </div>
                </div>
              </td>
              <td class="px-4 lg:px-7 py-8 border-b text-center border-r whitespace-nowrap">
                <p
                  v-if="el.quiz"
                  class="text-center text-gray-900 whitespace-no-wrap"
                >
                  {{ el.quiz.name }}
                </p>
                <p v-else class="text-center text-gray-900 whitespace-nowrap">
                  empty
                </p>
              </td>
              <td class="px-4 lg:px-7 py-8 border-b text-center border-r whitespace-nowrap">
                {{ el.quiz.quiztakers_set.score }}
              </td>

              <td class="px-4 lg:px-7 py-8 border-b text-center border-r whitespace-nowrap">
                {{
                  new Date(
                    el.quiz.quiztakers_set.finished_at
                  ).toLocaleDateString("en-IN", {
                    day: "numeric",
                    month: "long",
                    year: "numeric",
                  })
                }}
                -
                {{
                  new Date(
                    el.quiz.quiztakers_set.finished_at
                  ).toLocaleTimeString("en-IN")
                }}
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <!-- <tr>
              <td class="px-4 lg:px-7 py-8 border-b text-center border-r">
                empty
              </td>
              <td class="px-4 lg:px-7 py-8 border-b text-center border-r">
                empty
              </td>
              <td class="px-4 lg:px-7 py-8 border-b text-center border-r">
                empty
              </td>
              <td class="px-4 lg:px-7 py-8 border-b border-r text-center">
                empty
              </td>
            </tr> -->
            <tr class="w-full">
              <td colspan="4" class="text-center py-6">
                <!-- <div class="w-full flex justify-center items-center">
                  <img
                    src="@/assets/newd/loading.gif"
                    alt="Loading..."
                    class=""
                  />
                </div> -->
                NO Data Found
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Student/Header.vue";
import SideBar from "@/components/Student/SideBar.vue";

import axios from "axios";
import { mapGetters } from "vuex";

export default {
  components: {
    Header,
    SideBar,
  },
  data() {
    return {
      reportlist: "",
      loading: true,
    };
  },
  created() {
    
    this.getQuiz();
  },

  props: ["course_id"],

  computed: {
    ...mapGetters(["userInfo"]),
  },

  async created() {
    await axios
      .get(
        `${process.env.VUE_APP_API}/course_quizzes_detail/${this.course_id}/?Key=${this.userInfo.token_key}`
      )
      .then((res) => {
        if (res) {
          this.loading = false;
          this.reportlist = res.data;
          
          console.log(this.reportlist);
        } else if(res.data.status == 400) {
          this.loading = false;
        } 
      });
  },
};
</script>

<style scoped>
.heightfix {
  height: calc(100vh - 170px) !important;
}
</style>
