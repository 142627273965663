<template>
  <div class="min-h-screen relative">
    <Header />

    <!-- <FlashMessage v-if="this.messages.text != ''" :message="this.messages" /> -->

    <div
      class="min-h-screen container mx-auto max-w-[1200px] w-full mb-10 py-7 flex flex-col gap-10 px-4 md:px-6 lg:gap-16"
    >
      <div>
        <div class="flex flex-row items-center justify-between">
          <p class="my-6 text-3xl font-semibold text-gray-900">My Community</p>
          <!-- <router-link :to="{ name: 'Community' }" class="text-sm sm:text-md text-[#0F172A] font-medium">View
            All</router-link> -->
        </div>

        <div
          v-if="loading"
          class="flex items-center justify-center"
          style="height: calc(100vh - 350px) !important"
        >
          <img src="@/assets/newd/loading.gif" alt="" />
        </div>

        <div v-else class="mt-5 pb-10">


          <div v-if="this.courseDetails.length>0">
              <div
                v-if="this.courseDetails[0]"
                class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4"
              >
                <div
                  v-for="course in this.courseDetails[0].course"
                  :key="course"
                  class="bg-white shadow-lg rounded-lg p-4 sm:p-6 h-auto"
                >
                  <router-link
                    :to="{ name: 'StudentForums', params: { course_id: course.id } }"
                    class="cursor-pointer"
                  >
                    <div class="relative flex justify-center items-center">
                      <img
                        v-if="course.thumbnail"
                        class="object-cover h-[180px] sm:h-[200px] w-full rounded-lg"
                        :src="course.thumbnail"
                        alt="Course Thumbnail"
                      />
                      <img
                        v-else
                        class="object-cover h-24 w-full rounded-lg"
                        src="@/assets/coding.svg"
                        alt="Placeholder"
                      />
                    </div>
                    <div class="text-center flex flex-col gap-2 mt-2">
                      <p
                        class="text-sm sm:text-base text-gray-600 font-semibold text-left truncate"
                      >
                        {{ course.title }}
                      </p>
                      <!-- <p class="text-xs sm:text-sm text-gray-600 text-left">
                        {{ course.sections.length }} Units
                      </p> -->
                      <p
                        
                        class="mt-3 cursor-pointer py-2 text-xs sm:text-sm rounded-lg border border-[#0085DB] text-[#0F172A] font-medium text-center"
                      >
                      Open Community
                      </p>
                    </div>
                  </router-link>
                </div>
              </div>
          </div>
          <div
            v-else
            class="flex flex-col justify-center"
            style="height: calc(100vh - 350px) !important"
          >
            <p class="flex justify-center">No Courses</p>
          </div>

          <!--  <div v-for="course in courseDetails" :key="course.id" class="h-[380px] bg-white shadow-lg rounded-lg p-6">
            
            <router-link
              :to="{ name: 'StudentHome', params: { slug: userInfo.slug_id, course_id: course.id, instructor_id: course.instructor, course_name: course.title } }">
              <div class="relative flex justify-center items-center">

                <img v-if="course.thumbnail" class="h-[200px] w-full rounded-lg"
                  :src="course.thumbnail" alt="" />
                <img v-else class="h-24 w-52 rounded-lg" src="@/assets/coding.svg" alt="" />
              </div>

              <div class="text-center flex flex-col gap-2 mt-2 justify-center">
                <p class="text-sm text-gray-600 font-semibold text-left truncate">{{ course.title }}</p>
                <p class="text-xs text-gray-500 text-left truncate">{{ course.sections.length }} Members</p>

                <div class="my-3">
                  <router-link
                    :to="{ name: 'StudentForums', params: { course_id: course.id } }"
                    class="block text-center py-2 text-xs w-full rounded-lg border border-[#0085DB] text-[#0F172A] font-medium hover:bg-blue-900 hover:text-white transition duration-300">
                    Open Community
                  </router-link>
                </div>
              </div>
            </router-link> 
          </div> -->

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
// import FlashMessage from "@/components/flashMessage.vue";
import { mapGetters } from "vuex";
import Header from "@/components/Student/Header.vue";
export default {
  data() {
    return {
      profileInfo: null,
      popUp: false,
      updateDetails: null,
      selectedFile: null,
      tnxDetails: "",
      profile: false,
      // messages: {
      //   text: "",
      //   type: "null",
      // },
      selectedcontent: "Courses",
      courseDetails: null,
      boughtcourse: null,
      loading:true,
      courseDetails: null,
    };
  },

  components: {
    Header,
    // FlashMessage,
  },

  computed: mapGetters(["userInfo"]),

  async created() {
  

    await axios
      .get(
        `${process.env.VUE_APP_API}/studentsbuycoursedetails/?Key=${this.userInfo.token_key}`
      )
      .then((response) => {
        this.courseDetails = response.data.data;
         setTimeout(() => {
              this.loading = false;
            }, 600);
      });

    // await axios
    //   .get(`${process.env.VUE_APP_API}/courses/?format=json`)
    //   .then((response) => {
    //     this.courseDetails = response.data.data;
    //      this.loading = false;
    //     }),

  },

  methods: {

  },
};
</script>
<style scoped>
</style>
