<template>
  <router-view/>
</template>

<script setup lang="js"> 

import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";


const firebaseConfig = {
  apiKey: "AIzaSyCXdd35P5bRank6PMcDCBo_zAeW9E7lQmA",
  authDomain: "galectalms.firebaseapp.com",
  projectId: "galectalms",
  storageBucket: "galectalms.firebasestorage.app",
  messagingSenderId: "77197920870",
  appId: "1:77197920870:web:baf432cb57a16aa7e73ba7",
  measurementId: "G-XY4T4BNMM4"
};


try {
  const app = initializeApp(firebaseConfig);
  const messaging = getMessaging(app);

  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.register('/firebase-messaging-sw.js')
      .then(registration => {
        console.warn('Service Worker registered with scope:', registration.scope);
      })
      .catch(error => {
        console.error('Service Worker registration failed:', error);
      });

    // Handle incoming messages
    onMessage(messaging, (payload) => {
      console.log('Message received. ', payload);
    });

    // Get and store token
    getToken(messaging, { vapidKey: "BI2OLF_C6sZrc8hsydIGsNkuWhKSJ4q_F535hwzXNL-XS5lafFl8EX9IsVZaQ6BnsRZbCCKlYlo26nZE1AABD34" }).then((currentToken) => {
      if (currentToken) {
        // console.log("Token is:", currentToken);
        localStorage.setItem("message_token", currentToken);
      } else {
        console.log('No registration token available. Request permission to generate one.');
      }
    }).catch((err) => {
      console.error('An error occurred while retrieving token. ', err);
    });
  } else {
    console.warn('Service workers are not supported in this browser.');
  }
} catch (error) {
  console.error('Firebase initialization error:', error);
}



</script>

<style>


@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

*{
  font-family: "Inter", serif;
}

</style>
