<template>
  <div>
    <Header />

    <FlashMessage v-if="this.messages.text != ''" :message="this.messages" />
    <confirmPopup v-if="popupwindow == true" :title="titlepass" @cancelClick="popupwindow = false"
      @confirmClick="deletecourse(idpass)" />

    <div class="py-3">
      <div class="container mx-auto max-w-[1200px] px-6 md:px-0">
        <div class="flex flex-col lg:flex-row justify-between items-center gap-8 rounded-3xl shadow-xl my-10 h-full">
          <div class="lg:px-20 flex flex-col py-10 gap-6 justify-between h-full text-center lg:text-left px-6">
            <h1 class="font-bold text-3xl lg:text-5xl prime">
              Welcome Admin
              <span class="text-[#105C8E] text-xl lg:text-3xl ml-2 lg:ml-5"></span>
            </h1>

            <p class="content max-w-sm">
              Looking for information about Galecta LMS itself (features, use cases, or documentation).
              Referring to reusing or duplicating organizing content for it.
            </p>

            <form @submit.prevent="searchUserValue" class="w-full">
              <div class="flex flex-wrap items-center justify-center lg:justify-start gap-4">
                <input type="text" v-model="search_title" @input="searchUserValue" placeholder="Search The Course"
                  class="rounded-full px-6 py-3 border w-full lg:w-auto" />
                <button type="submit" class="bg-primary py-3 rounded-2xl text-white px-6">
                  Search
                </button>
              </div>
            </form>
          </div>

          <div class="flex justify-center lg:justify-end w-full lg:w-auto">
            <img src="@/assets/new/boy.png" alt=""
              class="w-full lg:w-auto h-auto rounded-r-3xl object-contain max-h-[400px]" />
          </div>
        </div>
      </div>
    </div>




    <Footer />

  </div>
</template>

<script>
import Footer from "@/components/Footer.vue";
import Header from "@/components/Admin/Header.vue";
import axios from "axios";
import { mapGetters } from "vuex";


import ConfirmPopup from "@/components/ConfirmPopup.vue";
import FlashMessage from "@/components/FlashMessage.vue";

export default {
  components: {
    Header,
    Footer,
    ConfirmPopup,
    FlashMessage
  },

  data() {
    return {
      courselist: "",
      instructorInfo: "",
      messages: "",
      popupwindow: false,
      loading: true,
      search_title: "",
    };
  },

  computed: mapGetters(["userInfo"]),

  async created() {
    await axios
      .get(
        `${process.env.VUE_APP_API}/instructor/?Key=${this.userInfo.token_key}`
      )
      .then((response) => {
        this.instructorInfo = response.data;
        console.log(this.instructorInfo, "info");
      });
    await axios
      .get(
        `${process.env.VUE_APP_API}/courses/?instructor=${this.instructorInfo.id}`
      )
      .then((response) => {
        this.courselist = response.data.data;

      });
  },
  mounted() {
    setTimeout(() => {
      this.loading = false;
    }, 2000)
  },
  methods: {
    async deletecourse(data) {
      await axios
        .delete(
          `${process.env.VUE_APP_API}/courseupdate/${data}/?Key=${this.userInfo.token_key}`
        )
        .then((response) => {
          this.res = response;
          if (this.res.statusText == "No Content") {
            this.messages = {
              text: "Deleted successfully",
              type: "success",
            };
            console.log("successfull");
          } else {
            this.messages = {
              text: " Event Faild",
              type: "error",
            };
          }
        });
      this.popupwindow = false;

      setTimeout(() => {
        this.$router.go(0);
        this.messages = {
          text: "",
          type: "null",
        };
      }, 2000);
    },
    async searchUserValue() {
      await axios
        .get(
          `${process.env.VUE_APP_API}/courses/?title=${this.search_title}&Key=${this.userInfo.token_key}`
        )
        .then((response) => {
          if (response.data.message == "No courses details") {
            console.log("Null");
          } else {
            this.courselist = response.data.data;
            this.courselist.forEach((element) => {
              element.displayprice = new Intl.NumberFormat("hi-IN", {
                style: "currency",
                currency: "INR",
                maximumFractionDigits: 0,
              }).format(element.price);
            });
          }
        });
    },
  }
};
</script>

<style></style>
