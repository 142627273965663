<template>
  <div>
    <Header />

    <FlashMessage v-if="this.messages.text != ''" :message="this.messages" />
    <confirmPopup v-if="popupwindow == true" :title="titlepass" @cancelClick="popupwindow = false"
      @confirmClick="deletecourse(idpass)" />

    <div class="py-10 max-h-screen overflow-y-auto">
      <div class="container mx-auto max-w-[1200px] flex flex-col gap-10 px-6">
        <div class="flex flex-col md:flex-row justify-between items-start gap-4">
          <h1 class="text-2xl font-bold text-primary text-start md:text-left">My Courses</h1>
          <form @submit.prevent="searchUserValue" class="flex w-full md:w-auto">
            <input type="text" name="search" id="search" v-model="search_title" @input="searchUserValue"
              placeholder="Search The Course"
              class="border-2 rounded-l-xl px-4 py-2 w-full md:w-[230px] outline-none" />
            <button type="submit" class="bg-blues py-2 md:py-1 rounded-r-xl text-white px-4">
              <img src="@/assets/search.webp" alt="Search" class="w-6 h-6">
            </button>
          </form>
        </div>


        <div v-if="loading" class="flex items-center justify-center h-[calc(100vh-350px)]">
          <img src="@/assets/newd/loading.gif" alt="Loading" />
        </div>

        <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 sm:gap-8 lg:gap-10" v-else>
          <div class="rounded-3xl shadow-md" v-for="course in courselist" :key="course">
            <div class="flex flex-col min-h-[430px] justify-between text-start sm:text-left px-4 sm:px-6 py-6">
              <img v-if="course.thumbnail" class="object-cover object-center h-[200px] w-full rounded-lg"
                :src="course.thumbnail" alt="Course Thumbnail" />
              <img v-else class="object-cover object-center h-24 w-52 mx-auto sm:mx-0 rounded" src="@/assets/coding.svg"
                alt="Default Thumbnail" />

              <router-link :to="{ name: 'InstructorStudents', params: { course_id: course.id } }"
                class="flex flex-col gap-1">
                <h1 class="font-bold text-lg text-primary py-2">
                  {{ course.title.slice(0, 50) }}<p v-if="course.title.length > 50"
                    class="text-primary text-sm mt-1 font-semibold">...More</p>

                </h1>
                <p class="text-secondary">{{ course.sections.length }} Units</p>
              </router-link>

              <div class="flex items-center justify-between gap-4 mt-2">
                <router-link :to="{ name: 'InstructorCourseAdd', params: { course_id: course.id } }">
                  <img src="@/assets/newd/icon1.svg" alt="Add Course" />
                </router-link>
                <router-link :to="{ name: 'InstructorCourseEdit', params: { course_id: course.id } }">
                  <img src="@/assets/newd/icon2.svg" alt="Edit Course" />
                </router-link>
                <div @click="titlepass = course.title; popupwindow = true; idpass = course.id;" class="cursor-pointer">
                  <img src="@/assets/newd/icon3.svg" alt="Delete Course" />
                </div>
              </div>

              <div class="flex flex-wrap justify-start items-center gap-2 mt-3">
                <button class="bg-primary text-xs rounded-2xl px-4 py-1.5 text-white"
                  @click="DuplicateCourse(course.id)">
                  Duplicate
                </button>
                <router-link :to="{ name: 'InstructorCoursePreview', params: { courseid: course.id } }">
                  <button class="bg-primary text-xs rounded-2xl px-4 py-1.5 text-white">
                    Preview
                  </button>
                </router-link>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Instructor/Header.vue";

import ConfirmPopup from "@/components/ConfirmPopup.vue";
import FlashMessage from "@/components/FlashMessage.vue";

import axios from "axios";
import { mapGetters } from "vuex";

export default {
  components: {
    Header,
    FlashMessage,
    ConfirmPopup,
  },

  data() {
    return {
      courselist: "",
      instructorInfo: "",
      messages: "",
      popupwindow: false,
      loading: true,
      search_title: "",
    };
  },

  computed: mapGetters(["userInfo"]),

  async created() {
    await axios
      .get(
        `${process.env.VUE_APP_API}/instructor/?Key=${this.userInfo.token_key}`
      )
      .then((response) => {
        this.instructorInfo = response.data;
        console.log(this.instructorInfo, "info");
      });
    await axios
      .get(
        `${process.env.VUE_APP_API}/courses/?instructor=${this.instructorInfo.id}`
      )
      .then((response) => {
        if (response.data.message == "No courses details") {
          console.log("Null");
        } else {
          this.courselist = response.data.data;
          this.loading = false;
          this.courselist.forEach((element) => {
            element.displayprice = new Intl.NumberFormat("hi-IN", {
              style: "currency",
              currency: "INR",
              maximumFractionDigits: 0,
            }).format(element.price);
          });
        }
      });
  },

  methods: {
    async DuplicateCourse(course_id) {
      await axios
        .get(
          `${process.env.VUE_APP_API}/courseduplicate/${course_id}/?Key=${this.userInfo.token_key}`,
          {}
        )
        .then((response) => {
          if (response.data.status == 200) {
            console.log("Course Duplicated");
            // this.coursedetailsget();
            this.messages = {
              text: "Course Duplicated",
              type: "success",
            };
            setTimeout(() => {
              axios
                .get(
                  `${process.env.VUE_APP_API}/courses/?instructor=${this.instructorInfo.id}`
                )
                .then((response) => {
                  if (response.data.message == "No courses details") {
                    console.log("Null");
                  } else {
                    this.courselist = response.data.data;

                    this.courselist.forEach((element) => {
                      element.displayprice = new Intl.NumberFormat("hi-IN", {
                        style: "currency",
                        currency: "INR",
                        maximumFractionDigits: 0,
                      }).format(element.price);
                    });
                  }
                });

              this.messages = {
                text: "",
                type: "",
              };
            }, 1000);
          } else {
            console.log("Course Duplicated Failed");
            this.messages = {
              text: " Course Duplicated Failed",
              type: "error",
            };
          }
        });
    },

    async deletecourse(data) {
      await axios
        .delete(
          `${process.env.VUE_APP_API}/courseupdate/${data}/?Key=${this.userInfo.token_key}`
        )
        .then((response) => {
          this.res = response;
          if (this.res.statusText == "No Content") {
            this.messages = {
              text: "Deleted successfully",
              type: "success",
            };
            console.log("successfull");
          } else {
            this.messages = {
              text: " Event Faild",
              type: "error",
            };
          }
        });
      this.popupwindow = false;

      setTimeout(() => {
        this.$router.go(0);
        this.messages = {
          text: "",
          type: "null",
        };
      }, 2000);
    },
    async searchUserValue() {
      await axios
        .get(
          `${process.env.VUE_APP_API}/courses/?title=${this.search_title}&Key=${this.userInfo.token_key}`
        )
        .then((response) => {
          if (response.data.message == "No courses details") {
            console.log("Null");
          } else {
            this.courselist = response.data.data;
            this.courselist.forEach((element) => {
              element.displayprice = new Intl.NumberFormat("hi-IN", {
                style: "currency",
                currency: "INR",
                maximumFractionDigits: 0,
              }).format(element.price);
            });
          }
        });
    },
  },
};
</script>

<style></style>
