import axios from 'axios';

axios.defaults.withCredentials = false


const state = {
    userInfo: null,
    error: null,
    isAuthenticated: false,
    subscriptionInfo : null,
    instructorInfo : null,
}

const getters = {
    userInfo: state => state.userInfo,
    loginError: state => state.error,
    isAuthenticated: state => state.isAuthenticated,
    subscriptionInfo: state=>state.subscriptionInfo,
    instructorInfo : state=>state.instructorInfo
}

const actions = {
    async loginUser({ commit }, credentials) {
       
        fetch(`${process.env.VUE_APP_API}/login`, {
            method: 'POST',
            mode: 'cors',
            credentials: 'include',
            requiredStatus: 'ok',
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'User-Agent': navigator.userAgent,
            },
            body: JSON.stringify(credentials)
        })
            .then(response =>
                response.json().then(data => ({
                    data: data,
                    status: response.status
                })).then(res => {
                    
                    if(res.data.token_key){
                        commit("setUserInfo", res.data)
                    } else {
                        commit("errMessage", res.data.error)
                        setTimeout(()=>{
                            commit("errMessage", "")
                        },3000)
                    }

                }));

    },
    async logOutUser({commit}){
        const response = await axios.get(`${process.env.VUE_APP_API}/singout`)
        commit("logout",response.data)
    }, 
    async subcription({ commit }, subdata){
        const response = await axios.get(`${process.env.VUE_APP_API}/userpermissionlist/?Key=${subdata.token_key}`)
        if (response && response.data.message == "Have a details"){
            commit('subdetails', response.data.data)
        } else  {
            let message = 'Nothing to show'
            commit('subdetails', message)
        } 

    },

    async instructordetails({commit},subdata){
        const response = await axios.get(`${process.env.VUE_APP_API}/instructor/?Key=${subdata.token_key}`)
        if (response && response.data.data){
            commit('instructorInfos', response.data)
        } else if(response && response.data.error) {
            let message = 'Nothing to show'
            commit('instructorInfos', message)
        } else if (response == undefined) { 
            let message = "500 Error"
            commit('instructorInfos', message)
        } else {
            let message = 'Please check your internet connection'
            commit('instructorInfos', message)
        }
    }
}

const mutations = {
    setUserInfo: (state, data) =>  { 
        state.userInfo = data ; 
        state.isAuthenticated = true;
        state.error = null;
    },
    errMessage: (state, data) => { 
        state.error = data
    },
    logout: (state) => { 
        state.userInfo = null; 
        state.isAuthenticated = false;
        state.subscriptionInfo = null,
        state.instructorInfo = null
 
    },
    subdetails:(state,data)=>{
        state.subscriptionInfo = data;
    },
    instructorInfos:(state,data)=>{
        state.instructorInfo = data;

    },
}

export default {
    state,
    getters,
    actions,
    mutations
}