<template>
  <div>
    <Header />

    <FlashMessage v-if="this.messages.text != ''" :message="this.messages" />
    <div class="">
      <div class="py-10 ">
        <div class="container px-6 mx-auto max-w-[1200px] flex flex-col gap-10">
          <div>
            <form ref="addCourseRef"
              class="rounded-3xl pt-10 px-5 pb-2 heightfix overflow-y-auto w-full max-w-4xl mx-auto"
              style="box-shadow: 0px 2px 20px 0px #105c8e26" @submit.prevent="updateDetails">
              <div class="flex items-start flex-wrap gap-4">
                <div class="hidden lg:flex"></div>
                <div>
                  <h1 class="py-2 text-xl text-center font-bold text-blues">
                    Edit Course
                  </h1>
                </div>

              </div>

              <div class="py-10 flex flex-col gap-6">
                <div class="flex flex-col lg:flex-row items-center gap-5 w-full">
                  <div class="flex flex-col gap-3 w-full">
                    <label for="title" class="text-sm">Title <span class="text-red-400">*</span></label>
                    <input id="title" v-model="courseInfo.title" type="text" required
                      class="bg-[#F9FAFC] rounded-lg text-sm w-full p-3 border-none outline-none" />
                  </div>
                  <div class="flex flex-col gap-3 w-full">
                    <label for="category" class="text-sm">Category <span class="text-red-400">*</span></label>
                    <select name="category" required
                      class="bg-[#F9FAFC] rounded-lg text-sm w-full p-3 border-none outline-none" id="category">
                      <option v-for="category in categories" :key="category" :value="category.id">
                        {{ category.title }}
                      </option>
                    </select>
                  </div>
                </div>

                <div class="flex flex-col gap-3 w-full">
                  <label for="shortDesc" class="text-sm">Short Description <span class="text-red-400">*</span></label>
                  <textarea id="shortDesc" required v-model="courseInfo.short_description" maxlength="1000"
                    class="bg-[#F9FAFC] rounded-lg text-sm w-full p-3 border-none outline-none"></textarea>
                </div>

                <div class="flex flex-col gap-3 w-full">
                  <label for="description" class="text-sm">Description <span class="text-red-400">*</span></label>
                  <QuillEditor style="height: 300px" theme="snow" class="border rounded-2xl" toolbar="full"
                    :content="description" contentType="html" />
                </div>

                <div class="flex flex-col gap-3 w-full">
                  <label for="outcome" class="text-sm">Outcome</label>
                  <textarea id="outcome" v-model="courseInfo.outcome" maxlength="1000"
                    class="bg-[#F9FAFC] rounded-lg text-sm w-full p-3 border-none outline-none"></textarea>
                </div>

                <div class="flex flex-col gap-3 w-full">
                  <label for="requirement" class="text-sm">Requirement</label>
                  <textarea id="requirement" v-model="courseInfo.requirements" maxlength="1000"
                    class="bg-[#F9FAFC] rounded-lg text-sm w-full p-3 border-none outline-none"></textarea>
                </div>

                <div class="flex flex-col lg:flex-row items-center gap-5 w-full">
                  <div class="flex flex-col gap-3 w-full">
                    <label for="language" class="text-sm">Language <span class="text-red-400">*</span></label>
                    <input id="language" v-model="courseInfo.language" type="text"
                      class="bg-[#F9FAFC] rounded-lg text-sm w-full p-3 border-none outline-none" />
                  </div>
                  <div class="flex flex-col gap-3 w-full">
                    <label for="level" class="text-sm">Level</label>
                    <select name="level" required
                      class="bg-[#F9FAFC] rounded-lg text-sm w-full p-3 border-none outline-none" id="level">
                      <option v-for="level in ['beginner', 'intermediate', 'advanced']" :key="level" :value="level">
                        {{ level }}
                      </option>
                    </select>
                  </div>
                </div>

                <div class="flex flex-col lg:flex-row items-center gap-5 w-full">
                  <div class="flex flex-col gap-3 w-full">
                    <label for="thumbnail" class="text-sm">Thumbnail <span class="text-red-400">*</span></label>
                    <img :src="courseInfo.thumbnail" class="w-[150px] h-[100px] rounded-lg" alt="">
                    <input id="thumbnail" type="file"
                      class="bg-[#F9FAFC] object-cover rounded-lg text-sm w-full p-3 border-none outline-none" />
                  </div>
                  <div class="flex flex-col gap-3 w-full">
                    <label for="videoUrl" class="text-sm">Video URL</label>
                    <input id="videoUrl" v-model="courseInfo.video_url" type="text"
                      class="bg-[#F9FAFC] rounded-lg text-sm w-full p-3 border-none outline-none" />
                  </div>
                </div>

                <div class="flex items-center gap-4">
                  <div class="flex items-center gap-2">
                    <label for="checkbox">Published</label>
                    <input type="checkbox" id="Published" v-model="courseInfo.is_published" />
                  </div>
                </div>
                <div class="flex gap-3 flex-wrap">
                  <button type="submit"
                    class="text-greens border border-greens rounded-lg text-lg font-semibold px-6 py-1.5">
                    Save
                  </button>
                  <router-link :to="{ name: 'InstructorCourses' }"
                    class="bg-blues text-white rounded-lg text-lg font-semibold px-6 py-1.5">Back</router-link>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Instructor/Header.vue";
import axios from "axios";
import { mapGetters } from "vuex";

import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";

import FlashMessage from "@/components/FlashMessage.vue";

export default {
  components: {
    Header,
    QuillEditor,
    FlashMessage
  },

  data() {
    return {
      courseInfo: "",
      categories: "",
      messages: {
        text: "",
        type: "null",
      },
      is_CategoryPopup: false,
      description: "<h4>Write your course content</h4>",
    };
  },

  props: ["course_id"],

  computed: mapGetters(["userInfo"]),

  async created() {

    this.description = this.courseInfo.description;

    axios
      .get(`${process.env.VUE_APP_API}/courses/?id=${this.course_id}`)
      .then((response) => {
        this.courseInfo = response.data.data[0];
      });

    axios
      .get(`${process.env.VUE_APP_API}/category/?format=json`)
      .then((res) => {
        this.categories = res.data.data;
      });
  },

  methods: {
    async updateDetails(el) {

      this.description = document.querySelector(".ql-editor").innerHTML;
      console.log(this.description);

      let instructor_details;
      await axios
        .get(`${process.env.VUE_APP_API}/coursesdata/${this.courseInfo.id}/`)
        .then((Response) => {
          instructor_details = Response.data;
        });

      let updatedCourseInfo = {
        instructor: instructor_details.instructor_id,
        uid: this.courseInfo.uid,
        title: el.target.elements.title.value,
        slug: this.courseInfo.slug,
        short_description: el.target.elements.shortDesc.value,
        description: this.description,
        outcome: el.target.elements.outcome.value,
        requirements: el.target.elements.requirement.value,
        language: el.target.elements.language.value,
        price: 0,
        course_free: true,
        level: el.target.elements.level.value,
        video_url: el.target.elements.videoUrl.value,
        is_published: el.target.elements.Published.checked,
        created_at: this.courseInfo.created_at,
        updated_at: this.courseInfo.updated_at,
        category: parseInt(el.target.elements.category.value),
        organization_id: this.userInfo.organizes_id,
      };

      await axios
        .put(
          `${process.env.VUE_APP_API}/courseupdate/${this.courseInfo.id}/?Key=${this.userInfo.token_key}`,
          updatedCourseInfo
        )
        .then((response) => {
          this.res = response;
          if (this.res.data.message) {
            this.messages = {
              text: "Course Updated successfully",
              type: "success",
            };
            console.log("successful");
          } else {
            this.messages = {
              text: " Event Faild",
              type: "error",
            };
            console.log("not successful");
          }
        });

      setTimeout(() => {
        this.messages = {
          text: "",
          type: "null",
        };
        this.$router.push({
          name: "InstructorCourses"
        });
      }, 2500);
    },
  },
};
</script>

<style scoped>
.heightfix {
  height: calc(100vh - 170px) !important;
}
</style>
