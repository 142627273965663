<template>

  <div class="m-2 relative z-[50] bg-white p-2 lg:hidden rounded-xl cursor-pointer h-[45px]" @click="isMobile = !isMobile">
    <div class="bg-gray-500 w-8 mt-1.5 h-1 rounded-lg"></div>
    <div class="bg-gray-500 w-8 mt-1.5 h-1 rounded-lg"></div>
    <div class="bg-gray-500 w-8 mt-1.5 h-1 rounded-lg"></div>
  </div>

  <div
    class="lg:flex fixed w-[90%] bg-white lg:w-[35%] xl:w-[30%] 2xl:w-[25%] lg:relative z-30 mt-10 lg:m-10  rounded-3xl heightfix overflow-y-auto" :class="{'hidden': !isMobile}"
    style="box-shadow: 0px 2px 30px 0px #01b0f926"
  >
    <div class="flex flex-col gap-5 relative w-full overflow-y-auto  p-10">
      <router-link
        :to="{ name: 'AdminStudents' }"
        class="flex items-center gap-5 p-4 rounded-3xl"
        :class="{ 'bg-[#0085DB]': $route.name == 'AdminStudents' }"
      >
        <img
          src="@/assets/newd/icons/Grid.png"
          v-if="$route.name == 'AdminStudents'"
          alt=""
        />
        <img
          src="@/assets/newd/icons/Grid-1.png"
          v-if="$route.name != 'AdminStudents'"
          alt=""
        />
        <p
          class="font-bold text-sm"
          :class="{
            'text-white': $route.name == 'AdminStudents',
            'text-[#333333]': $route.name != 'AdminStudents',
          }"
        >
          Students
        </p>
      </router-link>

      <router-link
        :to="{ name: 'AdminMessages' }"
        class="flex items-center gap-5 p-4 rounded-3xl"
        :class="{ 'bg-[#0085DB]': $route.name == 'AdminMessages' }"
      >
        <img
          src="@/assets/newd/icons/mdi-light_forum-1.png"
          v-if="$route.name == 'AdminMessages'"
          alt=""
        />
        <img
          src="@/assets/newd/icons/mdi-light_forum.png"
          v-if="$route.name != 'AdminMessages'"
          alt=""
        />

        <p
          class="font-bold text-sm"
          :class="{
            'text-white': $route.name == 'AdminMessages',
            'text-[#333333]': $route.name != 'AdminMessages',
          }"
        >
          Messages
        </p>
      </router-link>

      <router-link
        :to="{ name: 'AdminAssignments' }"
        class="flex items-center gap-5 p-4 rounded-3xl"
        :class="{ 'bg-[#0085DB]': $route.name == 'AdminAssignments' }"
      >
        <img
          src="@/assets/newd/icons/gg_notes-1.png"
          v-if="$route.name == 'AdminAssignments'"
          alt=""
        />
        <img
          src="@/assets/newd/icons/gg_notes.png"
          v-if="$route.name != 'AdminAssignments'"
          alt=""
        />
        <p
          class="font-bold text-sm"
          :class="{
            'text-white': $route.name == 'AdminAssignments',
            'text-[#333333]': $route.name != 'AdminAssignments',
          }"
        >
          Assignments
        </p>
      </router-link>

      <router-link
        :to="{ name: 'AdminForums' }"
        class="flex items-center gap-5 p-4 rounded-3xl"
        :class="{ 'bg-[#0085DB]': $route.name == 'AdminForums' }"
      >
        <img
          src="@/assets/newd/icons/Archive-1.png"
          v-if="$route.name == 'AdminForums'"
          alt=""
        />
        <img
          src="@/assets/newd/icons/Archive.png"
          v-if="$route.name != 'AdminForums'"
          alt=""
        />

        <p
          class="font-bold text-sm"
          :class="{
            'text-white': $route.name == 'AdminForums',
            'text-[#333333]': $route.name != 'AdminForums',
          }"
        >
          Forums
        </p>
      </router-link>

      <router-link
        :to="{ name: 'AdminClasses' }"
        class="flex items-center gap-5 p-4 rounded-3xl"
        :class="{ 'bg-[#0085DB]': $route.name == 'AdminClasses' }"
      >
        <img
          src="@/assets/newd/icons/Calendar2Week-1.png"
          v-if="$route.name == 'AdminClasses'"
          alt=""
        />
        <img
          src="@/assets/newd/icons/Calendar2Week.png"
          v-if="$route.name != 'AdminClasses'"
          alt=""
        />

        <p
          class="font-bold text-sm"
          :class="{
            'text-white': $route.name == 'AdminClasses',
            'text-[#333333]': $route.name != 'AdminClasses',
          }"
        >
          Live Classes
        </p>
      </router-link>

      <router-link
        :to="{ name: 'AdminQuizes' }"
        class="flex items-center gap-5 p-4 rounded-3xl"
        :class="{ 'bg-[#0085DB]': $route.name == 'AdminQuizes' }"
      >
        <img
          src="@/assets/newd/icons/BarChartLine-1.png"
          v-if="$route.name == 'AdminQuizes'"
          alt=""
        />
        <img
          src="@/assets/newd/icons/BarChartLine.png"
          v-if="$route.name != 'AdminQuizes'"
          alt=""
        />

        <p
          class="font-bold text-sm"
          :class="{
            'text-white': $route.name == 'AdminQuizes',
            'text-[#333333]': $route.name != 'AdminQuizes',
          }"
        >
          Assessment
        </p>
      </router-link>
    

      <router-link
        :to="{ name: 'AdminBlogs' }"
        class="flex items-center gap-5 p-4 rounded-3xl"
        :class="{ 'bg-[#0085DB]': $route.name == 'AdminBlogs' }"
      >

        <img
          src="@/assets/newd/icons/gg_notes-1.png"
          v-if="$route.name == 'AdminBlogs'"
          alt=""
        />
        <img
          src="@/assets/newd/icons/gg_notes.png"
          v-if="$route.name != 'AdminBlogs'"
          alt=""
        />

        <p
          class="font-bold text-sm"
          :class="{
            'text-white': $route.name == 'AdminBlogs',
            'text-[#333333]': $route.name != 'AdminBlogs',
          }"
        >
          Projects
        </p>
      </router-link>

      <router-link
        :to="{ name: 'AdminCourseComplete' }"
        class="flex items-center gap-5 p-4 rounded-3xl"
        :class="{ 'bg-[#0085DB]': $route.name == 'AdminCourseComplete' }"
      >

        <img
          src="@/assets/newd/icons/gg_notes-1.png"
          v-if="$route.name == 'AdminCourseComplete'"
          alt=""
        />
        <img
          src="@/assets/newd/icons/gg_notes.png"
          v-if="$route.name != 'AdminCourseComplete'"
          alt=""
        />

        <p
          class="font-bold text-sm"
          :class="{
            'text-white': $route.name == 'AdminCourseComplete',
            'text-[#333333]': $route.name != 'AdminCourseComplete',
          }"
        >
          Grading
        </p>
      </router-link>

      <router-link
        :to="{ name: 'AdminInviteCourse' }"
        class="flex items-center gap-5 p-4 rounded-3xl"
        :class="{ 'bg-[#0085DB]': $route.name == 'AdminInviteCourse' }"
      >
        <img
          src="@/assets/newd/icons/Calendar2Week-1.png"
          v-if="$route.name == 'AdminInviteCourse'"
          alt=""
        />
        <img
          src="@/assets/newd/icons/Calendar2Week.png"
          v-if="$route.name != 'AdminInviteCourse'"
          alt=""
        />

        <p
          class="font-bold text-sm"
          :class="{
            'text-white': $route.name == 'AdminInviteCourse',
            'text-[#333333]': $route.name != 'AdminInviteCourse',
          }"
        >
          Invite
        </p>
      </router-link>
      <router-link
        :to="{ name: 'AdminSkillGapAnalysis' }"
        class="flex items-center gap-5 p-4 rounded-3xl"
        :class="{ 'bg-[#0085DB]': $route.name == 'AdminSkillGapAnalysis' }"
      >

        <img
          src="@/assets/newd/icons/BarChartLine-1.png"
          v-if="$route.name == 'AdminSkillGapAnalysis'"
          alt=""
        />
        <img
          src="@/assets/newd/icons/BarChartLine.png"
          v-if="$route.name != 'AdminSkillGapAnalysis'"
          alt=""
        />

        <p
          class="font-bold text-sm"
          :class="{
            'text-white': $route.name == 'AdminSkillGapAnalysis',
            'text-[#333333]': $route.name != 'AdminSkillGapAnalysis',
          }"
        >
          Skill Gap Analysis
        </p>
      </router-link>

    </div>
  </div>
</template>

<script>
export default {
  data(){
    return {
      isMobile: false
    }
  }
};
</script>

<style scoped>
.heightfix {
  height: calc(100vh - 170px) !important;
}</style>
