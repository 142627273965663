<template>
  <div
    v-if="hide_menu"
    class="absolute top-[15%] z-[60] bg-blues p-2 lg:hidden cursor-pointer h-[40px] rounded-r-xl"
   @click="toggleMenu"
  >
    <!-- <div class="pl-2">
    <div class="bg-white w-6 mt-1 h-1 rounded-lg"></div>
    <div class="bg-white w-6 mt-1 h-1 rounded-lg"></div>
    <div class="bg-white w-6 mt-1 h-1 rounded-lg"></div>
    </div> -->
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="20"
      height="20"
      viewBox="0 0 50 50"
      fill="#fff"
    >
      <path
        d="M 0 7.5 L 0 12.5 L 50 12.5 L 50 7.5 Z M 0 22.5 L 0 27.5 L 50 27.5 L 50 22.5 Z M 0 37.5 L 0 42.5 L 50 42.5 L 50 37.5 Z"
      ></path>
    </svg>
  </div>

  <div
    class="lg:flex fixed w-[95%] bg-white lg:w-[35%] lg:left-0 overflow-y-auto xl:w-[30%] 2xl:w-[25%] lg:relative z-30 mt-10 lg:m-10 rounded-3xl heightfix transition-all duration-300"
     :class="{ hidden: !isMobile }"
    style="box-shadow: 0px 2px 30px 0px #01b0f926"
    
    
  >
    <div
      @click="toggleSidebar"
      class="absolute top-2 right-[2px] z-30 block lg:hidden bg-gray-200 rounded-full p-1 cursor-pointer"
    >
      <!-- <span class="text-2xl">⬅️</span> -->
      <img src="@/assets/leftArrow.svg" alt="" srcset="" class="w-5 h-5" />
    </div>
    <div class="flex flex-col gap-5 relative w-full overflow-y-auto p-10">
      <router-link
        :to="{ name: 'StudentCourseProgress' }"
        class="flex items-center gap-5 p-4 rounded-3xl"
        :class="{ 'bg-[#0085DB]': $route.name == 'StudentCourseProgress' }"
      >
        <img
          src="@/assets/newd/icons/Grid.png"
          v-if="$route.name == 'StudentCourseProgress'"
          alt=""
        />
        <img
          src="@/assets/newd/icons/Grid-1.png"
          v-if="$route.name != 'StudentCourseProgress'"
          alt=""
        />
        <p
          class="font-bold text-sm"
          :class="{
            'text-white': $route.name == 'StudentCourseProgress',
            'text-[#333333]': $route.name != 'StudentCourseProgress',
          }"
        >
          Lessons
        </p>
      </router-link>

      <router-link
        :to="{ name: 'StudentMessages' }"
        class="flex items-center gap-5 p-4 rounded-3xl"
        :class="{ 'bg-[#0085DB]': $route.name == 'StudentMessages' }"
      >
        <img
          src="@/assets/newd/icons/mdi-light_forum-1.png"
          v-if="$route.name == 'StudentMessages'"
          alt=""
        />
        <img
          src="@/assets/newd/icons/mdi-light_forum.png"
          v-if="$route.name != 'StudentMessages'"
          alt=""
        />

        <p
          class="font-bold text-sm"
          :class="{
            'text-white': $route.name == 'StudentMessages',
            'text-[#333333]': $route.name != 'StudentMessages',
          }"
        >
          Messages
        </p>
      </router-link>

      <router-link
        :to="{ name: 'StudentAssignments' }"
        class="flex items-center gap-5 p-4 rounded-3xl"
        :class="{ 'bg-[#0085DB]': $route.name == 'StudentAssignments' }"
      >
        <img
          src="@/assets/newd/icons/gg_notes-1.png"
          v-if="$route.name == 'StudentAssignments'"
          alt=""
        />
        <img
          src="@/assets/newd/icons/gg_notes.png"
          v-if="$route.name != 'StudentAssignments'"
          alt=""
        />
        <p
          class="font-bold text-sm"
          :class="{
            'text-white': $route.name == 'StudentAssignments',
            'text-[#333333]': $route.name != 'StudentAssignments',
          }"
        >
          Assignments
        </p>
      </router-link>

      <router-link
        :to="{ name: 'StudentForums' }"
        class="flex items-center gap-5 p-4 rounded-3xl"
        :class="{ 'bg-[#0085DB]': $route.name == 'StudentForums' }"
      >
        <img
          src="@/assets/newd/icons/Archive-1.png"
          v-if="$route.name == 'StudentForums'"
          alt=""
        />
        <img
          src="@/assets/newd/icons/Archive.png"
          v-if="$route.name != 'StudentForums'"
          alt=""
        />

        <p
          class="font-bold text-sm"
          :class="{
            'text-white': $route.name == 'StudentForums',
            'text-[#333333]': $route.name != 'StudentForums',
          }"
        >
          Forums
        </p>
      </router-link>

      <router-link
        :to="{ name: 'StudentClasses' }"
        class="flex items-center gap-5 p-4 rounded-3xl"
        :class="{ 'bg-[#0085DB]': $route.name == 'StudentClasses' }"
      >
        <img
          src="@/assets/newd/icons/Calendar2Week-1.png"
          v-if="$route.name == 'StudentClasses'"
          alt=""
        />
        <img
          src="@/assets/newd/icons/Calendar2Week.png"
          v-if="$route.name != 'StudentClasses'"
          alt=""
        />

        <p
          class="font-bold text-sm"
          :class="{
            'text-white': $route.name == 'StudentClasses',
            'text-[#333333]': $route.name != 'StudentClasses',
          }"
        >
          Live Classes
        </p>
      </router-link>

      <router-link
        :to="{ name: 'StudentAssessments' }"
        class="flex items-center gap-5 p-4 rounded-3xl"
        :class="{ 'bg-[#0085DB]': $route.name == 'StudentAssessments' }"
      >
        <img
          src="@/assets/newd/icons/BarChartLine-1.png"
          v-if="$route.name == 'StudentAssessments'"
          alt=""
        />
        <img
          src="@/assets/newd/icons/BarChartLine.png"
          v-if="$route.name != 'StudentAssessments'"
          alt=""
        />

        <p
          class="font-bold text-sm"
          :class="{
            'text-white': $route.name == 'StudentAssessments',
            'text-[#333333]': $route.name != 'StudentAssessments',
          }"
        >
          Assessment
        </p>
      </router-link>

      <router-link
        :to="{ name: 'StudentReport' }"
        class="flex items-center gap-5 p-4 rounded-3xl"
        :class="{ 'bg-[#0085DB]': $route.name == 'StudentReport' }"
      >
        <img
          src="@/assets/newd/icons/CloudArrowDown-1.png"
          v-if="$route.name == 'StudentReport'"
          alt=""
        />
        <img
          src="@/assets/newd/icons/CloudArrowDown.png"
          v-if="$route.name != 'StudentReport'"
          alt=""
        />

        <p
          class="font-bold text-sm"
          :class="{
            'text-white': $route.name == 'StudentReport',
            'text-[#333333]': $route.name != 'StudentReport',
          }"
        >
          Reports
        </p>
      </router-link>

      <router-link
        :to="{ name: 'StudentProject' }"
        class="flex items-center gap-5 p-4 rounded-3xl"
        :class="{ 'bg-[#0085DB]': $route.name == 'StudentProject' }"
      >
        <img
          src="@/assets/newd/icons/gg_notes-1.png"
          v-if="$route.name == 'StudentProject'"
          alt=""
        />
        <img
          src="@/assets/newd/icons/gg_notes.png"
          v-if="$route.name != 'StudentProject'"
          alt=""
        />
        <p
          class="font-bold text-sm"
          :class="{
            'text-white': $route.name == 'StudentProject',
            'text-[#333333]': $route.name != 'StudentProject',
          }"
        >
          Projects
        </p>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isMobile: false,
      hide_menu: true,
    };
  },
  methods: {
    toggleSidebar() {
      this.isMobile = false;
      this.hide_menu = true;
    },
     toggleMenu() {
      this.isMobile = !this.isMobile;
      this.hide_menu = !this.isMobile; // Hide button when menu is open
    },
  },
};
</script>

<style scoped>
.heightfix {
  height: calc(100vh - 170px) !important;
}
</style>
