<template>
  <div>
    <Header />

    <FlashMessage v-if="this.messages.text != ''" :message="this.messages" />

    <div class="py-10">
      <div class="container px-6 mx-auto max-w-[1200px] flex flex-col gap-10">
        <div>
          <form ref="addCourseRef" class="rounded-3xl pt-10 px-6 md:px-10 pb-2 heightfix overflow-y-auto"
            style="box-shadow: 0px 2px 20px 0px #105c8e26" @submit.prevent="createCourse">
            <div class="flex items-center justify-between flex-wrap">
              <h1 class="py-2 text-xl text-center font-bold text-blues w-full md:w-auto">
                Add New Course
              </h1>

            </div>

            <div class="py-10 flex flex-col gap-6">
              <div class="grid grid-cols-1 md:grid-cols-2 gap-5">
                <div class="flex flex-col gap-3">
                  <label for="title" class="text-sm">Title <span class="text-red-400">*</span></label>
                  <input id="title" type="text" required
                    class="bg-[#F9FAFC] rounded-lg text-sm w-full p-3 border-none outline-none" />
                </div>
                <div class="flex flex-col gap-3">
                  <label for="category" class="text-sm">Category <span class="text-red-400">*</span></label>
                  <select id="category" required
                    class="bg-[#F9FAFC] rounded-lg text-sm w-full p-3 border-none outline-none">
                    <option v-for="category in categories" :key="category.id" :value="category.id">
                      {{ category.title }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="flex flex-col gap-3">
                <label for="short-desc" class="text-sm">Short Description <span class="text-red-400">*</span></label>
                <textarea id="short-desc" required maxlength="1000"
                  class="bg-[#F9FAFC] rounded-lg text-sm w-full p-3 border-none outline-none"></textarea>
              </div>

              <div class="flex flex-col gap-3">
                <label for="description" class="text-sm">Description <span class="text-red-400">*</span></label>
                <QuillEditor style="height: 300px" theme="snow" class="border rounded-2xl" toolbar="full"
                  :content="description" contentType="html" />
              </div>
              <div class="grid grid-cols-1 md:grid-cols-2 gap-5">
                <div class="flex flex-col gap-3">
                  <label for="outcome" class="text-sm">Outcome <span class="text-red-400">*</span></label>
                  <textarea id="outcome" required maxlength="1000"
                    class="bg-[#F9FAFC] rounded-lg text-sm w-full p-3 border-none outline-none"></textarea>
                </div>
                <div class="flex flex-col gap-3">
                  <label for="requirement" class="text-sm">Requirement</label>
                  <textarea id="requirement" maxlength="1000"
                    class="bg-[#F9FAFC] rounded-lg text-sm w-full p-3 border-none outline-none"></textarea>
                </div>
              </div>
              <div class="grid grid-cols-1 md:grid-cols-2 gap-5">
                <div class="flex flex-col gap-3">
                  <label for="language" class="text-sm">Language</label>
                  <input id="language" type="text"
                    class="bg-[#F9FAFC] rounded-lg text-sm w-full p-3 border-none outline-none" />
                </div>
                <div class="flex flex-col gap-3">
                  <label for="level" class="text-sm">Level</label>
                  <select id="level" required
                    class="bg-[#F9FAFC] rounded-lg text-sm w-full p-3 border-none outline-none">
                    <option v-for="level in ['beginner', 'intermediate', 'advanced']" :key="level" :value="level">
                      {{ level }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="grid grid-cols-1 md:grid-cols-2 gap-5">
                <div class="flex flex-col gap-3">
                  <label for="thumbnail" class="text-sm">Thumbnail <span class="text-red-400">*</span></label>
                  <input id="thumbnail" type="file" required
                    class="bg-[#F9FAFC] rounded-lg text-sm w-full p-3 border-none outline-none" />
                </div>
                <div class="flex flex-col gap-3">
                  <label for="video_url" class="text-sm">Video URL</label>
                  <input id="video_url" type="text" required
                    class="bg-[#F9FAFC] rounded-lg text-sm w-full p-3 border-none outline-none" />
                </div>
              </div>

              <div class="flex items-center gap-2">
                <input type="checkbox" id="Published" />
                <label for="Published" class="text-sm">Published</label>
              </div>

              <!-- <div class="flex justify-between">
                <router-link :to="{ name: 'InstructorHome' }"
                  class=" bg-blues text-white rounded-lg text-lg font-semibold px-6 py-1.5">Back</router-link>
                <button type="submit" class="text-white bg-blues rounded-lg text-lg px-8 py-1.5">
                  Save
                </button>
              </div> -->
              <div class="flex gap-3 flex-wrap">
                <button type="submit"
                  class="text-greens border border-greens rounded-lg text-lg font-semibold px-6 py-1.5">
                  Save
                </button>
                <router-link :to="{ name: 'InstructorHome' }"
                  class="bg-blues text-white rounded-lg text-lg font-semibold px-6 py-1.5">Back</router-link>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Instructor/Header.vue";
import axios from "axios";
import { mapGetters } from "vuex";

import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";

import FlashMessage from "@/components/FlashMessage.vue";

export default {
  components: {
    Header,
    QuillEditor,
    FlashMessage
  },

  data() {
    return {
      categories: null,
      info: null,
      msgverify: "",
      messages: {
        text: "",
        type: "null",
      },
      is_CategoryPopup: false,
      description: "<h4>Write your course content</h4>",
    };
  },

  computed: mapGetters(["userInfo"]),

  async created() {
    axios
      .get(`${process.env.VUE_APP_API}/category/?format=json`)
      .then((res) => {
        this.categories = res.data.data;
      });
  },

  methods: {
    categoryAdd(el) {
      let bodyVal = {
        title: el.target.elements.title.value,
      };

      axios
        .post(
          `${process.env.VUE_APP_API}/categorynew/?Key=${this.userInfo.token_key}`,
          bodyVal
        )
        .then((response) => {
          console.log(response.data.message);
          if (response.data.message == "your Category added successfully") {
            this.messages = {
              text: "Category Added successfully",
              type: "success",
            };
            setTimeout(() => {
              this.messages = {
                text: "",
                type: "null",
              };
              this.is_CategoryPopup = false;
              this.categoryget();
            }, 1500);
          } else {
            this.messages = {
              text: response.data.message,
              type: "error",
            };
            setTimeout(() => {
              this.messages = {
                text: "",
                type: "null",
              };
            }, 2000);
          }
        });
    },

    async createCourse(e) {
      let token = this.userInfo.token_key;

      this.description = document.querySelector(".ql-editor").innerHTML;
      console.log(this.description);

      let urls = `${process.env.VUE_APP_API}/coursenew/?Key=${token}`;
      let HeaderVal = new FormData();
      if (e.target.elements.thumbnail.files.length > 0) {
        var thumbnailVal = e.target.elements.thumbnail.files[0];
        HeaderVal.append("thumbnail", thumbnailVal);
      }

      HeaderVal.append("title", e.target.elements.title.value);
      HeaderVal.append("category", parseInt(e.target.elements.category.value));
      HeaderVal.append(
        "short_description",
        e.target.elements["short-desc"].value
      );
      // HeaderVal.append("description", this.description);
      HeaderVal.append("description", this.description);
      HeaderVal.append("outcome", e.target.elements.outcome.value);
      HeaderVal.append("requirements", e.target.elements.requirement.value);
      HeaderVal.append("language", e.target.elements.language.value);
      HeaderVal.append("price", 0);
      HeaderVal.append("course_free", true);
      HeaderVal.append("level", e.target.elements.level.value);
      HeaderVal.append("video_url", e.target.elements.video_url.value);
      HeaderVal.append("is_published", e.target.elements.Published.checked);
      HeaderVal.append("instructor", this.userInfo.Instructor_id);
      HeaderVal.append("organization_id", this.userInfo.organizes_id);

      await axios
        .post(urls, HeaderVal, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(response => {
          console.log(response);
          if (response.data.message == "your Course added successfully") {
            this.messages = {
              text: "Courses Added successfully",
              type: "success"
            };
            setTimeout(() => {
              this.messages = {
                text: "",
                type: "null"
              };
              this.$refs.addCourseRef.reset();
              this.$router.push({
                name: "InstructorCourses"
              });
            }, 1500);
          } else {
            this.messages = {
              text: " Event Faild",
              type: "error"
            };
            setTimeout(() => {
              this.messages = {
                text: "",
                type: "null"
              };
            }, 2000);
          }
        });
    },
  },
};
</script>

<style scoped>
.heightfix {
  height: calc(100vh - 170px) !important;
}
</style>
