<template>
  <div>
    <!-- <Header /> -->

    <confirmPopup v-if='popupwindow==true' :title="titlepass" @cancelClick="popupwindow=false" @confirmClick="deleteEvents(idpass)" />
    <FlashMessage v-if="this.messages.text != ''" :message="this.messages" />

    <div class="flex">
      <SideBar />

      <div
        class="w-full mr-10 mt-10 rounded-3xl heightfix"
        style="box-shadow: 0px 2px 30px 0px #01b0f926"
      >
        <div class="flex h-full relative">
          <div class="relative rounded-3xl w-full">
            <div class="flex justify-end">
              <button
                @click="addevent = true"
                class="bg-[#047857] rounded-xl text-white px-4 py-1 mt-2 mr-6 z-40"
              >
                Add Class
              </button>
            </div>

            <vue-cal
              small
              ref="vuecal"
              events-on-month-view="short"
              active-view="month"
              :disable-views="['years', 'year', 'week']"
              :events="values"
              :on-event-click="onEventClick"
              class="rounded-3xl border-none absolute top-0 bottom-0 w-full"
            >
            </vue-cal>
          </div>
        </div>
      </div>

      <div
        v-if="addevent"
        class="fixed bg-primary bg-opacity-30 top-0 left-0 right-0 bottom-0 z-50 flex items-center justify-center"
      >
        <div
          class="lg:w-[40%] bg-white rounded-xl p-10 overflow-y-auto max-h-[600px]"
        >
          <div class="flex items-center justify-between">
            <h3 class="text-xl font-bold text-blues">Add Class</h3>
            <img
              src="@/assets/newd/close.png"
              class="cursor-pointer"
              alt=""
              @click="this.addevent = false"
            />
          </div>

          <div
            class="w-full mt-4 flex flex-col p-6 relative border border-gray-100 rounded-2xl"
          >
            <div class="w-full">
              <form
              ref="ScheduleEvent"
                @submit.prevent="scheduledate"
                class="flex flex-col gap-4 text-xs md:text-sm lg:text-base h-full"
              >
                <div
                  class="flex flex-col gap-2 w-full text-xs md:text-sm lg:text-base"
                >
                  <div class="w-full flex flex-col gap-2">
                    <label for="title" class="text-left">Title</label>
                    <input
                      id="meetingTitle"
                      type="text"
                      class="border border-gray-100 rounded text-xs md:text-sm lg:text-base py-2 px-2"
                      required
                    />
                  </div>
                  <div class="w-full flex flex-col gap-2">
                    <label for="duedate" class="text-left">Date</label>
                    <input
                      id="meetingDate"
                      type="datetime-local"
                      class="border border-gray-100 rounded text-xs md:text-sm lg:text-base py-2 px-2"
                      required
                    />
                  </div>
                </div>
                <!-- <div class="flex flex-col w-full gap-2">
                  <label for="description" class="text-left">Description</label>
                  <textarea
                    id="description"
                    rows="3"
                    cols="10"
                    class="border border-gray-100 h-full rounded text-xs md:text-sm lg:text-base py-2 px-2"
                  />
                </div> -->
                <div class="flex flex-col w-full">
                  <label for="attachments" class="text-left">
                    Session duration
                  </label>
                  <input
                    id="duration"
                    type="number"
                    class="border border-gray-100 rounded text-xs md:text-sm lg:text-base py-2 px-2"
                    required
                  />
                </div>

                <div class="flex flex-col w-full">
                  <label for="attachments" class="text-left">
                    Learner limit
                  </label>
                  <input
                    id="studentLimit"
                    type="number"
                    class="border border-gray-100 rounded text-xs md:text-sm lg:text-base py-2 px-2"
                    required
                  />
                </div>

                <div class="flex flex-col w-full">
                  <label for="attachments" class="text-left">
                    Meeting URL
                  </label>
                  <div class="flex items-center gap-3 w-full">
                    <input
                      id="meetingUrl"
                      type="text"
                      class="border border-gray-100 rounded text-xs md:text-sm lg:text-base py-2 px-2 w-[100%]"
                      v-model="generatedUrl"
                      required
                    />
                    <!-- <button
                      type="button"
                      id="urlGenerator"
                      class="bg-blues p-2 text-sm text-white rounded-xl w-[20%]"
                      @click="generateUrl()"
                    >
                      Generate URL
                    </button> -->
                  </div>
                </div>

                <div class="flex justify-end pb-4 mt-3">
                  <button
                    type="submit"
                    class="text-black border border-black rounded-lg text-sm px-10 py-2"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="popUp"
        class="fixed bg-primary bg-opacity-30 top-0 left-0 right-0 bottom-0 z-[100] flex items-center justify-center"
      >
        <div
          class="w-[40%] bg-white rounded-xl p-10 overflow-y-auto max-h-[600px]"
        >
          <div class="flex items-center justify-between">
            <h3 class="text-xl font-bold text-blues">Edit Class</h3>
            <img
              src="@/assets/newd/close.png"
              class="cursor-pointer"
              alt=""
              @click="this.popUp = false"
            />
          </div>

          <div
            class="w-full mt-4 flex flex-col p-6 relative border border-gray-100 rounded-2xl"
          >
            <div class="w-full">
              <form
              ref="ScheduleEvent"
                @submit.prevent="editEvents"
                class="flex flex-col gap-4 text-xs md:text-sm lg:text-base h-full"
              >
                <div
                  class="flex flex-col gap-2 w-full text-xs md:text-sm lg:text-base"
                >
                  <div class="w-full flex flex-col gap-2">
                    <label for="title" class="text-left">Title</label>
                    <input
                      id="meetingTitle"
                      type="text"
                      v-model="updatevalues.title"
                      class="border border-gray-100 rounded text-xs md:text-sm lg:text-base py-2 px-2"
                      required
                    />
                  </div>
                  <div class="w-full flex flex-col gap-2">
                    <label for="duedate" class="text-left">Date</label>
                    <input
                      id="meetingDate"
                      type="datetime-local"
                      v-model="updatevalues.date" 
                      class="border border-gray-100 rounded text-xs md:text-sm lg:text-base py-2 px-2"
                      required
                    />
                  </div>
                </div>
                <!-- <div class="flex flex-col w-full gap-2">
                  <label for="description" class="text-left">Description</label>
                  <textarea
                    id="description"
                    rows="3"
                    cols="10"
                    class="border border-gray-100 h-full rounded text-xs md:text-sm lg:text-base py-2 px-2"
                  />
                </div> -->
                <div class="flex flex-col w-full">
                  <label for="attachments" class="text-left">
                    Session duration
                  </label>
                  <input
                    id="duration"
                    type="number"
                    min="1" v-model="updatevalues.duration"
                    class="border border-gray-100 rounded text-xs md:text-sm lg:text-base py-2 px-2"
                    required
                  />
                </div>

                <div class="flex flex-col w-full">
                  <label for="attachments" class="text-left">
                    Student limit
                  </label>
                  <input
                    id="studentLimit"
                    type="number"
                    min="1" v-model="updatevalues.count"
                    class="border border-gray-100 rounded text-xs md:text-sm lg:text-base py-2 px-2"
                    required
                  />
                </div>

                <div class="flex flex-col w-full">
                  <label for="attachments" class="text-left">
                    Meeting URL
                  </label>
                  <div class="flex items-center gap-3 w-full">
                    <input
                      id="meetingUrl"
                      type="text"
                      class="border border-gray-100 rounded text-xs md:text-sm lg:text-base py-2 px-2 w-[100%]"
                      v-model="updatevalues.url"
                      required
                    />
                    <!-- <button
                      type="button"
                      id="urlGenerator"
                      class="bg-blues p-2 text-sm text-white rounded-xl w-[20%]"
                      @click="generateUrl()"
                    >
                      Generate URL
                    </button> -->
                  </div>
                </div>

                <div class="flex justify-end pb-4 mt-3">
                  <button
                    type="submit"
                    class="text-black border border-black rounded-lg text-sm px-10 py-2"
                  >
                    Update
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>


      <div
      v-if="eventInfo"
        class="fixed bg-primary bg-opacity-30 top-0 left-0 right-0 bottom-0 z-50 flex items-center justify-center"
      >
        <div
          class="w-[40%] bg-white rounded-xl p-10 overflow-y-auto max-h-[600px]"
        >
          <div class="flex items-center justify-between">
            <h3 class="text-xl font-bold text-blues">{{this.eventInfoData.title}}</h3>
            <img
              src="@/assets/newd/close.png"
              class="cursor-pointer"
              alt=""
              @click="eventInfo= false"
            />
          </div>

          <div
            class="w-full mt-4 flex flex-col p-6 relative border border-gray-100 rounded-2xl"
          >
            <div class="w-full">
              <form
              ref="ScheduleEvent"
                @submit.prevent="scheduledate"
                class="flex flex-col gap-4 text-xs md:text-sm lg:text-base h-full"
              >
                <div
                  class="flex flex-col gap-2 w-full text-xs md:text-sm lg:text-base"
                >
                  <div class="w-full flex flex-col gap-2">
                    <label for="title" class="text-left font-bold">Title</label>
                    <p>{{this.eventInfoData.title}}</p>
                    <!-- <input
                      id="meetingTitle"
                      type="text"
                      v-model="updatevalues.title"
                      class="border border-gray-100 rounded text-xs md:text-sm lg:text-base py-2 px-2"
                      required
                    /> -->
                  </div>
                  <div class="w-full flex flex-col gap-2">
                    <label for="duedate" class="text-left font-bold">Date</label>
                    <p>{{ eventInfoData.start && eventInfoData.start.format('DD/MM/YYYY') }}
                    ( {{ eventInfoData.start && eventInfoData.start.formatTime() }} 
                    ) </p>
                    <!-- <input
                      id="meetingDate"
                      type="datetime-local"
                      v-model="updatevalues.date" 
                      class="border border-gray-100 rounded text-xs md:text-sm lg:text-base py-2 px-2"
                      required
                    /> -->
                  </div>
                </div>
            
                <div class="flex flex-col w-full">
                  <label for="attachments" class="text-left font-bold">
                    Event Details
                  </label>

                  <!-- <p></p> -->
                  <p v-html="eventInfoData.contentFull" class="text-sm mt-1 text-gray-600" />
                  <!-- <input
                    id="duration"
                    type="number"
                    min="1" v-model="updatevalues.duration"
                    class="border border-gray-100 rounded text-xs md:text-sm lg:text-base py-2 px-2"
                    required
                  /> -->
                </div>

                
                

                <div class="flex justify-end pb-4 mt-3 gap-x-10">
                  <button @click="updatevalues=eventInfoData.event;popUp=true"
                    class="text-black border border-black rounded-lg text-sm px-10 py-2"
                  >
                    Update
                  </button>
                  <button @click="titlepass=eventInfoData.title;popupwindow=true;idpass=eventInfoData.id"
                    class="text-black border border-black rounded-lg text-sm px-10 py-2"
                  >
                    Delete
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import Header from "@/components/Admin/Header.vue";
import SideBar from "@/components/Admin/SideBar.vue";

import axios from "axios";
import { mapGetters } from "vuex";

import VueCal from "vue-cal";
import "vue-cal/dist/vuecal.css";

import FlashMessage from "@/components/FlashMessage.vue";
import ConfirmPopup from "@/components/ConfirmPopup.vue";

export default {
  components: {
    Header,
    SideBar,
    VueCal,
    ConfirmPopup,
    FlashMessage
  },
  data() {
    return {
      courseInfo: null,
      calendarInfo: null,
      scheduleDetails: null,
      popUp: false,
      updatevalues: null,
      messages: {
        text: "",
        type: "null",
      },
      popupwindow: false,
      titlepass: "",
      idpass: "",
      addevent: false,
      activeView: "month",
      eventInfo: false,
      eventInfoData: "",
      values: [],
      generatedUrl: "",
    };
  },

  created() {
    // this.getstudentdetails();
  },

  // props: ["course_id"],

  computed: {
    ...mapGetters(["userInfo"]),

    minDate() {
      return new Date().subtractDays(5);
    },
  },

  async mounted() {
    await this.getinfos();
    // setTimeout(() => {
    //   let chatContainer = document.getElementById("chatContainer");
    //   console.log("Height::::=", chatContainer.offsetHeight);
    //   chatContainer.scrollTo({
    //     top: chatContainer.scrollHeight + 300,
    //     behavior: "smooth",
    //   });
    // }, 200)
  },

  methods: {
    onEventClick(event, e) {
      this.eventInfo = true;
      this.eventInfoData = event;
    },

    async getinfos() {
      // let course_ids = parseInt(this.course_id);

      await axios
        .get(`${process.env.VUE_APP_API}/courses/`)
        .then((Response) => {
          this.courseInfo = Response.data.data;
        });

      await axios
        .get(
          `${process.env.VUE_APP_API}/calendar/view/?user=${this.userInfo.Instructor_id}&Key=${this.userInfo.token_key}`
        )
        .then((Response) => {
          if (Object.keys(Response.data) == "error") {
            this.values = [];
            console.log("Null");
          } else {
            this.calendarInfo = Response.data.data;
            this.values = [];
            this.calendarInfo.forEach((element) => {
              // adding 30mits to the date
              let startdate = new Date(element.date);
              let enddate = new Date(startdate);
              enddate = new Date(
                enddate.setMinutes(startdate.getMinutes() + 30)
              );
              enddate =
                [
                  enddate.getFullYear(),
                  enddate.getMonth() + 1,
                  enddate.getDate(),
                ].join("-") +
                " " +
                [
                  enddate.getHours(),
                  enddate.getMinutes(),
                  enddate.getSeconds(),
                ].join(":");
              // console.log(enddate,enddate.length,'end time before')
              // enddate = "2022-9-22 12:31:0"
              // console.log(enddate.length,'end time after')
              // reasigning the data to vue-cal event
              let tempvar = {
                start: element.date,
                end: enddate,
                title: element.title,
                class: "Progressive",
                contentFull: `<div class="w-full flex-col gap-1"> 
                                            <div class="flex flex-wrap gap-2">
                                                <span class="text-base">Link :</span>
                                                <span >${element.url}</span>
                                            </div> 
                                         <div class="flex gap-2"><span class="text-base">Duration :</span><span>${
                                           element.duration
                                         }</span></div>
                                           <div class="flex gap-2"><span class="text-base">Student limit :</span> <span>${
                                             element.count
                                           }</span></div>
                                            <div class="flex gap-2"><span class="text-base">Student Booked :</span> <span>${
                                              element.studentlist == null
                                                ? 0
                                                : element.studentlist.length
                                            }</span></div> 
                                            </div>`,
                id: element.id,
                event: element,
              };
              this.values.push(tempvar); // pushing the database event to the vue-cal
            });
            console.log(this.values);
          }
        });
    },

    async deleteEvents(id) {
      await axios
        .delete(
          `${process.env.VUE_APP_API}/calendarupdated/${id}/?Key=${this.userInfo.token_key}`
        )
        .then((response) => {
          response;
          if (response.statusText == "No Content") {
            this.messages = {
              text: "Event Deleted successfully",
              type: "success",
            };
          } else {
            this.messages = {
              text: " Event Faild",
              type: "error",
            };
          }
        });

      this.popupwindow = false;
      this.eventInfo = false;
      setTimeout(() => {
        this.messages = {
          text: "",
          type: "null",
        };
        this.getinfos();
      }, 1500);
    },

    async editEvents() {
      let updatedatas = {
        title: this.updatevalues.title,
        organization_id: this.updatevalues.organization_id,
        user: this.updatevalues.user,
        status: this.updatevalues.status,
        url: this.updatevalues.url,
        date: this.updatevalues.date,
        count: this.updatevalues.count,
        duration: this.updatevalues.duration,
        studentlist: null,
        // course_data: parseInt(this.course_id),
      };
      this.popUp = false;

      await axios
        .put(
          `${process.env.VUE_APP_API}/calendarupdated/${this.updatevalues.id}/?Key=${this.userInfo.token_key}`,
          updatedatas
        )
        .then((response) => {
          response;
          if (response.statusText == "OK") {
            this.messages = {
              text: "Event Updated successfully",
              type: "success",
            };
          } else {
            this.messages = {
              text: " Event Faild",
              type: "error",
            };
          }
        });

      this.updatevalues = null;
      this.eventInfo = false;
      setTimeout(async () => {
        this.messages = {
          text: "",
          type: "null",
        };
        await this.getinfos();
      }, 1500);
    },
    generateUrl() {
      let title = document.getElementById("meetingTitle").value;
      let durations = document.getElementById("duration").value;
      let timedetails = document.getElementById("meetingDate").value;
      timedetails = timedetails.replaceAll("T", " ");
      let inputdatas = {
        topic: title,
        start_time: timedetails,
        agenda: title,
        duration: durations,
      };
      console.log(inputdatas);

      axios
        .post(
          `${process.env.VUE_APP_API}/zoom/?Key=${this.userInfo.token_key}`,
          inputdatas
        )
        .then((response) => {
          // console.log(response)
          this.generatedUrl = response.data.data.zoom_join_url;
          // console.log(this.generatedUrl)
          document.getElementById("meetingUrl").classList.remove("hidden");
          document.getElementById("urlGenerator").classList.add("hidden");
        });
    },

    generateUrlEdit() {
      let title = this.updatevalues.title;
      let durations = this.updatevalues.duration;
      let timedetails = this.updatevalues.date;
      timedetails = timedetails.replaceAll("T", " ");
      let inputdatas = {
        topic: title,
        start_time: timedetails,
        agenda: title,
        duration: durations,
      };
      console.log(inputdatas);

      axios
        .post(
          `${process.env.VUE_APP_API}/zoom/?Key=${this.userInfo.token_key}`,
          inputdatas
        )
        .then((response) => {
          // console.log(response)
          this.updatevalues.url = response.data.data.zoom_join_url;
          // console.log(this.generatedUrl)
          document.getElementById("meetingUrledit").classList.remove("hidden");
          document.getElementById("Editurl").classList.add("hidden");
        });
    },

    async scheduledate(el) {
      let Urls = el.target.elements.meetingUrl.value;
      let timedetails = el.target.elements.meetingDate.value;
      let studentlimit = el.target.elements.studentLimit.value;
      let durations = el.target.elements.duration.value;
      let title = el.target.elements.meetingTitle.value;
      timedetails = timedetails.replaceAll("T", " ");

      // "organization_id": this.courseInfo[0].organization_id,

      let inputdatas = {
        title: title,
        organization_id: this.userInfo.organizes_id,
        user: this.userInfo.user.id,
        status: true,
        url: Urls,
        date: timedetails,
        count: studentlimit,
        duration: durations,
        studentlist: null,
        // course_data: parseInt(this.course_id),
      };

      console.log(inputdatas, "data first:::::");

      await axios
        .post(
          `${process.env.VUE_APP_API}/calendar_new/?Key=${this.userInfo.token_key}`,
          inputdatas
        )
        .then((response) => {
          this.scheduleDetails = response.data;
        });
      if (this.scheduleDetails.message == "your Calendar added successfully") {
        this.messages = {
          text: "Event Added successfully",
          type: "success",
        };
      } else if (this.scheduleDetails == "is not a staff user") {
        this.messages = {
          text: " Event Faild",
          type: "error",
        };
      }
      this.$refs.ScheduleEvent.reset();
      await this.getinfos();
      this.addevent = false;
      setTimeout(() => {
        this.generatedUrl = "";
        this.messages = {
          text: "",
          type: "null",
        };
      }, 1500);
    },
  },
};
</script>

<style scoped>

.heightfix{
  height: calc(100vh - 170px) !important;
}


.vuecal--blue-theme {
  border-radius: 50px;
  background-color: white;
  position: relative;
  border: none !important;
}

.vuecal__event {
  color: #115e59;
  background-color: #f5f5f5;
  border: 1px dashed #ba0606;
  overflow: hidden;
}
.vuecal__cell-content {
  height: 180px;
}
.vuecal__event.Progressive {
  background-color: #0f766e;
  border-radius: 6px;
  border: 1px solid rgb(248, 247, 247);
  color: #fff;
}
.vuecal--short-events .vuecal__event-title {
  font-size: 0.8em;
  text-align: center;
}
.vuecal__cell-events {
  height: 180px;
  overflow: scroll;
  scrollbar-color: white transparent;
  cursor: pointer;
}

.vuecal__cell-content::-webkit-scrollbar {
  width: 8px;
}

.vuecal__cell-content::-webkit-scrollbar-thumb {
  background: gray;
  border-radius: 15px;
}

.vuecal__cell-content::-webkit-scrollbar-track {
  background: #fff;
}
</style>
