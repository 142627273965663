<template>
  <div class="min-h-screen relative">
    <Header />
    <!-- <FlashMessage v-if="this.messages.text != ''" :message="this.messages" /> -->
    <div
      class="min-h-screen container mx-auto max-w-[1200px] w-full mb-10 py-7 flex flex-col gap-10 px-4 md:px-6 lg:gap-16"
    >
      <div>
        <div class="flex flex-row items-center justify-center">
          <p
            class="text-[#0F172A] text-xl sm:text-2xl md:text-3xl font-semibold py-5"
          >
            Blogs
          </p>
        </div>
        <div
          v-if="loading"
          class="flex items-center justify-center"
          style="height: calc(100vh - 350px) !important"
        >
          <img src="@/assets/newd/loading.gif" alt="" />
        </div>
        <div v-else>
          <div v-if="this.student_bloglist.length > 0">
            <div class="grid lg:grid-cols-3 gap-10">
              <div
                class="rounded-3xl"
                v-for="blog in this.student_bloglist"
                :key="blog"
                style="box-shadow: 0px 2px 20px 0px #105c8e26"
              >
                <router-link
                  :to="{
                    name: 'StudentBlogsList',
                    params: { student_blog_id: blog.id },
                  }"
                >
                  <div
                    class="flex flex-col min-h-[380px] justify-between gap-2 lg:text-left px-6 py-6 relative"
                  >
                    <img
                      v-if="blog.thumbnail"
                      class="object-cover object-center h-[250px] w-full rounded-lg"
                      :src="blog.thumbnail"
                      alt=""
                    />
                    <img
                      v-else
                      class="object-cover object-center h-24 w-52 rounded"
                      src="@/assets/coding.svg"
                      alt=""
                    />

                    <h1 class="font-bold text-lg text-primary leading-6">
                      {{ blog.title }}
                    </h1>
                    <p class="text-blues text-sm font-bold">
                      {{ blog.bolger_id.display_name }}
                    </p>

                    <div class="text-sm text-gray-500 font-medium">
                      {{ formatDate(blog.updated_date) }}
                    </div>

                    <p
                      class="text-black line-clamp-3 text-sm"
                      v-html="blog.content"
                    ></p>
                  </div>
                </router-link>
              </div>
            </div>
            <!-- Pagination Controls -->
            <div class="flex justify-center mt-6 space-x-3">
              <button
                class="px-4 py-2 bg-gray-200 rounded hover:bg-gray-400 hover:text-white disabled:text-black disabled:bg-gray-100 disabled:cursor-not-allowed"
                :disabled="currentPage === 1"
                @click="prevPage"
              >
                Previous
                <span
                  class="ml-1 transition-transform duration-200 ease-in-out group-hover:translate-x-1 inline-block"
                >
                  &laquo;</span
                >
              </button>
              <button
                class="px-5 py-2 bg-white border border-gray-400 text-gray-800 font-semibold rounded cursor-default"
                disabled
              >
                {{ currentPage }} / {{ totalPages }}
              </button>
              <button
                class="px-4 py-2 bg-gray-200 rounded hover:bg-gray-400 hover:text-white disabled:text-black disabled:bg-gray-100 disabled:cursor-not-allowed"
                :disabled="currentPage >= totalPages"
                @click="nextPage"
              >
                Next
                <span
                  class="ml-1 transition-transform duration-200 ease-in-out group-hover:translate-x-1 inline-block"
                >
                  &raquo;</span
                >
              </button>
            </div>
          </div>
          <div v-else class="text-lg font-normal text-center">
            No data Found
          </div>
        </div>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import Footer from "@/components/Footer.vue";
import Header from "@/components/Student/Header.vue";

export default {
  data() {
    return {
      student_bloglist: [],
      currentPage: 1,
      totalPages: 1,
      perPage: 10,
      loading: true,
    };
  },

  components: {
    Header,
    Footer,
  },
  computed: {
    ...mapGetters(["userInfo"]),
  },

  mounted() {
    this.fetchBlogs();
  },

  methods: {
    fetchBlogs() {
      axios
        .get(
          `${process.env.VUE_APP_API}/blog-details/list/?page=${this.currentPage}&Key=${this.userInfo.token_key}`
        )
        .then((res) => {
          if (res.data.data) {
            this.student_bloglist = res.data.data.data;
            setTimeout(() => {
              this.loading = false;
            }, 600);
            this.totalPages = Math.ceil(res.data.total / this.perPage);
          }
        })
        .catch((error) => {
          console.error("Error fetching blogs:", error);
        });
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
        this.fetchBlogs();
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.fetchBlogs();
      }
    },
    formatDate(date) {
      return new Date(date).toLocaleDateString("en-IN", {
        day: "numeric",
        month: "long",
        year: "numeric",
      });
    },
  },
};
</script>

<style scoped></style>
