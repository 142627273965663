<template>
  <div>
    <!-- <Header /> -->

    <FlashMessage v-if="this.messages.text != ''" :message="this.messages" />

    <div class="flex">
      <SideBar />

      <div
        class="w-[90%] mx-auto lg:w-full lg:mr-10 mt-10 rounded-3xl overflow-y-auto heightfix"
        style="box-shadow: 0px 2px 30px 0px #01b0f926"
      >
        <div class="flex flex-col h-full">
          <div class="flex items-center justify-between px-5 lg:px-10 py-10">
            <div>
              <h1 class="text-lg font-semibold">Invite course</h1>
            </div>
            <div>
              <button
                @click="this.addinvite = true"
                class="bg-blues text-white font-semibold text-xs px-4 py-2 rounded-xl"
              >
                Add Invite
              </button>
            </div>
          </div>
          <div
            v-if="loading"
            class="flex items-center justify-center"
            style="height: calc(100vh - 350px) !important"
          >
            <img src="@/assets/newd/loading.gif" alt="" />
          </div>
          <div v-else>
            <div v-if="this.invitelist.length > 0" >
              <div class="overflow-x-scroll">
              <table class="table-auto w-full ">
                <thead>
                  <tr>
                    <th
                      class="px-4 lg:px-7 py-8 border text-center border-r text-blues whitespace-nowrap"
                    >
                      S NO
                    </th>
                    <th
                      class="px-4 lg:px-7 py-8 border text-center border-r text-blues  w-[500px] mx-auto whitespace-nowrap"
                    >
                      Course Title
                    </th>
                    <th
                      class="px-4 lg:px-7 py-8 border text-center border-r text-blues"
                    >
                      Email
                    </th>

                    <th
                      class="px-4 lg:px-7 py-8 border text-center border-r text-blues"
                    >
                      Start Date
                    </th>

                    <th
                      class="px-4 lg:px-7 py-8 border text-center border-r text-blues"
                    >
                      End Date
                    </th>
                  </tr>
                </thead>

                <tbody v-if="this.invitelist.length > 0">
                  <tr v-for="(el, index) in this.invitelist" :key="el">
                    <td class="px-4 lg:px-7 py-8 border-b text-center border-r">
                      <p class="text-primary">
                        {{ (currentPage - 1) * perPage + index + 1 }}
                      </p>
                    </td>
                    <td class="px-4 lg:px-7 py-8 border-b text-center border-r">
                      <p v-if="el.coures" class="text-primary text-wrap w-[500px] mx-auto ">
                        {{ el.coures }}
                      </p>
                      <p v-else class="text-primary">NA</p>
                    </td>
                    <td
                      class="px-4 lg:px-7 py-8 border-b text-center border-r whitespace-nowrap"
                    >
                      <p v-if="el.email" class="text-primary">{{ el.email }}</p>
                      <p v-else class="text-primary">NA</p>
                    </td>
                    <td
                      class="px-4 lg:px-7 py-8 border-b text-center border-r whitespace-nowrap"
                    >
                      <p v-if="el.created_at" class="text-primary">
                        {{ formatDate(el.created_at) }}
                      </p>
                      <p v-else class="text-primary">NA</p>
                    </td>
                    <td
                      class="px-4 lg:px-7 py-8 border-b text-center border-r whitespace-nowrap"
                    >
                      <p v-if="el.updated_at" class="text-primary">
                        {{ formatDate(el.updated_at) }}
                      </p>
                      <p v-else class="text-primary">NA</p>
                    </td>
                  </tr>
                </tbody>
                <!-- <tbody v-else>
                <tr class="w-full">
                  <td colspan="8" class="text-center py-6">
                    <div class="w-full flex justify-center items-center">
                      No Data Found
                    </div>
                  </td>
                </tr>
              </tbody> -->
              </table>
              </div>
              <div   v-if="totalPages > 1" class="flex justify-center py-5 space-x-2">
                <button
                  class="px-3 py-2 bg-gray-200 rounded disabled:opacity-50"
                  :disabled="currentPage === 1"
                  @click="prevPage"
                >
                  &laquo;
                </button>

                <button
                  v-for="page in paginationRange"
                  :key="page"
                  class="px-3 py-2 rounded"
                  :class="{
                    'bg-blue-500 text-white': currentPage === page,
                    'bg-white border border-gray-400 text-gray-800':
                      currentPage !== page,
                  }"
                  @click="changePage(page)"
                >
                  {{ page }}
                </button>

                <button
                  class="px-3 py-2 bg-gray-200 rounded disabled:opacity-50"
                  :disabled="currentPage >= totalPages"
                  @click="nextPage"
                >
                  &raquo;
                </button>
              </div>
            </div>
            <div v-else class="flex justify-center">No Data Found</div>
          </div>
        </div>
      </div>

      <div
        v-if="this.addinvite"
        @click.self="this.addinvite = false"
        class="fixed bg-primary bg-opacity-30 top-0 left-0 right-0 bottom-0 z-50 flex items-center justify-center px-6 md:px-0"
      >
        <div
          class="w-[90%] lg:w-[40%] bg-white rounded-xl p-6 lg:p-10 overflow-y-auto max-h-[600px]"
        >
          <div class="flex items-center justify-center">
            <h3 class="text-xl font-bold text-blues text-center">Add invite</h3>
          </div>

          <div
            class="w-full mt-4 flex flex-col p-6 relative border-gray-100 rounded-2xl"
          >
            <div class="w-full">
              <form
                ref="createTeamRef"
                @submit.prevent="invitepost"
                class="flex flex-col gap-4 text-xs md:text-sm lg:text-base h-full"
              >
                <!-- <div class="flex flex-col gap-3 w-full">
                  <label for="category" class="flex gap-2 items-center">
                    <div>
                      Student list
                      <span class="text-red-400">*</span>
                    </div>
                  </label>

                  <select
                    name="studentmail"
                    class="bg-[#F9FAFC] rounded-lg text-sm w-full p-3 border-none outline-none"
                    id="studentmail"
                  >
                    <option value="" disabled selected>Select email</option>
                    <option
                      v-for="student in studentlist"
                      :key="student"
                      :value="student.email"
                    >
                      {{ student.email }}
                    </option>
                  </select>
                </div> -->
                <div class="flex flex-col gap-3 w-full">
                  <label for="studentmail" class="flex gap-2 items-center">
                    <div>Student list <span class="text-red-400">*</span></div>
                  </label>

                  <input
                    type="text"
                    id="studentmail"
                    v-model="searchQuery"
                    @input="Studentsearch"
                    placeholder="Search email"
                    class="bg-[#F9FAFC] rounded-lg text-sm w-full p-3 border-none outline-none"
                  />
                  <ul
                    v-if="showdetail == false && this.studentlist.length > 0"
                    class="bg-white w-full border rounded-lg shadow-md h-[150px] overflow-y-auto mt-1"
                  >
                    <li
                      v-for="student in studentlist.slice(0, lengthCount)"
                      :key="student.email"
                      class="p-2 cursor-pointer hover:bg-gray-100"
                      @click="selectStudent(student.email)"
                    >
                      {{ student.email }}
                    </li>
                  </ul>
                </div>

                <div class="flex justify-center pb-4 mt-3 gap-10">
                  <button
                    @click="closepopup"
                    class="border text-gray-500 rounded-lg text-sm px-5 lg:px-10 font-semibold py-2"
                  >
                    Close
                  </button>
                  <div class="flex gap-3 items-center">
                    <button
                      :disabled="disableStatus"
                      :class="{
                        'cursor-not-allowed bg-gray-200 text-black':
                          disableStatus === true,
                        'cursor-pointer bg-blues text-white ':
                          disableStatus === false,
                      }"
                      type="submit"
                      class="rounded-lg text-sm px-5 lg:px-10 font-semibold py-2"
                    >
                      Invite
                    </button>
                    <div v-if="loaders" class="spinner hidden"></div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Instructor/Header.vue";
import SideBar from "@/components/Instructor/SideBar.vue";
import FlashMessage from "@/components/FlashMessage.vue";
import axios from "axios";
import { mapGetters } from "vuex";

export default {
  components: {
    Header,
    SideBar,
    FlashMessage,
  },
  data() {
    return {
      messages: {
        text: "",
        type: "null",
      },
      addinvite: false,
      disableStatus: false,
      loaders: false,
      // courselist: [],
      invitelist: [],
      currentPage: 1,
      totalPages: 1,
      perPage: 10,
      loading: true,
      course_title: "",
      searchQuery: "",
      showdetail: false,
      lengthCount: 10,
    };
  },

  async created() {
    await this.studentlistget();
    await this.courselistget();
    await this.inviteget();
  },

  props: ["course_id"],

  computed: {
    ...mapGetters(["userInfo"]),
    paginationRange() {
      const totalPages = this.totalPages;
      const currentPage = this.currentPage;
      let pages = [];

      if (totalPages <= 5) {
        pages = [...Array(totalPages).keys()].map((x) => x + 1);
      } else {
        if (currentPage > 3) pages.push(1);
        if (currentPage > 4) pages.push("...");

        const startPage = Math.max(2, currentPage - 1);
        const endPage = Math.min(totalPages - 1, currentPage + 1);

        for (let i = startPage; i <= endPage; i++) {
          pages.push(i);
        }

        if (currentPage < totalPages - 3) pages.push("...");
        if (currentPage < totalPages - 2) pages.push(totalPages);
      }

      return pages;
    },
  },

  methods: {
   
    async studentlistget() {
      await axios
        .get(
          `${process.env.VUE_APP_API}/student-invite-email-get/?Key=${this.userInfo.token_key}`
        )
        .then((res) => {
          if (res.data.data) {
            this.studentlist = res.data.data;
            console.log("  this.studentlist", this.studentlist);
          }
        });
    },
    async courselistget() {
      await axios
        .get(
          `${process.env.VUE_APP_API}/courses/?id=${this.course_id}&&${this.userInfo.Instructor_id}`
        )
        .then((res) => {
          if (res.data.data) {
            this.course_title = res.data.data[0].title;
          }
        });
    },
    invitepost(el) {
      let bodyVal = {
        email: el.target.elements.studentmail.value,
        course: this.course_title,
        user: this.userInfo.user.id,
        status: true,
      };
      console.log("bodyVal", bodyVal);
      this.disableStatus = true;
      this.loaders = true;
      axios
        .post(
          `${process.env.VUE_APP_API}/invitation/?Key=${this.userInfo.token_key}`,
          bodyVal
        )
        .then((response) => {
          if (response.data.status == 200) {
            this.loaders = false;
            this.messages = {
              text: response.data.message,
              type: "success",
            };
            setTimeout(() => {
              this.messages = {
                text: "",
                type: "null",
              };
              this.addinvite = false;
              this.disableStatus = false;
              this.searchQuery = "";
              this.inviteget();
            }, 1500);
          } else {
            this.loaders = false;
            this.messages = {
              text: response.data.error,
              type: "error",
            };
            setTimeout(() => {
              this.messages = {
                text: "",
                type: "null",
              };

              this.disableStatus = false;
            }, 1500);
          }
        })

        .finally(() => {
          this.loaders = false;
        });
    },
    inviteget() {
      axios
        .get(
          `${process.env.VUE_APP_API}/invite-email-get/?Key=${this.userInfo.token_key}&coures=${this.course_title}&page=${this.currentPage}`
        )
        .then((res) => {
          if (res.data.data.status == 200) {
            this.invitelist = res.data.data.data;
            setTimeout(() => {
              this.loading = false;
            }, 600);
            console.log("this.invitelist1111", res.data.total);
            this.totalPages = Math.ceil(res.data.total / this.perPage);
          
          }
        });
    },
    formatDate(date) {
      return new Date(date).toLocaleDateString("en-IN", {
        day: "numeric",
        month: "long",
        year: "numeric",
      });
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
        this.inviteget();
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.inviteget();
      }
    },
     changePage(page) {
      if (page !== "..." && page !== this.currentPage) {
        this.currentPage = page;
        this.inviteget();
      }
    },
    Studentsearch() {
      this.showdetail = false;
      axios
        .get(
          `${process.env.VUE_APP_API}/student-invite-email-get/?search=${this.searchQuery}&${this.userInfo.token_key}`
        )
        .then((res) => {
          if (res.data.data) {
            this.studentlist = res.data.data;
          }
        });
    },
    selectStudent(email) {
      this.searchQuery = email;
      this.showdetail = true;
    },
    closepopup() {
      this.addinvite = false;
      this.searchQuery = "";
      this.showdetail = false;
    },
  },
};
</script>

<style scoped>
.heightfix {
  height: calc(100vh - 170px) !important;
}
/* loader  */
.spinner {
  --d: 8.6px;
  width: 1.5px;
  height: 1.5px;
  border-radius: 50%;
  color: #5593e0;
  box-shadow: calc(1 * var(--d)) calc(0 * var(--d)) 0 0,
    calc(0.707 * var(--d)) calc(0.707 * var(--d)) 0 0.5px,
    calc(0 * var(--d)) calc(1 * var(--d)) 0 1px,
    calc(-0.707 * var(--d)) calc(0.707 * var(--d)) 0 1.4px,
    calc(-1 * var(--d)) calc(0 * var(--d)) 0 1.9px,
    calc(-0.707 * var(--d)) calc(-0.707 * var(--d)) 0 2.4px,
    calc(0 * var(--d)) calc(-1 * var(--d)) 0 2.9px;
  animation: spinner-a90wxe 1s infinite steps(8);
}

@keyframes spinner-a90wxe {
  100% {
    transform: rotate(1turn);
  }
}
</style>
