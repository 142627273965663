<template>
  <div>
    <!-- <Header /> -->

    <div class="flex">
      <SideBar />

      <div
        class="w-[90%] mx-auto lg:w-full lg:mr-10 mt-10 rounded-3xl heightfix"
        style="box-shadow: 0px 2px 30px 0px #01b0f926"
      >
        <!-- desktop -->
        <div class="hidden lg:block h-full">
          <div class="flex h-full">
            <div
              class="flex flex-col gap-4 lg:w-[30%] 2xl:w-[25%] overflow-y-auto rounded-b-3xl"
            >
              <div class="pt-6 pb-2">
                <p class="text-primary text-center font-bold">Messages</p>
              </div>
              <div
              v-if="loading"
              class="flex items-center justify-center"
              style="height: calc(100vh - 350px) !important"
            >
              <img src="@/assets/newd/loading.gif" alt="" />
            </div>
            <div v-else>
              <div class="overflow-y-auto" v-if="studentList && studentList.data && studentList.data.length > 0">
                <div
                  v-for="student in this.studentList.data"
                  :key="student"
                  class=""
                >
                  <p
                    v-if="student.display_name"
                    @click="messageInfo(student)"
                    class="py-3 px-10 text-base lg:text-sm bg-white mb-0 hover:bg-gray-200 cursor-pointer flex"
                  >
                    <span class="px-2 capitalize">{{
                      student.display_name
                    }}</span>
                  </p>
                </div>
              </div>
              <div
              v-else
              class="flex flex-col justify-center"
              style="height: calc(100vh - 350px) !important"
            >
              <p class="flex justify-center">No Data Found</p>
            </div>
              </div>
            </div>

            <div
              v-if="ChatpopUp"
              class="bg-[#F0F5F9] h-full w-full rounded-3xl relative"
            >
              <div class="absolute w-full left-0 right-0">
                <div class="bg-[#FFFFFF] rounded-2xl m-4 py-2 px-3">
                  <p class="text-center text-primary font-semibold">
                    {{ this.selectedStudent.display_name }}
                  </p>
                </div>
              </div>

              <div class="h-full w-full px-3 pt-14 pb-16">
                <div class="p-4 h-full overflow-y-auto" id="chatContainer">
                  <div
                    v-for="message in totalmessage"
                    :key="message"
                    class="my-4 flex flex-col gap-1"
                  >
                    <p class="text-lg font-bold text-gray-700">
                      {{ message[3] }}
                    </p>
                    <span class="text-xs text-gray-600">{{ message[1] }}</span>
                    <p class="text-sm">{{ message[0] }}</p>
                  </div>
                </div>
              </div>

              <div class="absolute bottom-2 w-full left-0 right-0">
                <div
                  class="bg-[#FFFFFF] rounded-2xl m-4 flex items-center justify-between py-2 px-3 gap-10"
                >
                  <input
                    type="text"
                    class="w-[95%] border-none outline-none text-primary"
                    placeholder="Type your text here..."
                    @keyup.enter="sendmsg(this.selectedStudent)"
                    v-model="messageVal"
                    name=""
                    id=""
                  />
                  <button
                    @click="sendmsg(this.selectedStudent)"
                    class="bg-blues w-[30px] h-[30px] rounded-full flex items-center justify-center"
                  >
                    <img src="@/assets/newd/arrow.png" alt="" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- mobile -->
        <div class="block lg:hidden h-full">
          <div class="flex  h-full">
            <div v-if="studentmessage"
              class="flex flex-col gap-4 w-full overflow-y-auto rounded-b-3xl"
            >
              <div class="pt-6 pb-2">
                <p class="text-primary text-center font-bold">Messages</p>
              </div>

              <div class="overflow-y-auto">
                <div
                  v-for="student in this.studentList.data"
                  :key="student"
                  class=""
                >
                  <p
                    v-if="student.display_name"
                    @click="messageInfo(student)"
                    class="py-3 px-10 text-base lg:text-sm bg-white mb-0 hover:bg-gray-200 cursor-pointer flex"
                  >
                    <span class="px-2 capitalize">{{
                      student.display_name
                    }}</span>
                  </p>
                </div>
              </div>
            </div>

            <div
              v-if="ChatpopUp"
              class="bg-[#F0F5F9] lg:h-full w-full rounded-3xl relative"
            >
             <div class="flex justify-end p-2" @click="ChatpopUp = false; studentmessage = true">
            <p class=" text-blues rounded-lg text-sm font-semibold  underline cursor-pointer">Back</p>
            </div>
              <div class="absolute w-full left-0 right-0">
                <div class="bg-[#FFFFFF] rounded-2xl m-4 py-2 px-3">
                  <p class="text-center text-primary font-semibold">
                    {{ this.selectedStudent.display_name }}
                  </p>
                </div>
              </div>

              <div class="h-full w-full px-3 pt-14 pb-16">
                <div class="p-4 h-full overflow-y-auto" id="chatContainer">
                  <div
                    v-for="message in totalmessage"
                    :key="message"
                    class="my-4 flex flex-col gap-1"
                  >
                    <p class="text-lg font-bold text-gray-700">
                      {{ message[3] }}
                    </p>
                    <span class="text-xs text-gray-600">{{ message[1] }}</span>
                    <p class="text-sm">{{ message[0] }}</p>
                  </div>
                </div>
              </div>

              <div class="absolute bottom-2 w-full left-0 right-0">
                <div
                  class="bg-[#FFFFFF] rounded-2xl m-4 flex items-center justify-between py-2 px-3 gap-10"
                >
                  <input
                    type="text"
                    class="w-[95%] border-none outline-none text-primary"
                    placeholder="Type your text here..."
                    @keyup.enter="sendmsg(this.selectedStudent)"
                    v-model="messageVal"
                    name=""
                    id=""
                  />
                  <button
                    @click="sendmsg(this.selectedStudent)"
                    class="bg-blues w-[30px] h-[30px] rounded-full flex items-center justify-center"
                  >
                    <img src="@/assets/newd/arrow.png" alt="" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Student/Header.vue";
import SideBar from "@/components/Student/SideBar.vue";

import axios from "axios";
import { mapGetters } from "vuex";

export default {
  components: {
    Header,
    SideBar,
  },
  data() {
    return {
      messageVal: null,
      totalmessage: null,
      studentList: "",
      selectedStudent: null,
      ChatpopUp: false,
      instructorChat: [
        {
          id: "",
          display_name: "",
        },
      ],
       studentmessage:true,
       loading:true,
    };
  },

  created() {
    this.getstudentdetails();
  },

  props: ["course_id"],

  computed: {
    ...mapGetters(["userInfo"]),
  },

  mounted() {
    setTimeout(() => {
      let chatContainer = document.getElementById("chatContainer");

      if (chatContainer) {
        chatContainer.scrollTo({
          top: chatContainer.scrollHeight + 300,
          behavior: "smooth",
        });
      }
    }, 200);
  },

  methods: {
    scrollToBottomChat() {
      setTimeout(() => {
        console.log("Height::::=", chatContainer.offsetHeight);

        chatContainer.scrollTo({
          top: chatContainer.scrollHeight + 300,
          behavior: "smooth",
        });
      }, 200);
    },

    async getstudentdetails() {
      await axios
        .get(
          `${process.env.VUE_APP_API}/all_list/${this.course_id}/?Key=${this.userInfo.token_key}`
        )
        .then((response) => {
          // response.data["data"] = [
          //   ...new Map(
          //     response.data.data.map((i) => i[0]).map((m) => [m.id, m])
          //   ).values(),
          // ].map((i) => [i]);
          this.studentList = response.data;
           setTimeout(() => {
            this.loading = false;
          }, 1000);
        });
    },

    async messageInfo(data) {
      if (data) {
        this.selectedStudent = data;
        this.ChatpopUp = true;
        this.studentmessage=false;
        await axios
          .get(
            `${process.env.VUE_APP_API}/one_to_one_messages/${this.userInfo.user.id}/${this.selectedStudent.id}/?Key=${this.userInfo.token_key}`
          )
          .then((Response) => {
            this.totalmessage = Response.data.data.reverse();
          });

        this.scrollToBottomChat();
        // $(".chatArea").animate(
        //   {
        //     scrollTop: $(".chatArea")[0].scrollHeight,
        //   },
        //   1000
        // );
      }
    },

    async sendmsg(data) {
      let messagesPass = {
        text: this.messageVal,
        owner: this.userInfo.user.id,
        client: data.id,
      };

      await axios
        .post(
          `${process.env.VUE_APP_API}/talk/?Key=${this.userInfo.token_key}`,
          messagesPass
        )
        .then((Response) => {
          Response;
        });

      this.messageInfo(this.selectedStudent);
      this.scrollToBottomChat();
      setTimeout(() => {
        this.messageVal = null;
      }, 1000);
    },
  },
};
</script>

<style scoped>
.heightfix {
  height: calc(100vh - 170px) !important;
}
</style>
