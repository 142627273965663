<template>
  <div>
    <Header />
    <div class="w-full">
      <div class="container mx-auto max-w-[1200px] heightfix overflow-y-auto">
        <div class="py-10 px-6" v-if="notificationIndex == null">
          <div class="font-bold text-xl pb-3 flex items-center gap-2">
            Notifications
            <p
              class="bg-primary flex items-center justify-center text-white !w-[20px] !h-[20px] text-xs rounded-full"
              v-if="this.notificationList.length != 0"
            >
              {{ notificationList.length }}
            </p>
          </div>
          <div class="flex flex-col gap-2">
            <div v-if="this.notificationList.length != 0" class="mt-2 flex flex-col gap-4">
              <div
                class="flex flex-col border-2 gap-1 py-4 px-5 rounded-xl cursor-pointer"
                @click="setNotificationTab(index)"
                v-for="(notifi, index) in this.notificationList"
                :key="notifi"
              >
                <p class="font-bold text-lg">{{ notifi.topic }}</p>
                <p class="text-sm">
                  {{ new Date(notifi.created_at).toLocaleDateString("en-IN") }}
                </p>
              </div>
            </div>
            <p v-if="this.notificationList.length == 0" class="flex flex-col border-2 gap-1 py-4 px-5 rounded-xl cursor-pointer">No Notifications</p>
          </div>
        </div>

        <div class="py-10 px-6" v-if="notificationIndex != null">
          <button
            @click="notificationIndex = null"
            class="bg-primary text-xs font-bold text-white px-5 py-1.5 rounded-full"
          >
            Back
          </button>

          <div class="font-bold text-xl ml-2 mt-6 pb-3 flex items-center gap-2">
            {{ selectNotificationData.topic }}
            <!-- <p
                class="bg-primary flex items-center justify-center text-white !w-[20px] !h-[20px] text-xs rounded-full"
                v-if="this.notificationList.length != 0"
              >
                {{ notificationList.length }}
              </p> -->
          </div>

          <div class="flex">
            <p
              class="text-xs bg-primary px-3 py-1 rounded-xl text-white ml-2 font-semibold"
            >
              {{
                new Date(selectNotificationData.created_at).toLocaleDateString(
                  "en-IN"
                )
              }}
            </p>
          </div>

          <div class="flex flex-col gap-2 ml-2 mt-4">
            <p class="border-2 rounded-xl px-4 mt-2 py-4 text-sm">
              {{ selectNotificationData.message }}
            </p>
            <!-- <div v-if="this.notificationList.length != 0" class="mt-2">
                <div
                  class="flex flex-col border gap-1 py-4 px-5 rounded-lg cursor-pointer"
                  @click="setNotificationTab(index)"
                  v-for="(notifi, index) in this.notificationList"
                  :key="notifi"
                >
                  <p class="font-bold text-lg">{{ notifi.topic }}</p>
                  <p class="text-sm">
                    {{ new Date(notifi.created_at).toLocaleDateString("en-IN") }}
                  </p>
                </div>
              </div>
              <p v-if="this.notificationList.length == 0">No Data Found</p> -->
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Footer from "@/components/Footer.vue";
import Header from "@/components/Instructor/Header.vue";
import { mapGetters } from "vuex";
import axios from "axios";

export default {
  name: "Notifications",

  components: {
    Header,
    Footer,
  },

  data() {
    return {
      notificationList: [],
      notificationIndex: null,
      selectNotificationData: "",
    };
  },

  computed: {
    ...mapGetters(["userInfo"]),
  },

  created() {
    this.getNotifications();
  },

  methods: {
    setNotificationTab(index) {
      this.notificationIndex = index;
      this.selectNotificationData = this.notificationList[index];
    },
    async getNotifications() {
      await axios
        .get(
          `${process.env.VUE_APP_API}/notifications/${this.userInfo.user.id}/?Key=${this.userInfo.token_key}`
        )
        .then((response) => {
          if (response.data.status == 200) {
            this.notificationList = response.data.data;
          }
        });
    },
  },
};
</script>

<style scoped>
td,
thead,
tr {
  padding: 10px 20px;
  border: 1px solid black !important;
}

.heightfix {
  height: calc(100vh - 170px) !important;
}
</style>
