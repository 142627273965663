<template>
  <div>
    <!-- <Header /> -->

    <div class="flex">
      <SideBar />

      <div
        class="w-[90%] mx-auto lg:w-full lg:mr-10 mt-10 rounded-3xl overflow-x-auto overflow-y-auto heightfix "
        style="box-shadow: 0px 2px 30px 0px #01b0f926"
      >
        <div class="px-5 lg:px-10 pt-8 pb-4">
          <div class="flex items-center justify-between">
            <div>
              <h1 class="text-lg font-semibold">Live Classes</h1>
            </div>
          </div>

          <div class="h-full mt-5 grid lg:grid-cols-2 2xl:grid-cols-3 gap-10">
            <div
              v-for="events in eventInfo"
              :key="events"
              class="p-6 border rounded-xl"
            >
              <div>
                <div class="relative flex justify-center items-center">
                  <div
                    class="absolute top-4 left-6 bg-white z-20 py-1 px-4 rounded-lg flex items-center gap-2"
                  >
                    <div
                      style="
                        background-color: red;
                        width: 10px;
                        height: 10px;
                        border-radius: 100%;
                      "
                    ></div>
                    <p class="text-xs relative font-bold">Live</p>
                  </div>

                  <!--<img v-if="course.thumbnail" class="object-cover object-center h-full w-full rounded-lg"
                                :src="course.thumbnail" alt="" />  -->
                  <img
                    class="rounded-xl"
                    src="@/assets/new/events.png"
                    style="width: 100%"
                    alt=""
                  />

                  <!-- <button
                                class="absolute right-2 top-2 primary-color w-auto px-4 py-1 bg-opacity-70 text-sm rounded-md"
                                >
                                {{ course.category }}
                                </button> -->
                </div>

                <div
                  class="text-center mt-2 flex flex-col gap-1 justify-center"
                >
                  <p
                    class="text-sm text-gray-600 font-semibold text-left truncate mb-0 mt-2"
                  >
                    {{ events.title }}
                  </p>
                  <p class="text-xs text-gray-600 text-left truncate mb-0">
                    <span
                      class="text-gray-600"
                      style="color: #ff2929 !important"
                      >{{ events.date }}</span
                    >
                  </p>
                  <p
                    class="text-xs text-gray-600 text-left truncate mb-0 font-bold py-3"
                  >
                    Zoom Meeting
                  </p>

                  <div>
                    <!-- <p class="my-3 py-2 text-sm rounded-lg border-2 border-blues text-blues font-medium">Continue Learning
                                </p>  -->

                    <div v-if="events.studentlist != null">
                      <div
                        v-if="
                          events.studentlist.indexOf(
                            String(this.userInfo.user.id)
                          ) +
                            1 !=
                          0
                        "
                      >
                        <!-- <p
                          class="my-3 py-2 text-sm rounded-lg border-2 border-blues text-blues font-medium"
                          v-if="new Date(events.date) >= new Date()"
                        >
                          <a :href="events.url">Join Class </a>
                        </p>
                        <p
                          class="my-3 py-2 text-sm rounded-lg border-2 border-blues text-blues font-medium"
                          v-if="new Date(events.date) <= new Date()"
                        >
                          <a :href="events.url">Join Class </a>
                        </p> -->

                        <div v-if="new Date(events.date) >= new Date()">
                          <a :href="events.url"
                            ><p
                              class="w-full my-3 py-2 text-sm rounded-lg border-2 border-blues text-blues font-medium"
                            >
                              Join Class
                            </p></a
                          >
                        </div>

                        <div v-if="new Date(events.date) <= new Date()">
                          <a :href="events.url"
                            ><p
                              class="w-full my-3 py-2 text-sm rounded-lg border-2 border-blues text-blues font-medium"
                            >
                              Join Class
                            </p></a
                          >
                        </div>
                      </div>

                      <div
                        v-else-if="
                          events.count - events.studentlist.length == 0
                        "
                      >
                        <p
                          class="my-3 py-2 text-sm rounded-lg border-2 border-blues text-blues font-medium"
                        >
                          Booking Closed
                        </p>
                      </div>

                      <div v-else-if="new Date(events.date) >= new Date()">
                        <p
                          class="my-3 py-2 text-sm rounded-lg border-2 border-blues text-blues font-medium"
                        >
                          Book Class
                        </p>
                      </div>

                      <div v-else>
                        <p
                          class="my-3 py-2 text-sm rounded-lg border-2 border-blues text-blues font-medium"
                        >
                          Booking Closed
                        </p>
                      </div>
                      <!--  -->
                    </div>

                    <div v-else>
                      <p
                        class="my-3 py-2 text-sm rounded-lg border-2 border-blues text-blues font-medium"
                        v-if="new Date(events.date) <= new Date()"
                      >
                        Booking Closed
                      </p>

                      <p
                        class="my-3 py-2 text-sm rounded-lg border-2 border-blues text-blues font-medium"
                        v-if="new Date(events.date) >= new Date()"
                        @click="bookevents(events)"
                      >
                        Book Class
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import SideBar from "@/components/Student/SideBar.vue";

export default {
  data() {
    return {
      eventInfo: "",
      messages: {
        text: "",
        type: "null",
      },
      courseDetails: null,
      current_date: new Date().toLocaleDateString("en-IN"),
      cr_date: new Date().toLocaleDateString("en-IN", {
        day: "numeric",
        month: "long",
        year: "numeric",
      }),
    };
  },

  props: ["course_id"],

  components: {
    SideBar,
    // FlashMessage,
    // confirmPopup,
    // leftNavigationStudent,
    // VuePdfEmbed,
  },

  created() {
    this.geteventinfo();
    axios
      .get(`${process.env.VUE_APP_API}/courses/?format=json`)
      .then((response) => (this.courseDetails = response.data.data));
  },

  computed: {
    ...mapGetters(["userInfo"]),
  },

  mounted() {},

  methods: {
    async geteventinfo() {
      await axios
        .get(
          `${process.env.VUE_APP_API}/calendar/view/?user=${this.instructor_id}&Key=${this.userInfo.token_key}`
        )
        .then((response) => {
          if (Object.keys(response.data) == "error") {
            console.log("Null");
          } else {
            this.eventInfo = response.data.data;
            this.eventInfo = this.eventInfo.filter((element) => {
              return (
                element.course_data == this.course_id ||
                element.course_data == null
              );
            });
            this.eventInfo.forEach((element) => {
              element["display_date"] = new Date(
                element.date
              ).toLocaleDateString("en-IN", {
                day: "numeric",
                month: "long",
                year: "numeric",
              });
            });
            this.eventInfo = this.eventInfo.sort(function (el1, el2) {
              let x = new Date(el1.date),
                y = new Date(el2.date);
              return y - x;
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    bookevents(val) {
      if (val.studentlist == null) {
        let list_el = [];
        list_el.push(this.userInfo.user.id);
        val.studentlist = list_el;
      } else {
        let list_el1 = Object.values(val.studentlist);
        list_el1.push(this.userInfo.user.id);
        val.studentlist = list_el1;
      }
      const bookedvalues = {
        date: val.date,
        title: val.title,
        status: val.status,
        url: val.url,
        studentlist: val.studentlist,
        count: val.count,
        duration: val.duration,
        organization_id: val.organization_id,
        user: val.user,
        course_data: val.course_data,
      };

      axios
        .put(
          `${process.env.VUE_APP_API}/calendarupdated/${val.id}/?Key=${this.userInfo.token_key}`,
          bookedvalues
        )
        .then((response) => {
          if (response.statusText == "OK") {
            this.messages = {
              text: "Booked successfully",
              type: "success",
            };
          } else {
            this.messages = {
              text: " Event Faild",
              type: "error",
            };
          }
        });

      setTimeout(() => {
        this.messages = {
          text: " ",
          type: "null",
        };
        this.geteventinfo();
      }, 2000);
    },
  },
};
</script>

<style scoped>
.heightfix {
  height: calc(100vh - 170px) !important;
}
</style>
