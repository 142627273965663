<template>
  <div class="bg-[rgb(15,23,42)] py-3">
    <div class="container mx-auto max-w-[1200px] px-4">
      <div class="flex items-center justify-between">
        <!-- Logo -->
        <!-- <div>
          <img src="@/assets/new/moun.png" alt="Logo" class="w-40 py-4" />
        </div> -->
        <router-link
          :to="{ name: 'StudentHome' }"
          class="brand w-nav-brand w--current flex items-center"
        >
          <img :src="logo_image" alt="Logo" class="w-40 py-4 object-fit" />
        </router-link>

        <!-- Desktop Menu -->
        <div class="flex items-center gap-8 lg:w-[550px]">
          <div class="hidden lg:flex items-center gap-10">
            <router-link
              :to="{ name: 'StudentMyCourse' }"
              class="text-sm font-medium"
              :class="{
                'text-[#0085db]': $route.name === 'StudentMyCourse',
                'text-white': $route.name !== 'StudentMyCourse',
              }"
            >
              My Courses
            </router-link>
            <router-link
              :to="{ name: 'StudentCommunity' }"
              class="text-sm font-medium"
              :class="{
                'text-[#0085db]': $route.name === 'StudentCommunity',
                'text-white': $route.name !== 'StudentCommunity',
              }"
            >
              Community
            </router-link>
            <router-link
              :to="{ name: 'StudentCurriculum' }"
              class="text-sm font-medium"
              :class="{
                'text-[#0085db]': $route.name === 'StudentCurriculum',
                'text-white': $route.name !== 'StudentCurriculum',
              }"
            >
              Curriculum
            </router-link>
            <router-link
              :to="{ name: 'StudentBlogs' }"
              class="text-sm font-medium"
              :class="{
                'text-[#0085db]': $route.name === 'StudentBlogs',
                'text-white': $route.name !== 'StudentBlogs',
              }"
            >
              Blogs
            </router-link>
          </div>
          <!-- Notifications -->
          <div class="relative dropdown-container">
            <div class="relative" @click="MenuToggle('notification')">
              <p
                class="bg-white flex items-center justify-center text-primary !w-[15px] !h-[15px] text-xs rounded-full absolute -top-3 -right-3"
              >
                {{ notificationList.length }}
              </p>

              <img
                src="@/assets/notification.png"
                width="24"
                height="24"
                class="cursor-pointer"
              />
            </div>

            <div
              v-if="toggleMenuName == 'notification'"
              class="absolute right-[-70px] lg:right-0 top-14 lg:top-10 w-64 z-10 bg-white rounded-2xl shadow-lg p-4"
            >
              <div class="font-bold text-base pb-3 flex items-center gap-2">
                Notifications
                <p
                  class="bg-primary flex items-center justify-center text-white !w-[20px] !h-[20px] text-xs rounded-full"
                  v-if="this.notificationList.length != 0"
                >
                  {{ notificationList.length }}
                </p>
              </div>

              <div class="flex flex-col gap-2">
                <!-- {{ notificationList.length }} -->
                <!--  h-[150px] overflow-y-auto -->
                <div
                  v-if="this.notificationList.length != 0"
                  class="flex flex-col gap-2"
                >
                  <router-link
                    :to="{ name: 'StudentNotifications' }"
                    class="flex flex-col border gap-1 py-2 px-4 rounded-lg"
                    v-for="notifi in this.notificationList.slice(0, 3)"
                    :key="notifi"
                  >
                    <p class="font-bold text-sm">{{ notifi.topic }}</p>
                    <p class="text-xs">
                      {{
                        new Date(notifi.created_at).toLocaleDateString("en-IN")
                      }}
                    </p>
                  </router-link>

                  <router-link
                    :to="{ name: 'StudentNotifications' }"
                    v-if="this.notificationList.length > 3"
                    class="text-blues font-bold text-xs py-2"
                    >See More</router-link
                  >
                </div>

                <!-- <div class="flex flex-col border gap-1 py-2 px-4 rounded-lg">
                  <p class="font-semibold text-xs">New course added for you!</p>
                  <p class="font-semibold text-xs">11/02/2025</p>
                </div>
                <div class="flex flex-col border gap-1 py-2 px-4 rounded-lg">
                  <p class="font-semibold text-xs">New course added for you!</p>
                  <p class="font-semibold text-xs">11/02/2025</p>
                </div> -->
                <router-link
                  :to="{ name: 'StudentNotifications' }"
                  v-if="this.notificationList.length == 0"
                  class="flex text-sm flex-col border gap-1 py-2 px-4 rounded-lg"
                  >No Data Found</router-link
                >
              </div>
            </div>
          </div>

          <!-- Profile Dropdown -->
          <div class="relative z-50 dropdown-container">
            <div
              v-if="isAuthenticated && profileInfo != null"
              @click="MenuToggle('profile')"
              class="flex items-center cursor-pointer"
            >
              <img
                v-if="profileInfo.data.profile"
                :src="profileInfo.data.profile"
                class="w-10 h-10 rounded-full border-2 border-white"
              />
              <img
                v-else
                src="@/assets/new/image.png"
                class="w-10 h-10 rounded-full border-2 border-white"
              />
            </div>

            <div
              v-if="toggleMenuName == 'profile'"
              class="absolute right-0 mt-2 w-48 bg-white rounded-2xl shadow-lg"
            >
              <router-link
                  :to="{ name: 'StudentUserProfile' }" class="flex items-center gap-2 text-sm py-3 px-4 cursor-pointer">
                <img
                  src="@/assets/new/profile.png"
                  class="w-5 h-5"
                  alt="Profile"
                />

                <div
                  class="text-sm font-medium text-[#ADADAD] flex flex-col gap-1"
                >
                  <span
                    class="font-semibold"
                    :class="{
                      'text-[#0085db]': $route.name === 'StudentUserProfile',
                      'text-gray-800': $route.name !== 'StudentUserProfile',
                    }"
                    >Profile</span
                  >
                  <!-- <span>62135458</span>
                  <span>Compt Science</span> -->
                </div>
              </router-link>

              <hr />
              <div class="space-y-1">
                <div class="flex items-center gap-2 py-3 px-4 cursor-pointer">
                  <router-link
                    :to="{ name: 'CompleteCourse' }"
                    class="text-sm font-medium"
                    :class="{
                      'text-[#0085db]': $route.name === 'CompleteCourse',
                      'text-gray-800': $route.name !== 'CompleteCourse',
                    }"
                    >Complete Course</router-link
                  >
                </div>
              </div>
              <hr />
               <div class="space-y-1">
                <div class="flex items-center gap-2 py-3 px-4 cursor-pointer">
                  <router-link
                    :to="{ name: 'StudentTaskList' }"
                    class="text-sm font-medium"
                    :class="{
                      'text-[#0085db]': $route.name === 'StudentTaskList',
                      'text-gray-800': $route.name !== 'StudentTaskList',
                    }"
                    >Task List</router-link
                  >
                </div>
              </div>
              <hr />
              <div class="space-y-1">
                <!-- <div class="flex items-center gap-2 py-3 px-4 cursor-pointer ">
                  <img src="@/assets/newd/solar_settings-linear.png" class="w-5 h-5" alt="Settings Icon">
                  <router-link :to="{ name: 'StudentHome' }"
                    class="text-sm font-medium text-gray-800">Settings</router-link>
                </div>
                <hr class="border-gray-300" />

                <div class="flex items-center gap-2 py-3 px-4 cursor-pointer ">
                  <img src="@/assets/newd/material-symbols-light_feedback-outline-rounded.png" class="w-5 h-5"
                    alt="Feedback Icon">
                  <router-link :to="{ name: 'StudentHome' }"
                    class="text-sm font-medium text-gray-800">Feedback</router-link>
                </div>
                <hr class="border-gray-300" /> -->

                <!-- <div class="flex items-center gap-2 py-3 px-4 cursor-pointer ">
                  <img src="@/assets/newd/fluent_person-ribbon-20-regular.png" class="w-5 h-5" alt="Attendance Icon">
                  <router-link :to="{ name: 'StudentHome' }"
                    class="text-sm font-medium text-gray-800">Attendance</router-link>
                </div>
                <hr class="border-gray-300" />

                <div class="flex items-center gap-2 py-3 px-4 cursor-pointer ">
                  <img src="@/assets/newd/bx_support.png" class="w-5 h-5" alt="Support Icon">
                  <router-link :to="{ name: 'StudentHome' }"
                    class="text-sm font-medium text-gray-800">Support</router-link>
                </div> -->

                <div class="flex items-center gap-2 py-3 px-4 cursor-pointer">
                  <!-- <img src="@/assets/newd/bx_support.png" class="w-5 h-5" alt="Support Icon"> -->
                  <router-link
                    :to="{ name: 'StudentGrade' }"
                    class="text-sm font-medium"
                    :class="{
                      'text-[#0085db]': $route.name === 'StudentGrade',
                      'text-gray-800': $route.name !== 'StudentGrade',
                    }"
                    >Grade Book</router-link
                  >
                </div>
              </div>
              <hr />
              <div
                @click="
                  LogOutModal = true;
                  toggleMenuName = '';
                "
                class="flex items-center gap-2 py-3 px-4 cursor-pointer text-red-500 font-semibold"
              >
                <img src="@/assets/new/logout.png" width="15" height="15" />
                Logout
              </div>
            </div>
          </div>
          <!-- Mobile Menu Toggle -->
          <div class="lg:hidden relative dropdown-container">
            <button
              @click="MenuToggle('mobilemenu')"
              class="text-white focus:outline-none"
            >
              <svg
                class="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M4 6h16M4 12h16m-7 6h7"
                ></path>
              </svg>
            </button>

            <!-- mobile menu -->
            <div
              v-if="toggleMenuName == 'mobilemenu'"
              class="absolute right-6 top-20 z-50 bg-white rounded-2xl shadow-lg lg:hidden"
              style="width: 180px"
            >
              <router-link
                :to="{ name: 'StudentMyCourse' }"
                class="text-sm font-medium flex items-center gap-2 py-3 px-6 cursor-pointer"
                :class="{
                  'text-[#0085db]': $route.name === 'StudentMyCourse',
                  'text-black': $route.name !== 'StudentMyCourse',
                }"
              >
                My Courses
              </router-link>
              <hr />
              <router-link
                :to="{ name: 'StudentCommunity' }"
                class="text-sm font-medium flex items-center gap-2 py-3 px-6 cursor-pointer"
                :class="{
                  'text-[#0085db]': $route.name === 'StudentCommunity',
                  'text-black': $route.name !== 'StudentCommunity',
                }"
              >
                Community
              </router-link>
              <hr />
              <router-link
                :to="{ name: 'StudentCurriculum' }"
                class="text-sm font-medium flex items-center gap-2 py-3 px-6 cursor-pointer"
                :class="{
                  'text-[#0085db]': $route.name === 'StudentCurriculum',
                  'text-black': $route.name !== 'StudentCurriculum',
                }"
              >
                Curriculum
              </router-link>
              <hr />
              <router-link
                :to="{ name: 'StudentBlogs' }"
                class="text-sm font-medium flex items-center gap-2 py-3 px-6 cursor-pointer"
                :class="{
                  'text-[#0085db]': $route.name === 'StudentBlogs',
                  'text-black': $route.name !== 'StudentBlogs',
                }"
              >
                Blogs
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Logout
      v-if="LogOutModal"
      @logOut="logOut"
      @cancel="LogOutModal = !LogOutModal"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import axios from "axios";
import Logout from "@/components/Logout.vue";

export default {
  computed: {
    ...mapGetters(["isAuthenticated", "userInfo"]),
  },

  data() {
    return {
      logo_image: require(`${process.env.VUE_APP_COMS_LOGO}`),
      toggleMenuName: null,
      profileInfo: null,
      LogOutModal: false,
      notificationList: [],
    };
  },

  components: {
    Logout,
  },

  created() {
    this.getNotifications();

    if (this.userInfo) {
      console.log("start");
      let token = this.userInfo.token_key;
      let slug_id = this.userInfo.slug_id;
      axios
        .get(
          `${process.env.VUE_APP_API}/profile_detail/${slug_id}?Key=${token}&format=json`
        )
        .then((response) => (this.profileInfo = response.data));
    } else {
      console.log("Not Login");
    }
  },
  mounted() {
    document.addEventListener("click", this.handleClickOutside);
  },
  beforeUnmount() {
    document.removeEventListener("click", this.handleClickOutside);
  },

  methods: {
    ...mapActions(["logOutUser"]),

    async getNotifications() {
      await axios
        .get(
          `${process.env.VUE_APP_API}/notifications/${this.userInfo.user.id}/?Key=${this.userInfo.token_key}`
        )
        .then((response) => {
          if (response.data.data.status == 200) {
            this.notificationList = response.data.data.data;
          }
        });
    },

    MenuToggle(name) {
      if (this.toggleMenuName == name) {
        this.toggleMenuName = null;
      } else {
        this.toggleMenuName = name;
      }
    },

    async logOut() {
      await this.logOutUser();
      this.LogOutModal = false;
      this.$router.push({
        name: "Login",
      });
    },
    handleClickOutside(event) {
      if (!event.target.closest(".dropdown-container")) {
        this.toggleMenuName = null;
      }
    },
  },
};
</script>

<style></style>
