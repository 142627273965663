<template>
  <div class="min-h-screen relative">
    <Header />

    <div
      class="min-h-screen container mx-auto max-w-[1200px] w-full mb-10 py-7 flex flex-col gap-10 px-4 md:px-6 lg:gap-16"
    >
      <div class="overflow-y-scroll">
        <div
          class="flex flex-col md:flex-row items-center justify-between bg-[#EDF8FF] p-5 rounded-xl"
        >
          <p class="text-[#0085DB] text-lg md:text-2xl font-semibold">
            Task List
          </p>
          <div class="flex flex-col md:flex-row gap-3 items-end">
            <div>
              <form
                @submit.prevent="searchUserValue"
                class="flex w-full md:w-auto"
              >
                <input
                  type="text"
                  name="search"
                  id="search"
                  v-model="search_title"
                  @input="searchUserValue"
                  placeholder="Type to Search"
                  class="border-2 rounded-l-xl px-4 py-1.5 w-full md:w-[230px] outline-none"
                />
                <button
                  type="submit"
                  class="bg-blues py-2 md:py-1 rounded-r-xl text-white px-4"
                >
                  <img
                    src="@/assets/search.webp"
                    alt="Search"
                    class="w-6 h-6"
                  />
                </button>
              </form>
            </div>

            <div>
              <div class="relative dropdown-container">
                <button
                  @click="open_filter_drop = !open_filter_drop"
                  type="button"
                  class="px-4 py-1.5 text-[16px] rounded-md capitalize bg-[#0085DB] text-white font-semibold flex gap-1"
                >
                  <img src="@/assets/new/mynaui_filter.svg" alt="" srcset="" />
                  filter
                </button>
                <div
                  v-if="open_filter_drop"
                  class="absolute top-10 right-0 z-10 w-[150px] border bg-white shadow-lg rounded-md"
                >
                  <div class="w-full flex flex-col">
                    <div
                      class="flex gap-2 items-center cursor-pointer hover:bg-[#EDF8FF] p-1"
                      @click="filterTasks(true)"
                    >
                      <img
                        src="@/assets/newd/quill_to-do.svg"
                        alt=""
                        srcset=""
                        class="w-5 h-5"
                      />
                      <p>Complete</p>
                    </div>
                    <hr />
                    <div
                      class="flex gap-2 items-center cursor-pointer hover:bg-[#EDF8FF] p-1"
                      @click="filterTasks(false)"
                    >
                      <img
                        src="@/assets/newd/mdi_to-do.png"
                        alt=""
                        srcset=""
                        class="w-5 h-5"
                      />
                      <p>Incomplete</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="todolist.length > 0" class="overflow-y-auto">
          <div v-for="task in todolist" :key="task.id" class="py-2">
            <div class="flex gap-5 border-b border-[#E7E7E7] w-full p-4">
              <div class="">
                <img
                  v-if="task.completed == false"
                  src="@/assets/newd/mdi_to-do.png"
                  alt=""
                  srcset=""
                  class="w-6 h-6"
                />
                <img
                  v-else
                  src="@/assets/newd/quill_to-do.svg"
                  alt=""
                  srcset=""
                  class="w-6 h-6"
                />
              </div>

              <p class="text-sm   break-words w-[80%]"  :class="{
                        'font-bold text-black ':task.completed  !== true,
                        'font-normal  ': task.completed  === true,
                      }">
                {{ task.text }}
              </p>

              <div class="flex justify-end gap-2 w-[40%] lg:w-[15%]">
                <div class="flex flex-col gap-2">
                  <div class="text-sm text-gray-500 font-normal">
                    {{ formatDate(task.updated_at) }}
                  </div>
                  <div class="flex gap-2" v-if="task.completed == false">
                    <button @click="completeTask(task.id)" class="relative group">
                      <img
                        src="@/assets/newd/Vector(1).svg"
                        alt=""
                        srcset=""
                        class="w-6 h-6 bg-[#12AA241A] p-1"
                      />
                      <span
                          class="absolute top-8 left-1/2 transform -translate-x-1/2 bg-gray-700 text-white text-xs px-2 whitespace-nowrap py-1 rounded-md opacity-0 group-hover:opacity-100 transition-opacity"
                        >
                         Complete
                        </span>
                    </button>

                    <button @click="openEditPopup(task)" class="relative group">
                      <img
                        src="@/assets/edit.svg"
                        alt=""
                        srcset=""
                        class="w-6 h-6 bg-[#EDF8FF] p-1"
                      />
                       <span
                          class="absolute top-8 left-1/2 transform -translate-x-1/2 bg-gray-700 text-white text-xs px-2 whitespace-nowrap py-1 rounded-md opacity-0 group-hover:opacity-100 transition-opacity"
                        >
                          Edit
                        </span>
                    </button>
                    <button @click="deleteTask(task.id)" class="relative group">
                      <img
                        src="@/assets/new/dele.png"
                        alt=""
                        srcset=""
                        class="w-6 h-6 bg-[#FF29291A] p-1"
                      />
                      <span
                          class="absolute top-8 left-1/2 transform -translate-x-1/2 bg-gray-700 text-white text-xs px-2 whitespace-nowrap py-1 rounded-md opacity-0 group-hover:opacity-100 transition-opacity"
                        >
                          Delete
                        </span>
                    </button>
                  </div>
                </div>
              </div>

              <!-- Edit Popup -->

              <div
                v-if="isEditing"
                class="fixed bg-primary bg-opacity-5 top-0 left-0 right-0 bottom-0 z-50 flex items-center justify-center"
              >
                <div
                  class="w-[90%] lg:w-[40%] bg-white rounded-xl p-6 lg:p-10 overflow-y-auto max-h-[600px]"
                >
                  <div class="flex items-center justify-center">
                    <h3 class="text-xl font-bold text-blues text-center">
                      Edit Task
                    </h3>
                  </div>

                  <div
                    class="w-full mt-4 flex flex-col p-6 relative border-gray-100 rounded-2xl"
                  >
                    <div class="w-full">
                      <form
                        ref="createTeamRef"
                        @submit.prevent="updateTask()"
                        class="flex flex-col gap-4 text-xs md:text-sm lg:text-base h-full"
                      >
                        <div>
                          <textarea
                            v-model="editTaskText"
                            name=""
                            id=""
                            cols="30"
                            rows="5"
                            class="border p-1 w-full"
                          ></textarea>
                        </div>

                        <div class="flex justify-center pb-4 mt-3 gap-10">
                          <button
                            @click="isEditing = false"
                            class="border text-gray-500 rounded-lg text-sm px-5 lg:px-10 font-semibold py-2"
                          >
                            Close
                          </button>
                          <button
                            class="border text-white rounded-lg bg-blues text-sm px-5 lg:px-10 font-semibold py-2"
                          >
                            Update
                          </button>
                        </div>

                        <div
                          v-if="editmessages"
                          class="text-green-400 text-lg font-bold"
                        >
                          {{ editmessages }}
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- pagination -->
          <div v-if="totalPages > 1" class="flex justify-center py-5 space-x-2">
            <button
              class="px-3 py-2 bg-gray-200 rounded disabled:opacity-50"
              :disabled="currentPage === 1"
              @click="prevPage"
            >
              &laquo;
            </button>

            <button
              v-for="page in paginationRange"
              :key="page"
              class="px-3 py-2 rounded"
              :class="{
                'bg-blue-500 text-white': currentPage === page,
                'bg-white border border-gray-400 text-gray-800':
                  currentPage !== page,
              }"
              @click="changePage(page)"
            >
              {{ page }}
            </button>

            <button
              class="px-3 py-2 bg-gray-200 rounded disabled:opacity-50"
              :disabled="currentPage >= totalPages"
              @click="nextPage"
            >
              &raquo;
            </button>
          </div>
        </div>
        <div v-else class="flex justify-center pt-5">No Data Found</div>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
import axios from "axios";
import Footer from "@/components/Footer.vue";
import Header from "@/components/Student/Header.vue";
import { mapGetters } from "vuex";
export default {
  name: "TaskList",
  data() {
    return {
      todolist: [],
      search_title: "",
      loading: true,
      isEditing: false,
      editTaskText: "",
      editTaskId: null,
      showButton: false,
      editmessages: "",
      open_filter_drop: false,
      currentPage: 1,
      totalPages: 1,
      perPage: 10,
    };
  },

  components: {
    Header,
    Footer,
  },

  computed: {
    ...mapGetters(["userInfo"]),
    paginationRange() {
      const totalPages = this.totalPages;
      const currentPage = this.currentPage;
      let pages = [];

      if (totalPages <= 5) {
        pages = [...Array(totalPages).keys()].map((x) => x + 1);
      } else {
        if (currentPage > 3) pages.push(1);
        if (currentPage > 4) pages.push("...");

        const startPage = Math.max(2, currentPage - 1);
        const endPage = Math.min(totalPages - 1, currentPage + 1);

        for (let i = startPage; i <= endPage; i++) {
          pages.push(i);
        }

        if (currentPage < totalPages - 3) pages.push("...");
        if (currentPage < totalPages - 2) pages.push(totalPages);
      }

      return pages;
    },
  },
  created() {
    this.getTodo();
  },
  mounted() {
    document.addEventListener("click", this.handleClickOutside);
  },
  beforeUnmount() {
    document.removeEventListener("click", this.handleClickOutside);
  },
  methods: {
    handleClickOutside(event) {
      if (!event.target.closest(".dropdown-container")) {
        this.open_filter_drop = null;
      }
    },
    async getTodo() {
      await axios
        .get(
          `${process.env.VUE_APP_API}/to-do-get/${this.userInfo.user.id}/?Key=${this.userInfo.token_key}&page=${this.currentPage}`
        )
        .then((response) => {
          if (response.data.data.status == 200) {
            this.todolist = response.data.data.data;
            console.log("this.invitelist1111", response.data.total);
            this.totalPages = Math.ceil(response.data.total / this.perPage);
          }
        });
    },
    formatDate(date) {
      return new Date(date).toLocaleDateString("en-IN", {
        day: "numeric",
        month: "long",
        year: "numeric",
      });
    },
    async searchUserValue() {
      await axios
        .get(
          `${process.env.VUE_APP_API}/to-do-get/${this.userInfo.user.id}/?search=${this.search_title}&Key=${this.userInfo.token_key}`
        )
        .then((response) => {
          if (response.data.data.status == 200) {
            this.todolist = response.data.data.data;
          }
        });
    },
    async filterTasks(isComplete) {
      await axios
        .get(
          `${process.env.VUE_APP_API}/to-do-get/${this.userInfo.user.id}/?completed=${isComplete}&Key=${this.userInfo.token_key}`
        )
        .then((response) => {
          if (response.data.data.status == 200) {
            this.todolist = response.data.data.data;
            this.totalPages = Math.ceil(response.data.total / this.perPage);
            this.open_filter_drop = false;
          }
        });
    },
    openEditPopup(task) {
      this.editTaskText = task.text;
      this.editTaskId = task.id;
      this.isEditing = true;
    },
    updateTask() {
      let formdata = {
        text: this.editTaskText,
      };
      console.log("formdata", formdata);

      axios
        .put(
          `${process.env.VUE_APP_API}/to-do-text-edit/${this.editTaskId}/?Key=${this.userInfo.token_key}`,
          formdata
        )
        .then((response) => {
          if (response.data.status == 200) {
            this.editmessages = response.data.message;

            setTimeout(() => {
              this.editmessages = "";
              this.isEditing = false;
              this.getTodo();
            }, 2000);
          }
        })
        .finally(() => {
          this.loaders = false;
        });
    },
    deleteTask(id) {
      console.log("sdafsdfsdfsd", id);
      axios
        .delete(
          `${process.env.VUE_APP_API}/to-do-update/${id}/?Key=${this.userInfo.token_key}`
        )
        .then((response) => {
          if (response.data.status == 200) {
            this.getTodo();
          }
        });
    },
    completeTask(id) {
      let completedata = {
        completed: true,
      };
      axios
        .put(
          `${process.env.VUE_APP_API}/to-do-update/${id}/?Key=${this.userInfo.token_key}`,
          completedata
        )
        .then((response) => {
          if (response.data.status == 200) {
            this.getTodo();
          }
        });
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
        this.getTodo();
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.getTodo();
      }
    },
    changePage(page) {
      if (page !== "..." && page !== this.currentPage) {
        this.currentPage = page;
        this.getTodo();
      }
    },
  },
};
</script>

<style></style>
