<template>
  <div class="min-h-screen relative">
    <Header />
    <!-- <FlashMessage v-if="this.messages.text != ''" :message="this.messages" /> -->
    <div
      class="min-h-screen container mx-auto max-w-[1200px] w-full mb-10 py-7 flex flex-col gap-10 px-4 md:px-6 lg:gap-16"
    >
      <div>
        <div class="flex flex-row items-center justify-between">
          <p
            class="text-[#0F172A] text-xl sm:text-2xl md:text-3xl font-semibold"
          >
            My Courses 
          </p>
       
        </div>

    

        <div
          v-if="loading"
          class="flex items-center justify-center"
          style="height: calc(100vh - 350px) !important"
        >
          <img src="@/assets/newd/loading.gif" alt="" />
        </div>
        <div
          class=""
          v-else
        >
         
          
          <div v-if="this.courseDetails.length>0">

            <div v-if="this.courseDetails[0]" class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mt-6">

              <div
                v-for="course in this.courseDetails[0].course"
                :key="course"
                class="bg-white shadow-lg rounded-lg p-4 sm:p-6 h-auto"
              >


                <!-- <router-link 
                  :to="{
                    name: 'StudentCourse',
                    params: { courseid: course.id },
                  }"
                >
                  <div class="relative flex justify-center items-center">
                    <img
                      v-if="course.thumbnail"
                      class="object-cover h-[180px] sm:h-[200px] w-full rounded-lg"
                      :src="course.thumbnail"
                      alt="Course Thumbnail"
                    />
                    <img
                      v-else
                      class="object-cover h-24 w-full rounded-lg"
                      src="@/assets/coding.svg"
                      alt="Placeholder"
                    />
                  </div>
                  <div class="text-center flex flex-col gap-2 mt-2">
                    <p
                      class="text-sm sm:text-base text-gray-600 font-semibold text-left truncate"
                    >
                      {{ course.title }}
                    </p>
                    <p class="text-xs sm:text-sm text-gray-600 text-left">
                      {{ course.sections.length }} Units
                    </p>

                    <p
                      class="mt-3 py-2 text-xs sm:text-sm rounded-lg border border-[#0085DB] text-[#0F172A] font-medium text-center"
                    >
                      Continue Learning
                    </p>
                  </div>
                </router-link> -->

                <div @click="getDashboardOrPreviewUp(course.id)" class="cursor-pointer">
                  <div class="relative flex justify-center items-center">
                    <img
                      v-if="course.thumbnail"
                      class="object-cover h-[180px] sm:h-[200px] w-full rounded-lg"
                      :src="course.thumbnail"
                      alt="Course Thumbnail"
                    />
                    <img
                      v-else
                      class="object-cover h-24 w-full rounded-lg"
                      src="@/assets/coding.svg"
                      alt="Placeholder"
                    />
                  </div>
                  <div class="text-center flex flex-col gap-2 mt-2">
                    <p
                      class="text-sm sm:text-base text-gray-600 font-semibold text-left truncate"
                    >
                      {{ course.title }}
                    </p>
                    <p class="text-xs sm:text-sm text-gray-600 text-left">
                      {{ course.sections.length }} Units
                    </p>

                    <p @click="getDashboardOrPreviewUp(course.id)"
                      class="mt-3 cursor-pointer py-2 text-xs sm:text-sm rounded-lg border border-[#0085DB] text-[#0F172A] font-medium text-center"
                    >
                      Continue Learning
                    </p>
                  </div>
                </div>

              </div>

              <div
                v-if="this.courseDetails[0].course.length == 0"
                class="bg-white shadow-lg rounded-lg p-4 sm:p-6 h-auto"
              >
                  <p class="text-sm">No Courses</p>
              </div>

            </div>

            <div
                v-else
                class="bg-white shadow-lg rounded-lg p-4 sm:p-6 h-auto"
              >
                  <p class="text-sm">No Courses</p>
              </div>


          </div>

           <div
            v-else
            class="flex flex-col justify-center"
            style="height: calc(100vh - 350px) !important"
          >
            <p class="flex justify-center">No Courses</p>
          </div>
        
        </div>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import Footer from "@/components/Footer.vue";
import Header from "@/components/Student/Header.vue";

export default {
  data() {
    return {
      profileInfo: null,
      popUp: false,
      updateDetails: null,
      selectedFile: null,
      tnxDetails: "",
      profile: false,
      // messages: {
      //   text: "",
      //   type: "null",
      // },
      selectedcontent: "Courses",
      courseDetails: null,
      boughtcourse: null,
      progress: 90,
      loading: true,
    };
  },

  components: {
    Header,
    // FlashMessage,
    Footer,
  },
  computed: {
    ...mapGetters(["userInfo"]),
  },

  mounted(){
    if(!localStorage.getItem("previewIgnoreList")){
      localStorage.setItem("previewIgnoreList", JSON.stringify([]))
    }    
  },

  async created() {
    //   await axios
    //     .get(`${process.env.VUE_APP_API}/courses/?format=json`)
    //    .then((response) => {
    // this.courseDetails = response.data.data;
    // this.loading = false;

    // https://api.galecta.com/studentsbuycoursedetails/?Key=cff90a4514d5ab813c34782fe83297598cdf099e
    // });

    await axios
      .get(
        `${process.env.VUE_APP_API}/studentsbuycoursedetails/?Key=${this.userInfo.token_key}`
      )
      .then((response) => {
        this.courseDetails = response.data.data;
        setTimeout(() => {
              this.loading = false;
            }, 600);
      });
  },

  methods: {

    getDashboardOrPreviewUp(id){
        if(localStorage.getItem("previewIgnoreList")){
          let preList = new Set(JSON.parse(localStorage.getItem("previewIgnoreList")));
          
          if(preList.size != 0){
            if(preList.has(id.toString())){
              this.$router.push({name: 'StudentCourseProgress', params: { course_id: id }})
            }
            else{
              this.$router.push({name: 'StudentCourse', params: { courseid: id }})
            }
           
          }
          else{
           
            this.$router.push({name: 'StudentCourse', params: { courseid: id }})
          }
        }
        else{
         
          this.$router.push({name: 'StudentCourse', params: { courseid: id }})
        }
    }

  },
};
</script>

<style scoped></style>
