<template>
  <div>
   

    <div class="editorx_body">
      <div class id="codex-editor" />
    </div>

    <div class="container mx-auto max-w-7xl py-10"> 
      <button
      type="button"
      name="button"
      class="bg-primary text-white rounded-lg text-sm px-4 py-2 mt-4"
      @click="save()"
    >
      save
    </button>
    </div>
    

    <div class="editorx_body">
      <pre>{{ value }}</pre>
    </div> 

  
  
    <div v-if="value != null" class="container mx-auto max-w-7xl py-10"> 

          <div v-for="block in value.blocks" :key="block"> 

            <div v-if="block.type == 'embed'"> 
              <div>
                <iframe class="w-full h-[500px]" :src="block.data.embed" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>
              </div>
              <br/>
            </div>

            <div v-if="block.type == 'video'"> 
              <div>
                <!-- <iframe class="w-full h-[500px]" :src="block.data.embed" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe> -->
                <video src="https://api.galecta.com/media/Project Demo.webm" controls class="w-full h-[500px]">
                  <source src="movie.mp4" type="video/mp4">
                  <source src="movie.ogg" type="video/ogg">
                  Your browser does not support the video tag.
                </video>
                <!-- 4114797-uhd_3840_2160_25fps.mp4 -->
                <br/>
                <video src="https://api.galecta.com/media/4114797-uhd_3840_2160_25fps.mp4" controls class="w-full h-[500px]">
                  <source src="movie.mp4" type="video/mp4">
                  <source src="movie.ogg" type="video/ogg">
                  Your browser does not support the video tag.
                </video>

                <!-- <video src="https://api.galecta.com/media/S2.mp4" controls class="w-full h-[500px]">
                  <source src="movie.mp4" type="video/mp4">
                  <source src="movie.ogg" type="video/ogg">
                  Your browser does not support the video tag.
                </video> -->
                <br/>

              </div>
            </div>

            <!-- case "paragraph":
            convertedHtml += `<p>${block.data.text}</p>`;
            break; -->

            <div v-if="block.type == 'header'"> 
              <div>
                <!-- <p>{{block.data.text}}</p> -->
                <component :is="`h${block.data.level}`" class="text-4xl font-bold">
                  {{ block.data.text }}
                </component>
              </div>
              <br/>
            </div>

            <div v-if="block.type == 'list'"> 
              <div>
                <!-- <p>{{block.data.text}}</p> -->
                <!-- <component :is="`h${block.data.level}`">
                  {{ block.data.text }}
                </component> -->
                <ul v-if="block.data.style === 'unordered'">
                  <li v-for="(item, index) in block.data.items" :key="index">{{ item.content }}</li>
                </ul>
                <ol v-else>
                  <li v-for="(item, index) in block.data.items" :key="index"> {{ index+1 }}. {{ item.content }}</li>
                </ol>
              </div>
              <br/>
            </div>

            <div v-if="block.type == 'paragraph'"> 
              <div>
                <p>{{block.data.text}}</p>
              </div>
              <br/>
            </div>
         
            <div v-if="block.type == 'delimiter'">  
              <hr/>
            </div>
            <div v-if="block.type == 'image'">  
              <img class="img-fluid" :src="block.data.file.url" :title="block.data.caption" /><br /><em>{{block.data.caption}}</em>
              <br/>
            </div>
            <div v-if="block.type == 'code'"> 
              <div>
                
                <pre><code>{{block.data.code}}</code></pre>
              </div>
              <br/>
            </div>
            <div v-if="block.type == 'quiz'"> 
              <br/>
              <div>
                <QuizTest :quiz_id="127" :taker_id="10" />
              </div>
              <br/>
            </div>

            <div v-if="block.type == 'table'"> 
              <div>
                <table class="w-full border">
                  <tbody>

                    <tr class="border py-2 px-4" v-for="tr in block.data.content" :key="tr">
                      <td class="border py-2 px-4" v-for="td in tr" :key="td">{{td}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <br/>
            </div>

          </div>
       
    </div>
    


  </div>
</template>

<script>
import EditorJS from "@editorjs/editorjs";
import Header from "@editorjs/header";
import Paragraph from "@editorjs/paragraph";
import List from "@editorjs/list";
import { mapGetters } from "vuex";

// import QuizTool from './QuizTool'
import QuizTest from "./Student/Dashboard/QuizTestComp.vue";
import VideoUpload from "@/scripts/editor/VideoUpload";
import GetQuiz from "@/scripts/editor/GetQuiz";

export default {
  data() {
    return {
      value: null,
      dummyQuizData: {
        type: 'quiz',
        data: {
          question: 'What is the capital of France?',
          options: ['Berlin', 'Madrid', 'Paris', 'Rome'], 
          correctAnswer: 2
        }
      }
    }
  },

  components: {
    QuizTest
  },

  methods: {
    save: function () {
      editor.save().then((savedData) => {
        console.log(savedData);
        this.value = savedData;
      });
    },

    myEditor: function () {

       
      window.editor = new EditorJS({
        holder: "codex-editor",
        autofocus: true,
        /**
         * This Tool will be used as default
         */
        initialBlock: "paragraph",
        tools: {
            embed: {
            class: require('@editorjs/embed'),
            inlineToolbar: true,
          },
          header: {
            class: Header,
            shortcut: "CMD+SHIFT+H",
          },
          list: {
            class: List,
          },
          paragraph: {
            class: Paragraph,
            config: {
              placeholder: ".",
            },
          },
          table: {
                class: require('@editorjs/table'),
            },
            code: {
                class: require('@editorjs/code'),
            },
            image: {
                class: require('@editorjs/image'),
                inlineToolbar: true,
                config: {
                    endpoints: {
                      byFile: 'https://api.galecta.com/video-uploader/', // Your backend file uploader endpoint
                      byUrl: 'http://localhost:8008/fetchUrl', // Your endpoint that provides uploading by Url
                    }
                }
            },
            quiz: {
                class: GetQuiz, 
                inlineToolbar: true,
                config: {
                    data: "this.dummyQuizData.data",  
                }
            },
            video: {
              class: VideoUpload,
              inlineToolbar: true
            }
        },
        // data: {
        //   blocks: [this.dummyQuizData]  // Load the dummy quiz block as part of the editor's content
        // },
        onReady: function () {
          console.log("ready");
        },
        onChange: function () {
          console.log("change");
        },
      });
    },

   

  },
  mounted: function () {
    this.myEditor();

    const script = document.createElement("script");
    script.src =
      "https://ajax.googleapis.com/ajax/libs/jquery/3.3.1/jquery.min.js";
    script.onload = () => {
      console.log("jQuery loaded");
      // this.initJQuery();
    };
    document.head.appendChild(script);

  },
};
</script>

<style lang="css" scoped>
.editorx_body {
  /* width: 62%;
    margin-left: 15%; */
  width: 60%;
  margin-left: 20%;
  border: 2px solid #f1f3f5;
  box-sizing: border-box;
}
.ce-block--focused {
  background: linear-gradient(
    90deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(9, 9, 121, 0.5438550420168067) 35%,
    rgba(0, 212, 255, 1) 100%
  );
}
</style>
