<template>
    <div>
      <Header/>
    </div>
    <router-view />
</template>

<script> 
import Header from "@/components/Instructor/Header.vue";

export default {
  name: "Dashboard",
  components: {
    Header
  },
  props: ["course_id"],
}

</script>