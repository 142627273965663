class GetQuiz {

    constructor(){
        this.quizData = {
            "selectQuizId": null,
            "quiz": [
                {
                    tile: 'Rust for Python',
                    id: 1
                },
                {
                    tile: 'Rust for Actix Web',
                    id: 2
                },
                {
                    tile: 'Rust Intro',
                    id: 3
                }
            ]   
        }
    }

    render() {
        const wrapper = document.createElement('div');
        wrapper.classList.add("bg-white", "shadow-lg", "p-4", "rounded-xl", "flex", "items-center", "gap-3", "my-3");        
        
        const textElement = document.createElement('p');
        textElement.textContent = "Select Quiz ID:"
        textElement.classList.add("font-bold");

        wrapper.appendChild(textElement);

        const selectElement = document.createElement('select');
        selectElement.id = 'selectQuizId';
        selectElement.value="";
        selectElement.classList.add("px-2", "py-1", "rounded-lg");

        this.quizData.quiz.forEach(optionData => {
            const optionElement = document.createElement('option');
            optionElement.value = optionData.id;
            optionElement.textContent = optionData.tile;
            selectElement.appendChild(optionElement);
        })

        selectElement.onchange = () => {
            this.quizData.selectQuizId = selectElement.value;
        }

        wrapper.appendChild(selectElement);

        return wrapper;
    }

    save(blockContent) {
        return this.quizData.selectQuizId; 
    }

    static get toolbox() {
        return {
          title: 'Add Quiz',
          icon: '<img width="15" height="15" src="https://img.icons8.com/ios/50/quiz.png" alt="quiz"/>'
        };
    }

}

export default GetQuiz;