<template>



  <div>
    <Header />

    <div v-if="is_video == true" @click.self="is_video = false"
      class="fixed inset-0 bg-slate-900/60 right-0 left-0 top-0 z-[1300] h-full flex items-center justify-center">
      <div class="p-10 w-[55%]  relative  px-6 py-3  h-[75%] ">
        <div class="p-6 bg-white  rounded-xl h-full overflow-y-auto">

          <img src="@/assets/newd/close.png" class="cursor-pointer absolute right-0  top-0 z-[5000] h-5 w-5" alt=""
            @click="is_video = false" />


          <div class="w-full h-full flex flex-col rounded-2xl">
            <iframe v-if="this.videoLink" class="w-full h-full rounded-2xl" :src="this.videoLink"
              title="YouTube video player" frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen>
            </iframe>

            <h1 v-else class="text-center text-xl py-4">No Video found</h1>
          </div>

        </div>


      </div>
    </div>
    <div v-if="is_content == true" @click.self="is_content = false"
      class="fixed inset-0 bg-slate-900/60 right-0 left-0 top-0 z-[1300] h-full flex items-center justify-center">
      <div class="p-10 w-[55%]  relative  px-6 py-3  h-[85%] ">
        <div class="p-6 bg-white  rounded-xl h-full overflow-y-auto">

          <img src="@/assets/newd/close.png" class="cursor-pointer absolute right-0  top-0 z-[5000] h-5 w-5" alt=""
            @click="is_content = false" />


          <div class="w-full h-full flex flex-col rounded-2xl">
            <div v-html="contant"></div>
          </div>
        </div>


      </div>
    </div>



    <div class="container mx-auto max-w-[1200px] mt-4 pt-3 px-6">
      <div class="flex">
        <div class="flex items-center gap-4">
          <router-link :to="{ name: 'InstructorCourses' }"
            class="bg-blues text-xs font-bold text-white px-5 py-1.5 rounded-full">Back</router-link>
        </div>
      </div>
    </div>

    <div v-if="loading" class="flex items-center justify-center min-h-screen">
      <img src="@/assets/newd/loading.gif" alt="Loading...">
    </div>

    <div class="container mx-auto max-w-[1200px] pt-5 pb-20 px-4 sm:px-6" v-if="!loading">
      <div class="flex flex-col lg:flex-row gap-10" v-if="courselist">
        <div class="flex flex-col mt-10 lg:w-3/5 w-full">
          <div class="flex flex-col gap-6">
            <h1 class="text-3xl text-primary font-bold">
              {{ courselist[0].title }}
            </h1>
            <div class="flex flex-wrap items-center gap-5">
              <div class="flex items-center rounded-2xl gap-2">
                <img src="@/assets/new/rounded.png" alt="" />
                <p class="text-xs">{{ courselist[0].sections.length }} Units</p>
              </div>
              <div class="flex items-center rounded-2xl gap-2">
                <img src="@/assets/new/time.png" alt="" />
                <p class="text-xs">18h 20m Left</p>
              </div>
            </div>
            <div class="flex items-center gap-5">
              <router-link :to="{ name: 'InstructorCourseAdd', params: { course_id: courselist[0].id } }"
                class="bg-primary px-6 py-3 rounded-3xl text-white text-xs">Update Course</router-link>
            </div>
            <p class="text-primary mt-10">{{ courselist[0].short_description }}</p>
          </div>
        </div>
        <div class="lg:w-2/5 w-full">
          <img v-if="courselist[0].thumbnail" :src="courselist[0].thumbnail" alt="Course image"
            class="object-cover rounded-2xl w-full h-[300px]" />
        </div>
      </div>

      <div class="mt-10 pb-10 flex flex-col gap-10 px-6 md:px-0">
        <p class="text-xl font-bold">Syllabus</p>
        <div v-if="courseDetailInfo" class="flex flex-col gap-5 relative w-full h-full overflow-y-auto max-h-[500px]">
          <div v-for="section in sectionInfo.data" :key="section.id">
            <div class="bg-blues px-7 py-3 flex items-center justify-between rounded-2xl cursor-pointer">
              <p class="font-bold text-sm text-white">{{ section.title }}</p>
            </div>
            <div v-for="lesson in lessonInfo.results" :key="lesson.id">
              <div class="my-5 flex flex-col gap-4 bg-gray-200 py-5 rounded-2xl px-4"
                v-if="section.id == lesson.section_id">

                <div class="flex flex-col md:flex md:flex-row items-start justify-between gap-3 cursor-pointer">
                  <div class="flex items-center gap-2" @click="videoLink = lesson.video_url; is_video = true;">
                    <div class="w-[10px] h-[10px] bg-black rounded-full"></div>
                    <p class="font-bold text-sm text-black">{{ lesson.title }}</p>
                  </div>

                  <div class="grid grid-cols-1 md:grid-cols-3 gap-4 md:w-[420px] w-[150px]">
                    <p v-if="lesson.video_url" @click="videoLink = lesson.video_url; is_video = true;"
                      class="bg-blues text-white px-4 py-1.5 text-xs rounded-3xl font-semibold text-center">
                      Video
                    </p>

                    <p v-if="lesson.content" @click="contant = lesson.content; is_content = true;"
                      class="bg-blues text-white px-4 py-1.5 text-xs rounded-3xl font-semibold text-center">
                      Content Preview
                    </p>

                    <a v-if="lesson.pdf_file" :href="lesson.pdf_file" target="_blank" download="resource"
                      class="bg-blues text-white px-4 py-1.5 text-xs rounded-3xl font-semibold text-center">
                      Download PDF
                    </a>
                  </div>
                </div>

                <div class="mx-10">
                  <audio controls v-if="lesson.audio_file" class="rounded-xl md:w-[300px] w-full">
                    <source :src="lesson.audio_file" type="audio/ogg" />
                    <source :src="lesson.audio_file" type="audio/mpeg" />
                    Your browser does not support the audio element.
                  </audio>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Instructor/Header.vue";
import Footer from "@/components/Footer.vue";

import axios from "axios";
import { mapGetters } from "vuex";

export default {
  name: "CoursePreview",

  components: {
    Header,
    Footer,
  },

  computed: mapGetters(["userInfo"]),

  data() {
    return {
      lessonInfo: "",
      sectionInfo: "",
      courselist: "",
      courseDetailInfo: "",
      videoLink: "",
      is_video: false,
      is_content: false,
      content: "",
      loading: true,
    };
  },

  props: ["courseid"],

  mounted() {
    setTimeout(() => {
      this.loading = false;
    }, 2000)
  },

  async created() {
    await axios
      .get(
        `${process.env.VUE_APP_API}/courses/?id=${this.courseid}&format=json`
      )
      .then((response) => {
        this.courselist = response.data.data;
      });

    await axios
      .get(`${process.env.VUE_APP_API}/lessons/?Key=${this.userInfo.token_key}&course_name=${this.courseid}`)

      .then((response) => {
        this.lessonInfo = response.data;
        if (Object.keys(this.lessonInfo) == "error") {
          this.lessonCount = 0;
        } else {
          this.lessonCount = this.lessonInfo.count;
        }
      });
    await axios
      .get(`${process.env.VUE_APP_API}/section/?course_name=${this.courseid}`)
      .then((response) => {
        this.sectionInfo = response.data;
      });
    await axios
      .get(`${process.env.VUE_APP_API}/coursesdata/${this.courseid}/`)
      .then((response) => {
        this.courseDetailInfo = response.data;
      });
  },

  methods: {
    prefixUpdate(url) {
      if (process.env.NODE_ENV === "production") {
        return url.replace("http://", "https://");
      } else {
        return url;
      }
    },
  },
};
</script>
