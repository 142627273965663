<template>
  <div
    class="fixed flex bg-black bg-opacity-70 justify-center items-center inset-0 z-50 outline-none overflow-x-hidden overflow-y-auto"
    style="z-index: 5000"
  >
    <div class="w-[90%] lg:w-[35%] rounded-md m-4">
      <div
        class="relative flex flex-col clear-both justify-center rounded-t-lg w-full pt-12 p-4 md:p-10 bg-white gap-3"
      >
        <img
          @click="$emit('cancelClick')"
          class="absolute right-2 top-2 cursor-pointer"
          src="@/assets/newd/close.png"
        />
        <p class="text-center font-base text-xl text-gray-600">
          Are you sure want to delete ?
        </p>
        <h1 class="text-center font-bold text-xl text-teal-600">{{ title }}</h1>
      </div>
      <div
        class="body-popup flex flex-col rounded-b-lg py-3 md:py-6 px-3 md:px-6 bg-gray-200"
      >
        <div class="flex gap-4 justify-between w-full md:w-3/4 mx-auto">
          <button
            @click="$emit('confirmClick')"
            class="bg-teal-800 hover:bg-teal-600 text-white text-sm font-semibold rounded overflow-hidden px-4 py-2"
          >
            Yes
          </button>
          <button
            @click="$emit('cancelClick')"
            class="bg-white hover:bg-gray-300 text-gray-700 text-sm font-semibold rounded overflow-hidden px-4 py-2"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["title"],
  emits: ["cancelClick", "confirmClick"],
};
</script>

<style scoped>

</style>
