<template>
  <div>
    <div class="flex flex-col items-center justify-center min-h-screen bg-gray-100">
      <div  id="certificate"
        class="border-8 border-[#0085DB] p-8  shadow-lg w-full lg:w-[800px] text-center relative"
      >
        <div class="border-dotted border-4 border-[#0085DB] p-6 flex flex-col gap-5">
          <h1 class="text-3xl font-bold text-[#0085DB]">
            Certificate of Completion
          </h1>
          <p class="italic mt-4">This is to certify that</p>
          <p class="text-green-600 font-bold text-xl mt-2">{{certificate.display_name}}</p>
          <p class="italic mt-4">
            has successfully completed the course certification
          </p>
          <p class="text-2xl font-semibold mt-2">{{certificate.course_title}}</p>
          <p class="mt-2">
            with percentage of <span class="font-bold">{{certificate.percentage}}</span>
          </p>
          <p class="italic mt-6">dated</p>
          <p class="text-lg font-semibold mt-1">{{formatDate(certificate.end_time)}}</p>
        </div>
      </div>
      <div>
       <button @click="downloadCertificate" class="mt-4 px-4 py-2 bg-blue-500 text-white rounded">Download</button>
    </div>
    </div>
    
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
   certificate:[],
    };
  },
  props: ["complete_course_id"],
  created() {
    this.completecourse();
  },
   computed: {
    ...mapGetters(["userInfo"]),
  },
   mounted() {
    const script = document.createElement("script");
    script.src = "https://cdnjs.cloudflare.com/ajax/libs/html2canvas/1.4.1/html2canvas.min.js";
    script.onload = () => console.log("html2canvas loaded");
    document.body.appendChild(script);
  },
  methods: {
      async completecourse() {
      await axios
        .get(
          `${process.env.VUE_APP_API}/completed-certificates/?Key=${this.userInfo.token_key}&lesson_track__lesson_id__course_name__id=${this.complete_course_id}`
        )
        .then((response) => {
          this.certificate = response.data.data[0];
         
         
        });
    },
     formatDate(date) {
      return new Date(date).toLocaleDateString("en-IN", {
        day: "numeric",
        month: "long",
        year: "numeric",
      });
    },
     async downloadCertificate() {
      const certificateElement = document.getElementById("certificate");
      const canvas = await window.html2canvas(certificateElement, { scale: 2 });
      const link = document.createElement("a");
      link.href = canvas.toDataURL("image/jpeg");
      // link.download = "certificate.jpg";
       const fileName = `${this.certificate.display_name.replace(/\s+/g, '_')}_${this.certificate.course_title.replace(/\s+/g, '_')}.jpg`;
      link.download = fileName;
      link.click();
    },
  },
};
</script>

<style></style>
