<template>
  <div>
    <!-- <Header /> -->

    <div class="flex">
      <SideBar />

      <div
        class="w-[90%] mx-auto lg:w-full lg:mr-10 mt-10 rounded-3xl overflow-x-auto overflow-y-auto heightfix"
        style="box-shadow: 0px 2px 30px 0px #01b0f926"
      >
        <table v-if="!is_popupview" class="table-auto w-full overflow-x-auto">
          <thead>
            <tr>
              <th
                class="px-4 lg:px-7 py-8 border-b text-center border-r text-blues"
              >
                Sl.No
              </th>
              <th
                class="px-4 lg:px-7 py-8 border-b text-center border-r text-blues"
              >
                Assesment
              </th>
              <th
                class="px-4 lg:px-7 py-8 border-b text-center border-r text-blues"
              >
                Score
              </th>
              <th
                class="px-4 lg:px-7 py-8 border-b text-center border-r text-blues"
              >
                Status
              </th>
            </tr>
          </thead>
          <tbody v-if="loading">
            <tr class="w-full">
              <td colspan="4" class="text-center py-6">
                <div class="w-full flex justify-center items-center">
                  <img
                    src="@/assets/newd/loading.gif"
                    alt="Loading..."
                    class=""
                  />
                </div>
              </td>
            </tr>
          </tbody>
          <tbody v-else-if="this.published">
            <tr v-for="(el, index) in this.published" :key="el">
              <td class="px-4 lg:px-7 py-8 border-b text-center border-r">
                <div class="flex items-center justify-center">
                  <div class="ml-3">
                    <p class="text-gray-900 whitespace-no-wrap">
                      {{ index + 1 }}
                    </p>
                  </div>
                </div>
              </td>
              <td class="px-4 lg:px-7 py-8 border-b text-center border-r">
                <p
                  v-if="el.quiz"
                  class="text-center text-gray-900 whitespace-no-wrap"
                >
                  {{ el.quiz.name }}
                </p>
                <p v-else class="text-center text-gray-900 whitespace-no-wrap">
                  empty
                </p>
              </td>
              <td
                v-if="el.quiz.quiztakers_set.score == 0"
                class="px-4 lg:px-7 py-8 border-b text-center border-r"
              >
                -
              </td>
              <td
                v-else
                class="px-4 lg:px-7 py-8 border-b text-center border-r"
              >
                {{ el.quiz.quiztakers_set.score }}
              </td>
              <td class="px-4 lg:px-7 py-8 border-b text-center border-r">
                <div
                class="cursor-pointer"
                  v-if="
                    el.quiz.qualify_certificate == true &&
                    el.quiz.quiztakers_set.completed == true
                  "
                  @click="quizPreview(el.quiz.quiztakers_set.id)"
                >
                  <div
                    class="mx-10 mb-1 cursor-pointer rounded-lg flex justify-center gap-2 items-center py-3 bg-blues text-white"
                    
                  >
                    Preview
                  </div>
                </div>


                <router-link
                v-else-if="
                    el.quiz.qualify_certificate == true &&
                    el.quiz.quiztakers_set.completed != true
                  "
                class="mb-1 mx-10 cursor-pointer rounded-lg font-bold flex justify-center gap-2 py-3 hover:text-teal-500 text-black"
                  style="border: 1px solid #01b0f9"
                    :to="{
                      name: 'QuizTest',
                      params: {
                        course_id: this.course_id,
                        quiz_id: el.quiz.id,
                      },
                    }"
                  >
                    Start
                  </router-link>

                <!-- <div
                  class="mb-1 mx-10 cursor-pointer rounded-lg font-bold flex justify-center gap-2 py-3 hover:text-teal-500 text-black"
                  style="border: 1px solid #01b0f9"
                  v-else-if="
                    el.quiz.qualify_certificate == true &&
                    el.quiz.quiztakers_set.completed != true
                  "
                >
                  <router-link
                    :to="{
                      name: 'QuizTest',
                      params: {
                        course_id: this.course_id,
                        quiz_id: el.quiz.id,
                      },
                    }"
                  >
                    Start
                  </router-link>
                </div> -->
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <!-- <tr>
              <td class="px-4 lg:px-7 py-8 border-b text-center border-r">
                empty
              </td>
              <td class="px-4 lg:px-7 py-8 border-b text-center border-r">
                empty
              </td>
              <td class="px-4 lg:px-7 py-8 border-b text-center border-r">
                empty
              </td>
              <td class="px-4 lg:px-7 py-8 border-b border-r text-center">
                empty
              </td>
            </tr> -->
            <tr class="w-full">
              <td colspan="4" class="text-center py-6">
                <!-- <div class="w-full flex justify-center items-center">
                  <img src="@/assets/newd/loading.gif" alt="Loading..." class=""/>
                </div> -->
                NO Data Found
              </td>
            </tr>
          </tbody>
        </table>

        <div
          v-if="is_popupview"
          style="background-color: white !important"
          class="bg-white rounded-2xl"
        >
          <div class="mx-auto relative m-4 p-8 rounded-md">
            <div
              v-if="dataval"
              class="w-full lg:p-4 mx-auto lg:w-12/12 relative text-center text-xl font-semibold"
            >
              {{ dataval.quiz.name }}
              <div class="absolute right-8 top-8">
                <div
                  @click="quiz_data()"
                  class="mb-1 cursor-pointer rounded-lg flex border justify-center w-24 gap-2 items-center py-1 bg-white"
                >
                  <div
                    class="text-sm text-gray-700 hover:text-teal-500"
                    title="back"
                  >
                    back
                  </div>
                  <div class="flex items-center">
                    <img
                      src="https://img.icons8.com/external-simple-solid-edt.graphics/15/undefined/external-Back-arrows-simple-solid-edt.graphics-2.png"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="h-screen overflow-y-auto pb-10" v-if="dataval">
            <div
              v-for="(el, index) in dataval.coursequizusersanswer_set"
              :key="el"
              class="border max-w-4xl mx-auto mt-4 text-start p-10 rounded-3xl"
            >
              <p class="py-2 font-bold">
                {{ index + 1 }}) {{ el.question.question_text }}
              </p>

              <div
                v-for="(al, index) in el.question.coursequizanswer_set"
                :key="al"
              >
                <p
                  class=""
                  :class="[
                    al.is_correct == true ? 'text-blue-900 font-bold' : '',
                  ]"
                >
                  {{ index + 1 }}) {{ al.answer_text }} <br />
                </p>
              </div>

              <div class="px-6 mt-6 py-6 rounded-2xl border my-2">
                <h1
                  class="px-5 lg:px-5 py-2 lg:py-4 items-center border-gray-200 bg-gray-100 text-xs font-semibold rounded-2xl text-gray-600 tracking-wider"
                >
                  Your Answer :
                </h1>
                <p
                  v-if="el.answer"
                  class="px-4 text-sm font-bold"
                  :class="[
                    el.answer.is_correct == true
                      ? 'text-green-700'
                      : 'text-red-400',
                  ]"
                >
                  {{ el.answer.answer_text }}
                </p>
                <p v-else class="px-4 text-sm font-bold">Not attend</p>
              </div>
            </div>
          </div>

          <!-- <button
                v-if="currentQuestion > 0"
                class="gradient-btn"
                @click="pvsQt"
              >
                Previous
              </button>
              <button
                v-if="currentQuestion + 1 < this.questions.length"
                class="gradient-btn"
                @click="nxtQt"
              >
                Next
              </button> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Student/Header.vue";
import SideBar from "@/components/Student/SideBar.vue";

import axios from "axios";
import { mapGetters } from "vuex";

export default {
  components: {
    Header,
    SideBar,
  },
  data() {
    return {
      dataval: "",
      is_popupview: false,
      published: "",
      quiz_id: 0,
      currentQuestion: 0,
      score: 0,
      percentage: 0,
      countDown: 30,
      timer: null,
      startindex: "",
      startQuiz: false,
      completed: false,
      message: "Congrats, Your are Completed Course Quiz.",
      HeaderVal: "",
      list_data: [],
      messages: {
        text: "",
        type: "null",
      },
      quiz_details: "",
      loading: true,
    };
  },
  created() {
    this.getQuiz();
  },

  props: ["course_id"],

  computed: {
    ...mapGetters(["userInfo"]),
  },
  methods: {
    quiz_data() {
      this.is_popupview = false;
    },
    async quizPreview(id) {
      this.is_popupview = true;

      let token = this.userInfo.token_key;
      await axios
        .get(
          `${process.env.VUE_APP_API}/quiztaker__quizzes_pk/${id}/?Key=${token}`
        )
        .then((response) => {
          this.dataval = response.data.data;
          console.log("response::", response.data.data);
        });
    },
    async getQuiz() {
      if (this.userInfo) {
        let token = this.userInfo.token_key;
        console.log(
          `${process.env.VUE_APP_API}/course_quizzes_detail/${this.course_id}/?Key=${token}`
        );
        await axios
          .get(
            `${process.env.VUE_APP_API}/course_quizzes_detail/${this.course_id}/?Key=${token}`
          )
          .then((response) => {
            if (response.data.error == "No Quiz found for this course") {
              console.log("Event Faild");
              this.messages = {
                text: "No Quiz found for this course",
                type: "error",
              };
            } else {
              this.published = response.data.data;
              this.loading = false;

             
             
              // this.published = response.data.data[0].quiz.qualify_certificate
              this.quiz_details = response.data.data;
              console.log("index_data::", this.index_data);
              this.questions =
                response.data.data[0].quiz.coursequizquestion_set;
              console.log("this.questions", this.questions);

              console.log("response.data.data", response.data.data);
              console.log(
                "response.data.data",
                response.data.data[0].quiz.qualify_certificate
              );
              console.log("quiz values", this.quiz_details.quiz);
              if (response.data.data[0].quiz.quiztakers_set.completed == true) {
                this.completed = true;
              }
            }
             if (response.data.status == 400) {
                this.loading = false;
              }
          });
      }
    },
    startQuizFunc(index, quiz_title_data) {
      this.questions = this.quiz_details[index].quiz.coursequizquestion_set;
      this.quiz_title = quiz_title_data;
      this.startQuiz = true;
    },

    handleAnswerClick(question_id, answer_id, isCorrect) {
      console.log(question_id, answer_id, isCorrect);
      if (question_id != false) {
        this.list_data.push({
          question: question_id,
          answer: answer_id,
        });
      }

      clearTimeout(this.timer);
      let nextQuestion = this.currentQuestion + 1;
      if (isCorrect) {
        this.score = this.score + 1;
        console.log("this.score", this.score);
        // this.percentage = (this.score / this.questions.length) * 100;
      }
      if (nextQuestion < this.questions.length) {
        this.currentQuestion = nextQuestion;

        this.countDown = 30;
        // this.countDownTimer();
      } else {
        this.completed = true;
        this.saveQuizResult();
      }
    },
    async saveQuizResult() {
      this.HeaderVal = {
        score: this.score,
        quiz: this.course_id,
        quiz_title: this.quiz_title,
        usersanswer: this.list_data,
        completed: true,
      };
      console.log("post_befer __ this.HeaderVal:::", this.HeaderVal);
      if (this.userInfo) {
        let token = this.userInfo.token_key;
        console.log(
          `${process.env.VUE_APP_API}/course_save_multi_answer/?Key=${token}`,
          this.HeaderVal
        );
        await axios
          .patch(
            `${process.env.VUE_APP_API}/course_save_multi_answer/?Key=${token}`,
            this.HeaderVal
          )
          .then((response) => {
            console.log("response", response);
            console.log("response", response.data);
            if (response.data.message == "Course Answer updated successfully") {
              this.messages = {
                text: "Congrats, Your are Completed Course Quiz.",
                type: "success",
              };
            } else {
              this.messages = {
                text: " Event Faild",
                type: "error",
              };
            }
          });
        setTimeout(() => {
          this.messages = {
            text: " ",
            type: "null",
          };
          location.reload();
          this.getQuiz();
        }, 200);
      }

      console.log("user answer quiz saved");
    },
  },
};
</script>

<style scoped>
.heightfix {
  height: calc(100vh - 170px) !important;
}
</style>
