<template>
  <div>
    <!-- <Header /> -->

    <confirmPopup v-if='popupwindow==true' :title="titlepass" @cancelClick="popupwindow=false" @confirmClick="QuizDeleted(titlepass)" />
    <FlashMessage v-if="this.messages.text != ''" :message="this.messages" />

    <div class="flex">
      <SideBar />

      <div
        class="w-full mr-10 mt-10 rounded-3xl overflow-y-auto heightfix"
        style="box-shadow: 0px 2px 30px 0px #01b0f926"
      >
        <div class="flex flex-col h-full" v-if="this.is_selected == 'list'">
          <div class="flex items-center justify-between px-5 lg:px-10 pt-10">
            <div>
              <h1 class="text-lg font-semibold">Assignments</h1>
            </div>
            <div>
              <button
                @click="this.addQuiz = true"
                class="bg-blues text-white font-semibold text-xs px-4 py-2 rounded-xl"
              >
                Add Assessment
              </button>
            </div>
          </div>


          <div v-if="loading" class="flex flex-col justify-center h-screen "> 
            <div class="flex justify-center">
      <img src="@/assets/newd/loading.gif" alt="">
      </div>
    </div>

          <div v-else-if="this.generalquiz.length>0 & !loading" class="flex flex-col gap-10 px-5 lg:px-10 pt-5 pb-10 w-full">
            <div
              v-for="quiz in this.generalquiz"
              :key="quiz"
              class="p-5 lg:p-10 flex items-center gap-5 lg:gap-10 w-full border rounded-2xl"
            >
              <div class="flex  flex-col gap-2 lg:gap-0 lg:flex-row items-center justify-between w-full">
                <div class="flex flex-col lg:flex-row items-center gap-3 lg:gap-10  w-full lg:w-auto">
                  <img src="@/assets/new/assignment.png" class="rounded-xl w-full lg:w-auto" />

                  <div class="flex flex-col md:flex-row lg:flex-col gap-1 md:gap-4 lg:gap-4">
                    <h1 class="text-gray-700 text-base font-bold">
                      {{ quiz.quiz.name }}
                    </h1>

                    <h3
                      class="text-left text-sm px-1 py-1 text-blue-600 font-medium"
                    >
                      {{ quiz.quiz.coursequizquestion_set.length }} Questions
                    </h3>

                    <div v-if="quiz.quiz.qualify_certificate == true">
                      <label
                        class="text-left text-sm px-1 py-1 text-green-700 font-medium"
                      >
                        <input
                          type="checkbox"
                          @click="quiz_published(quiz.quiz.name, false)"
                          :checked="true"
                        />
                        Published</label
                      >
                    </div>
                    <div v-else>
                      <label
                        class="text-left text-sm px-1 py-1 text-red-700 font-medium"
                      >
                        <input
                          type="checkbox"
                          @click="quiz_published(quiz.quiz.name, true)"
                          :checked="false"
                        />
                        Unpublished</label
                      >
                    </div>
                  </div>
                </div>

                <div class="flex flex-col md:flex-row gap-3 items-center">
                  <button
                    class="border border-blue-500 bg-white px-6 rounded"
                    @click="quizgetscoreinfo(quiz.quiz.name)"
                    :class="[
                      this.is_selected == 'score'
                        ? 'border border-teal-500'
                        : 'border border-gray-200',
                    ]"
                  >
                    Scoreboard
                  </button>
                  <button
                    class="border border-red-400 px-2 rounded flex gap-1 text-sm items-center py-1 hover:bg-red-100"
                    @click="
                      titlepass = quiz.quiz.name;
                      popupwindow = true;
                    "
                  >
                    <font-awesome-icon
                      class="text-base text-red-400 self-center h-6 w-6"
                      :icon="['fas', 'trash-alt']"
                    />
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="h-screen flex flex-col justify-center">
            <p class="flex justify-center">No Data Found</p>
          </div>


        </div>

        <div class="flex flex-col w-full" v-if="this.is_selected == 'score'">
          <div class="px-3 lg:px-10 mt-10 pb-2">
            <button
              class="bg-blues text-xs font-bold text-white px-5 py-1.5 rounded-full"
              @click="this.is_selected = 'list'"
            >
              Back
            </button>
          </div>

          <div class="p-3 lg:p-10">
            <div
              class="bg-[#F9FAFC] py-4 px-8 flex flex-col md:flex-row items-center justify-between rounded-xl"
            >
              <div class="">
                <p class="text-lg text-blues font-semibold">Scoreboard</p>
              </div>

              <div @click="quizget(scoredetails.name)"
                class="bg-blues text-white rounded-lg py-1.5 px-5 flex items-center gap-2 cursor-pointer"
              >
                <img src="@/assets/newd/download1.png" alt="" />
                <p class="text-sm text-white">Download</p>
              </div>
            </div>
          </div>

          <div class="lg:px-3 px-10 pb-10 ">
            <div class="grid grid-cols-1 lg:grid-cols-3 gap-10">
              <div
                class="bg-[#F9FAFC] py-8 px-8 flex flex-col gap-4 items-center justify-center rounded-xl"
              >
                <p class="font-bold text-center text-[#333333]">
                  Learners visited
                </p>
                <p class="text-xl font-bold text-blues">
                  {{ scoredetails.count }}
                </p>
              </div>
              <div
                class="bg-[#F9FAFC] py-8 px-8 flex flex-col gap-4 items-center justify-center rounded-xl"
              >
                <p class="font-bold text-center text-[#333333]">
                  Learners appeared
                </p>
                <p class="text-xl font-bold text-blues">
                  {{ scoredetails.completed_count }}
                </p>
              </div>
              <div
                class="bg-[#F9FAFC] py-8 px-8 flex flex-col gap-4 items-center justify-center rounded-xl"
              >
                <p class="font-bold text-center text-[#333333]">
                  Learners not attended
                </p>
                <p class="text-xl font-bold text-blues">
                  {{ scoredetails.not_completed_count }}
                </p>
              </div>
            </div>
          </div>

          <div class="lg:px-10 lg:rounded-3xl overflow-x-auto ">
            <table class="table-auto w-full  lg:rounded-3xl">
              <thead>
                <tr>
                  <th
                    class="px-4 lg:px-7 py-8 border-b text-center border-r text-blues bg-[#F9FAFC] whitespace-nowrap"
                  >
                    S NO
                  </th>
                  <th
                    class="px-4 lg:px-7 py-8 border-b text-center border-r text-blues bg-[#F9FAFC]"
                  >
                    Email
                  </th>
                  <th
                    class="px-4 lg:px-7 py-8 border-b text-center border-r text-blues bg-[#F9FAFC]"
                  >
                    Learner
                  </th>
                  <th
                    class="px-4 lg:px-7 py-8 border-b text-center border-r text-blues bg-[#F9FAFC]"
                  >
                    Score
                  </th>
                  <th
                    class="px-4 lg:px-7 py-8 border-b text-center text-blues bg-[#F9FAFC]"
                  >
                    Status
                  </th>
                </tr>
              </thead>
              <tbody v-if="scoredetails.data">
                <tr v-for="(el, index) in scoredetails.data" :key="el">
                  <td class="px-4 lg:px-7 py-8 border-b text-center border-r">
                    <p class="text-primary">{{ index + 1 }}</p>
                  </td>
                  <td class="px-4 lg:px-7 py-8 border-b text-center border-r">
                    <p class="text-primary">{{ el.user.email }}</p>
                  </td>
                  <td class="px-4 lg:px-7 py-8 border-b text-center border-r">
                    <p class="text-primary">{{ el.user.display_name }}</p>
                  </td>
                  <td class="px-4 lg:px-7 py-8 border-b text-center border-r">
                    <p v-if="el.score" class="text-primary">{{ el.score }}</p>
                    <p v-else>-</p>
                  </td>
                  <td class="px-4 lg:px-7 py-8 border-b text-center">
                    <p
                      v-if="el.completed"
                      class="text-center text-gray-900 whitespace-no-wrap"
                    >
                      Completed
                    </p>
                    <p
                      v-else
                      class="text-center text-gray-900 whitespace-no-wrap"
                    >
                      Not Attended
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div
        v-if="this.addQuiz"
        @click.self="this.addQuiz = false"
        class="fixed bg-primary bg-opacity-30 top-0 left-0 right-0 bottom-0 z-50 flex items-center justify-center"
      >
        <div
          class="lg:w-[40%] bg-white rounded-xl p-10 overflow-y-auto max-h-[600px]"
        >
          <div class="flex items-center justify-center">
            <h3 class="text-xl font-bold text-blues text-center">Add Quiz</h3>
            <!-- <img
              src="@/assets/newd/close.png"
              class="cursor-pointer"
              alt=""
              @click="this.assignmentPopup = false"
            /> -->
          </div>

          <div
            class="w-full mt-4 flex flex-col p-6 relative border-gray-100 rounded-2xl"
          >
            <div class="w-full">
              <form ref="quizAddReff"
                @submit.prevent="QuizPost"
                class="flex flex-col gap-4 text-xs md:text-sm lg:text-base h-full"
              >
                <div class="flex flex-col gap-3 w-full">
                  <label for="attachments" class="text-left"
                    >Attachments *</label
                  >
                  <input
                  id="source"
                    type="file"
                    class="bg-gray-100 py-2 px-2 w-full rounded text-xs md:text-sm lg:text-base"
                    @change="inputchange"
                    required
                  />
                </div>
                <div class="flex justify-center pb-4 mt-3 gap-10">
                  <button
                    @click="this.addQuiz = false"
                    class="border text-gray-500 rounded-lg text-sm px-10 font-semibold py-2"
                  >
                    Close
                  </button>
                  <button
                    type="submit"
                    class="text-white bg-blues rounded-lg text-sm px-10 font-semibold py-2"
                  >
                    Upload
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Admin/Header.vue";
import SideBar from "@/components/Admin/SideBar.vue";

import ConfirmPopup from "@/components/ConfirmPopup.vue";
import FlashMessage from "@/components/FlashMessage.vue";

import axios from "axios";
import { mapGetters } from "vuex";

export default {
  components: {
    Header,
    SideBar,
    ConfirmPopup,
    FlashMessage
  },
  data() {
    return {
      not_attended: 0,
      current_coursename: "",
      scoredetails: "",
      published: "",
      popupwindow: false,
      titlepass: "",
      is_selected: "list",
      quiz_id: 0,
      generalquiz: [],
      messages: {
        text: "",
        type: "null",
      },
      addQuiz: false,
      loading:true,
    };
  },

  // created() {
  //   this.quizgetinfo();
  // },

  // props: ["course_id"],

  computed: {
    ...mapGetters(["userInfo"]),
  },

  mounted() {},

  methods: {

    async QuizPost(el) {
      var sourceFile;
      if (el.target.elements.source.files.length > 0) {
        sourceFile = el.target.elements.source.files[0];
      } else {
        sourceFile = "";
      }
      // console.log("this.course_id", this.course_id);

      let urls = `${process.env.VUE_APP_API}/excelcourse_question_answer_new/?Key=${this.userInfo.token_key}`;
      let HeaderVal = new FormData();

      // let couseId = this.courseId;

      HeaderVal.append("my_file", sourceFile);
      console.log("HeaderVal::", HeaderVal);

      await axios
        .post(urls, HeaderVal, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response.data.message == "Course Quiz process successfully") {
            this.messages = {
              text: "Course Quiz added successfully",
              type: "success",
            };
            // this.$refs.lessonaddFn.reset();
            this.popUpLesson = false;
            this.addQuiz = false;
            this.quizgetinfo();
          } else {
            this.messages = {
              text: "Event Faild",
              type: "error",
            };
          }
        });
      setTimeout(() => {
        this.messages = {
          text: "",
          type: "null",
        };
        (this.questionAnswer = []), (this.quizCourse = null);
        setTimeout(() => {
          // window.location.reload();
          // this.$refs.quizAddReff.reset();
        }, 200);
      }, 1500);
      // axios.post(`${process.env.VUE_APP_API}/course_question_answer_new/?Key=${this.userInfo.token_key}`,this.quizCourse)
      // .then(response => {
      //     console.log(response)
      //     if(response.status == 200 && response.statusText == "OK"){
      //         this.messages = {
      //             text: "Quiz Added successfully",
      //             type: 'success',
      //         }
      //     } else {
      //         this.messages = {
      //             text: " Event Faild",
      //             type: 'error',
      //         }
      //     }
      //     // this.quizCourse = null
      //     // this.questionAnswer = []
      //     setTimeout(() => {
      //     this.messages = {
      //         text: "",
      //         type: 'null',
      //     }
      //     this.questionAnswer= [],
      //     this.quizCourse= null
      //     setTimeout(() => {
      //         window.location.reload()
      //         this.$refs.quizAddReff.reset()
      //     }, 200);

      // }, 1500)
      // })
    },

    // async quizgetinfo() {
    //   this.is_selected = "list";
    //   // console.log(`${process.env.VUE_APP_API}/course_quizzes_detail/${this.course_id}/?Key=${this.userInfo.token_key}`)
    //   await axios
    //     .get(
    //       `${process.env.VUE_APP_API}/course_quizzes_detail/?Key=${this.userInfo.token_key}`
    //     )
    //     .then((response) => {
    //       if (response.data.data) {
    //         this.generalquiz = response.data.data;
    //         this.loading=false;
    //         this.published = this.generalquiz;
    //         console.log(this.published);
    //         // console.log("this.generalquiz::",this.generalquiz[0].quiz.qualify_certificate)
    //       }else if(response.data.status==400){
    //         this.loading=false;
    //       }
    //     });
    // },

    async quizget(course_name) {
      // console.log("dataaaaaaaaaaaaaaaa",`${process.env.VUE_APP_API}/coursequizdownloadattemptcount/${this.course_id}/${this.current_coursename}/?Key=${this.userInfo.token_key}`)
      await axios
        .get(
          `${process.env.VUE_APP_API}/coursequizdownloadattemptcount/${this.current_coursename}/?Key=${this.userInfo.token_key}`
        )
        .then((response) => {
          const link = document.createElement("a");
          link.href = `${process.env.VUE_APP_API}/coursequizdownloadattemptcount/${this.current_coursename}/?Key=${this.userInfo.token_key}`;
          link.setAttribute("download", "filetest.xlsx");
          document.body.appendChild(link);
          link.click();
          // console.log("aftorlink::",link)
        });
    },

    async quiz_published(coursequizname, status) {
      console.log("before::", this.published);
      this.published = status;
      console.log("before coursequizname::", coursequizname);
      let that = this;
      let messagesPass = {
        qualify_certificate: status,
        name: coursequizname,
      };
      console.log(
        `${process.env.VUE_APP_API}/coursequizpublished/?Key=${this.userInfo.token_key}`,
        messagesPass
      );
      await axios
        .put(
          `${process.env.VUE_APP_API}/coursequizpublished/?Key=${this.userInfo.token_key}`,
          messagesPass
        )
        .then((response) => {
          this.publishedquiz = response.data;
          console.log("this.publishedquiz:::", this.publishedquiz);
          if (response.data.message == "This published update successfully") {
            this.quizgetinfo();
            console.log("this.published", that.published);
            if (that.published == true) {
              // this.generalquiz = []
              this.messages = {
                text: "course quiz published successfully",
                type: "success",
              };
            } else {
              // this.generalquiz = []
              this.messages = {
                text: "course quiz Unpublished successfully",
                type: "error",
              };
            }

            this.popupwindow = false;
          } else {
            this.messages = {
              text: "Quiz name, description, total score and min score filed check Faild",
              type: "error",
            };
          }
        });
      setTimeout(() => {
        this.messages = {
          text: "",
          type: "null",
        };
      }, 1500);
    },

    async quizgetscoreinfo(coursename) {
      this.is_selected = "score";
      this.current_coursename = coursename;
      console.log(
        `${process.env.VUE_APP_API}/coursequizattemptcount/?Key=${this.userInfo.token_key}`
      );
      await axios
        .get(
          `${process.env.VUE_APP_API}/coursequizattemptcount/?Key=${this.userInfo.token_key}`
        )
        .then((response) => {
          if (response.data.data) {
            this.scoredetails = response.data;
            console.log("this.generalquiz", this.scoredetails);
            // this.published = this.generalquiz[0].quiz.qualify_certificate
            // console.log("this.generalquiz::",this.generalquiz[0].quiz.qualify_certificate)
          }
        });
    },
    QuizDeleted(titlepass) {
      // console.log(this.quizCourse)
      let messagesPass = {
        name: titlepass,
      };
      axios
        .put(
          `${process.env.VUE_APP_API}/coursequizdeleted/?Key=${this.userInfo.token_key}`,
          messagesPass
        )
        .then((response) => {
          if (response.data.message == "This data deleted successfully") {
            this.generalquiz = [];
            this.messages = {
              text: "course Quiz Deleted successfully",
              type: "success",
            };
            this.popupwindow = false;
          } else {
            this.messages = {
              text: " Event Faild",
              type: "error",
            };
          }
          setTimeout(() => {
            this.$router.go(0);
            this.messages = {
              text: " ",
              type: "null",
            };
          }, 2000);
        });
    },
  },
};
</script>

<style scoped>
.heightfix {
  height: calc(100vh - 170px) !important;
}
</style>
