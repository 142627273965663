<template>
  <div class="min-h-screen relative">
    <Header />

    <FlashMessage v-if="this.messages.text != ''" :message="this.messages" />

    <div class="min-h-screen w-full container mx-auto">
      <section
        id="UserProfile"
        v-if="profileInfo"
        class="w-full py-3 mt-10 flex flex-col md:flex-row flex-wrap gap-20 items-center justify-center"
      >
        <!-- Profile Card -->
        <div
          class="bg-white shadow-md rounded-lg p-5 text-center w-full md:h-[340px] md:w-[280px]"
        >
          <div class="flex flex-col items-center">
            <div v-if="profileInfo.data.profile == ''">
              <p
                class="border rounded-full w-24 h-24 flex items-center justify-center text-4xl text-black capitalize bg-gray-200 font-semibold"
              >
                {{ profileInfo.data.user.slice(0, 1) }}
              </p>
            </div>
            <div v-else>
              <img
                :src="profileInfo.data.profile"
                alt="Profile Picture"
                class="w-24 h-24 rounded-full"
              />
            </div>
          </div>
          <div class="mt-4">
            <p class="text-lg font-bold capitalize">
              {{ profileInfo.data.user }}
            </p>
            <p class="text-sm text-gray-500">
              {{ profileInfo.data.designation }}
            </p>
          </div>
          <div class="mt-10 flex justify-center gap-5">
            <a :href="profileInfo.data.github" target="_blank" title="GitHub">
              <img
                src="@/assets/githubprofile.svg"
                class="h-6 w-6 cursor-pointer"
                alt="GitHub"
              />
            </a>
            <a
              :href="profileInfo.data.linkedin"
              target="_blank"
              title="LinkedIn"
              class="text-gray-500 hover:text-blue-500"
            >
              <svg
                fill="currentColor"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="0"
                class="w-6 h-6"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="none"
                  d="M16 8a6 6 0 016 6v7h-4v-7a2 2 0 00-2-2 2 2 0 00-2 2v7h-4v-7a6 6 0 016-6zM2 9h4v12H2z"
                ></path>
                <circle cx="4" cy="4" r="2" stroke="none"></circle>
              </svg>
            </a>
          </div>
        </div>

        <!-- Personal Details Card -->
        <div
          class="w-full lg:w-[720px] flex justify-center bg-white shadow-md rounded-2xl px-6 py-10"
        >
          <div class="w-full md:w-[800px] flex flex-col gap-5">
            <div class="flex justify-between gap-2">
              <div class="text-[#0085DB] text-xl font-bold mt-2 p-4">
                Personal Details
              </div>
              <!-- <div @click="ProfileEdit" class="mt-8 relative">
                <button class="px-5 rounded border text-white bg-[#0F172A]">
                  Edit
                </button>
              </div> -->
            </div>
            <div class="flex flex-col gap-5 justify-center">
              <div class="overflow-x-auto">
                <table class="min-w-full bg-white border-gray-300">
                  <tbody>
                    <tr>
                      <td class="py-2 px-4 font-medium text-gray-800">
                        Full Name
                      </td>
                      <td class="py-2 px-4 text-gray-600">
                        : {{ profileInfo.data.user }}
                      </td>
                    </tr>
                    <tr>
                      <td class="py-2 px-4 font-medium text-gray-800">Email</td>
                      <td class="py-2 px-4 text-gray-600">
                        : {{ profileInfo.data.Email }}
                      </td>
                    </tr>
                    <tr>
                      <td class="py-2 px-4 font-medium text-gray-800">
                        Mobile
                      </td>
                      <td class="py-2 px-4 text-gray-600">
                        : {{ profileInfo.data.mobile }}
                      </td>
                    </tr>
                    <tr>
                      <td class="py-2 px-4 font-medium text-gray-800">
                        Address
                      </td>
                      <td class="py-2 px-4 text-gray-600">
                        : {{ profileInfo.data.address }}
                      </td>
                    </tr>
                    <tr>
                      <td class="py-2 px-4 font-medium text-gray-800">
                        Description
                      </td>
                      <td class="py-2 px-4 text-gray-600">
                        : {{ profileInfo.data.description.slice(0, 180) }}
                        <p v-if="profileInfo.data.description.length > 180">
                          ...More
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="mt-4 flex justify-center rounded-xl gap-5">
                <div @click="ProfileEdit" class="relative">
                  <button class="px-4 py-2 rounded-lg text-white bg-[#0F172A]">
                    Edit
                  </button>
                </div>

                <div @click="ChangePasswordPopup" class="relative">
                  <button class="px-4 py-2 rounded-lg text-white bg-[#0F172A]">
                    Change Password
                  </button>
                </div>

                <!-- <router-link
                  class="flex items-center gap-2 px-4 py-2 text-white bg-[#0085DB] rounded-lg"
                >
                  <img
                    src="@/assets/newd/changee.png"
                    alt="Change Icon"
                    class="w-5 h-5"
                  />
                  <span class="text-sm font-medium">Change Password</span>
                </router-link> -->
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <!-- PopUp -->
    <div
      v-if="popUp && updateDetails != null"
      class="fixed w-full min-h-screen bg-black bg-opacity-75 top-0 z-50 flex justify-center items-center"
    >
      <div
        class="w-full md:w-5/6 lg:w-5/6 xl:w-3/6 2xl:w-2/6 h-full mx-auto flex flex-col items-center"
      >
        <div
          class="bg-white p-8 rounded-lg shadow-md w-full max-w-md flex flex-col gap-3"
        >
          <div class="flex justify-between">
            <h2 class="text-2xl font-bold mb-6 text-[#0085DB]">Edit Details</h2>
            <img
              class="h-5 w-5 md:h-8 md:w-8 cursor-pointer"
              @click="popUp = false"
              src="@/assets/closebutton.svg"
            />
          </div>
          <form class="">
            <div class="mb-4">
              <label class="block text-sm font-medium text-[#333333]"
                >Destination <span class="text-red-400">*</span></label
              >
              <input
                type="text"
                v-model="updateDetails.designation"
                class="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                placeholder="Student"
                required
              />
            </div>
            <div class="mb-4">
              <label class="block text-sm font-medium text-[#333333]"
                >Mobile <span class="text-red-400">*</span></label
              >
              <input
                type="text"
                v-model="updateDetails.mobile"
                class="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                placeholder="123456789"
                required
              />
            </div>
            <div class="mb-4">
              <label class="block text-sm font-medium text-[#333333]"
                >Address <span class="text-red-400">*</span></label
              >
              <input
                type="text"
                v-model="updateDetails.address"
                class="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                placeholder="21/2, Interting street, Common city - 365986"
                required
              />
            </div>
            <div class="mb-4">
              <label class="block text-sm font-medium text-gray-700"
                >Description <span class="text-red-400">*</span></label
              >
              <textarea
                class="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                placeholder="My name is this you can ask me what event it sear tire"
                required
              ></textarea>
            </div>
            <div class="mb-4">
              <label class="block text-sm font-medium text-[#333333]"
                >GitHub <span class="text-red-400">*</span></label
              >
              <input
                type="text"
                v-model="updateDetails.github"
                class="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                placeholder="Link"
                required
              />
            </div>
            <div class="mb-4">
              <label class="block text-sm font-medium text-[#333333]"
                >LinkedIn <span class="text-red-400">*</span></label
              >
              <input
                type="text"
                v-model="updateDetails.linkedin"
                class="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                placeholder="Link"
                required
              />
            </div>
            <div class="flex justify-center items-center w-full">
              <button
                @click="SubmitProfile()"
                type="submit"
                class="w-full max-w-[118px] h-[40px] px-4 py-2 bg-[#0F172A] text-white rounded-md hover:bg-blue-700 transition"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div
      v-if="changePassword"
      class="fixed w-full min-h-screen bg-black bg-opacity-75 top-0 z-50 flex justify-center items-center"
    >
      <div
        class="w-full md:w-5/6 lg:w-5/6 xl:w-3/6 2xl:w-2/6 h-full mx-auto flex flex-col items-center"
      >
        <div
          class="bg-white p-8 rounded-lg shadow-md w-full max-w-md flex flex-col gap-3"
        >
          <div class="flex justify-between">
            <h2 class="text-2xl font-bold mb-6 text-[#0085DB]">
              Change Password
            </h2>
            <img
              class="h-5 w-5 md:h-8 md:w-8 cursor-pointer"
              @click="changePassword = false"
              src="@/assets/closebutton.svg"
            />
          </div>
          <form @submit.prevent="changepassword">
            <div class="mb-4">
              <label class="block text-sm font-medium text-[#333333]"
                >Old Password <span class="text-red-400">*</span></label
              >

                <div class="flex items-center relative"> 
                    <input
                        type="password"
                        id="oldpassword"
                        class="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                        placeholder="Old Password"
                        required
                    />

                    <img v-if="passwordType == 'oldpassword'" src="@/assets/eyeslash.svg" @click="hideShow('oldpassword')"
                        alt="" class="cursor-pointer inline-block z-50 absolute bg-white right-4" />
                    <img v-else src="@/assets/eye.svg" @click="hideShow('oldpassword')" alt=""
                        class="cursor-pointer inline-block z-50 absolute bg-white right-4" />

                </div>

              

             
            </div>
            <div class="mb-4">
              <label class="block text-sm font-medium text-[#333333]"
                >New Password <span class="text-red-400">*</span></label
              >
              <!-- <input
                type="password"
                id="oldtoNew"
                class="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                placeholder="New Password"
                required
              />
              <img v-if="passwordType == 'password'" src="@/assets/eyeslash.svg" @click="hideShow('oldtoNew')"
                        alt="" class="cursor-pointer inline-block z-50" style="margin-left: -30px" />
                    <img v-else src="@/assets/eye.svg" @click="hideShow('oldtoNew')" alt=""
                        class="cursor-pointer inline-block z-50" style="margin-left: -30px" /> -->

                
                <div class="flex items-center relative"> 
                    <input
                        type="password"
                        id="oldtoNew"
                        class="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                        placeholder="New Password"
                        required
                    />

                    <img v-if="passwordType == 'oldtoNew'" src="@/assets/eyeslash.svg" @click="hideShow('oldtoNew')"
                        alt="" class="cursor-pointer inline-block z-50 absolute bg-white right-4" />
                    <img v-else src="@/assets/eye.svg" @click="hideShow('oldtoNew')" alt=""
                        class="cursor-pointer inline-block z-50 absolute bg-white right-4" />

                </div>

            </div>

            <div class="mb-4">
              <label class="block text-sm font-medium text-[#333333]"
                >New Confirm Password <span class="text-red-400">*</span></label
              >

              <div class="flex items-center relative"> 
                    <input
                        type="password"
                        id="changeconfirm"
                        class="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                        placeholder="New Confirm Password"
                        required
                    />

                    <img v-if="passwordType == 'changeconfirm'" src="@/assets/eyeslash.svg" @click="hideShow('changeconfirm')"
                        alt="" class="cursor-pointer inline-block z-50 absolute bg-white right-4" />
                    <img v-else src="@/assets/eye.svg" @click="hideShow('changeconfirm')" alt=""
                        class="cursor-pointer inline-block z-50 absolute bg-white right-4" />

                </div>

              <!-- <input
                type="password"
                id="changeconfirm"
                class="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                placeholder="New Confirm Password"
                required
              />
              <img v-if="passwordType == 'password'" src="@/assets/eyeslash.svg" @click="hideShow('changeconfirm')"
                        alt="" class="cursor-pointer inline-block z-50" style="margin-left: -30px" />
                    <img v-else src="@/assets/eye.svg" @click="hideShow('changeconfirm')" alt=""
                        class="cursor-pointer inline-block z-50" style="margin-left: -30px" /> -->
            </div>

            <div class="flex justify-center items-center w-full">
              <button
                type="submit"
                class="w-full px-4 py-2 bg-[#0F172A] text-white rounded-md hover:bg-blue-700 transition"
              >
                Change Password
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div
      id="image_uploads"
      class="bg-white rounded-lg shadow-sm w-[350px] outline-none"
    >
      <!-- Your content here -->
    </div>
  </div>
</template>

<script>
import axios from "axios";
import FlashMessage from "@/components/FlashMessage.vue";
import { mapGetters } from "vuex";
import Header from "@/components/Student/Header.vue";
import { faLaugh } from "@fortawesome/free-regular-svg-icons";


export default {
  data() {
    return {
      profileInfo: null,
      popUp: false,
      updateDetails: null,
      selectedFile: null,
      tnxDetails: "",
      profile: false,
      messages: {
        text: "",
        type: "null",
      },
      selectedcontent: "Courses",
      courseDetails: null,
      boughtcourse: null,
      changePassword: false,
      passwordType: "password",
    };
  },

  components: {
    Header,
    FlashMessage,
  },

  computed: {
    ...mapGetters(["userInfo"]),
  },

  async created() {
    await this.openProcess();
    let tempvar_courselist = [];
    await axios
      .get(`${process.env.VUE_APP_API}/courses/?format=json`)
      .then((response) => (this.courseDetails = response.data.data));

    this.courseDetails = this.courseDetails.filter((course) => {
      if (tempvar_courselist.includes(course.id)) {
        return course;
      }
    });
  },

  methods: {
    hideShow(id) {
      const passwordField = document.querySelector(`#${id}`);
      passwordField.getAttribute("type") === "password"
        ? passwordField.setAttribute("type", "text")
        : passwordField.setAttribute("type", "password");
      this.passwordType = id;
    },

    ChangePasswordPopup() {
      this.changePassword = true;
    },

    async changepassword() {
      let oldpassWord = document.querySelector("#oldpassword").value;
      let newpassword = document.querySelector("#oldtoNew").value;
      let confirmpassword = document.querySelector("#changeconfirm").value;
      if (newpassword == confirmpassword) {
        var responseMail;
        let loginHeader = {
          username: this.userInfo.user.email,
          password: oldpassWord,
        };
        // OldPassword Validations

        await axios
          .post(`${process.env.VUE_APP_API}/login`, loginHeader)
          .then((response) => {
            if (response.data.user) {
              responseMail = response.data.user.email;
            } else {
              responseMail = "error";
            }
          });

        if (responseMail == this.userInfo.user.email) {
          const headerVal = {
            old_password: document.querySelector("#oldpassword").value,
            new_password: document.querySelector("#changeconfirm").value,
          };
          await axios
            .put(
              `${process.env.VUE_APP_API}/api/change-password/?Key=${this.userInfo.token_key}`,
              headerVal
            )
            .then((response) => {
              if (response.data.message == "Password updated successfully") {
                this.messages = {
                  text: "Password updated successfully",
                  type: "success",
                };
              } else {
                this.messages = {
                  text: " Event Faild",
                  type: "error",
                };
              }
            })
            .catch((error) => {
              console.log(error);
            });
          document.querySelector("#oldpassword").value = "";
          document.querySelector("#oldtoNew").value = "";
          document.querySelector("#changeconfirm").value = "";

          this.changePassword = false;

          setTimeout(() => {
            this.messages = {
              text: " ",
              type: "",
            };
            // this.$router.push({
            //     name: 'Home'
            // })
          }, 3000);
        } else {
          this.errorMessage = {
            text: " Old Password Not Matching",
            type: "error",
          };

          setTimeout(() => {
            this.errorMessage = {
              text: " ",
              type: "",
            };
          }, 1500);
        }
      } else {
        console.log("error");
        this.errorMessage = {
          text: " Confirm Password Miss Match",
          type: "error",
        };
        setTimeout(() => {
          this.errorMessage = {
            text: " ",
            type: "",
          };
        }, 1500);
      }
    },

    async imageHandles() {
      this.profile = false;
      let headerprofile = new FormData();
      headerprofile.append("id", this.userInfo.user.id);
      headerprofile.append(
        "profile",
        document.querySelector("#image_uploads").files[0]
      );

      await axios
        .put(
          `${process.env.VUE_APP_API}/upload_photo/?Key=${this.userInfo.token_key}`,
          headerprofile
        )
        .then((response) => {
          if (response.data.message == "profile Data Updated successfully") {
            this.messages = {
              text: "Profile Uploaded successfully",
              type: "success",
            };
          } else {
            this.messages = {
              text: " Event Faild",
              type: "error",
            };
          }
        })
        .catch((error) => {
          this.messages = {
            text: error.response.data.error,
            type: "error",
          };
        });

      setTimeout(() => {
        this.messages = {
          text: "",
          type: "null",
        };
        this.openProcess();
      }, 3000);
    },

    onFileChanged(event) {
      this.selectedFile = event.target.files[0];
    },

    async onUpload() {
      // upload file
      await axios.post("my-domain.com/file-upload", this.selectedFile);
    },

    async openProcess() {
      axios
        .get(
          `${process.env.VUE_APP_API}/paymentlist/?Key=${this.userInfo.token_key}`
        )
        .then((res) => {
          console.log(res);
          this.tnxDetails = res.data;
        });
      let token = this.userInfo.token_key;
      let slug_id = this.userInfo.slug_id;

      await axios
        .get(
          `${process.env.VUE_APP_API}/profile_detail/${slug_id}?Key=${token}&format=json`
        )
        .then((response) => (this.profileInfo = response.data));
    },

    async ProfileEdit() {
      let details_id = this.profileInfo.data.id;
      let token = this.userInfo.token_key;
      this.popUp = true;

      await axios
        .get(
          `${process.env.VUE_APP_API}/profile_update/${details_id}?Key=${token}&format=json`
        )
        .then((response) => (this.updateDetails = response.data));
    },

    async SubmitProfile() {
      let details_id = this.profileInfo.data.id;
      let token = this.userInfo.token_key;
      let update = this.updateDetails;
      this.popUp = false;
      // console.log("updateDetails",updateDetails)

      await axios
        .put(
          `${process.env.VUE_APP_API}/profile_update/${details_id}/?Key=${token}&format=json`,
          update
        )
        .then((response) => {
          if (response.data.message == "Profile updated successfully") {
            this.messages = {
              text: "Profile Uploaded successfully",
              type: "success",
            };
          } else {
            this.messages = {
              text: " Event Faild",
              type: "error",
            };
          }
        });

      setTimeout(() => {
        this.messages = {
          text: "",
          type: "null",
        };
        this.openProcess();
      }, 3000);
    },
  },
};
</script>

<style scoped>
#image_uploads {
  background-color: white;
  border-radius: 5px;
  box-shadow: 2px 2px 4px black;
  width: 350px;
  outline: none;
}
</style>
