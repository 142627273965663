<template>
  <div>
    <!-- <Header /> -->

    <FlashMessage v-if="this.messages.text != ''" :message="this.messages" />

    <div class="flex">
      <SideBar />

      <div
        class="w-[90%] mx-auto lg:w-full lg:mr-10 mt-10 rounded-3xl overflow-y-auto heightfix"
        style="box-shadow: 0px 2px 30px 0px #01b0f926"
      >
        <div class="flex flex-col h-full" v-if="this.is_selected == 'teams'">
          <div class="flex items-center justify-between px-5 lg:px-10 py-10">
            <div>
              <h1 class="text-lg font-semibold">Projects & Teams</h1>
            </div>
            <div>
              <button
                @click="this.addQuiz = true"
                class="bg-blues text-white font-semibold text-xs px-4 py-2 rounded-xl"
              >
                Add Team
              </button>
            </div>
          </div>

          <div class="grid grid-cols-1 lg:grid-cols-3">
            <div
              v-for="(team, index) in this.teams"
              :key="team"
              class="px-5 lg:px-10 pb-10"
            >
              <div
                class="px-6 py-6 flex flex-col justify-between gap-10 w-full border rounded-2xl cursor-pointer bg-gray-100"
                @click="setTab(index, 'project')"
              >
                <div class="flex flex-col gap-2">
                  <!-- {{ team }} -->
                  <h1 class="font-bold text-xl">{{ team.title }}</h1>
                  <p class="text-base">
                    Total Team Members: {{ team.teammembers.length }}
                  </p>
                  <!-- <p>{{ team }}</p> -->
                </div>

                <div>
                  <button
                    class="bg-blues text-white px-4 py-2 rounded-xl text-xs font-semibold"
                    @click="setTab(index, 'project')"
                  >
                    View Team
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="flex flex-col" v-if="this.is_selected == 'project'">
          <div class="px-5 lg:px-8 mt-10 pb-2 flex items-center justify-between">
            <button
              class="bg-blues text-xs font-bold text-white px-5 py-1.5 rounded-full"
              @click="this.is_selected = 'teams'"
            >
              Back
            </button>

            <div
              v-if="
                this.userInfo.user.id == SelectedData.teamleader.id ||
                this.userInfo.user.id == SelectedData.staff.id
              "
            >
              <!-- {{ this.userInfo.user.id }} {{ SelectedData.teamleader.id }} {{  SelectedData.staff.id }} -->
              <button
                v-if="this.projectsList.length < 1"
                @click="this.project = true"
                class="bg-blues text-white font-semibold text-xs px-4 py-2 rounded-xl"
              >
                Add Project
              </button>
            </div>
          </div>

          <div class="px-5 lg:px-10 pt-5">
            <h1 class="text-xl font-bold">Projects</h1>
          </div>

          <div class="pt-5 grid lg:grid-cols-3">
            <div
              v-for="(project, index) in this.projectsList"
              :key="project"
              class="px-5 lg:px-10 pb-10"
            >
              <div
                class="p-5 lg:p-10 flex flex-col justify-between gap-10 w-full border rounded-2xl cursor-pointer bg-gray-100"
              >
                <div class="flex flex-col gap-2">
                  <!-- {{ project }} -->
                  <h1 class="font-bold text-xl">{{ project.title }}</h1>
                  <!-- <p class="text-base"> <span class="font-semibold">Description: </span> {{ project.title }}</p> -->
                  <p class="text-base">
                    <span class="font-semibold">Marks: </span>
                    {{ project.mark }}
                  </p>
                  <!-- <p>{{ team }}</p> -->
                </div>

                <div class="flex items-center gap-4">
                  <button
                    class="bg-blues text-white px-4 py-2 rounded-xl text-xs font-semibold"
                    @click="setTabNotes(index, 'notes')"
                  >
                    View Project
                  </button>

                  <button
                    v-if="
                      this.userInfo.user.id == SelectedData.teamleader.id ||
                      this.userInfo.user.id == SelectedData.staff.id
                    "
                    class="bg-blues text-white px-4 py-2 rounded-xl text-xs font-semibold"
                    @click="selectEditTab(index)"
                  >
                    Edit
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div class="px-5 lg:px-10 pt-5">
            <h1 class="text-xl font-bold">Team Leader</h1>
          </div>

          <div class="pt-6 px-5 lg:px-10 grid grid-cols-1">
            <div
              class="px-5 py-5 flex flex-col md:flex-row items-center justify-between gap-10 w-full border rounded-2xl cursor-pointer bg-gray-100"
            >
              <div class="flex flex-col gap-2">
                <h1 class="font-semibold text-base">
                  {{ this.SelectedData.teamleader.display_name }}
                </h1>
                <p class="text-xs">{{ this.SelectedData.teamleader.email }}</p>
              </div>

              <!-- <div v-if="this.projectsList.length != 0">  
                <button 
                    class="bg-blues text-white px-4 py-2 rounded-xl text-xs font-semibold"
                    @click="addMarkTab(this.SelectedData.teamleader.id)"
                  >
                    Add Mark
                  </button>
              </div> -->

                <div v-if="this.projectsList.length != 0" class="flex items-center gap-4"> 
                    
                    <button v-if="this.SelectedData.teamleader.mark == null"
                      class="bg-blues text-white px-4 py-2 rounded-xl text-xs font-semibold"
                      @click="addMarkTab(this.SelectedData.teamleader.id)"
                    >
                      Add Mark
                    </button>
                    <button v-if="this.SelectedData.teamleader.mark != null"
                      class="bg-greens text-white px-4 py-2 rounded-xl text-xs font-semibold"
                    >
                      Marks: {{ this.SelectedData.teamleader.mark.mark }}
                    </button>

                    <!-- <button v-if="this.SelectedData.teamleader.mark != null"
                      class="bg-blues text-white px-4 py-2 rounded-xl text-xs font-semibold"
                      @click="addMarkTab(this.SelectedData.teamleader.mark.mark.id)"
                    >
                      Edit Mark
                    </button> -->
                </div>

            </div>
          </div>

          <!-- {{ this.SelectedData.teamleader }} -->

          <div class=" px-5 lg:px-10 pt-10">
            <h1 class="text-xl font-bold">
              Members ({{ this.SelectedData.teammembers.length }})
            </h1>
          </div>

   

          <div class="pt-6 grid grid-cols-1">
            <div
              v-for="member in this.SelectedData.teammembers"
              :key="member"
              class="px-5 lg:px-10 pb-5"
            >
              <div
                class="px-5 py-5 flex flex-col md:flex-row items-center justify-between gap-10 w-full border rounded-2xl cursor-pointer bg-gray-100"
              >
                <div class="flex flex-col gap-2">
                  <h1 class="font-semibold text-base">
                    {{ member.display_name }}
                  </h1>
                  <p class="text-xs">{{ member.email }}</p>
                </div>


               
                <div v-if="this.projectsList.length != 0" class="flex items-center gap-4"> 
                  <button v-if="member.mark == null"
                      class="bg-blues text-white px-4 py-2 rounded-xl text-xs font-semibold"
                      @click="addMarkTab(member.id)"
                    >
                      Add Mark
                    </button>

                    <button v-if="member.mark != null"
                      class="bg-greens text-white px-4 py-2 rounded-xl text-xs font-semibold"
                    >
                      Marks: {{ member.mark.mark }}
                    </button>

                    <button v-if="member.mark != null"
                      class="bg-blues text-white px-4 py-2 rounded-xl text-xs font-semibold"
                      @click="editMarkPopup(member.mark.id, member.mark.mark)"
                    >
                      Edit Mark
                    </button>

                </div>

              </div>
            </div>
          </div>
        </div>

        <div class="flex flex-col" v-if="this.is_selected == 'notes'">
          <div class="px-5 lg:px-8 mt-10 pb-2 flex items-center justify-between">
            <button
              class="bg-blues text-xs font-bold text-white px-5 py-1.5 rounded-full"
              @click="this.is_selected = 'project'"
            >
              Back
            </button>

            <!-- <div>
              <button
                @click="this.createProjectNotesPopup = true"
                class="bg-blues text-white font-semibold text-xs px-4 py-2 rounded-xl"
              >
                Add Project Note
              </button>
            </div> -->
          </div>

          <div class="flex flex-col gap-4 px-10 mt-5">
            <h1 class="text-3xl font-bold">{{ selectedProjectData.title }}</h1>

            <div v-html="selectedProjectData.description"></div>
          </div>

          <!-- {{ projectsNotesList }}  -->

          <div class="px-5 lg:px-10 flex flex-col gap-5 pt-10">
            <div class="flex items-center justify-between">
              <h1 class="text-lg font-bold">
                Project Notes ({{ projectsNotesList.length }})
              </h1>
              <div>
                <button
                  @click="this.createProjectNotesPopup = true"
                  class="bg-blues text-white font-semibold text-xs px-4 py-2 rounded-xl"
                >
                  Add Project Note
                </button>
              </div>
            </div>

            <div>
              <div
                v-for="projectsNote in this.projectsNotesList"
                :key="projectsNote"
                class="pb-5"
              >
                <div
                  class="px-6 py-3 flex items-center gap-10 w-full border rounded-2xl cursor-pointer bg-gray-100"
                >
                  <div class="flex flex-col gap-2">
                    <h1 class="font-semibold text-base">
                      {{ projectsNote.user.email }}
                    </h1>
                    <!-- <p class="text-sm" >{{ projectsNote.note }}</p> -->
                    <div v-html="projectsNote.note"></div>
                    <!-- <p class="text-base"> <span class="font-semibold">Description: </span> {{ project.title }}</p>
                    <p class="text-base"> <span class="font-semibold">Marks: </span> {{ project.mark }}</p> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="this.addQuiz"
        @click.self="this.addQuiz = false"
        class="fixed bg-primary bg-opacity-30 top-0 left-0 right-0 bottom-0 z-50 flex items-center justify-center"
      >
        <div
          class="lg:w-[40%] bg-white rounded-xl p-10 overflow-y-auto max-h-[600px]"
        >
          <div class="flex items-center justify-center">
            <h3 class="text-xl font-bold text-blues text-center">Add Team</h3>
            <!-- <img
                src="@/assets/newd/close.png"
                class="cursor-pointer"
                alt=""
                @click="this.assignmentPopup = false"
              /> -->
          </div>

          <div
            class="w-full mt-4 flex flex-col p-6 relative border-gray-100 rounded-2xl"
          >
            <div class="w-full">
              <form
                ref="createTeamRef"
                @submit.prevent="createTeam"
                class="flex flex-col gap-4 text-xs md:text-sm lg:text-base h-full"
              >

                <div class="w-full flex flex-col gap-2">
                  <label for="team_title" class="text-left">Title</label>
                  <input
                    required
                    id="team_title"
                    type="text"
                    class="border border-gray-100 rounded text-xs md:text-sm lg:text-base py-2 px-2"
                  />
                </div>

                <!-- <div class="flex flex-col gap-3 w-full">
                  <label for="attachments" class="text-left"
                    >Attachments *</label
                  >
                  <input
                    id="source"
                    type="file"
                    class="bg-gray-100 py-2 px-2 w-full rounded text-xs md:text-sm lg:text-base"
                    @change="inputchange"
                    required
                  />
                </div> -->

                <select id="teamleader" class="py-3 px-5 rounded-xl" required>
                  <option selected disabled>Select teamleader</option>
                  <option
                    v-for="el in this.studentList.data"
                    :key="el"
                    :value="el.id"
                  >
                    {{ el.display_name }}
                  </option>
                </select>

                <!-- <select id="staff" class="py-3 px-5 rounded-xl" required>
                  <option selected disabled>Select Staff</option>
                  <option
                    v-for="el in this.studentList.data"
                    :key="el"
                    :value="el.id"
                  >
                    {{ el.display_name }}
                  </option>
                </select> -->

                <p>Select Team Members</p>

                <div
                  class="h-[150px] rounded-xl overflow-y-auto bg-gray-100 px-4 py-4"
                >
                  <div
                    v-for="el in this.studentList.data"
                    :key="el"
                    class="py-1"
                  >
                    <label>
                      <input
                        type="checkbox"
                        v-model="teamMembers"
                        :value="el.id"
                      />
                      {{ el.display_name }}
                    </label>
                  </div>
                </div>

                <!-- 
                <select v-model="teamMembers" class="py-2 px-5 rounded-xl"> 
                    <option selected disabled>Select Staff</option>
                    <option v-for="el in this.studentList.data" :key="el" :value="el.id">{{ el.display_name }}</option>
                </select> -->

                <div class="flex justify-center pb-4 mt-3 gap-10">
                  <button
                    @click="this.addQuiz = false"
                    class="border text-gray-500 rounded-lg text-sm px-5 lg:px-10 font-semibold py-2"
                  >
                    Close
                  </button>
                  <button
                    type="submit"
                    class="text-white bg-blues rounded-lg text-sm px-5  lg:px-10 font-semibold py-2"
                  >
                    Add
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="this.project"
        @click.self="this.project = false"
        class="fixed bg-primary bg-opacity-30 top-0 left-0 right-0 bottom-0 z-50 flex items-center justify-center"
      >
        <div
          class="w-[90%] lg:w-[50%] bg-white rounded-xl p-6 lg:p-10 overflow-y-auto max-h-[600px]"
        >
          <div class="flex items-center justify-center">
            <h3 class="text-xl font-bold text-blues text-center">
              Add Project
            </h3>

            <!-- <img
                src="@/assets/newd/close.png"
                class="cursor-pointer"
                alt=""
                @click="this.assignmentPopup = false"
              /> -->
          </div>
          <div
            class="w-full mt-4 flex flex-col p-6 relative border-gray-100 rounded-2xl"
          >
            <div class="w-full">
              <form
                ref="createProjectRef"
                @submit.prevent="createProject"
                class="flex flex-col gap-4 text-xs md:text-sm lg:text-base h-full"
              >
                <div class="w-full flex flex-col gap-2">
                  <label for="title" class="text-left">Title</label>
                  <input
                    required
                    id="title"
                    type="text"
                    class="border border-gray-100 rounded text-xs md:text-sm lg:text-base py-2 px-2"
                  />
                </div>

                <!-- <div class="w-full flex flex-col gap-2">
                  <label for="desc" class="text-left">Description</label>
                  <textarea
                    cols="5"
                  required
                    id="desc"
                    type="text"
                    class="border border-gray-100 rounded text-xs md:text-sm lg:text-base py-2 px-2">
                  </textarea>
                </div> -->

                <QuillEditor
                  style="height: 300px"
                  theme="snow"
                  class=""
                  toolbar="full"
                  :content="description"
                  contentType="html"
                />

                <!-- <div class="w-full flex flex-col gap-2">
                  <label for="marks" class="text-left">Marks</label>
                  <input
                    required
                    id="marks"
                    type="number"
                    class="border border-gray-100 rounded text-xs md:text-sm lg:text-base py-2 px-2"
                  />
                </div> -->

                <div class="flex justify-center pb-4 mt-3 gap-10">
                  <button
                    @click="this.project = false"
                    class="border text-gray-500 rounded-lg text-sm px-5 lg:px-10 font-semibold py-2"
                  >
                    Close
                  </button>
                  <button
                    type="submit"
                    class="text-white bg-blues rounded-lg text-sm px-5 lg:px-10 font-semibold py-2"
                  >
                    Add Project
                  </button>
                </div>
              </form>
            </div>
          </div>

          <!-- <div> 
            {{ projectsList }} 
          </div> -->
        </div>
      </div>

      <div
        v-if="this.projectEdit"
        @click.self="this.projectEdit = false"
        class="fixed bg-primary bg-opacity-30 top-0 left-0 right-0 bottom-0 z-50 flex items-center justify-center"
      >
        <div
          class=" w-[90%] lg:w-[50%] bg-white rounded-xl p-10 overflow-y-auto max-h-[600px]"
        >
          <div class="flex items-center justify-center">
            <h3 class="text-xl font-bold text-blues text-center">
              Update Project
            </h3>

            <!-- <img
                src="@/assets/newd/close.png"
                class="cursor-pointer"
                alt=""
                @click="this.assignmentPopup = false"
              /> -->
          </div>
          <div
            class="w-full mt-4 flex flex-col p-0 lg:p-6 relative border-gray-100 rounded-2xl"
          >
            <div class="w-full">
              <form
                ref="createProjectRef"
                @submit.prevent="editProject"
                class="flex flex-col gap-4 text-xs md:text-sm lg:text-base h-full"
              >
                <div class="w-full flex flex-col gap-2">
                  <label for="title" class="text-left">Title</label>
                  <input
                    required
                    id="titleEdit"
                    type="text"
                    class="border border-gray-100 rounded text-xs md:text-sm lg:text-base py-2 px-2"
                  />
                </div>

                <!-- <div class="w-full flex flex-col gap-2">
                  <label for="desc" class="text-left">Description</label>
                  <textarea
                    cols="5"
                  required
                    id="desc"
                    type="text"
                    class="border border-gray-100 rounded text-xs md:text-sm lg:text-base py-2 px-2">
                  </textarea>
                </div> -->

                <QuillEditor
                  style="height: 300px"
                  theme="snow"
                  class=""
                  toolbar="full"
                  :content="descriptionProjectEdit"
                  contentType="html"
                />

                <!-- <div class="w-full flex flex-col gap-2">
                  <label for="marks" class="text-left">Marks</label>
                  <input
                    required
                    id="marksEdit"
                    type="number"
                    class="border border-gray-100 rounded text-xs md:text-sm lg:text-base py-2 px-2"
                  />
                </div> -->

                <div class="flex justify-center pb-4 mt-3 gap-10">
                  <button
                    @click="this.projectEdit = false"
                    class="border text-gray-500 rounded-lg text-sm px-5 lg:px-10 font-semibold py-2"
                  >
                    Close
                  </button>
                  <button
                    type="submit"
                    class="text-white bg-blues rounded-lg text-sm px-5 lg:px-10 font-semibold py-2"
                  >
                   Update
                  </button>
                </div>
              </form>
            </div>
          </div>

          <!-- <div> 
            {{ projectsList }} 
          </div> -->
        </div>
      </div>


      <div
        v-if="this.createProjectNotesPopup"
        @click.self="this.createProjectNotesPopup = false"
        class="fixed bg-primary bg-opacity-30 top-0 left-0 right-0 bottom-0 z-50 flex items-center justify-center"
      >
        <div
          class=" w-[90%] lg:w-[40%] bg-white rounded-xl p-6 lg:p-10 overflow-y-auto max-h-[600px]"
        >
          <div class="flex items-center justify-center">
            <h3 class="text-xl font-bold text-blues text-center">
              Add Project Note
            </h3>

            <!-- <img
                src="@/assets/newd/close.png"
                class="cursor-pointer"
                alt=""
                @click="this.assignmentPopup = false"
              /> -->
          </div>
          <div
            class="w-full mt-4 flex flex-col p-6 relative border-gray-100 rounded-2xl"
          >
            <div class="w-full">
              <form
                ref="createProjectNoteRef"
                @submit.prevent="createProjectNotes"
                class="flex flex-col gap-4 text-xs md:text-sm lg:text-base h-full"
              >
                <div class="w-full flex flex-col gap-2">
                  <label for="desc" class="text-left">Note</label>
                  <!-- <textarea
                    cols="5"
                  required
                    id="note"
                    type="text"
                    class="border border-gray-100 rounded text-xs md:text-sm lg:text-base py-2 px-2">
                  </textarea> -->

                  <QuillEditor
                    style="height: 300px"
                    theme="snow"
                    class=""
                    toolbar="full"
                    :content="note_content"
                    contentType="html"
                  />
                </div>

                <div class="flex justify-center pb-4 mt-3 gap-10">
                  <button
                    @click="this.createProjectNotesPopup = false"
                    class="border text-gray-500 rounded-lg text-sm px-5 lg:px-10 font-semibold py-2"
                  >
                    Close
                  </button>
                  <button
                    type="submit"
                    class="text-white bg-blues rounded-lg text-sm px-5 lg:px-10 font-semibold py-2"
                  >
                    Add Project Note
                  </button>
                </div>
              </form>
            </div>
          </div>

          <!-- <div> 
            {{ projectsList }} 
          </div> -->
        </div>
      </div>

      <div
        v-if="this.addMarkPopup"
        @click.self="this.addMarkPopup = null"
        class="fixed bg-primary bg-opacity-30 top-0 left-0 right-0 bottom-0 z-50 flex items-center justify-center"
      >
        <div
          class="w-[90%] lg:w-[40%] bg-white rounded-xl p-6 lg:p-10 overflow-y-auto max-h-[600px]"
        >
          <div class="flex items-center justify-center">
            <h3 class="text-xl font-bold text-blues text-center">
                Add Mark
            </h3>
            <!-- <img
                src="@/assets/newd/close.png"
                class="cursor-pointer"
                alt=""
                @click="this.assignmentPopup = false"
              /> -->
          </div>
          <div
            class="w-full mt-4 flex flex-col p-6 relative border-gray-100 rounded-2xl"
          >
            <div class="w-full">
              <form
                ref="studentMarkRef"
                @submit.prevent="addMark"
                class="flex flex-col gap-4 text-xs md:text-sm lg:text-base h-full"
              >

                <div class="w-full flex flex-col gap-2">
                  <label for="mark" class="text-left">Mark</label>
                  <input
                    required
                    id="markStudent"
                    type="number"
                    class="border border-gray-100 rounded text-xs md:text-sm lg:text-base py-2 px-2"
                  />
                </div>

                <div class="flex justify-center pb-4 mt-3 gap-10">
                  <button
                    @click="this.addMarkPopup = null"
                    class="border text-gray-500 rounded-lg text-sm px-5 lg:px-10 font-semibold py-2"
                  >
                    Close
                  </button>
                  <button
                    type="submit"
                    class="text-white bg-blues rounded-lg text-sm px-5 lg:px-10 font-semibold py-2"
                  >
                   Add Mark
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>


      <div
        v-if="this.editMarkPopupBool"
        @click.self="this.editMarkPopupBool = false"
        class="fixed bg-primary bg-opacity-30 top-0 left-0 right-0 bottom-0 z-50 flex items-center justify-center"
      >
        <div
          class="w-[90%] lg:w-[40%] bg-white rounded-xl p-6 lg:p-10 overflow-y-auto max-h-[600px]"
        >
          <div class="flex items-center justify-center">
            <h3 class="text-xl font-bold text-blues text-center">
                Edit Mark
            </h3>
            <!-- <img
                src="@/assets/newd/close.png"
                class="cursor-pointer"
                alt=""
                @click="this.assignmentPopup = false"
              /> -->
          </div>
          <div
            class="w-full mt-4 flex flex-col p-6 relative border-gray-100 rounded-2xl"
          >
            <div class="w-full">
              <form
                ref="studentEditMarkRef"
                @submit.prevent="editMark"
                class="flex flex-col gap-4 text-xs md:text-sm lg:text-base h-full"
              >

                <div class="w-full flex flex-col gap-2">
                  <label for="mark" class="text-left">Mark</label>
                  <input
                    required
                    id="markEditStudent"
                    type="number"
                    class="border border-gray-100 rounded text-xs md:text-sm lg:text-base py-2 px-2"
                  />
                </div>

                <div class="flex justify-center pb-4 mt-3 gap-10">
                  <button
                    @click="this.editMarkPopupBool = false"
                    class="border text-gray-500 rounded-lg text-sm  px-5 lg:px-10 font-semibold py-2"
                  >
                    Close
                  </button>
                  <button
                    type="submit"
                    class="text-white bg-blues rounded-lg text-sm px-5 lg:px-10 font-semibold py-2"
                  >
                   Edit Mark
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import Header from "@/components/Instructor/Header.vue";
import SideBar from "@/components/Instructor/SideBar.vue";
import ConfirmPopup from "@/components/ConfirmPopup.vue";
import FlashMessage from "@/components/FlashMessage.vue";

import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";

import axios from "axios";
import { mapGetters } from "vuex";

export default {
  components: {
    Header,
    SideBar,
    ConfirmPopup,
    FlashMessage,
    QuillEditor,
  },
  data() {
    return {
      teamMembers: [],
      project: false,
      addQuiz: false,
      loading: false,
      studentList: "",
      messages: {
        text: "",
        type: "null",
      },
      teams: null,
      is_selected: "teams",
      SelectedData: "",
      projectsList: "",
      selectedProjectData: "",
      projectsNotesList: "",
      createProjectNotesPopup: false,
      description: "",
      note_content: "",
      projectEdit: false,
      descriptionProjectEdit: "",
      addMarkPopup: null,
      storeIndex: null,
      editMarkId: null,
      editMarkPopupBool: false
    };
  },

  created() {
    this.getTeams();
    this.getstudentdetails();
  },

  props: ["course_id"],

  computed: {
    ...mapGetters(["userInfo"]),
  },

  mounted() {},

  methods: {

    addMarkTab(id){
        this.addMarkPopup = id;
    },

    editMarkPopup(id, value){
      // console.log("check id::", id);
      this.editMarkId = id;
      this.editMarkPopupBool = true;
      setTimeout(()=>{
        document.getElementById("markEditStudent").value = value;
      }, 50)
    },

    async addMark(){  
      await axios
        .post(
          `${process.env.VUE_APP_API}/assign/marks/?Key=${this.userInfo.token_key}`,
          {
            "mark": document.getElementById("markStudent").value,
            "project": this.projectsList[0].id,
            "member": this.addMarkPopup
          }
        )
        .then((response) => {
          if (response.data.status == 200) {
            this.messages = {
              text: response.data.message,
              type: "success",
            };

            const updatedMark = document.getElementById("markStudent").value;
            const memberId = this.addMarkPopup;
            const member = this.SelectedData.teammembers.find((m) => m.id === memberId);

            if(member){
              member.mark = {
                mark: updatedMark
              }
            }

          

            setTimeout(() => {
              this.messages = {
                text: "",
                type: "null",
              };
          
              this.$refs.studentMarkRef.reset();
              this.addMarkPopup = null;
              this.GetProjects();
             

              this.getTeams();
              this.SelectedData = ""
              this.setTab(this.storeIndex, 'project');

          
            }, 1500);
          }
        });
    },

    async editMark(){

      console.log(this.editMarkId);

      await axios
        .patch(
          `${process.env.VUE_APP_API}/mark/${this.editMarkId}/update/?Key=${this.userInfo.token_key}`,
          {
            "mark": document.getElementById("markEditStudent").value,
          }
        )
        .then((response) => {
          if (response.data.mark) {
            this.messages = {
              text: "Mark Edit Successfully",
              type: "success",
            };

            const updatedMark = document.getElementById("markEditStudent").value;
            const editMarkIds = this.editMarkId;

            const ignoreNull = this.SelectedData.teammembers.filter((m) => m.mark !== null);
            const mark = ignoreNull.find((m) => m.mark.id === editMarkIds);


            if(mark){
               
              mark.mark = {
                id: this.editMarkId,
                mark: updatedMark
              }
              console.log(mark);
            
            }
            setTimeout(() => {
              this.messages = {
                text: "",
                type: "null",
              };
              // this.$refs.studentEditMarkRef.reset();
              this.editMarkPopupBool = false;
              // window.location
              // this.editMarkId = null;
              // this.getTeams();
              // this.is_selected = 'teams';
            }, 1500);

          }
        });
    },

    selectEditTab(index){
        this.projectEdit = true;
        this.selectedProjectData = "";
        this.selectedProjectData = this.projectsList[index];

        setTimeout(()=>{
          this.descriptionProjectEdit = this.selectedProjectData.description;
          document.getElementById("titleEdit").value = this.selectedProjectData.title;
          // document.getElementById("marksEdit").value = this.selectedProjectData.mark;
        }, 50)
    },

    setTab(index, data) {
      this.storeIndex = index;
      this.projectsList = [];
      this.SelectedData = "";
      // this.SelectedData = { ...this.teams[index] };
      this.SelectedData = this.teams[index];
      this.is_selected = data;
      this.GetProjectsById(this.SelectedData.id);
    },

    setTabNotes(index, data) {
      this.projectsNotesList = [];
      this.selectedProjectData = "";
      this.selectedProjectData = this.projectsList[index];
      this.is_selected = data;
      this.getProjectsNotesById(this.selectedProjectData.id);
      // this.GetProjects()
    },

    async getTeams() {
      // this.teams = [];
      await axios
        .get(
          `${process.env.VUE_APP_API}/teams/${this.course_id}/?Key=${this.userInfo.token_key}`
        )
        .then((response) => {
          if (response.data.status == 200) {
            this.teams = response.data.data;
          } else {
            console.log("got error");
          }
        });
    },

    async getstudentdetails() {
      await axios
        .get(
          `${process.env.VUE_APP_API}/all_list/${this.course_id}/?Key=${this.userInfo.token_key}`
        )
        .then((response) => {
          console.log(response);
          this.studentList = response.data;
          this.loading = false;
        });
    },

    async createTeam() {
      await axios
        .post(
          `${process.env.VUE_APP_API}/teams/create/?Key=${this.userInfo.token_key}`,
          { 
            title: document.getElementById("team_title").value,
            course: this.course_id,
            teamleader: document.getElementById("teamleader").value,
            staff: this.userInfo.Instructor_id,
            teammembers: this.teamMembers,
          }
        )
        .then((response) => {
          if (response.data.status == 200) {
            this.messages = {
              text: response.data.message,
              type: "success",
            };
            setTimeout(() => {
              this.messages = {
                text: "",
                type: "null",
              };
              this.teamMembers = [];
              this.$refs.createTeamRef.reset();
              this.addQuiz = false;
              this.getTeams();
            }, 1500);
          }
        });
    },

    async createProject() {
      this.description = document.querySelector(".ql-editor").innerHTML;
      console.log(this.description);

      await axios
        .post(
          `${process.env.VUE_APP_API}/projects/create/?Key=${this.userInfo.token_key}`,
          {
            title: document.getElementById("title").value,
            description: this.description,
            mark: 0,
            team: this.SelectedData.id,
          }
        )
        .then((response) => {
          if (response.data.status == 200) {
            this.messages = {
              text: response.data.message,
              type: "success",
            };
            setTimeout(() => {
              this.messages = {
                text: "",
                type: "null",
              };
              // this.teamMembers = [];
              this.$refs.createProjectRef.reset();
              this.project = false;
              this.description = "";
              this.GetProjects();
              // this.getTeams();
            }, 1500);
          }
        });
    },

    async editProject() {
      this.descriptionProjectEdit = document.querySelector(".ql-editor").innerHTML;
      console.log(this.descriptionProjectEdit);

      await axios
        .put(
          `${process.env.VUE_APP_API}/projects/${this.selectedProjectData.id}/update/?Key=${this.userInfo.token_key}`,
          {
            title: document.getElementById("titleEdit").value,
            description: this.descriptionProjectEdit,
            mark: 0,
            team: this.selectedProjectData.team.id,
          }
        )
        .then((response) => {
          if (response.data.status == 200) {
            this.messages = {
              text: response.data.message,
              type: "success",
            };
            setTimeout(() => {
              this.messages = {
                text: "",
                type: "null",
              };
              // this.teamMembers = [];
              // this.$refs.createProjectRef.reset();
              this.projectEdit = false;
              this.descriptionProjectEdit = "";
              this.GetProjects();
              // this.getTeams();
            }, 1500);
          }
        });
    },

    async GetProjectsById(id) {
      await axios
        .get(
          `${process.env.VUE_APP_API}/projects/${id}/?Key=${this.userInfo.token_key}`
        )
        .then((response) => {
          if (response.data.status == 200) {
            this.projectsList = response.data.data;
          } else {
            console.log("got in something wrong");
          }
        });
    },

    async GetProjects() {
      await axios
        .get(
          `${process.env.VUE_APP_API}/projects/${this.SelectedData.id}/?Key=${this.userInfo.token_key}`
        )
        .then((response) => {
          if (response.data.status == 200) {
            this.projectsList = response.data.data;
          } else {
            console.log("got in something wrong");
          }
        });
    },

    async getProjectsNotesById(id) {
      await axios
        .get(
          `${process.env.VUE_APP_API}/project-notes/${id}/?Key=${this.userInfo.token_key}`
        )
        .then((response) => {
          if (response.data.status == 200) {
            this.projectsNotesList = response.data.data;
          } else {
            console.log("got in something wrong");
          }
        });
    },

    async getProjectsNotes() {
      await axios
        .get(
          `${process.env.VUE_APP_API}/project-notes/${this.selectedProjectData.id}/?Key=${this.userInfo.token_key}`
        )
        .then((response) => {
          if (response.data.status == 200) {
            this.projectsNotesList = response.data.data;
          } else {
            console.log("got in something wrong");
          }
        });
    },

    async createProjectNotes() {
      // this.note_content = this.note_content;

      this.note_content = document.querySelector(".ql-editor").innerHTML;
      console.log(this.note_content);

      await axios
        .post(
          `${process.env.VUE_APP_API}/project-notes/create/?Key=${this.userInfo.token_key}`,
          {
            note: this.note_content,
            project: this.selectedProjectData.id,
            user: this.userInfo.user.id,
          }
        )
        .then((response) => {
          if (response.data.status == 200) {
            this.messages = {
              text: response.data.message,
              type: "success",
            };
            setTimeout(() => {
              this.messages = {
                text: "",
                type: "null",
              };
              // this.teamMembers = [];
              this.$refs.createProjectNoteRef.reset();
              this.createProjectNotesPopup = false;
              this.note_content = "";
              this.getProjectsNotes();
              // this.getTeams();
            }, 1500);
          }
        });
    },
  },
};
</script>

<style scoped>
.heightfix {
  height: calc(100vh - 170px) !important;
}
</style>
