import { createRouter, createWebHistory } from "vue-router";
import Login from "../views/Login.vue";
import StudentPasswordChange from "@/views/Student/PasswordChange.vue";
import StudentUserProfile from "@/views/Student/UserProfile.vue";
import StudentCurriculum from "@/views/Student/Curriculum.vue";
import StudentCommunity from "@/views/Student/Community.vue";
import StudentMyCourse from "@/views/Student/MyCourse.vue";
import StudentHome from "@/views/Student/StudentHome.vue";
import InstructorHome from "@/views/Instructor/InstructorHome.vue";
import InstructorDashboard from "@/views/Instructor/Dashboard.vue";
import InstructorCourses from "@/views/Instructor/InstructorCourses.vue";
import InstructorMessages from "@/views/Instructor/Dashboard/Messages.vue";
import InstructorStudents from "@/views/Instructor/Dashboard/Students.vue";
import InstructorAssignments from "@/views/Instructor/Dashboard/Assignments.vue";
import InstructorForums from "@/views/Instructor/Dashboard/Forums.vue";
import SignUp from "@/views/SignUp.vue";
import InstructorQuizs from "@/views/Instructor/Dashboard/Quizs.vue";
import InstructorClasses from "@/views/Instructor/Dashboard/LiveClasses.vue";
import InstructorNewCourses from "@/views/Instructor/NewCourse.vue";
import InstructorCourseAdd from "@/views/Instructor/AddLessionUnit.vue";
import EditorTry from "@/views/Instructor/EditorTry.vue";
import InstructorAssignmentsSubmitted from "@/views/Instructor/Dashboard/AssignementsSubmitted.vue";
import InstructorCourseEdit from "@/views/Instructor/EditCourse.vue";
import StudentCourse from "@/views/Student/CoursePreview.vue";
import StudentDashboard from "@/views/Student/Dashboard.vue";
import StudentCourseProgress from "@/views/Student/Dashboard/CourseProgress.vue";
import StudentMessages from "@/views/Student/Dashboard/Messages.vue";
import StudentAssignments from "@/views/Student/Dashboard/Assignments.vue";
import StudentVideoPreview from "@/views/Student/Dashboard/CourseVideo.vue";
import StudentForums from "@/views/Student/Dashboard/Forums.vue";
import StudentClasses from "@/views/Student/Dashboard/Classes.vue";
import StudentAssessments from "@/views/Student/Dashboard/Assessments.vue";
import QuizTest from "@/views/Student/Dashboard/QuizTest.vue";
import StudentReport from "@/views/Student/Dashboard/Report.vue";
import InstructorCoursePreview from "@/views/Instructor/CoursePreview.vue";
import StudentGrade from "@/views/Student/GradeBook.vue";
import EditorJS from "@/views/EditorJS.vue";
import CompleteCourse from "@/views/Student/CompleteCourse.vue";
import CertificatePage from "@/views/Student/CertificatePage.vue";
import InstructorBlogs from "@/views/Instructor/Dashboard/Blogs.vue";
import CourseComplete from "@/views/Instructor/Dashboard/CourseComplete.vue";
import StudentProject from "@/views/Student/Dashboard/Project.vue";
import InstructorCertificatePage from "@/views/Instructor/CertificatePage.vue";
import InstructorBlogsView from "@/views/Instructor/InstructorBlogs.vue";
import InstructorBlogsList from "@/views/Instructor/InstructorBlogsList.vue";
import InstructorBlogDetails from "@/views/Instructor/InstructorBlogDetails.vue";
import InstructorBlogsEdit from "@/views/Instructor/EditBlogs.vue";
import StudentNotifications from "@/views/Student/Notifications.vue";
import InstructorNotifcations from "@/views/Instructor/Notifications.vue";
import StudentBlogs from "@/views/Student/BlogsView.vue";
import StudentBlogsList from "@/views/Student/BlogsList.vue";
import PageNotFound from "@/views/PageNotFound.vue";
import SkillGapAnalysis from "@/views/Instructor/Dashboard/SkillGapAnalysis.vue";
import InstructorInviteCourse from "@/views/Instructor/Dashboard/InviteCourse.vue";
import AdminHome from "@/views/Admin/AdminHome.vue";
import AdminDashboard from "@/views/Admin/Dashboard.vue";
import AdminStudents from "@/views/Admin/Dashboard/Students.vue";
import AdminAssignments from "@/views/Admin/Dashboard/Assignments.vue";
import AdminQuizes from "@/views/Admin/Dashboard/Quizes.vue";
import AdminForums from "@/views/Admin/Dashboard/Forums.vue";
import AdminClasses from "@/views/Admin/Dashboard/LiveClasses.vue";
import AdminBlogs from "@/views/Admin/Dashboard/Blogs.vue";
import AdminAssignmentsSubmitted from "@/views/Admin/Dashboard/AssignementsSubmitted.vue";
import AdminMessages from "@/views/Admin/Dashboard/Messages.vue";
import AdminInviteCourse from "@/views/Instructor/Dashboard/InviteCourse.vue";
import AdminSkillGapAnalysis from "@/views/Admin/Dashboard/SkillGapAnalysis.vue";
import AdminCourseComplete from "@/views/Admin/Dashboard/CourseComplete.vue";
import StudentTaskList from "@/views/Student/TaskList.vue";




import {
  loginGuards,
  adminGuards,
  studentGuards,
  instructorGuards,
} from "./guards";

import InstructorProfile from "@/views/Instructor/Profile.vue";

const routes = [
  {
    path: "/admin/home",
    name: "AdminHome",
    component: AdminHome,
    beforeEnter: adminGuards,
  },
  {
    path: "/admin/dashboard",
    name: "AdminDashboard",
    component: AdminDashboard,
    beforeEnter: adminGuards,
    // props: (router) => ({ course_id: router.params.course_id }),
    children: [
      {
        path: "skill-analysis",
        name: "AdminSkillGapAnalysis",
        component: AdminSkillGapAnalysis,
        props: true,
      },
      {
        path: "messages",
        name: "AdminMessages",
        component: AdminMessages,
        props: true,
      },
      {
        path: "students",
        name: "AdminStudents",
        component: AdminStudents,
        props: true,
      },
      {
        path: "assignments",
        name: "AdminAssignments",
        component: AdminAssignments,
        props: true,
      },
      {
        path: "assignments/submitted/:assignment_id",
        name: "AdminAssignmentsSubmitted",
        component: AdminAssignmentsSubmitted,
        props: true,
      },
      {
        path: "forums",
        name: "AdminForums",
        component: AdminForums,
        props: true,
      },
      {
        path: "quizs",
        name: "AdminQuizes",
        component: AdminQuizes,
        props: true,
      },
      {
        path: "classes",
        name: "AdminClasses",
        component: AdminClasses,
        props: true,
      },
      {
        path: "blogs",
        name: "AdminBlogs",
        component: AdminBlogs,
        props: true,
      },
      {
        path: "coursecomplete",
        name: "AdminCourseComplete",
        component: AdminCourseComplete,
        props: true,
      },
      {
        path: "invitecourse",
        name: "AdminInviteCourse",
        component: AdminInviteCourse,
        props: true,
      },
    ],
  },
  {
    path: "/:pagenotfound(.*)*",
    name: "PageNotFound",
    component: PageNotFound,
  },
  {
    path: "/student/dashboard/:course_id",
    name: "StudentDashboard",
    component: StudentDashboard,
    beforeEnter: studentGuards,
    props: (router) => ({ course_id: router.params.course_id }),
    children: [
      {
        path: "video/:lesson_id",
        name: "StudentVideoPreview",
        component: StudentVideoPreview,
        props: true,
      },
      {
        path: "courses",
        name: "StudentCourseProgress",
        component: StudentCourseProgress,
        props: true,
      },
      {
        path: "messages",
        name: "StudentMessages",
        component: StudentMessages,
        props: true,
      },
      {
        path: "assignments",
        name: "StudentAssignments",
        component: StudentAssignments,
        props: true,
      },
      {
        path: "forums",
        name: "StudentForums",
        component: StudentForums,
        props: true,
      },
      {
        path: "classes",
        name: "StudentClasses",
        component: StudentClasses,
        props: true,
      },
      {
        path: "assessments",
        name: "StudentAssessments",
        component: StudentAssessments,
        props: true,
      },
      {
        path: "reports",
        name: "StudentReport",
        component: StudentReport,
        props: true,
      },
      {
        path: "projects",
        name: "StudentProject",
        component: StudentProject,
        props: true,
      },
    ],
  },
  {
    path: "/quiz/test/:course_id/:quiz_id",
    name: "QuizTest",
    component: QuizTest,
    props: true,
  },
  {
    path: "/student/grade/",
    name: "StudentGrade",
    component: StudentGrade,
    props: true,
    beforeEnter: studentGuards,
  },
  {
    path: "/completecourse/",
    name: "CompleteCourse",
    component: CompleteCourse,
    beforeEnter: studentGuards,
  },
  {
    path: "/tasklist/",
    name: "StudentTaskList",
    component: StudentTaskList,
    beforeEnter: studentGuards,
  },
  {
    path: "/certificate/:complete_course_id",
    name: "CertificatePage",
    component: CertificatePage,
    props: (router) => ({
      complete_course_id: router.params.complete_course_id,
    }),
    props: true,
    beforeEnter: studentGuards,
  },
  {
    path: "/student/course/:courseid",
    name: "StudentCourse",
    component: StudentCourse,
    props: (router) => ({
      courseid: router.params.courseid,
    }),
    props: true,
    beforeEnter: studentGuards,
  },
  // {
  //   path: "/student/videorender",
  //   name: "StudentVideoRender",
  //   component: StudentVideoRender,
  // },
  {
    path: "/student/curriculum",
    name: "StudentCurriculum",
    component: StudentCurriculum,
    beforeEnter: studentGuards,
  },
  {
    path: "/student/community",
    name: "StudentCommunity",
    component: StudentCommunity,
    beforeEnter: studentGuards,
  },
  {
    path: "/student/mycourse",
    name: "StudentMyCourse",
    component: StudentMyCourse,
    beforeEnter: studentGuards,
  },
  {
    path: "/student/blogs",
    name: "StudentBlogs",
    component: StudentBlogs,
    beforeEnter: studentGuards,
  },
  {
    path: "/student/blogs_list/:student_blog_id",
    name: "StudentBlogsList",
    component: StudentBlogsList,
    props: true,
    beforeEnter: studentGuards,
  },
  {
    path: "/signup",
    name: "SignUp",
    component: SignUp,
    meta: { guest: true },
    beforeEnter: loginGuards,
  },
  {
    path: "/student/userprofile",
    name: "StudentUserProfile",
    component: StudentUserProfile,
    beforeEnter: studentGuards,
  },
  {
    path: "/student/passwordchange",
    name: "StudentPasswordChange",
    component: StudentPasswordChange,
    beforeEnter: studentGuards,
    // props: (router) => ({
    //   keyword: router.params.keyword,
    //   keyval: router.params.keyval,
    // }),
  },
  // Instructor Start
  {
    path: "/instructor/dashboard/:course_id",
    name: "InstructorDashboard",
    component: InstructorDashboard,
    beforeEnter: instructorGuards,
    props: (router) => ({ course_id: router.params.course_id }),
    children: [
      {
        path: "skill-analysis",
        name: "SkillGapAnalysis",
        component: SkillGapAnalysis,
        props: true,
      },
      {
        path: "messages",
        name: "InstructorMessages",
        component: InstructorMessages,
        props: true,
      },
      {
        path: "students",
        name: "InstructorStudents",
        component: InstructorStudents,
        props: true,
      },
      {
        path: "assignments",
        name: "InstructorAssignments",
        component: InstructorAssignments,
        props: true,
      },
      {
        path: "assignments/submitted/:assignment_id",
        name: "InstructorAssignmentsSubmitted",
        component: InstructorAssignmentsSubmitted,
        props: true,
      },
      {
        path: "forums",
        name: "InstructorForums",
        component: InstructorForums,
        props: true,
      },
      {
        path: "quizs",
        name: "InstructorQuizs",
        component: InstructorQuizs,
        props: true,
      },
      {
        path: "classes",
        name: "InstructorClasses",
        component: InstructorClasses,
        props: true,
      },
      {
        path: "blogs",
        name: "InstructorBlogs",
        component: InstructorBlogs,
        props: true,
      },
      {
        path: "coursecomplete",
        name: "CourseComplete",
        component: CourseComplete,
        props: true,
      },
      {
        path: "invitecourse",
        name: "InstructorInviteCourse",
        component: InstructorInviteCourse,
        props: true,
      },
    ],
  },
  // {
  //   path: '/instructor/messages',
  //   name: 'InstructorMessages',
  //   component: InstructorMessages
  // },
  // {
  //   path: '/instructor/dashboard',
  //   name: 'InstructorStudents',
  //   component: InstructorStudents
  // },
  {
    path: "/instructor/editorjs",
    name: "editorjs",
    beforeEnter: instructorGuards,
    component: EditorTry,
  },
  // InstructorProfile
  {
    path: "/instructor/profile",
    name: "InstructorProfile",
    beforeEnter: instructorGuards,
    component: InstructorProfile,
  },
  {
    path: "/instructor/new-courses",
    name: "InstructorNewCourses",
    component: InstructorNewCourses,
    beforeEnter: instructorGuards,
  },
  {
    path: "/instructor/preivew/:courseid/",
    name: "InstructorCoursePreview",
    component: InstructorCoursePreview,
    props: true,
    beforeEnter: instructorGuards,
  },

  {
    path: "/instructor/course/:course_id/add",
    name: "InstructorCourseAdd",
    component: InstructorCourseAdd,
    props: true,
    beforeEnter: instructorGuards,
  },
  {
    path: "/instructor/course/:course_id/edit",
    name: "InstructorCourseEdit",
    component: InstructorCourseEdit,
    props: true,
    beforeEnter: instructorGuards,
  },
  {
    path: "/instructor/home",
    name: "InstructorHome",
    component: InstructorHome,
    beforeEnter: instructorGuards,
  },
  {
    path: "/instructor/notifications",
    name: "InstructorNotifcations",
    component: InstructorNotifcations,
    beforeEnter: instructorGuards,
  },
  {
    path: "/instructor/courses",
    name: "InstructorCourses",
    component: InstructorCourses,
    beforeEnter: instructorGuards,
  },
  {
    path: "/instructor/blogs",
    name: "InstructorBlogsView",
    component: InstructorBlogsView,
    beforeEnter: instructorGuards,
  },
  {
    path: "/instructor/blogs/list",
    name: "InstructorBlogsList",
    component: InstructorBlogsList,
    beforeEnter: instructorGuards,
  },
  {
    path: "/instructor/blogs/details/:blog_id",
    name: "InstructorBlogDetails",
    component: InstructorBlogDetails,
    props: true,
    beforeEnter: instructorGuards,
  },
  {
    path: "/certificate/preivew/:all_course_id/",
    name: "InstructorCertificatePage",
    component: InstructorCertificatePage,
    props: true,
    beforeEnter: instructorGuards,
  },
  {
    path: "/instructor/blogs/:blog_id/edit",
    name: "InstructorBlogsEdit",
    component: InstructorBlogsEdit,
    props: true,
    beforeEnter: instructorGuards,
  },
  // Instructor End
  {
    path: "/student/home",
    name: "StudentHome",
    component: StudentHome,
    props: (router) => ({ course_id: router.params.course_id }),
    beforeEnter: studentGuards,
  },
  {
    path: "/student/Community",
    name: "Community",
    component: StudentHome,
    beforeEnter: studentGuards,
  },
  {
    path: "/student/Mycourse",
    name: "Mycourse",
    component: StudentHome,
    beforeEnter: studentGuards,
  },
  {
    path: "/student/StudentCourseCurriculum",
    name: "StudentCourseCurriculum",
    component: StudentHome,
    beforeEnter: studentGuards,
  },
  {
    path: "/",
    name: "Login",
    component: Login,
    beforeEnter: loginGuards,
  },
  {
    path: "/editorjs",
    name: "EditorJS",
    component: EditorJS,
  },
  {
    path: "/student/notifications",
    name: "StudentNotifications",
    component: StudentNotifications,
    beforeEnter: studentGuards,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
