<template>
  <div>
    <!-- <Header /> -->

    <div class="flex">
      <SideBar />
      <div
        class="w-full mr-10 mt-10 rounded-3xl overflow-y-auto heightfix"
        style="box-shadow: 0px 2px 30px 0px #01b0f926"
      >
        <div class="flex flex-col gap-3 h-full">
          <div class="flex justify-end items-center p-5">
            <form @submit.prevent="searchValue" class="flex">
              <input
                type="text"
                name="lead_search"
                id="lead_search"
                v-model="search_title"
                placeholder="Search"
                class="border-2 rounded-l-xl px-4 py-2 w-[80%] lg:w-[230px] outline-none"
              />
              <button
                type="submit"
                class="bg-blues py-1 rounded-r-xl text-white px-5"
              >
                <!-- <img src="@/assets/search.webp" alt="" srcset="" class="w-6 h-6"> -->

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  class="w-7 h-7"
                  viewBox="0,0,256,256"
                >
                  <g
                    fill="#fbf7f7"
                    fill-rule="nonzero"
                    stroke="none"
                    stroke-width="1"
                    stroke-linecap="butt"
                    stroke-linejoin="miter"
                    stroke-miterlimit="10"
                    stroke-dasharray=""
                    stroke-dashoffset="0"
                    font-family="none"
                    font-weight="none"
                    font-size="none"
                    text-anchor="none"
                    style="mix-blend-mode: normal"
                  >
                    <g transform="scale(5.12,5.12)">
                      <path
                        d="M21,3c-9.37891,0 -17,7.62109 -17,17c0,9.37891 7.62109,17 17,17c3.71094,0 7.14063,-1.19531 9.9375,-3.21875l13.15625,13.125l2.8125,-2.8125l-13,-13.03125c2.55469,-2.97656 4.09375,-6.83984 4.09375,-11.0625c0,-9.37891 -7.62109,-17 -17,-17zM21,5c8.29688,0 15,6.70313 15,15c0,8.29688 -6.70312,15 -15,15c-8.29687,0 -15,-6.70312 -15,-15c0,-8.29687 6.70313,-15 15,-15z"
                      ></path>
                    </g>
                  </g>
                </svg>
              </button>
            </form>
          </div>
          <div class="overflow-x-auto">
          <table class="table-auto w-full ">
            <thead>
              <tr>
                <th
                  class="px-4 lg:px-7 py-8 border text-center border-r text-blues whitespace-nowrap"
                >
                  S NO
                </th>
                <th
                  class="px-4 lg:px-7 py-8 border text-center border-r text-blues"
                >
                  Name
                </th>
                <th
                  class="px-4 lg:px-7 py-8 border text-center border-r text-blues"
                >
                  Email
                </th>

                <th
                  class="px-4 lg:px-7 py-8 border text-center border-r text-blues whitespace-nowrap"
                >
                  Start Date
                </th>

                <th
                  class="px-4 lg:px-7 py-8 border text-center border-r text-blues whitespace-nowrap"
                >
                  End Date
                </th>

                <th
                  class="px-4 lg:px-7 py-8 border text-center border-r text-blues"
                >
                  Percentage
                </th>
                <th
                  class="px-4 lg:px-7 py-8 border text-center border-r text-blues"
                >
                  Perview
                </th>
              </tr>
            </thead>

            <tbody v-if="this.complete_detail.length > 0">
              <tr v-for="(el, index) in this.complete_detail" :key="el">
                <td class="px-4 lg:px-7 py-8 border-b text-center border-r">
                  <p class="text-primary">{{ index + 1 }}</p>
                </td>
                <td class="px-4 lg:px-7 py-8 border-b text-center border-r">
                  <p v-if="el.display_name" class="text-primary">
                    {{ el.display_name }}
                  </p>
                  <p v-else class="text-primary">NA</p>
                </td>
                <td
                  class="px-4 lg:px-7 py-8 border-b text-center border-r whitespace-nowrap"
                >
                  <p v-if="el.email" class="text-primary">{{ el.email }}</p>
                  <p v-else class="text-primary">NA</p>
                </td>
                <td
                  class="px-4 lg:px-7 py-8 border-b text-center border-r whitespace-nowrap"
                >
                  <p v-if="el.start_time" class="text-primary">
                    {{ formatDate(el.start_time) }}
                  </p>
                  <p v-else class="text-primary">NA</p>
                </td>
                <td
                  class="px-4 lg:px-7 py-8 border-b text-center border-r whitespace-nowrap"
                >
                  <p v-if="el.end_time" class="text-primary">
                    {{ formatDate(el.end_time) }}
                  </p>
                  <p v-else class="text-primary">NA</p>
                </td>
                <td class="px-4 lg:px-7 py-8 border-b text-center border-r">
                  <p v-if="el.percentage" class="text-primary">
                    {{ el.percentage }}
                  </p>
                  <p v-else class="text-primary">NA</p>
                </td>
                <td class="px-4 lg:px-7 py-8 border-b text-center border-r">
                  <router-link
                    :to="{
                      name: 'InstructorCertificatePage',
                    }"
                    target="_blank"
                    class="text-sm sm:text-md text-[#0085DB] font-medium cursor-pointer"
                  >
                    View
                  </router-link>
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr class="w-full">
                <td colspan="8" class="text-center py-6">
                  <div class="w-full flex justify-center items-center">
                    No Data Found
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Admin/Header.vue";
import SideBar from "@/components/Admin/SideBar.vue";

import axios from "axios";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      complete_detail: [],
      search_title: "",
    };
  },
  components: {
    Header,
    SideBar,
  },
  created() {
    this.completecourse();
  },
  computed: {
    ...mapGetters(["userInfo"]),
  },
  // props: ["course_id"],
  methods: {
    async completecourse() {
      await axios
        .get(
          `${process.env.VUE_APP_API}/completed-certificates/?Key=${this.userInfo.token_key}`
        )
        .then((response) => {
          this.complete_detail = response.data.data;
        });
    },
    formatDate(date) {
      return new Date(date).toLocaleDateString("en-IN", {
        day: "numeric",
        month: "long",
        year: "numeric",
      });
    },
    async searchValue() {
      await axios
        .get(
          `${process.env.VUE_APP_API}/completed-certificates/?Key=${this.userInfo.token_key}&search=${this.search_title}`
        )
        .then((response) => {
          this.complete_detail = response.data.data;
        });
    },
  },
};
</script>

<style scoped>
.heightfix {
  height: calc(100vh - 170px) !important;
}
</style>
