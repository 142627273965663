<template>
    <div class="flex flex-col lg:flex-row heightfix">
        <div class="lg:w-[35%] relative">
            <!-- <img :src="login_logo" class="w-[200px] h-[30px] absolute top-8 left-8" alt="" /> -->
            <img :src="login_logo" class="w-[200px] h-[50px] absolute top-8 left-8 object-contain" alt="" />

            <img src="@/assets/newd/testbg.png" class="w-full h-full" alt="" />
            <img src="@/assets/loginbg.svg" class="absolute bottom-0 lg:h-[85%] lg:-right-[20%]" alt="" />
        </div>

        <div class="flex flex-col gap-10 p-10 lg:items-center lg:w-[65%] lg:justify-center">


            <form @submit.prevent="loginUserss" class="lg:w-[50%] flex flex-col gap-4">

                <h2 class="text-4xl font-bold">Sign in</h2>
                <p class="text-sm">Please fill your information below</p>

                <div>
                    <label class="leading-7 text-sm text-gray-600">Email</label>
                    <input @change="emptyspaceremove" ref="input" type="email" id="email" name="email"
                        class="w-full bg-white rounded border border-gray-300 focus:primary-border focus:ring-2 focus:primary-border text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                        placeholder="Email" required />
                </div>

                <div>
                    <label class="leading-7 text-sm text-gray-600">Password</label>
                    <input type="password" id="password" name="password"
                        class="w-full bg-white rounded border border-gray-300 focus:primary-border focus:ring-2 focus:primary-border text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                        placeholder="Password" required />
                    <img v-if="passwordType == 'password'" src="@/assets/eyeslash.svg" @click="hideShow('password')"
                        alt="" class="cursor-pointer inline-block z-50" style="margin-left: -30px" />
                    <img v-else src="@/assets/eye.svg" @click="hideShow('password')" alt=""
                        class="cursor-pointer inline-block z-50" style="margin-left: -30px" />
                </div>

                <h1 v-if="loginError" class="text-red-700 font-semibold p-2 text-base">
                    {{ loginError }}
                </h1>
                <h1 v-if="credentialsError" class="text-red-700 font-semibold p-2 text-base">
                    {{ credentialsError }}
                </h1>

                <!-- <button type="button" @click="forgetpassWord()"
                    class="text-left px-2 py-1 text-sm text-sky-600 hover:text-red-500">
                    Forgot Password?
                </button> -->

                <div class="flex justify-end mt-4 bottom-2">
                    <button v-if="!loading" type="submit"
                        class="bg-black text-white py-2 px-6 text-sm rounded-lg hover:bg-blue-700 transition relative"
                        style="background-color: #0f172a !important">
                        Login
                    </button>
                    <button v-if="loading" type="submit"
                        class="bg-black text-white py-2 px-6 text-sm rounded-lg hover:bg-blue-700 transition relative"
                        style="background-color: #0f172a !important">
                        Loading...
                    </button>
                </div>
            </form>
        </div>
        <!-- <div id="forgetPassword" class="hidden w-full lg:w-1/2 mt-5 lg:mt-0 ">
            <form @submit.prevent="resetMail()">
                <div id="forgetPasswordEl" class="relative p-6 flex flex-col w-full  lg:w-5/6  justify-center ">
                    <h1 class="text-md lg:text-lg py-1 mb-0">Reset Your Password</h1> <button type='button'
                        @click="backtologin" class="absolute top-6 right-6 text-sky-700  hover:text-sky-500 py-1">Back
                        to Login</button>
                    <label>Email Id</label>
                    <input type='email' id='resetMailID'
                        class="border leading-7 text-sm text-gray-600 border-gray-300 placeh"
                        placeholder="Enter your email" required />
                    <button v-if="!loading" type="submit"
                        class="button-primary header-button w-button my-4">Send</button>
                    <button v-else type="submit" class=" button-primary header-button w-button my-4">
                        <img class="mx-auto h-5" src="../assets/Pulse.gif" alt="">
                    </button>
                    <span id='alert' class='hidden text-red-500 rounded text-center text-sm font-semibold'>Enter
                        Vaild Mail Id</span>

                    <span id='mailDone' class='hidden text-green-700 rounded text-center text-sm font-semibold'>Mail
                        Sent to Entered email id</span>
                </div>
            </form>
        </div> -->
    </div>
</template>

<script>

import axios from "axios"
import {
    mapActions,
    mapGetters
} from "vuex";
export default {
    data: function () {
        return {
            login_logo: require(`${process.env.VUE_APP_LOGIN_COMS_LOGO}`),
            login: {
                username: "",
                password: ""
            },
            loading: false,
            passwordType: "password",
            varificationPopup: true,
            credentialsError: "",
            messages: {
                text: "",
                type: 'null',
            },
            ip_address: ""
        };
    },
    // components: {
    //     Navigation,
    //     FlashMessage,
    // },
    computed: mapGetters(["userInfo", "loginError"]),
    mounted() {
        // this.$refs.input.focus()
        // document.querySelector("#mailDone").onclick = function () {
        //     this.$router.go()
        // }

        fetch("https://ipinfo.io/json")
            .then(response => response.json())
            .then(data => {
                this.ip_address = data.ip;
                // document.getElementById("ip-address").textContent = 
                //       `IP Address: ${data.ip}`;
            })
            .catch(error => {
                console.error("Error fetching IP address:", error);
                // document.getElementById("ip-address").textContent = 
                //       "Unable to retrieve IP address.";
            });

    },
    methods: {
        ...mapActions(["loginUser", "subcription", "instructordetails"]),

        hideShow(id) {
            const passwordField = document.querySelector(`#${id}`)
            passwordField.getAttribute('type') === 'password' ? passwordField.setAttribute('type', 'text') : passwordField.setAttribute('type', 'password')
            this.passwordType = document.querySelector(`#${id}`).getAttribute('type')
        },

        async loginUserss() {
            let data = {
                username: document.getElementById("email").value.toLowerCase(),
                password: document.getElementById("password").value,
                ip_address: this.ip_address
            }
            if (localStorage.getItem("message_token")) {
                data["device_token"] = localStorage.getItem("message_token")
            }
            // console.log(data,'user details')
            await this.loginUser(data);

            this.loading = true;

            setTimeout(() => {
                let subdata = this.userInfo;
                this.subcription(subdata);
                this.instructordetails(subdata)
                this.loading = false;
            }, 1000)

            setTimeout(() => {
                if (this.userInfo.user.permission == 'student') {
                    const userPermission = this.userInfo.user.permission;
                    this.$router.push({
                        name: 'StudentHome'
                    });
                    this.loading = false;
                } else if (this.userInfo.user.permission == 'instructor') {

                    this.$router.push({
                        name: 'InstructorHome'
                    });
                    this.loading = false;
                } else if (this.userInfo.user.permission == 'super_admin') {

                    this.$router.push({
                        name: 'AdminHome'
                    })
                } else {
                    this.$router.push({
                        name: 'organizationDashboard',
                        params: {
                            slug_id: this.userInfo.slug_id
                        }
                    });
                    this.loading = false;
                }

            }, 1000)
        },

        handleSubmit: async function (e) {
            let is_activation
            this.loading = true;

            let mailId = {
                email: e.target.elements.email.value.toLowerCase()
            }
            // console.log(mailId,'emailis')
            await axios.post(`${process.env.VUE_APP_API}/email_verification/`, mailId)
                .then(response => {
                    if (response.data.message == "Email already verified") {
                        is_activation = true
                        // console.log("Email already verified")
                    } else if (response.data.message == "Invalid Email credentials") {
                        // console.log(" invalid Email credentials") 
                        this.credentialsError = "Invalid credentials"
                        setTimeout(() => {
                            this.credentialsError = ""
                            this.loading = false;
                        }, 2000);
                    } else {
                        this.varificationPopup = false
                        is_activation = false
                        this.loading = false;
                        //    console.log("Email not verified")

                    }
                })
                .catch(error => {
                    console.log(error)
                })

            if (is_activation) {
                e.preventDefault();
                let data = {
                    username: (e.target.elements.email.value).toLowerCase(),
                    password: e.target.elements.password.value,
                }
                // console.log(data,'user details')
                await this.loginUser(data);
                setTimeout(() => {
                    let subdata = this.userInfo;
                    this.subcription(subdata);
                    this.instructordetails(subdata)
                    this.loading = false;
                }, 3000)

                setTimeout(() => {
                    if (this.userInfo.user.permission == 'student') {
                        this.$router.push({
                            name: 'StudentHome'
                        });
                        this.loading = false;
                    } else if (this.userInfo.user.permission == 'instructor') {

                        this.$router.push({
                            name: 'Instructorcourse-list'
                        })
                    } else {
                        this.$router.push({
                            name: 'organizationDashboard',
                            params: {
                                slug_id: this.userInfo.slug_id
                            }
                        });
                        this.loading = false;
                    }

                }, 3000)

            } else {
                console.log("MailID have a issue")
            }

        },

        resendActivationMail() {
            // console.log(document.querySelector("#email").value)
            this.varificationPopup = true
            let mailId = {
                email: document.querySelector("#email").value
            }
            axios.post(`${process.env.VUE_APP_API}/regenerate_token/`, mailId)
                .then(response => {
                    // console.log(response.data.message)
                    if (response.data.message == "Email send") {

                        this.messages = {
                            text: "Email send",
                            type: 'success',
                        }
                    } else {
                        this.messages = {
                            text: " Event Faild",
                            type: 'error',
                        }
                    }
                    setTimeout(() => {
                        this.messages = {
                            text: "",
                            type: 'null',
                        }
                    }, 2500)

                })
                .catch(error => {
                    console.log(error)
                })

        },

        forgetpassWord() {
            document.getElementById("signIn").style.display = "none";
            document.getElementById("forgetPassword").style.display = "block";
        },

        backtologin() {
            document.getElementById("forgetPassword").style.display = "none";
            document.getElementById("signIn").style.display = "block";
        },

        resetMail() {
            this.loading = true
            document.querySelector("#resetMailID").value;
            const resetMail = {
                "email": document.querySelector("#resetMailID").value
            }

            axios.post(`${process.env.VUE_APP_API}/api/password_reset/`, resetMail)
                .then(response => {
                    if (response.data.status == "OK") {
                        this.loading = false
                        document.querySelector("#resetMailID").value = ""
                        document.querySelector("#mailDone").classList.replace("hidden", "block")
                        setTimeout(() => {
                            document.querySelector("#mailDone").classList.replace("block", "hidden")
                            document.getElementById("signIn").style.display = "block";
                            document.getElementById("forgetPassword").style.display = "none";
                        }, 3500)
                    }
                })

                .catch(error => {
                    document.querySelector("#resetMailID").value = ""
                    document.querySelector("#alert").classList.replace("hidden", "block")
                    setTimeout(() => {
                        document.querySelector("#alert").classList.replace("block", "hidden")
                        this.loading = false

                    }, 2500)
                })
        },
        emptyspaceremove(el) {
            el.target.value = el.target.value.trim()
        }

    }

};

</script>

<style scoped>
.heightfix {
    height: calc(100vh) !important;
}
</style>
