<template>
  <div>
    <!-- <Header /> -->

    <div class="flex">
      <SideBar />

      <div
        class="w-full mr-10 mt-10 rounded-3xl overflow-x-auto overflow-y-auto heightfix"
        style="box-shadow: 0px 2px 30px 0px #01b0f926"
      >
        <div class="px-5 lg:px-10 mt-10 pb-2">
          <router-link
            :to="{ name: 'InstructorAssignments' }"
            class="bg-blues text-xs font-bold text-white px-5 py-1.5 rounded-full"
            >Back</router-link
          >
        </div>

        <h1 class="text-lg font-bold px-5 lg:px-10 pt-5 pb-5">Assignments Submitted</h1>

        <div
          v-if="assignment_list != null"
          class="grid grid-cols-1 lg:grid-cols-4 px-5 lg:px-10 gap-10"
        >
          <div
            v-for="assignment in assignment_list"
            :key="assignment"
            @click="detailsPopup(assignment)"
            class="flex flex-col gap-3 border p-5 rounded-xl"
          >
            <img
              class="rounded-xl object-cover"
              src="@/assets/new/assignment.png"
              style="width: 100%"
              alt=""
            />

            <div class="flex flex-col gap-1 mt-2">
              <p class="text-sm px-1">
                <span class="font-bold">Submitted at</span>
                {{ assignment.display_date }}
              </p>
              <p class="text-sm px-1">
                <span class="font-bold">Submitted by</span>
                {{ assignment.user.display_name }}
              </p>
              <p class="text-sm px-1">
                <span class="font-bold">Description:</span>
                {{ assignment.student_description.slice(0, 160) }}
              </p>
            </div>

            <div class="flex mx-1 gap-3">
              <button
                v-if="assignment.verify_status == 'submitted'"
                class="px-3 rounded-xl bg-sky-800 py-1.5 text-xs text-white font-semibold"
              >
                Submitted
              </button>
              <button
                v-else-if="assignment.verify_status == 'accepted'"
                class="px-3 rounded-xl text-xs py-1.5 bg-teal-700 text-white font-semibold"
              >
                Accepted
              </button>
              <button
                v-else
                class="text-xs px-3 py-1.5 rounded-xl bg-pink-800 text-white font-semibold"
              >
                Rejected
              </button>
            </div>
          </div>
        </div>

        <div v-else class="px-10">
          <div class="flex flex-col">
            <h1 class="text-sm text-gray-600">No Assignments Submitted</h1>
          </div>
        </div>

        <div
          v-if="is_detailsPopup"
          class="fixed bg-primary bg-opacity-30 top-0 left-0 right-0 bottom-0 z-50 flex items-center justify-center"
        >
          <div
            class="lg:w-[40%] bg-white rounded-xl p-10 overflow-y-auto max-h-[600px]"
          >
            <div class="flex items-center justify-between">
              <h3 class="text-xl font-bold text-blues">Assignment</h3>
              <img
                src="@/assets/newd/close.png"
                class="cursor-pointer w-6"
                alt=""
                @click="(is_detailsPopup = false), (is_preview = false)"
              />
            </div>

            <div
              class="w-full mt-4 flex flex-col p-6 relative border border-gray-100 rounded-2xl"
            >
              <div class="w-full">
                <div
                  class="flex flex-col gap-4 text-xs md:text-sm lg:text-base h-full"
                >
                  <div class="flex flex-col w-full gap-2">
                    <p class="text-sm font-semibold">Title</p>
                    <p class="text-sm">{{ assignmentDetails.title }}</p>
                  </div>
                  <div class="flex flex-col w-full gap-2">
                    <p class="text-sm font-semibold">Description</p>
                    <p class="text-sm">
                      {{ assignmentDetails.student_description }}
                    </p>
                  </div>

                  <div>
                    <a :href="assignmentDetails.file" target="_blank">
                      <button
                        class="bg-blues px-4 py-1.5 rounded-lg text-white font-semibold text-xs"
                      >
                        Download Submitted Document
                      </button>
                    </a>
                  </div>

                  <div
                    v-if="assignmentDetails.verify_status == 'submitted'"
                    class="flex gap-4"
                  >
                    <div
                      class="flex justify-arround gap-2 rounded-2xl cursor-pointer hover:text-white text-white bg-green-900 w-auto py-1.5 px-4 items-center"
                      @click="accept()"
                    >
                      <font-awesome-icon
                        class="text-sm self-center"
                        :icon="['fas', 'check-circle']"
                      />
                      <p class="text-xs hover:text-white">Accept</p>
                    </div>
                    <div
                      class="flex justify-arround gap-2 rounded-2xl text-white cursor-pointer hover:text-white font-bold bg-red-700 w-auto py-1.5 text-xs px-4 items-center"
                      @click="reject()"
                    >
                      <font-awesome-icon
                        class="text-sm self-center"
                        :icon="['fas', 'times-circle']"
                      />
                      <p class="text-xs">Reject</p>
                    </div>
                  </div>

                  <div v-else class="flex flex-col gap-2">
                    <h1 class="text-base font-bold">Already validated</h1>
                    <p class="capitalize text-sm font-semibold">
                      Status :
                      <span
                        :class="[
                          assignmentDetails.verify_status == 'rejected'
                            ? 'text-red-700'
                            : 'text-green-700',
                        ]"
                      >
                        {{ assignmentDetails.verify_status }}
                      </span>
                    </p>
                  </div>

                  <!-- <vue-pdf-embed :source="convertHttpToHttps(assignmentDetails.file)" class="w-full h-[100px]" /> -->

                  <!-- <div class="flex justify-end pb-4 mt-3">
                    <button
                      type="submit"
                      class="text-black border border-black rounded-lg text-sm px-10 py-2"
                    >
                      Submit
                    </button>
                  </div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Admin/Header.vue";
import SideBar from "@/components/Admin/SideBar.vue";

import axios from "axios";
import { mapGetters } from "vuex";

import VuePdfEmbed from 'vue-pdf-embed'

export default {
  components: {
    Header,
    SideBar,
    VuePdfEmbed
  },
  data() {
    return {
      assignment_list: "",
      is_detailsPopup: false,
      assignmentDetails: null,
      messages: {
        text: "",
        type: "null",
      },
      is_preview: false,
    };
  },
  created() {
    this.getdetails();
  },

  props: ["assignment_id"],

  computed: {
    ...mapGetters(["userInfo"]),
  },
  methods: {
    convertHttpToHttps(url) {
      if (url.startsWith('http://')) {
        return url.replace('http://', 'https://');
      }
      return url; // If it's already https or any other scheme, return as is
    },

    accept() {
      let acceptsVal = {
        user: this.assignmentDetails.user.id,
        assignment_id: this.assignmentDetails.assignment_id,
        title: this.assignmentDetails.title,
        student_description: this.assignmentDetails.student_description,
        submit_date: this.assignmentDetails.submit_date,
        verify_status: "accepted",
      };

      axios
        .put(
          `${process.env.VUE_APP_API}/student_assignment/updated/${this.assignmentDetails.id}/?Key=${this.userInfo.token_key}`,
          acceptsVal
        )
        .then((response) => {
          console.log(response);
          if (
            response.data.message == "Student Assignment updated successfully"
          ) {
            this.messages = {
              text: "Assignment Accepted successfully",
              type: "success",
            };
          } else {
            this.messages = {
              text: " Event Faild",
              type: "error",
            };
          }
        });

      setTimeout(() => {
        this.messages = {
          text: "",
          type: "null",
        };
        this.is_detailsPopup = false;
        this.getdetails();
      }, 1500);
    },

    reject() {
      let rejectedVal = {
        user: this.assignmentDetails.user.id,
        assignment_id: this.assignmentDetails.assignment_id,
        title: this.assignmentDetails.title,
        student_description: this.assignmentDetails.student_description,
        submit_date: this.assignmentDetails.submit_date,
        verify_status: "rejected",
      };

      axios
        .put(
          `${process.env.VUE_APP_API}/student_assignment/updated/${this.assignmentDetails.id}/?Key=${this.userInfo.token_key}`,
          rejectedVal
        )
        .then((response) => {
          console.log(response);
          if (
            response.data.message == "Student Assignment updated successfully"
          ) {
            this.messages = {
              text: "Assignment Rejected",
              type: "success",
            };
          } else {
            this.messages = {
              text: " Event Faild",
              type: "error",
            };
          }
        });

      setTimeout(() => {
        this.messages = {
          text: "",
          type: "null",
        };
        this.is_detailsPopup = false;
        this.getdetails();
      }, 1500);
    },

    detailsPopup(value) {
      this.is_detailsPopup = true;
      this.assignmentDetails = value;
    },

    async getdetails() {
      await axios
        .get(
          `${process.env.VUE_APP_API}/assignment/list/view/?assignment_id=${this.assignment_id}&Key=${this.userInfo.token_key}`
        )
        .then((response) => {
          console.log(response);
          if (response.data.message == "No details found") {
            console.log("Null");
            this.assignment_list = null;
          } else {
            this.assignment_list = response.data;

            this.assignment_list.data.forEach((element) => {
              console.log(element.title);
              element["display_date"] = new Date(
                element.submit_date
              ).toLocaleDateString("en-IN", {
                day: "numeric",
                month: "long",
                year: "numeric",
              });
            });

            this.assignment_list = this.assignment_list.data;
          }
        });
    },
  },
};
</script>

<style scoped>

.heightfix{
  height: calc(100vh - 170px) !important;
}

</style>


