<template>
    <footer class="bg-[#0F172A] py-8">
        <div class="container lg:mx-auto flex flex-col items-center lg:gap-5">
            <div class="flex flex-col items-center mt-5">
                <a href="#" class="flex items-center justify-center mb-3">
                    <!-- <img :src="logo_image" alt="Logo" /> -->
                    <img :src="logo_image" alt="Logo" class="object-conatin lg:h-[70px]" />

                </a>
                <p class="text-white lg:text-2xl font-semibold text-center mt-5">Learning Management System</p>
            </div>

            <div class="flex justify-center space-x-4 mt-6">
                <a href="#">
                    <img src="@/assets/newd/linkedin.svg" alt="LinkedIn"
                        class="hover:opacity-80 object-contain w-10 h-10 rounded-full" />
                </a>
                <a href="#">
                    <img src="@/assets/newd/facebook.svg" alt="Facebook"
                        class="hover:opacity-80 object-contain w-10 h-10 rounded-full" />
                </a>
                <a href="#">
                    <img src="@/assets/newd/instgram.svg" alt="Instagram"
                        class="hover:opacity-80 object-contain w-10 h-10 rounded-full" />
                </a>
                <a href="#">
                    <img src="@/assets/newd/twitter.svg" alt="Twitter"
                        class="hover:opacity-80 object-contain w-10 h-10 rounded-full" />
                </a>
            </div>


        </div>

        <hr class="border-gray-600 my-6" />

        <div class="text-center text-white text-sm py-2">
            © 2024, Galecta Private Limited. All rights reserved |
            Powered by <a href="https://galecta.com/" class="text-blue-400 hover:underline">GALECTA</a>
        </div>
    </footer>
</template>

<script>
export default {
    data() {
        return {
             logo_image: require(`${process.env.VUE_APP_COMS_LOGO}`),
            title: "",
        };
    },
    created() {
        this.title = process.env.VUE_APP_Title;
    },
};
</script>