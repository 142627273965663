<template>
  <div>
    <Header />

    <div class="container mx-auto max-w-[1200px] py-20 px-4">
      <div class="flex flex-col gap-3" v-if="this.courselist">
        <img
          v-if="this.courselist[0].thumbnail"
          :src="this.courselist[0].thumbnail"
          alt="course image"
          class="object-cover rounded-2xl h-[400px]"
        />

        <div class="flex flex-col gap-10 lg:gap-0 lg:flex-row items-center justify-between mt-10">
          <div class="flex flex-col lg:flex-row items-center lg:gap-10 gap-5">
            <div class="md:w-[500px] xl:w-[650px]">
              <h1 class="text-2xl lg:text-3xl text-primary font-bold text-wrap">
                {{ this.courselist[0].title }}
              </h1>
            </div>
            <div class="flex items-center gap-10">
              <div class="flex items-center rounded-2xl gap-2 whitespace-nowrap">
                <img src="@/assets/new/rounded.png" alt="" />
                <p class="text-xs">
                  {{ courselist[0].sections.length }} Units
                </p>
              </div>
              <div class="flex items-center rounded-2xl gap-2 whitespace-nowrap">
                <img src="@/assets/new/time.png" alt="" />
                <p class="text-xs">18h 20m Left</p>
              </div>
            </div>
          </div>

          <div> 
              <router-link :to="{ name: 'StudentCourseProgress', params: { course_id: courselist[0].id } }" class="bg-primary px-6 py-3 rounded-3xl text-white text-xs">Dashboard</router-link>
          </div>

        </div>

        <p class="text-primary mt-5">{{ courselist[0].short_description }}</p>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Student/Header.vue";
import Footer from "@/components/Footer.vue";

import axios from "axios";
import { mapGetters } from "vuex";

export default {
  name: "CoursePreview",

  components: {
    Header,
    Footer,
  },

  data() {
    return {
      lessonInfo: "",
      sectionInfo: "",
      courselist: "",
      courseDetailInfo: "",
      lessonCount: "",
      tap: "about",
      videoLink: "",
      is_video: false,
      is_content: false,
      content: "",
      courselimited: "",
      documentList: "",
      courselistsuggest: "",
      is_topbutton: false,
      pdf_popup: false,
      pdf_val: "",
      timeTable: "",
      timeTable_val: "",
      host: process.env.VUE_APP_API,
    };
  },

  props: ["courseid"],

  mounted(){
    if(localStorage.getItem("previewIgnoreList")){
        console.log(JSON.parse(localStorage.getItem("previewIgnoreList")));
        const previewList = JSON.parse(localStorage.getItem("previewIgnoreList"));
        previewList[previewList.length] = this.courseid;
        setTimeout(()=>{
          localStorage.setItem("previewIgnoreList", JSON.stringify(previewList)) 
        }, 300)
    }
  },

  async created() {
    await axios
      .get(
        `${process.env.VUE_APP_API}/courses/?id=${this.courseid}&format=json`
      )
      .then((response) => {
        this.courselist = response.data.data;
      });
  },
};
</script>
