<template>
  <div class="bg-[rgb(15,23,42)] py-3 px-6">
    <div class="container mx-auto max-w-[1200px]">
      <div class="flex items-center justify-between">
        <!-- <router-link :to="{ name: 'InstructorHome' }" class="brand w-nav-brand w--current flex items-center">
          <img :src="logo_image" alt="" class="w-40 py-4 object-fit" />
        </router-link> -->

        <router-link
          :to="{ name: 'InstructorHome' }"
          class="brand w-nav-brand w--current flex items-center"
        >
          <img :src="logo_image" alt="Logo" class="w-40 py-4 object-fit" />
        </router-link>

        <div class="flex items-center">
          <div class="hidden md:flex items-center gap-5">
            <div class="mr-10 flex items-center gap-10">
              <div class="relative cursor-pointer dropdown-container">
                <div
                  class="flex items-center gap-2"
                  @click="MenuToggle('menu')"
                >
                  <p class="text-sm text-white hover:text-white font-medium">
                    Menu
                  </p>
                  <img src="@/assets/newd/down.png" class="w-3" alt="" />
                </div>

                <div
                  class="absolute right-0 top-12 z-50 bg-white rounded-2xl shadow-lg"
                  style="width: 180px"
                  v-if="toggleMenuName == 'menu'"
                >
                  <router-link
                    :to="{ name: 'InstructorNewCourses' }"
                    class="text-sm font-medium flex items-center gap-2 py-3 px-6 cursor-pointer"
                    :class="{
                      'text-[#0085db]': $route.name === 'InstructorNewCourses',
                      'text-black': $route.name !== 'InstructorNewCourses',
                    }"
                  >
                    New Course</router-link
                  >

                  <hr />

                  <router-link
                    :to="{ name: 'InstructorCourses' }"
                    class="text-sm font-medium flex items-center gap-2 py-3 px-6 cursor-pointer"
                    :class="{
                      'text-[#0085db]': $route.name === 'InstructorCourses',
                      'text-black': $route.name !== 'InstructorCourses',
                    }"
                  >
                    Courses</router-link
                  >

                  <hr />

                  <router-link
                    :to="{ name: 'InstructorBlogsView' }"
                    class="text-sm font-medium flex items-center gap-2 py-3 px-6 cursor-pointer"
                    :class="{
                      'text-[#0085db]': $route.name === 'InstructorBlogsView',
                      'text-black': $route.name !== 'InstructorBlogsView',
                    }"
                  >
                    New Blog</router-link
                  >

                  <hr />

                  <router-link
                    :to="{ name: 'InstructorBlogsList' }"
                    class="text-sm font-medium flex items-center gap-2 py-3 px-6 cursor-pointer"
                    :class="{
                      'text-[#0085db]': $route.name === 'InstructorBlogsList',
                      'text-black': $route.name !== 'InstructorBlogsList',
                    }"
                  >
                    Blogs</router-link
                  >

                  <hr />

                  <!-- <router-link :to="{ name: 'InstructorInviteCourse' }" class="text-sm font-medium flex items-center gap-2 py-3 px-6 cursor-pointer" :class="{ 'text-[#0085db]': $route.name === 'InstructorInviteCourse','text-black': $route.name !== 'InstructorInviteCourse' }">
               Invite Course</router-link> -->
                  <div>
                    <button
                      @click="
                        this.invitecourse = true;
                        toggleMenuName = '';
                      "
                      class="text-sm font-medium flex items-center gap-2 py-3 px-6 cursor-pointer"
                      :class="{
                        ' text-[#0085db]': invitecourse,
                        ' text-black': !invitecourse,
                      }"
                    >
                      Invite Course
                    </button>
                  </div>

                  <!-- <div class="flex items-center gap-2 py-3 px-6 cursor-pointer">
                  <router-link
                    :to="{ name: 'InstructorCourses' }"
                    class="text-sm font-medium"
                    style="color: #333333 !important"
                    >Courses</router-link
                  >
                </div> -->

                  <!-- <div class="flex items-center gap-2 py-3 px-6 cursor-pointer">
                <p
                  class="text-sm text-[#F87474] font-semibold"
                  style="color: #f87474 !important"
                  @click="logOut"
                >
                  Course with AI
                </p>
              </div> -->
                </div>
              </div>
              <!-- <div>
              <router-link
                :to="{ name: 'Community' }"
                class="text-sm text-white hover:text-white font-medium"
                >Community</router-link
              >
            </div>
            <div>
              <router-link
                :to="{ name: 'StudentCourseCurriculum' }"
                class="text-sm text-white hover:text-white font-medium"
                >Curriculum</router-link
              >
            </div> -->
            </div>
          </div>

          <div
            class="flex items-center mr-10 cursor-pointer relative dropdown-container"
          >
            <img
              src="@/assets/notification.png"
              width="24"
              height="24"
              @click="MenuToggle('notification')"
            />

            <div
              class="absolute left-1/2 -translate-x-1/2 top-12 lg:right-0 px-6 py-2 lg:pb-5 lg:top-14 z-50 bg-white rounded-2xl shadow-lg"
              style="width: 250px"
              v-if="toggleMenuName == 'notification'"
            >
              <div>
                <p class="font-bold text-base py-2">Notifications</p>
              </div>
              <div class="flex flex-col gap-2">
                <div
                  v-if="this.notificationList.length != 0"
                  class="flex flex-col gap-2"
                >
                  <!-- <router-link :to="{ name: 'InstructorNotifcations' }" class="flex flex-col border gap-1 py-2 px-4 rounded-lg" v-for="notifi in this.notificationList" :key="notifi">
                    <p class="font-bold text-sm">{{ notifi.topic }}</p>
                    <p class="text-xs">{{ new Date(notifi.created_at).toLocaleDateString("en-IN") }}</p>
                  </router-link> -->
                  <router-link
                    :to="{ name: 'InstructorNotifcations' }"
                    class="flex flex-col border gap-1 py-2 px-4 rounded-lg"
                    v-for="notifi in this.notificationList.slice(0, 3)"
                    :key="notifi"
                  >
                    <p class="font-bold text-sm">{{ notifi.topic }}</p>
                    <p class="text-xs">
                      {{
                        new Date(notifi.created_at).toLocaleDateString("en-IN")
                      }}
                    </p>
                  </router-link>

                  <router-link
                    :to="{ name: 'InstructorNotifcations' }"
                    v-if="this.notificationList.length > 3"
                    class="text-blues font-bold text-xs py-2"
                    >See More</router-link
                  >
                </div>

                <!-- <div class="flex flex-col border gap-1 py-2 px-4 rounded-lg">
                  <p class="font-semibold text-xs">New course added for you!</p>
                  <p class="font-semibold text-xs">11/02/2025</p>
                </div>
                <div class="flex flex-col border gap-1 py-2 px-4 rounded-lg">
                  <p class="font-semibold text-xs">New course added for you!</p>
                  <p class="font-semibold text-xs">11/02/2025</p>
                </div> -->
                <router-link
                  :to="{ name: 'InstructorNotifcations' }"
                  v-if="this.notificationList.length == 0"
                  class="flex flex-col text-sm border gap-1 py-2 px-4 rounded-lg"
                  >No Data Found</router-link
                >

                <!-- <div class="flex flex-col border gap-1 py-2 px-4 rounded-lg">
                  <p class="font-semibold text-xs">New course added for you!</p>
                  <p class="font-semibold text-xs">11/02/2025</p>
                </div>
                <div class="flex flex-col border gap-1 py-2 px-4 rounded-lg">
                  <p class="font-semibold text-xs">New course added for you!</p>
                  <p class="font-semibold text-xs">11/02/2025</p>
                </div> -->
                <!-- <p>No Data Found</p> -->
              </div>
            </div>
          </div>

          <div class="relative dropdown-container">
            <div
              @click="MenuToggle('profile')"
              v-if="isAuthenticated && profileInfo != null"
              class="flex items-center cursor-pointer gap-2"
            >
              <div class="flex items-center">
                <div v-if="profileInfo.data.profile != ''">
                  <img
                    :src="profileInfo.data.profile"
                    alt=""
                    class="w-10 h-10 rounded-full border-2 border-white"
                  />
                </div>
                <div v-else>
                  <img
                    src="@/assets/new/image.png"
                    alt=""
                    class="w-10 h-10 rounded-full border-2 border-white"
                  />
                </div>
              </div>
            </div>

            <div
              class="absolute right-0 top-14 lg:top-20 z-50 bg-white rounded-2xl shadow-lg"
              style="width: 180px"
              v-if="toggleMenuName == 'profile'"
            >
              <!-- <div class="flex items-center gap-2 py-3 px-6 cursor-pointer">
                <img
                  src="@/assets/new/profile.png"
                  width="20"
                  height="20"
                  alt=""
                />

                <router-link
                  :to="{ name: 'StudentHome' }"
                  class="text-sm font-medium"
                  style="color: #333333 !important"
                  >Profile</router-link
                >
              </div> -->

              <router-link
                  :to="{ name: 'InstructorProfile' }" class="flex items-center gap-2 py-3 px-4 text-sm cursor-pointer">
                <img
                  src="@/assets/new/profile.png"
                  class="w-5 h-5"
                  alt="Profile"
                />
                <div>
                  <span
                    class="font-semibold"
                    :class="{
                      'text-[#0085db]': $route.name === 'InstructorProfile',
                      'text-gray-800': $route.name !== 'InstructorProfile',
                    }"
                    >Profile</span
                  >
                  <!-- <span>62135458</span>
                  <span>Compt Science</span> -->
                </div>
              </router-link>

              <hr>

              <router-link
                :to="{ name: 'InstructorCourses' }"
                class="text-sm font-medium flex items-center gap-2 py-3 px-6 cursor-pointer"
              >
                <!-- <img
                  src="@/assets/new/profile.png"
                  width="20"
                  height="20"
                  alt=""
                /> -->

                <p>Courses</p>
              </router-link>

              <hr />

              <div
                class="flex items-center gap-2 py-3 px-6 cursor-pointer"
                @click="
                  LogOutModal = true;
                  toggleMenuName = '';
                "
              >
                <img
                  src="@/assets/new/logout.png"
                  width="15"
                  height="15"
                  alt=""
                />
                <p
                  class="text-sm text-[#F87474] font-semibold"
                  style="color: #f87474 !important"
                >
                  Logout
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="lg:hidden md:hidden relative dropdown-container">
          <button
            @click="MenuToggle('mobile_menu')"
            class="text-white focus:outline-none"
          >
            <svg
              class="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M4 6h16M4 12h16m-7 6h7"
              ></path>
            </svg>
          </button>
          <div
            v-if="toggleMenuName == 'mobile_menu'"
            class="absolute right-6 top-20 z-50 bg-white rounded-2xl shadow-lg lg:hidden"
            style="width: 180px"
          >
            <router-link
              :to="{ name: 'InstructorNewCourses' }"
              class="text-sm font-medium flex items-center gap-2 py-3 px-6 cursor-pointer"
              :class="{
                'text-[#0085db]': $route.name === 'InstructorNewCourses',
                'text-black': $route.name !== 'InstructorNewCourses',
              }"
            >
              New Course</router-link
            >

            <hr />

            <router-link
              :to="{ name: 'InstructorCourses' }"
              class="text-sm font-medium flex items-center gap-2 py-3 px-6 cursor-pointer"
              :class="{
                'text-[#0085db]': $route.name === 'InstructorCourses',
                'text-black': $route.name !== 'InstructorCourses',
              }"
            >
              Courses</router-link
            >

            <hr />

            <router-link
              :to="{ name: 'InstructorBlogsView' }"
              class="text-sm font-medium flex items-center gap-2 py-3 px-6 cursor-pointer"
              :class="{
                'text-[#0085db]': $route.name === 'InstructorBlogsView',
                'text-black': $route.name !== 'InstructorBlogsView',
              }"
            >
              New Blog</router-link
            >

            <hr />

            <router-link
              :to="{ name: 'InstructorBlogsList' }"
              class="text-sm font-medium flex items-center gap-2 py-3 px-6 cursor-pointer"
              :class="{
                'text-[#0085db]': $route.name === 'InstructorBlogsList',
                'text-black': $route.name !== 'InstructorBlogsList',
              }"
            >
              Blogs</router-link
            >
            <hr />
            <div>
              <button
                @click="
                  this.invitecourse = true;
                  toggleMenuName = '';
                "
                class="text-sm font-medium flex items-center gap-2 py-3 px-6 cursor-pointer"
                :class="{
                  ' text-[#0085db]': invitecourse,
                  ' text-black': !invitecourse,
                }"
              >
                Invite Course
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- invite course popup start -->
    <div
      v-if="this.invitecourse"
      @click.self="this.invitecourse = false"
      class="fixed bg-primary bg-opacity-30 top-0 left-0 right-0 bottom-0 z-50 flex items-center justify-center"
    >
      <div class="md:w-[40%] 2xl:w-[35%] bg-white rounded-xl p-10">
        <div class="flex items-center justify-center">
          <h3 class="text-xl font-bold text-blues text-center">
            Invite Course
          </h3>
        </div>

        <div
          class="w-full mt-4 flex flex-col p-6 relative border-gray-100 rounded-2xl"
        >
          <div class="w-full">
            <form
              ref="createTeamRef"
              @submit.prevent="invitepost"
              class="flex flex-col gap-4 text-xs md:text-sm lg:text-base h-full"
            >
              <div class="flex flex-col gap-3 w-full">
                <label for="category" class="flex gap-2 items-center">
                  <div>
                    Course list
                    <span class="text-red-400">*</span>
                  </div>
                </label>
                <select
                  name="course"
                  class="bg-[#F9FAFC] rounded-lg text-sm w-full p-3 border-none outline-none"
                  id="course"
                  required
                >
                  <option value="" disabled selected>Select course</option>
                  <option
                    v-for="course in courselist"
                    :key="course"
                    :value="course.title"
                  >
                    <span> {{ course.title }}</span>
                  </option>
                </select>
              </div>

              <!-- <div class="flex flex-col gap-3 w-full">
                <label for="category" class="flex gap-2 items-center">
                  <div>
                    Student list
                    <span class="text-red-400">*</span>
                  </div>
                </label>

                <select
                  name="studentmail"
                  class="bg-[#F9FAFC] rounded-lg text-sm w-full p-3 border-none outline-none"
                  id="studentmail"
                >
                 <option value="" disabled selected>Select email</option>
                  <option
                    v-for="student in studentlist"
                    :key="student"
                    :value="student.email"
                  >
                    {{ student.email }}
                  </option>
                </select>
              </div> -->

              <div class="flex flex-col gap-3 w-full">
                <label for="studentmail" class="flex gap-2 items-center">
                  <div>Student list <span class="text-red-400">*</span></div>
                </label>

                <input
                  type="text"
                  id="studentmail"
                  v-model="searchQuery"
                  @input="Studentsearch"
                  placeholder="Search email"
                  class="bg-[#F9FAFC] rounded-lg text-sm w-full p-3 border-none outline-none"
                />
                <ul
                  v-if="showdetail == false && this.studentlist.length > 0"
                  class="bg-white w-full border rounded-lg shadow-md h-[150px] overflow-y-auto mt-1"
                >
                  <li
                    v-for="student in studentlist.slice(0, lengthCount)"
                    :key="student.email"
                    class="p-2 cursor-pointer hover:bg-gray-100"
                    @click="selectStudent(student.email)"
                  >
                    {{ student.email }}
                  </li>
                </ul>
              </div>

              <div class="flex justify-center pb-4 mt-3 gap-10">
                <button
                  @click="closepopup"
                  class="border text-gray-500 rounded-lg text-sm px-10 font-semibold py-2"
                >
                  Close
                </button>
                <div class="flex gap-3 items-center">
                  <button
                    :disabled="disableStatus"
                    :class="{
                      'cursor-not-allowed bg-gray-200 text-black':
                        disableStatus === true,
                      'cursor-pointer bg-blues text-white ':
                        disableStatus === false,
                    }"
                    type="submit"
                    class="rounded-lg text-sm px-10 font-semibold py-2"
                  >
                    Invite
                  </button>
                  <div v-if="loaders" class="spinner"></div>
                </div>
              </div>
              <div
                v-if="invite_messages"
                class="text-green-500 font-bold text-left px-2 py-2 rounded-lg"
              >
                {{ invite_messages }}
              </div>
              <div
                v-if="invite_err_messages"
                class="text-red-500 font-bold text-left px-2 py-2 rounded-lg"
              >
                {{ invite_err_messages }}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <!-- invite course popup end -->
    <Logout
      v-if="LogOutModal"
      @logOut="logOut"
      @cancel="LogOutModal = !LogOutModal"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import axios from "axios";
// import $ from "jquery";
// import LogOutPopUp from "@/components/LogOutPopUp";
import Logout from "@/components/Logout.vue";

export default {
  computed: {
    ...mapGetters(["isAuthenticated", "userInfo"]),
  },

  data() {
    return {
      logo_image: require(`${process.env.VUE_APP_COMS_LOGO}`),
      toggleMenuName: null,
      profileInfo: null,
      LogOutModal: false,
      notificationList: [],
      invitecourse: false,
      courselist: [],
      studentlist: [],
      invite_messages: "",
      mobileMenuOpen: false,
      loaders: false,
      disableStatus: false,
      invite_err_messages: "",
      searchQuery: "",
      showdetail: false,
      lengthCount: 10,
    };
  },

  components: {
    Logout,
  },

  created() {
    this.getNotifications();
    this.courselistget();
    this.studentlistget();
    if (this.userInfo) {
      console.log("start");
      let token = this.userInfo.token_key;
      let slug_id = this.userInfo.slug_id;
      axios
        .get(
          `${process.env.VUE_APP_API}/profile_detail/${slug_id}?Key=${token}&format=json`
        )
        .then((response) => (this.profileInfo = response.data));
    } else {
      console.log("Not Login");
    }
  },
  mounted() {
    document.addEventListener("click", this.handleClickOutside);
  },
  beforeUnmount() {
    document.removeEventListener("click", this.handleClickOutside);
  },

  methods: {
    ...mapActions(["logOutUser"]),

    MenuToggle(name) {
      if (this.toggleMenuName == name) {
        this.toggleMenuName = null;
      } else {
        this.toggleMenuName = name;
      }
    },

    async getNotifications() {
      await axios
        .get(
          `${process.env.VUE_APP_API}/notifications/${this.userInfo.user.id}/?Key=${this.userInfo.token_key}`
        )
        .then((response) => {
          if (response.data.status == 200) {
            this.notificationList = response.data.data;
            this.lengthCount = this.notificationList.length;
          }
        });
    },

    async logOut() {
      await this.logOutUser();
      this.LogOutModal = false;
      this.$router.push({
        name: "Login",
      });
    },
    handleClickOutside(event) {
      if (!event.target.closest(".dropdown-container")) {
        this.toggleMenuName = null;
      }
    },
    // courselistget() {
    //   axios
    //     .get(
    //       `${process.env.VUE_APP_API}/course-title-get/?Key=${this.userInfo.token_key}`
    //     )
    //     .then((res) => {
    //       if (res.data.data) {
    //         this.courselist = res.data.data;
    //       }
    //     });
    // },
    studentlistget() {
      axios
        .get(
          `${process.env.VUE_APP_API}/student-invite-email-get/?Key=${this.userInfo.token_key}`
        )
        .then((res) => {
          if (res.data.data) {
            this.studentlist = res.data.data;
            console.log("  this.studentlist", this.studentlist);
          }
        });
    },
    invitepost(el) {
      let bodyVal = {
        email: el.target.elements.studentmail.value,
        course: el.target.elements.course.value,
        user: this.userInfo.user.id,
        status: true,
      };
      console.log("bodyVal", bodyVal);
      this.disableStatus = true;
      this.loaders = true;
      axios
        .post(
          `${process.env.VUE_APP_API}/invitation/?Key=${this.userInfo.token_key}`,
          bodyVal
        )
        .then((response) => {
          if (response.data.status == 200) {
            this.invite_messages = response.data.message;

            this.loaders = false;

            setTimeout(() => {
              this.invite_messages = "";
              this.invitecourse = false;
              this.disableStatus = false;
              this.searchQuery = "";
              this.showdetail == false;
            }, 3000);
          } else if (response.data.status == 400) {
            this.loaders = false;
            this.invite_err_messages = response.data.error;
            setTimeout(() => {
              this.invite_err_messages = "";
              // this.invitecourse = false;
              this.disableStatus = false;
            }, 3000);
          } else {
            this.disableStatus = false;
          }
        })

        .finally(() => {
          this.loaders = false;
        });
    },
    courselistget() {
      axios
        .get(
          `${process.env.VUE_APP_API}/courses/?instructor=${this.userInfo.Instructor_id}`
        )
        .then((res) => {
          if (res.data.data) {
            this.courselist = res.data.data;
            console.log("courselist////", this.courselist);
          }
        });
    },

    Studentsearch() {
      this.showdetail = false;
      axios
        .get(
          `${process.env.VUE_APP_API}/student-invite-email-get/?search=${this.searchQuery}&${this.userInfo.token_key}`
        )
        .then((res) => {
          if (res.data.data) {
            this.studentlist = res.data.data;
            // this.showdetail == this.searchQuery.trim() !== "";
          }
        });
    },
    selectStudent(email) {
      this.searchQuery = email;
      this.showdetail = true;
      // this.invitecourse = false;
    },
    closepopup() {
      this.invitecourse = false;
      this.searchQuery = "";
      this.showdetail = false;
    },
  },
};
</script>

<style scoped>
/* loader  */
.spinner {
  --d: 8.6px;
  width: 1.5px;
  height: 1.5px;
  border-radius: 50%;
  color: #5593e0;
  box-shadow: calc(1 * var(--d)) calc(0 * var(--d)) 0 0,
    calc(0.707 * var(--d)) calc(0.707 * var(--d)) 0 0.5px,
    calc(0 * var(--d)) calc(1 * var(--d)) 0 1px,
    calc(-0.707 * var(--d)) calc(0.707 * var(--d)) 0 1.4px,
    calc(-1 * var(--d)) calc(0 * var(--d)) 0 1.9px,
    calc(-0.707 * var(--d)) calc(-0.707 * var(--d)) 0 2.4px,
    calc(0 * var(--d)) calc(-1 * var(--d)) 0 2.9px;
  animation: spinner-a90wxe 1s infinite steps(8);
}

@keyframes spinner-a90wxe {
  100% {
    transform: rotate(1turn);
  }
}
</style>
