<template>
  <div class="min-h-screen relative">
    <Header />

    <div
      class="min-h-screen container mx-auto max-w-[1200px] w-full mb-10 py-7 flex flex-col gap-10 px-4 md:px-6 lg:gap-16"
    >
      <div>
        <div class="flex flex-row items-center justify-between">
          <p
            class="text-[#0F172A] text-xl sm:text-2xl md:text-3xl font-semibold"
          >
            Complete Course
          </p>
        </div>
        <div
          v-if="loading"
          class="flex items-center justify-center"
          style="height: calc(100vh - 350px) !important"
        >
          <img src="@/assets/newd/loading.gif" alt="" />
        </div>
        <div v-else>
        <div
          class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mt-6"
          v-if="complete_course.length > 0"
        >
          <div
            v-for="course in this.complete_course"
            :key="course.id"
            class="bg-white shadow-lg rounded-lg p-4 sm:p-6 h-auto"
          >
            <div class="relative flex justify-center items-center">
              <img
                v-if="course.thumbnail"
                class="object-cover h-[180px] sm:h-[200px] w-full rounded-lg"
                :src="course.thumbnail"
                alt="Course Thumbnail"
              />
              <img
                v-else
                class="object-cover h-24 w-full rounded-lg"
                src="@/assets/coding.svg"
                alt="Placeholder"
              />
            </div>
            <div class="text-center flex flex-col gap-2 mt-2">
              <p
                class="text-sm sm:text-base text-gray-600 font-semibold text-left truncate"
              >
                {{ course.course_title }}
              </p>

              <div class="flex gap-2 items-center mt-2">
                <div
                  class="relative w-full bg-gray-200 rounded-full h-3 sm:h-4"
                >
                  <div
                    class="absolute left-0 top-0 h-full bg-[#0085DB] rounded-full transition-all duration-300"
                    :style="{ width: course.percentage }"
                  ></div>
                </div>
                <p class="text-sm text-black">{{ course.percentage }}</p>
              </div>

              <router-link
                :to="{
                  name: 'CertificatePage',
                  params: { complete_course_id: course.course_id },
                }"
                target="_blank"
                class="text-sm sm:text-md text-[#0F172A] font-medium"
              >
                <p
                  class="mt-3 py-2 text-xs sm:text-sm rounded-lg border border-[#0085DB] text-[#0F172A] font-medium text-center cursor-pointer hover:bg-[#0085DB] hover:text-white"
                >
                  Preview
                </p>
              </router-link>
            </div>
          </div>
        </div>
        <div
          v-else
          class="flex flex-col justify-center"
          style="height: calc(100vh - 350px) !important"
        >
          <p class="flex justify-center text-black">No Data Found</p>
        </div>
        </div>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
import axios from "axios";
import Footer from "@/components/Footer.vue";
import Header from "@/components/Student/Header.vue";
import { mapGetters } from "vuex";
export default {
  name: "CompleteCourse",
  data() {
    return {
      complete_course: [],
      loading: true,
    };
  },

  components: {
    Header,
    Footer,
  },

  computed: {
    ...mapGetters(["userInfo"]),
  },
  created() {
    this.completecourse();
  },
  methods: {
    async completecourse() {
      await axios
        .get(
          `${process.env.VUE_APP_API}/completed-certificates/?Key=${this.userInfo.token_key}`
        )
        .then((response) => {
          this.complete_course = response.data.data;
          setTimeout(() => {
            this.loading = false;
          }, 600);
          this.complete_course.forEach((element) => {
            element["end_time"] = new Date(element.end_time).toLocaleDateString(
              "en-IN",
              {
                day: "numeric",
                month: "long",
                year: "numeric",
              }
            );
          });
        });
    },
  },
};
</script>

<style></style>
