<template>
  <div>
    <div class="flex">
      <!-- <SideBar /> -->

      <div
        class="lg:flex fixed w-[80%] bg-white lg:w-[35%] xl:w-[30%] 2xl:w-[25%] lg:relative z-50 m-10 p-10 rounded-3xl"
        :class="{ hidden: !isMobile }"
        style="box-shadow: 0px 2px 30px 0px #01b0f926"
      >
        <div class="flex flex-col gap-5 relative w-full h-full overflow-y-auto heighttab">
          <div v-for="section in sectionInfo.data" :key="section">
            <div
              class="activebg flex items-center rounded-2xl py-1 gap-5 justify-between cursor-pointer"
              @click="courseSectionToggle(section.id)"
            >
              <p class="font-bold text-sm pt-3 text-black">
                {{ section.title }}
              </p>
              <img
                src="@/assets/new/upw.png"
                v-if="activeCourseMenu == section.id"
                style="height: 8px !important"
                alt=""
              />
              <img
                src="@/assets/new/downw.png"
                v-if="activeCourseMenu != section.id"
                style="height: 8px !important"
                alt=""
              />
            </div>

            <div
              v-for="(lesson, index) in lessonInfo.results" :key="index"
            >
              <div
                class="my-5 flex flex-col gap-4"
                v-if="section.id == lesson.section_id"
              >
                <div
                  class="flex items-center rounded-2xl gap-3 cursor-pointer"
                  @click="setUrlTap(lesson.video_url, index, lesson)"
                >
                  <img src="@/assets/new/ideo.png" class="mt-1" alt="" />
                  <p class="font-bold text-sm mt-3 py-1 text-black">
                    {{ lesson.title }}
                  </p>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>

      <div
        class="w-full mr-10 mt-10 rounded-3xl overflow-x-auto overflow-y-auto heightfix"
        style="box-shadow: 0px 2px 30px 0px #01b0f926"
      >
        <div class="p-10 flex flex-col gap-10">

            <div v-if="selectedlessonData.video_url != null">
                
                <iframe v-if="selectedlessonData.video_url != ''"
                  class="w-full h-full mx-auto rounded p-3 height500"
                  :src="selectedlessonData.video_url"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                >
                </iframe>

            </div>

          <!-- <div class="flex flex-col lg:flex-row items-center gap-10">
            <div class="lg:w-[50%]">
              <img
                :src="courseDetailInfo[0].thumbnail"
                class="rounded-2xl h-[300px] object-cover w-full"
              />
            </div>

            <div class="flex flex-col gap-3 lg:w-[50%]">
              <h1 class="text-3xl text-primary font-bold">
                {{ courseDetailInfo[0].title }}
              </h1>

              <div class="flex items-center gap-10">
                <div class="flex items-center rounded-2xl py-3 gap-2">
                  <img src="@/assets/new/rounded.png" alt="" />
                  <p class="text-xs">
                    {{ courseDetailInfo[0].sections.length }} Units
                  </p>
                </div>
                <div class="flex items-center rounded-2xl py-3 gap-2">
                  <img src="@/assets/new/time.png" alt="" />
                  <p class="text-xs">18h 20m Left</p>
                </div>
              </div>

              <div class="flex items-center justify-between w-full">
                <div>
                  <p class="text-sm">In progress</p>
                </div>
                <div>
                  <p class="text-sm">45%</p>
                </div>
              </div>

              <div class="relative w-full bg-gray-200 rounded-full h-4">
                <div
                  class="absolute left-0 top-0 h-4 activebg rounded-full transition-all duration-300"
                  :style="{ width: progress + '%' }"
                ></div>
              </div>
            </div>
          </div>

          <div class="flex flex-col gap-4 px-2">
            <h2 class="text-2xl text-primary font-bold">Course Description</h2>
            <p>{{ this.courseDetailInfo[0].short_description }}</p>
          </div>

          <div class="flex flex-col gap-10 px-2">
            <h2 class="text-2xl text-primary font-bold">Course Content</h2>
            <div class="flex flex-col gap-4">
              <div v-for="section in sectionInfo.data" :key="section">
              </div>
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SideBar from "@/components/Student/SideBar.vue";
import axios from "axios";
import { mapGetters } from "vuex";

export default {
  components: {
    SideBar,
  },
  data() {
    return {
      messageVal: null,
      totalmessage: null,
      studentList: "",
      selectedStudent: null,
      ChatpopUp: false,
      instructorChat: [
        {
          id: "",
          display_name: "",
        },
      ],
      sectionInfo: "",
      lessonInfo: "",
      videoLink: "",
      activeIndex: null,
      isLessonTab: false,
      selectedlessonData: null,
      courseDetailInfo: null,
      progress: 90,
      activeCourseMenu: null,
    };
  },

  props: ["course_id", "lesson_id"],

  async created() {
    this.detailsgets();
    await axios
      .get(`${process.env.VUE_APP_API}/courses/?id=${this.course_id}`)
      .then((response) => {
        this.courseDetailInfo = response.data.data;
      });

    await axios
      .get(`${process.env.VUE_APP_API}/coursesdata/${this.course_id}/`)
      .then((response) => {
        this.instructorChat[0].id = response.data.user_id;
      });
    await axios
      .get(`${process.env.VUE_APP_API}/courses/?id=${parseInt(this.course_id)}`)
      .then((response) => {
        this.instructorChat[0].display_name = response.data.data[0].instructor;
      });

    this.getCompletedLesson();
  },

  computed: {
    ...mapGetters(["userInfo"]),
  },

  methods: {
    convertHttpToHttps(url) {
      if (url.startsWith("http://")) {
        return url.replace("http://", "https://");
      }
      return url;
    },

    async getCompletedLesson() {
      await axios
        .get(
          `${process.env.VUE_APP_API}/completed-lesson-tracking/${this.userInfo.user.id}/${this.course_id}/?Key=${this.userInfo.token_key}`
        )
        .then((response) => {
          if (response.data.status == 200) {
            console.log(response.data.data, "data:::");
          } else {
            console.log("Something went wrong::");
          }
        });
    },

    async completeUnit(unit_id, lesson_id) {
      await axios
        .post(
          `${process.env.VUE_APP_API}/lesson-tracking/${this.userInfo.user.id}/${unit_id}/${lesson_id}/?Key=${this.userInfo.token_key}`,
          {}
        )
        .then((response) => {
          if (response) {
            console.log(response);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    courseSectionToggle(id) {
      if (this.activeCourseMenu == id) {
        this.activeCourseMenu = null;
      } else {
        this.activeCourseMenu = id;
      }
    },

    prevCourse() {
      if (this.activeIndex > 0) {
        this.activeIndex = this.activeIndex - 1;
        this.selectedlessonData = this.lessonInfo.results[this.activeIndex];
        this.videoLink = this.lessonInfo.results[this.activeIndex].video_url;
      }
    },

    nextCourse() {
      if (this.activeIndex < this.lessonInfo.results.length - 1) {
        this.activeIndex = this.activeIndex + 1;
        this.selectedlessonData = this.lessonInfo.results[this.activeIndex];
        this.videoLink = this.lessonInfo.results[this.activeIndex].video_url;
      }
    },

    setUrlTap(url, index, lesson) {
      this.isLessonTab = true;
      this.videoLink = url;
      this.activeIndex = index;
      this.selectedlessonData = lesson;
    },

    setUrl(url, index, lesson) {
      this.videoLink = url;
      this.activeIndex = index;
      this.selectedlessonData = lesson;
    },

    async detailsgets() {
      await axios
        .get(
          `${process.env.VUE_APP_API}/section/?course_name=${this.course_id}`
        )
        .then((response) => {
          this.sectionInfo = response.data;
        });

      await axios
        .get(
          `${process.env.VUE_APP_API}/lessons/?course_name=${this.course_id}`
        )
        .then((response) => {
          this.lessonInfo = response.data;
          if (Object.keys(this.lessonInfo) == "error") {
            this.lessonCount = 0;
          } else {
            this.lessonCount = this.lessonInfo.count;
          }
        });
    },
  },
};
</script>

<style scoped>

.heighttab {
  height: calc(100vh - 250px) !important;
}

.heightfix {
  height: calc(100vh - 170px) !important;
}
</style>
