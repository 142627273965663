<template>
  <div>
    <Header />
    <FlashMessage v-if="this.messages.text != ''" :message="this.messages" />

    <div class="py-10">
      <div class="container px-6 mx-auto max-w-[1200px] flex flex-col gap-10">
        <div>
          <form ref="blogRef" class="rounded-3xl pt-10 px-10 pb-2 heightfix overflow-y-auto"
            style="box-shadow: 0px 2px 20px 0px #105c8e26" @submit.prevent="updateDetails">
            <div class="flex items-center justify-between">
              <div>
                <h1 class="py-2 text-xl text-center font-bold text-blues">
                  Edit Blog
                </h1>
              </div>

             
            </div>

            <div class="py-10 flex flex-col gap-10">
              <div class="flex flex-col lg:flex-row items-center gap-5 w-full">
                <div class="flex flex-col gap-3 w-full">
                  <label for="title" class="text-sm" style="">
                    Title
                    <span class="text-red-400">*</span>
                  </label>
                  <input id="title" type="text" v-model="bloglist.title" required
                    class="bg-[#F9FAFC] rounded-lg text-sm w-full p-3 border-none outline-none" />
                </div>
                <div class="flex flex-col gap-3 w-full">
                  <label for="category" class="flex gap-2 items-center">
                    <div>
                      Category
                      <span class="text-red-400">*</span>
                    </div>

                    <svg @click="is_CategoryPopup = true" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                      class="w-5 h-5 cursor-pointer" viewBox="0 0 48 48">
                      <path fill="#4caf50"
                        d="M44,24c0,11.045-8.955,20-20,20S4,35.045,4,24S12.955,4,24,4S44,12.955,44,24z"></path>
                      <path fill="#fff" d="M21,14h6v20h-6V14z"></path>
                      <path fill="#fff" d="M14,21h20v6H14V21z"></path>
                    </svg>
                  </label>

                  <select name="category" required
                    class="bg-[#F9FAFC] rounded-lg text-sm w-full p-3 border-none outline-none" id="category">
                    <option v-for="category in categories" :key="category" :value="category.id">
                      {{ category.name }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="flex flex-col gap-3 w-full">
                <label for="title" class="text-sm" style="">
                  Thumbnail
                  <span class="text-red-400">*</span>
                </label>
                <img v-if="bloglist.thumbnail" :src="bloglist.thumbnail" class="w-[150px] h-[100px] rounded-lg"
                  alt="" />
                <input id="thumbnail" type="file" @change="handleFileUpload"
                  class="bg-[#F9FAFC] rounded-lg text-sm w-full p-3 border-none outline-none" />
              </div>
              <div class="flex flex-col gap-3 w-full">
                <label for="title" class="text-sm" style="">
                  Description
                  <span class="text-red-400">*</span>
                </label>
                <QuillEditor style="height: 300px" theme="snow" class="border rounded-2xl" toolbar="full"
                  :content="bloglist.content" @update:content="bloglist.content = $event" contentType="html" />
              </div>

              <div class="flex items-center gap-2 py-10">
                <input type="checkbox" id="Published" v-model="bloglist.published" />
                <label for="checkbox" class="text-sm">Published</label>
              </div>

              
              <div class="flex gap-3 flex-wrap">
                <button type="submit"
                  class="text-greens border border-greens rounded-lg text-lg font-semibold px-6 py-1.5">
                  Save
                </button>
                <router-link :to="{ name: 'InstructorBlogsList' }"
                  class="bg-blues text-white rounded-lg text-lg font-semibold px-6 py-1.5">Back</router-link>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

    <!-- Category Add in Course  -->
    <div v-if="is_CategoryPopup"
      class="fixed top-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center" style="z-index: 5001">
      <div class="w-5/6 lg:w-2/3 h-1/3 2xl:w-1/3 2xl:h-1/5 bg-white relative flex flex-col justify-center py-10">
        <div @click="is_CategoryPopup = false" class="absolute right-8 top-8 cursor-pointer z-50">
          <div class="mb-1 rounded-lg flex border cursor-pointer">
            <div class="px-1 md:px-2 py-1 text-sm md:text-base text-gray-700 hover:text-red-500">
              Close
            </div>
            <img class="h-5 w-5 md:h-8 md:w-8" src="@/assets/closebutton.svg" />
          </div>
        </div>
        <h4 class="text-gray-700 text-center mt-10">Add Category</h4>
        <form class="w-11/12 mx-auto pb-10" @submit.prevent="categoryAdd">
          <div class="flex flex-col gap-3">
            <div>
              <label for="title">
                Title
                <span class="text-red-400">*</span>
              </label>
              <input id="categorytitle" type="text" required
                class="border border-gray-300 rounded-md text-sm w-full p-2" />
            </div>
            <div class="flex justify-end">
              <button type="submit" class="text-white bg-blues rounded-lg text-sm px-10 font-semibold py-2">
                Add Category
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Instructor/Header.vue";
import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import axios from "axios";
import { mapGetters } from "vuex";
import FlashMessage from "@/components/FlashMessage.vue";

export default {
  components: {
    Header,
    QuillEditor,
    FlashMessage,
  },

  data() {
    return {
      bloglist: [],
      messages: {
        text: "",
        type: "null",
      },
      categories: [],
    };
  },

  props: ["blog_id"],
  computed: mapGetters(["userInfo"]),

  async created() {
    this.fetchBlogs();
    this.categoryget();
  },
  methods: {
    fetchBlogs() {
      axios
        .get(
          `${process.env.VUE_APP_API}/blog-details/deleted/${this.blog_id}/?Key=${this.userInfo.token_key}`
        )
        .then((res) => {
          if (res.data.data) {
            this.bloglist = res.data.data;
          }
        })
        .catch((error) => {
          console.error("Error fetching blogs:", error);
        });
    },

    categoryget() {
      axios
        .get(
          `${process.env.VUE_APP_API}/blog-category/list/?Key=${this.userInfo.token_key}`
        )
        .then((res) => {
          if (res.data.data.data) {
            this.categories = res.data.data.data;
          }
        });
    },
    handleFileUpload(event) {
      this.thumbnailFile = event.target.files[0];
    },

    async updateDetails(e) {
      //   this.description = document.querySelector(".ql-editor").innerHTML;
      //   console.log(this.description);

      //   let thumbnailVal = null;
      //   if (e.target.elements.thumbnail.files.length > 0) {
      //     thumbnailVal = e.target.elements.thumbnail.files[0];
      //   }
      //   let updatedBlogInfo = {
      //     title: e.target.elements.title.value,
      //     published: e.target.elements.Published.checked,
      //     content: this.description,
      //     category_id: parseInt(e.target.elements.category.value),
      //     bolger_id: this.userInfo.user.id,
      //     thumbnail: this.thumbnailVal,
      //   };
      this.description = document.querySelector(".ql-editor").innerHTML;
      console.log("Updated Description:", this.description);

      let updatedBlogInfo = new FormData();
      updatedBlogInfo.append("title", e.target.elements.title.value);
      updatedBlogInfo.append("published", e.target.elements.Published.checked);
      updatedBlogInfo.append("content", this.description);
      updatedBlogInfo.append(
        "category_id",
        parseInt(e.target.elements.category.value)
      );
      updatedBlogInfo.append("bolger_id", this.userInfo.user.id);

      if (this.thumbnailFile) {
        updatedBlogInfo.append("thumbnail", this.thumbnailFile);
      }

      await axios
        .patch(
          `${process.env.VUE_APP_API}/blog-details/deleted/${this.blog_id}/?Key=${this.userInfo.token_key}`,
          updatedBlogInfo
        )
        .then((response) => {
          this.res = response;
          if (this.res.data.status == 200) {
            this.messages = {
              text: "Blogs Updated successfully",
              type: "success",
            };
            console.log("successful");
          } else {
            this.messages = {
              text: " Event Faild",
              type: "error",
            };
            console.log("not successful");
          }
        });

      setTimeout(() => {
        this.messages = {
          text: "",
          type: "null",
        };
        this.$router.push({
          name: "InstructorBlogsList",
        });
      }, 2500);
    },
  },
};
</script>

<style></style>
