<template>
  <div>
    <!-- <Header /> -->

    <FlashMessage v-if="this.messages.text != ''" :message="this.messages" />
    <!-- <confirmPopup v-if='popupwindow==true' :title="titlepass" @cancelClick="popupwindow=false" @confirmClick="deleteAssignment(idpass)" /> -->

    <div class="flex">
      <SideBar />

      <div
        class="w-[90%] mx-auto lg:w-full lg:mr-10 mt-10 rounded-3xl overflow-x-auto overflow-y-auto heightfix"
        style="box-shadow: 0px 2px 30px 0px #01b0f926"
      >
        <div class="px-5 lg:px-10 pt-8 pb-4">
          <div class="flex items-center justify-between flex-wrap lg:flex-nowrap">
            <div>
              <h1 class="text-lg font-semibold">Assignments</h1>
            </div>
            <div>
              <button
                @click="this.assignmentPopup = true"
                class="bg-blues text-white font-semibold text-xs px-4 py-2 rounded-xl"
              >
                Add Assignemt
              </button>
            </div>
          </div>

          <div class="h-full">
            <div
          v-if="loading"
          class="flex items-center justify-center"
          style="height: calc(100vh - 350px) !important"
        >
          <img src="@/assets/newd/loading.gif" alt="" />
        </div>
            <div class="grid lg:grid-cols-3 gap-10 mt-5 overflow-y-auto" v-if="assignmentInfo != null && !loading" >
              <div
                v-for="assignment in assignmentInfo.data"
                :key="assignment"
                class="border rounded-2xl px-5 py-5 flex flex-col gap-2"
              >
                <router-link :to="{ name: 'InstructorAssignmentsSubmitted', params: { 'assignment_id': assignment.id } }" class="flex flex-col gap-2">
                  <!-- {{ assignment.id }} ::: assignment id -->

                  <img
                    class="rounded-xl"
                    src="@/assets/new/assignment.png"
                    style="width: 100%"
                    alt=""
                  />

                  <p class="text-secondary px-1 text-sm mt-3">
                    <span class="font-bold text-primary">Title:</span>
                    {{ assignment.title.slice(0, 125) }}
                  </p>
                  <p class="text-secondary px-1 text-sm">
                    <span class="font-bold text-primary">Description:</span>
                    {{ assignment.description.slice(0, 300) }}
                  </p>
                </router-link>
              </div>
            </div>
             <div v-else class=" flex flex-col justify-center"  style="height: calc(100vh - 350px) !important">
            <p class="flex justify-center">No Data Found</p>
          </div>
          </div>

          <div
            v-if="this.assignmentPopup"
            class="fixed bg-primary bg-opacity-30 top-0 left-0 right-0 bottom-0 z-50 flex items-center justify-center"
          >
            <div
              class="lg:w-[40%] bg-white rounded-xl p-10 overflow-y-auto max-h-[600px]"
            >
              <div class="flex items-center justify-between">
                <h3 class="text-xl font-bold text-blues">Add Assignments</h3>
                <img
                  src="@/assets/newd/close.png"
                  class="cursor-pointer"
                  alt=""
                  @click="this.assignmentPopup = false"
                />
              </div>

              <div
                class="w-full mt-4 flex flex-col p-6 relative border border-gray-100 rounded-2xl"
              >
                <div class="w-full">
                  <form
                    ref="addassignment"
                    @submit.prevent="scheduleAssignment"
                    class="flex flex-col gap-4 text-xs md:text-sm lg:text-base h-full"
                  >
                    <div
                      class="flex flex-col gap-2 w-full text-xs md:text-sm lg:text-base"
                    >
                      <div class="w-full flex flex-col gap-2">
                        <label for="title" class="text-left">Title</label>
                        <input
                          id="title"
                          type="text"
                          class="border border-gray-100 rounded text-xs md:text-sm lg:text-base py-2 px-2"
                        />
                      </div>
                      <div class="w-full flex flex-col gap-2">
                        <label for="duedate" class="text-left"
                          >Submit date</label
                        >
                        <input
                          id="duedate"
                          type="date"
                          class="border border-gray-100 rounded text-xs md:text-sm lg:text-base py-2 px-2"
                        />
                      </div>
                    </div>
                    <div class="flex flex-col w-full gap-2">
                      <label for="description" class="text-left"
                        >Description</label
                      >
                      <textarea
                        id="description"
                        rows="3"
                        cols="10"
                        class="border border-gray-100 h-full rounded text-xs md:text-sm lg:text-base py-2 px-2"
                      />
                    </div>
                    <div class="flex flex-col w-full">
                      <label for="attachments" class="text-left"
                        >Attachments *</label
                      >
                      <input
                        id="files"
                        type="file"
                        class="border bg-white border-gray-100 py-2 px-2 w-full rounded text-xs md:text-sm lg:text-base"
                        @change="inputchange"
                        required
                      />
                    </div>
                    <div class="flex justify-end pb-4 mt-3">
                      <button
                        type="submit"
                        class="text-black border border-black rounded-lg text-sm px-10 py-2"
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Instructor/Header.vue";
import SideBar from "@/components/Instructor/SideBar.vue";

import ConfirmPopup from "@/components/ConfirmPopup.vue";
import FlashMessage from "@/components/FlashMessage.vue";


import axios from "axios";
import { mapGetters } from "vuex";

export default {
  components: {
    Header,
    SideBar,
    FlashMessage
  },
  data() {
    return {
      assignmentInfo: null,
      messages: {
        text: "",
        type: "null",
      },
      popupwindow: false,
      titlepass: null,
      idpass: null,
      assignmentPopup: false,
      assignmentEditPopup: false,
      editvalues: null,
      editOptions: false,
      loading:true,
    };
  },
  created() {
    this.getdetails();
  },

  props: ["course_id"],

  computed: {
    ...mapGetters(["userInfo"]),
  },
  methods: {
    async scheduleAssignment(el) {
      let fileVal = el.target.elements.files.files[0];
      let urls = `${process.env.VUE_APP_API}/assignment/new/?Key=${this.userInfo.token_key}`;
      let formData = new FormData();
      formData.append("file", fileVal);
      formData.append("title", el.target.elements.title.value);
      formData.append("description", el.target.elements.description.value);
      formData.append("duedate", el.target.elements.duedate.value);
      formData.append("assig_status", "false");
      formData.append("instructor_id", this.userInfo.Instructor_id);
      formData.append("organizes_id", this.userInfo.organizes_id);
      formData.append("course_id", this.course_id);

      await axios
        .post(urls, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.log(response.data);
          if (response.data.message == "your Assignment added successfully") {
            this.messages = {
              text: "Assignment Added successfully",
              type: "success",
            };
            setTimeout(() => {
              this.messages = {
                text: "",
                type: "null",
              };
              this.$refs.addassignment.reset();
              this.assignmentPopup = false;
              this.getdetails();
            }, 1500);
          } else {
            this.messages = {
              text: " Event Faild",
              type: "error",
            };
          }
        });
    },
    async getdetails() {
      await axios
        .get(
          `${process.env.VUE_APP_API}/Inst_Assignment/view/?instructor_id=${this.userInfo.Instructor_id}&course_id=${this.course_id}&Key=${this.userInfo.token_key}`
        )
        .then((response) => {
          if(response.data.data){

          
          if (Object.keys(response.data) == "error") {
            console.log("Null");
            this.assignmentInfo = response.data;
          } else {
            this.assignmentInfo = response.data;
            this.assignmentInfo.data.forEach((element) => {
              element["display_date"] = new Date(
                element.duedate
              ).toLocaleDateString("en-IN", {
                day: "numeric",
                month: "long",
                year: "numeric",
              });
            });
          }
          }
        })
        
        .finally(() => {
          setTimeout(() => {
            this.loading = false;
          }, 1000);
        });
    },
  },
};
</script>


<style scoped>

.heightfix{
  height: calc(100vh - 170px) !important;
}

</style>

