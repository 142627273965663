<template>
  <div class="w-full h-full">
    <FlashMessage v-if="this.messages.text != ''" :message="this.messages" />

    <div class="w-full max-w-7xl mx-auto flex flex-col mt-10 gap-4 h-full">
      <div
        v-if="dataval == null"
        class="max-w-4xl w-full rounded-3xl mx-auto flex items-center justify-center"
      >
        <div class="w-full h-full flex flex-col items-center justify-center">
          <div class="h-full w-full mt-10">
            <div
              v-if="questions.length > 0"
              class="w-full h-full flex flex-col justify-center items-center"
            >
              <div class="w-full h-auto flex flex-col justify-center">
                <div
                  class="text-center text-lg font-medium"
                  style="color: #01b0f9"
                >
                  Question {{ currentQuestion + 1 }} / {{ questions.length }}
                </div>

                <div
                  class="border-red-400 bg-indigo-900 h-0.5 my-10"
                  :max="30"
                  :value="countDown"
                ></div>
                <div v-if="questions[currentQuestion].image">
                  <img
                    class="w-full h-full"
                    :src="questions[currentQuestion].image"
                  />
                </div>
                <div class="text-center py-4">
                  <p v-html="questions[currentQuestion].question_text"></p>
                </div>
                <div class="flex flex-wrap justify-center gap-4 w-full">
                  <button
                    style="border: 1px solid #01b0f9"
                    class="border rounded py-3 px-4 w-5/12 relative text-sm hover:bg-blue-900 hover:text-white"
                    :class="[
                      questions[currentQuestion].ansclk == option.id
                        ? 'bg-blue-900 text-white border-gray-400'
                        : '',
                    ]"
                    :key="index"
                    v-for="(option, index) in questions[currentQuestion]
                      .coursequizanswer_set"
                    @click="handleAnswerClick(option.is_correct, option.id)"
                  >
                    {{ option.answer_text }}
                  </button>
                </div>
              </div>
            </div>

            <div
              v-else
              class="w-full h-32 flex flex-col justify-center items-center max-h-[358px] text-xl"
            >
              {{ this.message }}
            </div>
            <div
              v-if="questions.length > 0"
              class="max-w-4xl bg-gray-200 overflow-x-auto"
              style="min-width: 360px"
            >
              <!-- <ul class="flex gap-1 py-2 justify-center">
                    <li
                      v-for="(i, index) in questions.length"
                      :key="i"
                      class="
                        border
                        px-2
                        bg-white
                        rounded
                        hover:bg-indigo-900 hover:text-white
                        align-middle
                        text-sm
                        w-12
                        h-9
                        leading-[40px]
                      "
                      :class="[
                        currentQuestion == index ? 'bg-indigo-900 text-white' : '',
                      ]"
                      @click="currentQuestion = index"
                    >
                      {{ index + 1 }}
                    </li>
                </ul>-->
            </div>
            <div
              v-if="questions.length > 0"
              class="mt-20 pb-5 flex gap-10 justify-center"
            >
              <button
                v-if="currentQuestion > 0"
                class="gradient-btn"
                @click="pvsQt"
              >
                Previous
              </button>
              <button
                v-if="currentQuestion + 1 < this.questions.length"
                class="gradient-btn"
                @click="nxtQt"
              >
                Next
              </button>
              <button
                v-if="currentQuestion + 1 == this.questions.length"
                @click="ckans"
                class="gradient-btn"
              >
                Submit
              </button>
            </div>
            <div v-else>
              <!-- <router-link :to="{ name: 'home' }">
                  <button class="gradient-btn">Home</button>
                </router-link>-->
            </div>
          </div>
        </div>
      </div>

      <div class="overflow-y-auto pb-10" v-if="dataval != null">
        <!-- <div class="flex items-center gap-5"> 
            <button
              v-if="
                currentAnsweredQuestion > 0
              "
              class="bg-primary text-white font-semibold px-4 py-1.5 text-xs rounded-xl"
              @click="pvsQtSubmitted"
            >
              Prev
            </button>
  
            <button
              v-if="
                currentAnsweredQuestion + 1 <
                dataval.coursequizusersanswer_set.length
              "
              class="bg-primary text-white font-semibold px-4 py-1.5 text-xs rounded-xl"
              @click="nxtQtSubmitted"
            >
              Next
            </button>
          </div> -->

        <div
          v-for="(el, index) in dataval.coursequizusersanswer_set"
          :key="el"
          class="border max-w-4xl mx-auto mt-4 text-start p-10 rounded-3xl"
        >
          <p class="py-2 font-bold">
            {{ index + 1 }}) {{ el.question.question_text }}
          </p>

          <div
            v-for="(al, index) in el.question.coursequizanswer_set"
            :key="al"
          >
            <p
              class
              :class="[al.is_correct == true ? 'text-blue-900 font-bold' : '']"
            >
              {{ index + 1 }}) {{ al.answer_text }}
              <br />
            </p>
          </div>

          <div class="px-6 mt-6 py-6 rounded-2xl border my-2">
            <h1
              class="px-5 lg:px-5 py-2 lg:py-4 items-center border-gray-200 bg-gray-100 text-xs font-semibold rounded-2xl text-gray-600 tracking-wider"
            >
              Your Answer :
            </h1>
            <p
              v-if="el.answer"
              class="px-4 text-sm font-bold mt-2"
              :class="[
                el.answer.is_correct == true
                  ? 'text-green-700'
                  : 'text-red-400',
              ]"
            >
              {{ el.answer.answer_text }}
            </p>
            <p v-else class="px-4 text-sm font-bold">Not attend</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

import axios from "axios";
import { mapActions, mapGetters } from "vuex";
import FlashMessage from "@/components/FlashMessage.vue";

// import router from "../../router";
export default {
  name: "testMCqview",
  data() {
    return {
      currentQuestion: 0,
      countDown: 840,
      showScore: false,
      questions: [],
      tst: false,
      score: 0,
      is_completed: false,
      is_submit: false,
      nextquz: 0,
      message: "",
      mcqset: "",
      dataval: null,
      answeredData: "",
      currentAnsweredQuestion: 0,
      messages: {
        text: "",
        type: "null",
      },
      // quiz_details: [],
      // currentQuizDetails: ""
    };
  },
  props: ["quiz_id", "taker_id", "course_id"],

  computed: mapGetters([
    "isAuthenticated",
    "userInfo",
    "profileInfo",
    "cartInfo",
    "courseTrackerInfo",
    "subscriptionInfo",
  ]),
  components: {
    FlashMessage,
  },

  async created() {
    this.mcqCall();
    this.tst = false;
    this.countDownTimer();

    let token = this.userInfo.token_key;

    // this.getQuiz();

      console.log(this.taker_id, "this.taker_id::");

      if (this.taker_id != null) {
        axios
          .get(
            `${process.env.VUE_APP_API}/quiztaker__quizzes_pk/${this.taker_id}/?Key=${token}`
          )
          .then((response) => {
            if (response.data.status == 200) {
              this.dataval = response.data.data;
            }
            console.log("response:: test", response.data.message);
          });
      }
    // this.quizPreview();
  },

  mounted() {
    //
  },

  methods: {
    async mcqCall() {
      // course_quizzes_pk
      let token = this.userInfo.token_key;
      await axios
        .get(
          `${process.env.VUE_APP_API}/course_quizzes_pk/${this.quiz_id}/?Key=${token}`
        )
        .then((r) => {
          this.mcqset = r.data.data[0];
          console.log(this.mcqset.quiz.coursequizquestion_set);
          this.questions = r.data.data[0].quiz.coursequizquestion_set;

          if (this.questions) {
            this.questions.forEach((el) => {
              el.ansclk = "";
              el.anr = false;
            });
          }
        });
    },

    handleAnswerClick(isCorrect, id) {
      this.questions[this.currentQuestion].ansclk = id;
      this.questions[this.currentQuestion].anr = isCorrect;
      let nextQuestion = this.currentQuestion + 1;

      if (nextQuestion < this.questions.length) {
        this.currentQuestion = nextQuestion;
      }
    },
    countDownTimer() {
      if (this.countDown > 0) {
        this.timer = setTimeout(() => {
          this.countDown -= 1;
          this.countDownTimer();
        }, 1000);
      }
    },
    submitMcq() {
      this.showScore = true;
      localStorage.setItem("mcqTstv", true);
      this.tst = true;
    },
    nxtQt() {
      let nextQuestion = this.currentQuestion + 1;
      if (nextQuestion < this.questions.length) {
        this.currentQuestion = nextQuestion;
      }
    },
    pvsQt() {
      if (this.currentQuestion > 0) {
        let nextQuestion = this.currentQuestion - 1;
        if ((nextQuestion) => 0) {
          this.currentQuestion = nextQuestion;
        }
      }
    },

    nxtQtSubmitted() {
      if (
        this.currentAnsweredQuestion <
        this.dataval.coursequizusersanswer_set.length
      ) {
        this.currentAnsweredQuestion = this.currentAnsweredQuestion + 1;
      }
      // let nextQuestion = this.currentQuestion + 1;
      // if (nextQuestion < this.questions.length) {
      //   this.currentQuestion = nextQuestion;
      // }
    },

    pvsQtSubmitted() {
      if (this.currentAnsweredQuestion > 0) {
        this.currentAnsweredQuestion = this.currentAnsweredQuestion - 1;
      }
      // if (this.currentQuestion > 0) {
      //   let nextQuestion = this.currentQuestion - 1;
      //   if ((nextQuestion) => 0) {
      //     this.currentQuestion = nextQuestion;
      //   }
      // }
    },

    // async quizPreview() {
    //   // this.is_popupview = true;
    //   let token = this.userInfo.token_key;
    //   await axios
    //     .get(
    //       `${process.env.VUE_APP_API}/quiztaker__quizzes_pk/${this.taker_id}/?Key=${token}`
    //     )
    //     .then((response) => {
    //       if (response.data.status == 200) {
    //         this.dataval = response.data.data;
    //       }
    //       console.log("response:: test", response.data.message);
    //     });
    // },

    // async quizPreview(id) {
    //   let token = this.userInfo.token_key;
    //   await axios
    //     .get(
    //       `${process.env.VUE_APP_API}/quiztaker__quizzes_pk/${id}/?Key=${token}`
    //     )
    //     .then((response) => {
    //       this.dataval = response.data.data;
    //       console.log("response::", response.data.data);
    //     });
    // },

    async getSUbmittedQuiz(id) {

      let token = this.userInfo.token_key;

      axios
        .get(
          `${process.env.VUE_APP_API}/quiztaker__quizzes_pk/${id}/?Key=${token}`
        )
        .then((response) => {
          if (response.data.status == 200) {
            this.dataval = response.data.data;
            console.log(response.data.data, "response.data.data");
          }
          console.log("response:: test", response.data.message);
        });
    },

    async ckans() {
      var ans_set = [];
      let token = this.userInfo.token_key;
      this.questions.forEach((el) => {
        let val = {
          question: el.id,
          answer: el.ansclk,
        };
        ans_set.push(val);
        if (el.anr) {
          this.score += 1;
        }
      });

      this.HeaderVal = {
        score: this.score,
        quiz: this.mcqset.quiz.course,
        quiz_title: this.mcqset.quiz.name,
        usersanswer: ans_set,
        completed: true,
      };
      await axios
        .patch(
          `${process.env.VUE_APP_API}/course_save_multi_answer/?Key=${token}`,
          this.HeaderVal
        )
        .then((response) => {
          console.log("response", response);
         
          // this.quizPreview();

          if (response.data.status == 200) {
            this.messages = {
              text: "Quiz Submitted.",
              type: "success",
            };

            setTimeout(() => {
              this.messages = {
                text: "",
                type: "",
              };
              this.getSUbmittedQuiz(response.data.data);
              // console.log(response.data.data);
              // this.apiId = response.data.data;
              // this.taker_id = response.data.data;
              this.$emit("pushLessonComplete");
              // localStorage.setItem()
              setTimeout(()=>{
                window.location.reload();
              }, 500)
              // 
            }, 2000);

            this.getSUbmittedQuiz(response.data.data);
          }
        });
    },
  },
};
</script>

<style scoped>
.gradient-btn {
  background: #0f172a;
  --tw-shadow: 0 10px 30px -3px rgba(255, 31, 57, 0.1),
    0 10px 30px -6px rgba(255, 31, 57, 0.3);
  transition: all 0.2s linear;
  color: white;
  border: 0px;
  padding: 8px 16px;
  outline: 2px solid transparent;
  outline-offset: 2px;
  border-radius: 0.25rem;
  font-size: 1rem;
  line-height: 1.5rem;
  min-width: 160px;
  font-weight: 700;
}

/* .gradient-btn:hover {
      background: radial-gradient(
        125.44% 4484.38% at 107.67% 116.67%,
        #0b08c4 0,
        #030166 100%
      );
      --tw-shadow: 0 10px 30px -3px rgba(255, 31, 57, 0.1),
        0 10px 30px -6px rgba(255, 31, 57, 0.3);
      color: white;
    } */

/* @media (min-width: 300px) and (max-width: 1024px) {
      .gradient-btn {
        background: radial-gradient(
          125.44% 4484.38% at 107.67% 116.67%,
          #030166 0,
          #0b08c4 100%
        );
        --tw-shadow: 0 10px 30px -3px rgba(255, 31, 57, 0.1),
          0 10px 30px -6px rgba(255, 31, 57, 0.3);
        transition: all 0.2s linear;
        color: white;
        border: 0px;
        padding: 8px 16px;
        font-size: 0.875rem;
        line-height: 1.25rem;
        outline: 2px solid transparent;
        outline-offset: 2px;
        border-radius: 0.25rem;
        font-weight: 700;
      }
    } */
</style>
