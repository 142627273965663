<template>
  <div>
    <!-- <Header /> -->

    <div class="flex">
      <SideBar />

      <!-- {{  studentList }} -->

      <div
        class="w-[90%] mx-auto lg:w-full lg:mr-10 mt-10 rounded-3xl overflow-x-auto overflow-y-auto heightfix"
        style="box-shadow: 0px 2px 30px 0px #01b0f926"
      >
        <div class="py-10 px-8">
          <h2 class="text-xl font-bold text-blues">Skill Gap Analysis</h2>
        </div>

        <div class="px-8">
          <table class="table-auto w-full overflow-x-auto">
            <thead>
              <tr>
                <!-- <th class="px-4 lg:px-7 pb-6 text-center">
                  <div
                    class="bg-blues px-3 py-3 rounded-2xl text-white font-medium text-sm arrow_box"
                  >
                    <p>Name</p>
                  </div>
                </th> -->
                <th class="px-4 lg:px-7 pb-6 text-center">
                  <div
                    class="bg-blues px-3 py-6 font-bold rounded-2xl text-white text-sm arrow_box"
                  >
                    <p>Student</p>
                  </div>
                </th>
                <th class="px-4 lg:px-7 pb-6 text-center">
                  <div
                    class="bg-blues px-3 py-6 font-bold rounded-2xl text-white text-sm arrow_box"
                  >
                    <p>A1</p>
                  </div>
                </th>

                <th class="px-4 lg:px-7 pb-6 text-center">
                  <div
                    class="bg-blues px-3 py-6 font-bold rounded-2xl text-white text-sm arrow_box"
                  >
                    <p>A2</p>
                  </div>
                </th>

                <th class="px-4 lg:px-7 pb-6 text-center">
                  <div
                    class="bg-blues px-3 py-6 font-bold rounded-2xl text-white text-sm arrow_box"
                  >
                    <p>A3</p>
                  </div>
                </th>

                <th class="px-4 lg:px-7 pb-6 text-center">
                  <div
                    class="bg-blues px-3 py-6 font-bold rounded-2xl text-white text-sm arrow_box"
                  >
                    <p>A4</p>
                  </div>
                </th>
              </tr>
            </thead>

            <!-- <thead>
<tr>
  
  <th
    class="px-4 border-t lg:px-7 py-8 border-b text-center border-r text-blues"
  >
    Name
  </th>
  <th
    class="px-4 border-t lg:px-7 py-8 border-b text-center border-r text-blues"
  >
    Email
  </th>
  <th
    class="px-4 border-t lg:px-7 py-8 border-b text-center border-r text-blues"
  >
    Last login
  </th>

  <th
    class="px-4 border-t lg:px-7 py-8 border-b text-center border-r text-blues"
  >
    IP Address
  </th>

  <th
    class="px-4 border-t lg:px-7 py-8 border-b text-center border-r text-blues"
  >
    Account Created
  </th>

  <th
    class="px-4 border-t lg:px-7 py-8 border-b text-center border-r text-blues"
  >
    Account Created
  </th>
 
</tr>
</thead> -->

            <tbody
              v-if="loading"
              style="height: calc(100vh - 350px) !important"
            >
              <tr class="w-full">
                <td colspan="8" class="text-center py-6">
                  <div class="flex items-center justify-center">
                    <img src="@/assets/newd/loading.gif" alt="" />
                  </div>
                </td>
              </tr>
            </tbody>
            <tbody
              v-else-if="
                studentList &&
                studentList.data &&
                studentList.data.length &&
                !loading
              "
            >
              <tr v-for="el in this.studentList.data" :key="el">
                <!-- <td class="px-4 lg:px-7 py-8 border-b text-center border-r">
    <p class="text-primary">{{ index + 1 }}</p>
  </td> -->
                <!-- <td
                  class="px-4 lg:px-7 py-8 border-b text-center border-r border-t"
                >
                  <p v-if="el.display_name" class="text-primary">
                    {{ el.display_name }}
                  </p>
                  <p v-else class="text-primary">NA</p>
                </td> -->
                <td
                  class="px-4 lg:px-7 py-8 border-b text-center border-l border-r border-t"
                >
                  <p class="text-primary">{{ el.email }}</p>
                </td>
                <td
                  class="px-4 lg:px-7 py-8 border-b text-center border-r border-t whitespace-nowrap"
                >
                2
                  <!-- <p class="text-primary">
                    {{
                      new Date(el.last_in).toLocaleDateString("en-IN", {
                        day: "numeric",
                        month: "long",
                        year: "numeric",
                      })
                    }}
                    -
                    {{ new Date(el.last_in).toLocaleTimeString("en-IN") }}
                  </p> -->
                </td>

                <td
                  class="px-4 lg:px-7 py-8 border-b text-center border-r border-t"
                >
                1
                  <!-- <p v-if="el.ip_address" class="text-primary">
                    {{ el.ip_address }}
                  </p>
                  <p v-else class="text-primary">NA</p> -->
                </td>

                <td
                  class="px-4 lg:px-7 py-8 border-b text-center border-r border-t whitespace-nowrap"
                >
                2
                  <!-- <p class="text-primary">
                    {{
                      new Date(el.lms_joined_date).toLocaleDateString("en-IN", {
                        day: "numeric",
                        month: "long",
                        year: "numeric",
                      })
                    }}
                    -
                    {{
                      new Date(el.lms_joined_date).toLocaleTimeString("en-IN")
                    }}
                  </p> -->
                </td>

                <td
                  class="px-4 lg:px-7 py-8 border-b text-center border-r border-t whitespace-nowrap"
                >
                1
                  <!-- <p class="text-primary">
                    {{
                      new Date(el.lms_joined_date).toLocaleDateString("en-IN", {
                        day: "numeric",
                        month: "long",
                        year: "numeric",
                      })
                    }}
                    -
                    {{
                      new Date(el.lms_joined_date).toLocaleTimeString("en-IN")
                    }}
                  </p> -->
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr class="w-full">
                <td colspan="8" class="text-center py-6">
                  <div class="w-full flex justify-center items-center">
                    No Data Found
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <!-- <div class="">  
              <div class="flex w-full pt-10">
                  <div class="flex rounded-2xl w-full" style="box-shadow: 0px 2px 30px 0px #01B0F926;"> 
                      <table clas="table-auto w-full"> 
                          <thead> 
                              <tr>
                                  <th class="px-4 lg:px-7 py-8 text-center border-r">S NO</th>
                                  <th class="px-4 lg:px-7 py-8 text-center border-r">Name</th>
                                  <th class="px-4 lg:px-7 py-8 text-center border-r">Email</th>
                                  <th class="px-4 lg:px-7 py-8 text-center border-r">Last login</th>
                                  <th class="px-4 lg:px-7 py-8 text-center">Mail to </th>
                              </tr>
                          </thead>
                          <tbody> 
                              <tr>
                                  <td></td>
                              </tr>
                          </tbody>
                      </table>
                  </div>
              </div>
          </div> -->

      <div
        v-if="popUp"
        class="fixed bg-primary bg-opacity-30 top-0 left-0 right-0 bottom-0 z-50"
      >
        <div class="relative w-full h-full">
          <div
            class="w-[40%] absolute bottom-4 right-4 bg-white rounded-2xl p-10 overflow-y-auto max-h-[600px]"
          >
            <div class="flex items-center justify-between">
              <h3 class="text-xl font-bold text-blues">New Message</h3>
              <img
                src="@/assets/newd/close.png"
                class="cursor-pointer"
                alt=""
                @click="this.popUp = false"
              />
            </div>

            <div class="w-full flex flex-col py-6 relative">
              <div class="w-full">
                <form
                  ref="mailref"
                  @submit.prevent="sendmail"
                  class="flex flex-col gap-4 text-xs md:text-sm lg:text-base h-full"
                >
                  <div class="flex">
                    <p
                      class="bg-blues text-white font-semibold text-sm px-4 py-1 rounded-xl"
                    >
                      {{ this.mailid.display_name }}
                    </p>
                  </div>

                  <div
                    class="flex flex-col gap-2 w-full text-xs md:text-sm lg:text-base"
                  >
                    <div class="w-full flex flex-col gap-2">
                      <input
                        id="subject"
                        placeholder="Subject"
                        type="text"
                        class="border-b rounded outline-none text-xs md:text-sm lg:text-base py-2 px-2"
                        required
                      />
                    </div>
                  </div>
                  <!-- <div class="flex flex-col w-full gap-2">
                    <label for="description" class="text-left">Description</label>
                    <textarea
                      id="description"
                      rows="3"
                      cols="10"
                      class="border border-gray-100 h-full rounded text-xs md:text-sm lg:text-base py-2 px-2"
                    />
                  </div> -->
                  <div class="flex flex-col gap-2 w-full mt-2">
                    <!-- <label for="attachments" class="text-left text-gray-400">
                      Message
                    </label> -->
                    <textarea
                      textarea
                      rows="12"
                      cols="10"
                      id="content"
                      class="border border-gray-200 outline-none h-full w-full p-2 rounded"
                      placeholder="Your message"
                    ></textarea>
                  </div>

                  <div class="flex justify-start mt-4">
                    <button
                      type="submit"
                      class="bg-blues px-8 py-2 text-sm text-white rounded-xl"
                    >
                      Send
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Instructor/Header.vue";
import SideBar from "@/components/Instructor/SideBar.vue";

import axios from "axios";
import { mapGetters } from "vuex";

export default {
  components: {
    Header,
    SideBar,
  },
  data() {
    return {
      popUp: false,
      minimize: true,
      maximize: false,
      studentList: null,
      mailid: null,
      messages: {
        text: "",
        type: "null",
      },
      loading: true,
    };
  },
  created() {
    this.getstudentdetails();
  },

  props: ["course_id"],

  computed: {
    ...mapGetters(["userInfo"]),
  },
  methods: {
    async getstudentdetails() {
      await axios
        .get(
          `${process.env.VUE_APP_API}/all_list/${this.course_id}/?Key=${this.userInfo.token_key}`
        )
        .then((response) => {
          console.log(response);
          this.studentList = response.data;
          this.loading = false;
        });
    },

    async sendmail(event) {
      const HeaderVal = {
        subject: event.target.elements.subject.value,
        time: "",
        content_1: event.target.elements.content.value,
        content_2: "",
        content_3: "",
      };
      // await axios
      //   .post(
      //     `${process.env.VUE_APP_API}/instructor_send_personal/${this.mailid.id}/?Key=${this.userInfo.token_key}`,
      //     HeaderVal
      //   )
      //   .then((response) => {
      //     if (response.data.message == "successfully Email send") {
      //       this.messages = {
      //         text: "Mail Sent successfully",
      //         type: "success",
      //       };
      //     } else {
      //       this.messages = {
      //         text: " Event Faild",
      //         type: "error",
      //       };
      //     }
      //   });

      await axios
        .post(
          `${process.env.VUE_APP_API}/user/notifications/?Key=${this.userInfo.token_key}`,
          {
            topic: event.target.elements.subject.value,
            message: event.target.elements.content.value,
            user_id: [this.userInfo.user.id],
          }
        )
        .then((response) => {
          if (response.data.message == "successfully Email send") {
            this.messages = {
              text: "Mail Sent successfully",
              type: "success",
            };
          } else {
            this.messages = {
              text: " Event Faild",
              type: "error",
            };
          }
        });

      this.$refs.mailref.reset();
      this.popUp = false;
      setTimeout(() => {
        this.messages = {
          text: "",
          type: "null",
        };
      }, 1500);
    },

    async sendmailallStudnt() {
      await axios
        .post(
          `${process.env.VUE_APP_API}/instructor_send_student/${this.course_id}/?Key=${this.userInfo.token_key}`
        )
        .then((Response) => {
          if (Response.message == "successfully Email send") {
            this.messages = {
              text: "Mail Sent successfully",
              type: "success",
            };
          } else {
            this.messages = {
              text: " Event Faild",
              type: "error",
            };
          }
        });
      setTimeout(() => {
        this.messages = {
          text: "",
          type: "null",
        };
      }, 1500);
    },
  },
};
</script>

<style scoped>
.heightfix {
  height: calc(100vh - 170px) !important;
}

.arrow_box {
  position: relative;
  background: #0085db;
}
.arrow_box:after,
.arrow_box:before {
  top: 100%;
  left: 50%;
  border: solid transparent;
  content: "";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.arrow_box:after {
  border-color: rgba(136, 183, 213, 0);
  border-top-color: #0085db;
  border-width: 6px;
  margin-left: -6px;
}
.arrow_box:before {
  border-color: rgba(194, 225, 245, 0);
  border-top-color: #0085db;
  border-width: 6px;
  margin-left: -6px;
}
</style>
