<template>
  <div>
    <Header />
    <div class="w-full">
      <div class="container mx-auto max-w-[1200px]">
        <!-- <div class="flex flex-row items-center justify-between mt-4">
          <p class="my-6 blues text-4xl font-semibold">Grade Book</p>
        </div> -->

        <div class="px-2 py-2 ml-3 flex flex-col gap-1 mt-10">
          <!-- {{ userInfo }}  -->
          <!-- {{ userInfo.display_name }} -->
          <p>
            <span class="font-semibold">Student Name:</span>
            {{ userInfo.display_name }}
          </p>
          <p>
            <span class="font-semibold">Student id:</span>
            {{ userInfo.slug_id }}
          </p>
          <p><span class="font-semibold">College Name:</span> Alliance</p>
        </div>

        <div class="flex items-center gap-10 justify-end pb-10">
          <select name="" id="" class="p-2 rounded-lg">
            <option value="" selected disabled>Semester</option>
            <option value="">I</option>
            <option value="">II</option>
            <option value="">III</option>
            <option value="">IV</option>
            <option value="">V</option>
            <option value="">VI</option>
          </select>
          <select name="" id="" class="p-2 rounded-lg">
            <option value="" selected disabled>Year</option>
            <option value="">2023</option>
            <option value="">2024</option>
            <option value="">2025</option>
          </select>
        </div>

        <div
          class="mt-4 mb-10 inline-block overflow-x-auto md:overflow-y-scroll h-full w-full"
        >
          <table
            border="1"
            align="center"
            cellspacing="0"
            cellpadding="10px"
            class="overflow-x-auto"
          >
            <tr>
              <td rowspan="2" align="center">Sl.NO</td>
              <td rowspan="2" align="center">Course Code</td>
              <td rowspan="2" align="center">Course Name</td>
              <td colspan="3" align="center">Internal Marks</td>
              <td colspan="3" align="center">External Marks</td>
              <td colspan="3" align="center">Total Marks</td>
              <td rowspan="2" align="center">Month & Year of Passing</td>
              <td rowspan="2" align="center">Status</td>
            </tr>

            <tr>
              <td align="center">Max</td>
              <td align="center">Min</td>
              <td align="center">Obtained</td>
              <td align="center">Max</td>
              <td align="center">Min</td>
              <td align="center">Obtained</td>
              <td align="center">Max</td>
              <td align="center">Min</td>
              <td align="center">Obtained</td>
            </tr>

            <tr v-for="(i, index) in 5" :key="index">
              <td rowspan="1" align="center" valign="top">{{ index }}</td>
              <td rowspan="1" align="center">ODE</td>
              <td>1</td>
              <td>2</td>
              <td>3</td>
              <td align="center">1</td>
              <td>1</td>
              <td>2</td>
              <td>3</td>
              <td>1</td>
              <td>2</td>
              <td>3</td>
              <td>2022-01-2024</td>
              <td>Pass</td>
            </tr>

            <tr class="none ptt">
              <td colspan="6" class="none ptt">
                <span class="font-bold mr-3">Aggregate CGPA:</span> 7%
              </td>
              <td colspan="3" class="none ptt">Grand Total</td>
              <td colspan="1" class="none ptt" align="center">45</td>
              <td colspan="1" class="none ptt" align="center">23</td>
              <td colspan="1" class="none ptt" align="center">65</td>
              <td colspan="2" class="none ptt" align="right">Pass</td>
            </tr>
            <tr class="none">
              <td colspan="6" class="none">
                <span class="font-bold mr-3">ACAVSBDN:</span>First Class
              </td>
            </tr>
            <tr class="none">
              <td colspan="6" class="none">
                <span class="font-bold mr-3">Date:</span> SEP 10 2025
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
    <Footer/>
  </div>
</template>

<script>
import Footer from "@/components/Footer.vue";
import Header from "@/components/Student/Header.vue";
import { mapGetters } from "vuex";

export default {
  name: "GradeBook",

  components: {
    Header,
    Footer,
  },

  computed: {
    ...mapGetters(["userInfo"]),
  },
};
</script>

<style scoped> 

td, thead, tr {
    padding: 10px 20px;
    border: 1px solid black !important;
}

</style>
