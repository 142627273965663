<template>
  <div class="fixed bottom-0 right-0 p-10 ml-60 z-50" style="z-index: 6001">
    <div
      v-if="message"
      :class="{
        'bg-red-800 text-white': message.type === 'error',
        'bg-green-900 text-white': message.type === 'success',
      }"
      class="rounded-lg shadow-md p-4"
      style="min-width: 240px"
    >
      <div class="flex items-center text-xl text-white justify-center">
        {{ message.text }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["message"],
};
</script>
