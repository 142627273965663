<template>
  <div>
    <!-- <Header /> -->

    <div class="flex">
      <SideBar />

      <div
        class="w-full mr-10 mt-10 rounded-3xl heightfix"
        style="box-shadow: 0px 2px 30px 0px #01b0f926"
      >
        <div class="flex h-full">
          <!-- <div
            class="flex flex-col gap-4 lg:w-[30%] 2xl:w-[25%] overflow-y-auto"
          >
            <div class="pt-6 pb-2">
              <p class="text-primary text-center font-bold">Messages</p>
            </div>

            <div class="overflow-y-auto">
              <div
                v-for="student in this.studentList.data"
                :key="student"
                class=""
              >
                <p
                  v-if="student[0].display_name"
                  @click="messageInfo(student)"
                  class="py-3 px-10 text-base lg:text-sm bg-white mb-0 hover:bg-gray-200 cursor-pointer flex"
                >
                  <span class="px-2 capitalize">{{
                    student[0].display_name
                  }}</span>
                </p>
              </div>
            </div>
          </div> -->

          <div class="bg-[#F0F5F9] h-full w-full rounded-3xl relative">
            <div class="absolute w-full left-0 right-0">
              <div class="bg-[#FFFFFF] rounded-2xl m-4 py-2 px-3">
                <p class="text-center text-primary font-semibold">
                  Forums
                  <!-- {{ this.selectedStudent[0].display_name }} -->
                </p>
              </div>
            </div>

            <div class="h-full w-full px-3 pt-14 pb-16">
              <div class="p-4 h-full overflow-y-auto" id="chatContainer">
                <div
                  v-for="message in totalmessage"
                  :key="message"
                  class="my-4 flex flex-col gap-1"
                >
                  <p class="text-lg font-bold text-gray-600">{{ message[3] }}</p>
                  <span class="text-xs text-gray-600">{{ message[1] }}</span>
                  <p class="text-sm">{{ message[0] }}</p>
                </div>
              </div>
            </div>

            <div class="absolute bottom-2 w-full left-0 right-0">
              <div
                class="bg-[#FFFFFF] rounded-2xl m-4 flex items-center justify-between py-2 px-3 gap-10"
              >
                <input
                  type="text"
                  class="w-[95%] border-none outline-none text-primary"
                  placeholder="Type your text here..."
                  @keyup.enter="sendmsg()"
                  v-model="messageVal"
                  name=""
                  id=""
                />
                <button
                  @click="sendmsg()"
                  class="bg-blues w-[30px] h-[30px] rounded-full flex items-center justify-center"
                >
                  <img src="@/assets/newd/arrow.png" alt="" />
                </button>
              </div>
            </div>
          </div>
        </div>

        <!-- <h1>Hello Message</h1> -->
        <!-- <table class="table-auto w-full">
            <thead>
              <tr>
                <th class="px-4 lg:px-7 py-8 border-b text-center border-r">
                  S NO
                </th>
                <th class="px-4 lg:px-7 py-8 border-b text-center border-r">
                  Name
                </th>
                <th class="px-4 lg:px-7 py-8 border-b text-center border-r">
                  Email
                </th>
                <th class="px-4 lg:px-7 py-8 border-b text-center border-r">
                  Last login
                </th>
                <th class="px-4 lg:px-7 py-8 border-b text-center">Mail to</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="i in (0, 100)" :key="i">
                <td class="px-4 lg:px-7 py-8 border-b text-center border-r">1</td>
                <td class="px-4 lg:px-7 py-8 border-b text-center border-r">
                  Malcolm Lockyer
                </td>
                <td class="px-4 lg:px-7 py-8 border-b text-center border-r">
                  1961
                </td>
                <th class="px-4 lg:px-7 py-8 border-b text-center border-r">
                  Last login
                </th>
                <th class="px-4 lg:px-7 py-8 border-b text-center">Mail to</th>
              </tr>
            </tbody>
          </table> -->
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Admin/Header.vue";
import SideBar from "@/components/Admin/SideBar.vue";

import axios from "axios";
import { mapGetters } from "vuex";

export default {
  components: {
    Header,
    SideBar,
  },
  data() {
    return {
        messageVal: null,
        totalmessage: null,
    };
  },

  // created() {
  //   this.getdetails();
  // },

  // props: ["course_id"],

  computed: {
    ...mapGetters(["userInfo"]),
  },

  mounted() {
    setTimeout(() => {
      let chatContainer = document.getElementById("chatContainer");
      console.log("Height::::=", chatContainer.offsetHeight);

      chatContainer.scrollTo({
        top: chatContainer.scrollHeight + 300,
        behavior: "smooth",
      });
    }, 200);
  },

  methods: {
    scrollToBottomChat() {
      setTimeout(() => {
        console.log("Height::::=", chatContainer.offsetHeight);

        chatContainer.scrollTo({
          top: chatContainer.scrollHeight + 300,
          behavior: "smooth",
        });
      }, 200);
    },

    // async getdetails() {
    //   await axios
    //     .get(
    //       `${process.env.VUE_APP_API}/messages/${this.userInfo.user.id}/?Key=${this.userInfo.token_key}`
    //     )
    //     .then((Response) => {
    //       this.totalmessage = Response.data.data.reverse();
    //     });


    //     this.scrollToBottomChat();

    // //   $(".chatArea").animate(
    // //     {
    // //       scrollTop: $(".chatArea")[0].scrollHeight,
    // //     },
    // //     1000
    // //   );
    //   // let messageDisplay = this.$refs.chatArea;
    //   // messageDisplay.scrollTop = messageDisplay.scrollHeight;
    // },

    async sendmsg() {
      let messagesPass = {
        text: this.messageVal,
        owner: this.userInfo.user.id,
        // course_code: parseInt(this.course_id),
      };

      await axios
        .post(
          `${process.env.VUE_APP_API}/talk/?Key=${this.userInfo.token_key}`,
          messagesPass
        )
        .then((Response) => {
          Response;
        });

      this.getdetails();
      // this.scrollToBottomChat();
      this.messageVal = null;

      setTimeout(()=>{
        this.messageVal = null;
      }, 1000)
      
    //   $(".chatArea").animate(
    //     {
    //       scrollTop: $(".chatArea")[0].scrollHeight,
    //     },
    //     1000
    //   );
      // let messageDisplay = this.$refs.chatArea;
      // messageDisplay.scrollTop = messageDisplay.scrollHeight;
    },
  },
};
</script>

<style scoped>

.heightfix{
  height: calc(100vh - 170px) !important;
}

</style>
