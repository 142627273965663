<template>
  <div class="page-wrapper">
    <Header />
    <!-- <FlashMessage v-if="this.messages.text != ''" :message="this.messages" /> -->

    <div class="py-3">
      <div class="container mx-auto max-w-[1200px]">
        <div
          class="flex flex-col lg:flex-row justify-between items-center gap-8 rounded-3xl shadow-xl my-20 h-full"
        >
          <div
            class="lg:px-20 flex flex-col py-14 gap-10 justify-between h-full lg:text-left px-10"
          >
            <h1 class="font-bold text-3xl lg:text-5xl prime">
              Welcome Student
              <span class="text-[#105C8E] text-xl lg:text-3xl ml-2 lg:ml-5">
              </span>
            </h1>

            <p class="content max-w-sm">
              Looking for information about Galecta LMS itself (features, use
              cases, or documentation). Referring to reusing or duplicating
              organizing content for it.
            </p>

            <div class="flex flex-row items-center">
              <button
                class="bg-[#0F172A] w-40 h-10 rounded-md text-white mx-auto lg:mx-0"
              >
                <router-link :to="{ name: 'StudentMyCourse' }">
                  Start Learning</router-link
                >
              </button>
            </div>
          </div>

          <div
            class="flex justify-center lg:justify-end gap-4 relative heightfull"
          >
            <img
              src="@/assets/new/boy.png"
              alt=""
              class="max-w-full h-auto rounded-r-3xl object-contain heightfull"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="py-3">
      <div class="container mx-auto max-w-[1200px]">
        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
          <!-- calender -->
          <div class="flex flex-col gap-3 md:min-w-[330px]">
            <h1 class="text-[#0F172A] text-2xl font-semibold">Calendar</h1>
            <div class="h-[330px]">
              <vue-cal
                small
                ref="vuecal"
                events-on-month-view="short"
                :disable-views="['years', 'year', 'week', 'day']"
                :events="values"
                :on-event-click="onEventClick"
                class=""
              >
              </vue-cal>
            </div>
          </div>

          <!-- todo -->
          <div class="flex flex-col gap-3 md:min-w-[330px]">
            <div
              class="bg-[#EDF8FF] flex justify-between py-2 px-3 rounded-xl items-center"
            >
              <h1 class="text-[#0085DB] text-xl font-semibold">
                Tasks for Today
              </h1>
              <router-link
                :to="{ name: 'StudentTaskList' }"
                v-if="this.todolist.length > 3"
              >
                <img
                  src="@/assets/chevron-down.png"
                  alt=""
                  srcset=""
                  class="object-contain w-5 h-5"
                />
              </router-link>
            </div>
            <div
              class="flex flex-col gap-2 justify-start bg-white rounded-2xl shadow-lg p-6 h-[330px]"
            >
              <form @submit.prevent="addTask">
                <div class="flex flex-col gap-2">
                  <p
                    v-if="success_message"
                    class="text-xs text-green-400 font-bold"
                  >
                    {{ success_message }}
                  </p>
                   <p
                    v-if="success_delemessage"
                    class="text-xs text-red-400 font-bold"
                  >
                    {{ success_delemessage }}
                  </p>
                  
                  <div class="">
                    <input
                      v-model="newTask"
                      type="text"
                      class="border-b outline-none flex-1 p-1 w-full"
                      placeholder="+Add New Task"
                      @input="showButton = newTask.length > 0"
                      @keyup.enter="addTask"
                    />
                  </div>
                  <div
                    v-show="showButton"
                    class="flex justify-end items-center"
                  >
                    <div class="flex gap-1">
                      <button
                        class="text-white rounded transition-opacity cursor-pointer"
                      >
                        <!-- ✅ -->
                        <img
                          src="@/assets/newd/Vector(1).svg"
                          alt=""
                          srcset=""
                          class="w-5 h-5 bg-[#EBEBEB] p-1"
                        />
                      </button>
                      <div @click="cleartask" class="cursor-pointer">
                        <img
                          src="@/assets/newd/closebutton.svg"
                          alt=""
                          class="w-6 h-6"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </form>
              <ul class="overflow-y-auto" v-if="todolist.length > 0">
                <li v-for="task in todolist" :key="task.id" class="group py-2">
                  <div
                    class="flex justify-between bg-[#F4F4F4] w-full p-2 rounded-md"
                    v-if="task.completed == false"
                  >
                    <p class="text-sm break-words w-[230px] line-clamp-2">
                      {{ task.text }}
                    </p>
                    <div
                      class="flex gap-2 w-[25%] opacity-100 xl:opacity-0 group-hover:opacity-100 transition-opacity duration-200"
                    >
                      <!-- <button @click="completeTask(task.id)" class="">✅</button> -->
                      <button @click="completeTask(task.id)" class="">
                        <img
                          src="@/assets/newd/Vector(1).svg"
                          alt=""
                          srcset=""
                          class="w-6 h-6 bg-[#EBEBEB] p-1"
                        />
                      </button>

                      <button @click="openEditPopup(task)" class="">
                        <img
                          src="@/assets/newd/Group.svg"
                          alt=""
                          srcset=""
                          class="w-6 h-6 bg-[#EBEBEB] p-1"
                        />
                      </button>
                      <button @click="deleteTask(task.id)" class="">
                        <img
                          src="@/assets/newd/Vector.svg"
                          alt=""
                          srcset=""
                          class="w-6 h-6 bg-[#EBEBEB] p-1"
                        />
                      </button>
                    </div>
                  </div>
                </li>
              </ul>

              <div v-else class="text-center">
                No task
              </div>
              <!-- Edit Popup -->
              <!-- <div
                v-if="isEditing"
                class="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center"
              >
                <div class="bg-white p-4 rounded shadow-lg">
                  <h3 class="mb-2">Edit Task</h3>
                  <input
                    v-model="editTaskText"
                    type="text"
                    class="border p-1 w-full"
                  />
                  <div class="flex justify-end mt-2">
                    <button
                      @click="updateTask"
                      class="bg-blue-500 text-white px-2 py-1 rounded mr-2"
                    >
                      Update
                    </button>
                    <button
                      @click="isEditing = false"
                      class="bg-gray-500 text-white px-2 py-1 rounded"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div> -->
              <div
                v-if="isEditing"
                class="fixed bg-primary bg-opacity-30 top-0 left-0 right-0 bottom-0 z-50 flex items-center justify-center"
              >
                <div
                  class="w-[90%] lg:w-[40%] bg-white rounded-xl p-6 lg:p-10 overflow-y-auto max-h-[600px]"
                >
                  <div class="flex items-center justify-center">
                    <h3 class="text-xl font-bold text-blues text-center">
                      Edit Task
                    </h3>
                  </div>

                  <div
                    class="w-full mt-4 flex flex-col p-6 relative border-gray-100 rounded-2xl"
                  >
                    <div class="w-full">
                      <form
                        ref="createTeamRef"
                        @submit.prevent="updateTask()"
                        class="flex flex-col gap-4 text-xs md:text-sm lg:text-base h-full"
                      >
                        <div>
                          <textarea
                            v-model="editTaskText"
                            name=""
                            id=""
                            cols="30"
                            rows="5"
                            class="border p-1 w-full"
                          ></textarea>
                        </div>

                        <div class="flex justify-center pb-4 mt-3 gap-10">
                          <button
                            @click="isEditing = false"
                            class="border text-gray-500 rounded-lg text-sm px-5 lg:px-10 font-semibold py-2"
                          >
                            Close
                          </button>
                          <button
                            class="border text-white rounded-lg bg-blues text-sm px-5 lg:px-10 font-semibold py-2"
                          >
                            Update
                          </button>
                        </div>

                        <div
                          v-if="editmessages"
                          class="text-green-400 text-lg font-bold"
                        >
                          {{ editmessages }}
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>

              <!-- <div class="" v-for="todo in [0, 1, 2, 3]" :key="todo">
                <div
                  class="flex justify-center gap-4 bg-[#105C8E1A] p-4 rounded-lg items-center"
                >
                  <img
                    src="@/assets/Vector(11).png"
                    alt=""
                    srcset=""
                    class="object contain w-4 h-4 mt-1"
                  />
                  <div class="flex flex-col gap-1">
                    <p class="text-sm font-bold text-[#333333] text-wrap">
                      15 Assignments Due
                    </p>
                  </div>
                </div>
              </div> -->
            </div>
          </div>

          <!-- Announcements -->
          <div class="flex flex-col gap-3 md:min-w-[330px]">
            <div
              class="bg-[#EDF8FF] flex justify-between py-2 px-3 rounded-xl items-center"
            >
              <h1 class="text-[#0085DB] text-xl font-semibold">
                Announcements
              </h1>
              <router-link
                :to="{ name: 'StudentNotifications' }"
                v-if="this.notificationList.length > 3"
              >
                <img
                  src="@/assets/chevron-down.png"
                  alt=""
                  srcset=""
                  class="object-contain w-5 h-5"
                />
              </router-link>
            </div>
            <div
              class=" bg-white rounded-2xl shadow-lg p-6 w-full h-[330px]"
            >
              <div v-if="notificationList.length > 0" class="flex flex-col gap-5   lg:justify-between">
                <div
                  class="border-b pb-4 border-gray-300"
                  v-for="notifi in this.notificationList.slice(0, 3)"
                  :key="notifi"
                >
                  <div class="flex gap-4">
                    <img
                      src="@/assets/Group 610.png"
                      alt=""
                      srcset=""
                      class="object contain w-7 h-7 mt-1"
                    />
                    <div class="flex flex-col gap-1">
                      <h1
                        class="text-sm font-bold text-black w-[230px] text-wrap line-clamp-2"
                      >
                        {{ notifi.topic }}
                      </h1>
                      <p
                        class="text-xs font-bold text-[#ADADAD] text-wrap line-clamp-2"
                      >
                        {{ notifi.message }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else>No Announcement</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- My Learning -->
    <div class="pt-10 pb-20">
      <div class="container mx-auto max-w-[1200px] flex flex-col gap-10">
        <div>
          <div class="flex flex-row items-center justify-between">
            <p class="text-[#0F172A] text-3xl font-semibold">My Courses</p>
            <router-link
              :to="{ name: 'StudentMyCourse' }"
              class="text-md text-[#0F172A] font-medium flex gap-1 items-center"
              >View All
              <img
                src="@/assets/newd/chevron-down(1).png"
                alt=""
                srcset=""
                class="w-4 h-4"
            /></router-link>
          </div>
          <div v-if="loading" class="flex items-center justify-center">
            <img src="@/assets/newd/loading.gif" alt="" />
          </div>
          <div v-else class="mt-6">
            <div v-if="this.courseDetails.length > 0">
              <div v-if="this.courseDetails[0]" class="grid grid-cols-1 gap-4">
                <div
                  v-for="course in this.courseDetails[0].course.slice(0, 3)"
                  :key="course"
                  class="bg-white shadow-[0px_2px_30px_0px_rgba(1,0,0,0.1)] rounded-lg p-4 sm:p-6 h-auto"
                >
                  <div
                    @click="getDashboardOrPreviewUp(course.id)"
                    class="cursor-pointer lg:flex lg:gap-5 w-full"
                  >
                    <div
                      class="relative flex justify-center items-center lg:w-[30%]"
                    >
                      <img
                        v-if="course.thumbnail"
                        class="object-cover h-[180px] sm:h-[200px] w-full rounded-lg"
                        :src="course.thumbnail"
                        alt="Course Thumbnail"
                      />
                      <img
                        v-else
                        class="object-cover h-24 w-full rounded-lg"
                        src="@/assets/coding.svg"
                        alt="Placeholder"
                      />
                    </div>
                    <div
                      class="text-center flex flex-col lg:justify-between gap-2 mt-2 lg:w-[70%]"
                    >
                      <p
                        class="text-sm sm:text-base lg:text-lg text-gray-600 font-semibold text-left truncate"
                      >
                        {{ course.title }}
                      </p>
                      <p class="text-xs sm:text-sm text-gray-600 text-left">
                        {{ course.sections.length }} Units
                      </p>
                      <p
                        class="text-xs sm:text-sm text-gray-600 text-left line-clamp-2"
                      >
                        {{ course.short_description }}
                      </p>
                      <div class="lg:w-[400px] flex flex-col gap-2">
                        <div class="flex items-center justify-between w-full">
                          <div>
                            <p
                              class="text-sm"
                              v-if="course.completion_percentage < 100"
                            >
                              In progress
                            </p>
                            <p v-else class="text-sm">Complete</p>
                          </div>
                          <div>
                            <p class="text-sm">
                              {{ course.completion_percentage }}%
                            </p>
                          </div>
                        </div>

                        <div
                          class="relative w-full bg-gray-200 rounded-full h-4"
                        >
                          <div
                            class="absolute left-0 top-0 h-4 bg-blues rounded-full transition-all duration-300"
                            :style="{
                              width: course.completion_percentage + '%',
                            }"
                          ></div>
                        </div>
                      </div>
                      <p
                        @click="getDashboardOrPreviewUp(course.id)"
                        class="mt-3 cursor-pointer py-2 text-xs sm:text-sm rounded-lg border border-[#0085DB] lg:w-[200px] text-[#0F172A] font-medium text-center"
                      >
                        Continue Learning
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-else class="text-lg font-normal text-center">
              No data Found
            </div>

            <!-- <div
              v-for="course in this.courselist.slice(0, 3)"
              :key="course"
              class="h-auto p-6 shadow-lg rounded-xl bg-white"
            >
              <router-link
                :to="{ name: 'StudentCourse', params: { courseid: course.id } }"
              >
                <div class="relative flex justify-center items-center">
                  <img
                    v-if="course.thumbnail"
                    class="object-cover h-[200px] w-full rounded-lg"
                    :src="course.thumbnail"
                    alt="Course Thumbnail"
                  />
                  <img
                    v-else
                    class="object-cover h-24 w-full rounded"
                    src="fallback-image.jpg"
                    alt="Placeholder"
                  />
                </div>
                <div class="text-center flex flex-col gap-2 mt-2">
                  <p
                    class="text-sm text-gray-600 font-semibold text-left truncate"
                  >
                    {{ course.title }}
                  </p>
                  <p class="text-xs text-gray-600 text-left">
                    {{ course.sections.length }} Units
                  </p>
                
                  <p
                    class="mt-3 py-2 text-xs rounded-lg border border-[#0085DB] text-[#0F172A] font-medium text-center"
                  >
                    Continue Learning
                  </p>
                </div>
              </router-link>
            </div> -->
          </div>
        </div>

        <!-- Additional Courses -->
        <div>
          <div class="flex flex-row items-center justify-between">
            <p class="text-[#0F172A] text-3xl font-semibold">
              Additional Courses
            </p>
            <router-link
              :to="{ name: 'StudentCommunity' }"
              class="text-md text-[#0F172A] font-medium flex gap-1 items-center"
              >View All
              <img
                src="@/assets/newd/chevron-down(1).png"
                alt=""
                srcset=""
                class="w-4 h-4"
            /></router-link>
          </div>
          <div v-if="loading" class="flex items-center justify-center">
            <img src="@/assets/newd/loading.gif" alt="" />
          </div>
          <div v-else class="mt-6">
            <div v-if="this.courseDetails.length > 0">
              <div
                v-if="this.courseDetails[0]"
                class="grid grid-cols-1 gap-4 mt-6"
              >
                <div
                  v-for="course in this.courseDetails[0].course.slice(0, 3)"
                  :key="course"
                  class="bg-white shadow-[0px_2px_30px_0px_rgba(1,0,0,0.1)] rounded-lg p-4 sm:p-6 h-auto"
                >
                  <div
                    @click="getDashboardOrPreviewUp(course.id)"
                    class="cursor-pointer lg:flex lg:gap-5 w-full"
                  >
                    <div
                      class="relative flex justify-center items-center lg:w-[30%]"
                    >
                      <img
                        v-if="course.thumbnail"
                        class="object-cover h-[180px] sm:h-[200px] w-full rounded-lg"
                        :src="course.thumbnail"
                        alt="Course Thumbnail"
                      />
                      <img
                        v-else
                        class="object-cover h-24 w-full rounded-lg"
                        src="@/assets/coding.svg"
                        alt="Placeholder"
                      />
                    </div>
                    <div
                      class="text-center flex flex-col lg:justify-between gap-2 mt-2 lg:w-[70%]"
                    >
                      <p
                        class="text-sm sm:text-base lg:text-lg text-gray-600 font-semibold text-left truncate"
                      >
                        {{ course.title }}
                      </p>
                      <p class="text-xs sm:text-sm text-gray-600 text-left">
                        {{ course.sections.length }} Units
                      </p>
                      <p
                        class="text-xs sm:text-sm text-gray-600 text-left line-clamp-2"
                      >
                        {{ course.short_description }}
                      </p>
                      <div class="lg:w-[400px] flex flex-col gap-2">
                        <div class="flex items-center justify-between w-full">
                          <div>
                            <p
                              class="text-sm"
                              v-if="course.completion_percentage < 100"
                            >
                              In progress
                            </p>
                            <p v-else class="text-sm">Complete</p>
                          </div>
                          <div>
                            <p class="text-sm">
                              {{ course.completion_percentage }}%
                            </p>
                          </div>
                        </div>

                        <div
                          class="relative w-full bg-gray-200 rounded-full h-4"
                        >
                          <div
                            class="absolute left-0 top-0 h-4 bg-blues rounded-full transition-all duration-300"
                            :style="{
                              width: course.completion_percentage + '%',
                            }"
                          ></div>
                        </div>
                      </div>
                      <p
                        @click="getDashboardOrPreviewUp(course.id)"
                        class="mt-3 cursor-pointer py-2 text-xs sm:text-sm rounded-lg border border-[#0085DB] lg:w-[200px] text-[#0F172A] font-medium text-center"
                      >
                        Continue Learning
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-else class="text-lg font-normal text-center">
              No data Found
            </div>

            <!-- <div v-for="course in courselist.slice(0, 3)" :key="course" class="h-auto p-6 shadow-lg rounded-xl">
              <router-link :to="{ name: 'StudentCourse', params: { courseid: course.id } }">
                <div class="relative flex justify-center items-center">
                  <img v-if="course.thumbnail" class="object-cover h-[200px] w-full rounded-lg" :src="course.thumbnail"
                    alt="" />
                  <img v-else class="object-cover h-24 w-full rounded" src="fallback-image.jpg" alt="" />
                </div>
                <div class="text-center flex flex-col gap-2 mt-2">
                  <p class="text-sm text-gray-600 font-semibold text-left truncate">{{ course.title }}</p>
                  <p class="text-xs text-gray-600 text-left">{{ course.sections.length }} Units</p>
                  <div class="flex gap-x-20">
                   
                  

                  </div>
                  <p class="mt-3 py-2 text-xs rounded-lg border border-[#0085DB] text-[#0F172A] font-medium">Start Now
                    !!!
                  </p>
                  <a href="#" class="text-sm text-[#0085DB] font-medium">Know More</a>
                </div>
              </router-link>
            </div> -->
          </div>
        </div>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Student/Header.vue";
import Footer from "@/components/Footer.vue";
import axios from "axios";
// import FlashMessage from "@/components/flashMessage.vue";
import { mapGetters } from "vuex";
import VueCal from "vue-cal";
import "vue-cal/dist/vuecal.css";
export default {
  name: "Home",
  data() {
    return {
      courselist: "",
      blog_list: "",
      webinar_List: "",
      title: "",
      purchased_course: "",
      allyourcourse: "",
      listOfcourse: [],
      courseDetails: null,
      courseDetails: [],
      progress: 70,
      loading: true,
      notificationList: [],
      todolist: [],
      newTask: "",
      isEditing: false,
      editTaskText: "",
      editTaskId: null,
      showButton: false,
      editmessages: "",
      success_message: "",
      success_delemessage:"",
    };
  },
  computed: mapGetters(["userInfo"]),
  components: {
    Header,
    Footer,
    // FlashMessage,
    VueCal,
  },
  mounted() {
    // setTimeout(() => {
    //   this.loading = false;
    // }, 2000);
  },
  async created() {
    this.getNotifications();
    this.getTodo();
    this.title = process.env.VUE_APP_Title;

    await axios
      .get(
        `${process.env.VUE_APP_API}/student-buy-course-percentage/?Key=${this.userInfo.token_key}`
      )
      .then((response) => {
        this.courseDetails = response.data.data;
        setTimeout(() => {
          this.loading = false;
        }, 600);
      });

    // await axios
    //   .get(`${process.env.VUE_APP_API}/courses/?format=json`)
    //   .then((response) => {
    //     this.courselist = response.data.data;
    //   });
  },
  methods: {
    cleartask() {
      this.newTask = "";
      this.showButton = false;
    },
    getDashboardOrPreviewUp(id) {
      if (localStorage.getItem("previewIgnoreList")) {
        let preList = new Set(
          JSON.parse(localStorage.getItem("previewIgnoreList"))
        );

        if (preList.size != 0) {
          if (preList.has(id.toString())) {
            this.$router.push({
              name: "StudentCourseProgress",
              params: { course_id: id },
            });
          } else {
            this.$router.push({
              name: "StudentCourse",
              params: { courseid: id },
            });
          }
        } else {
          this.$router.push({
            name: "StudentCourse",
            params: { courseid: id },
          });
        }
      } else {
        this.$router.push({ name: "StudentCourse", params: { courseid: id } });
      }
    },
    async getNotifications() {
      await axios
        .get(
          `${process.env.VUE_APP_API}/notifications/${this.userInfo.user.id}/?Key=${this.userInfo.token_key}`
        )
        .then((response) => {
          if (response.data.data.status == 200) {
            this.notificationList = response.data.data.data;
            console.log(" this.notificationList", this.notificationList);
          }
        });
    },
    openEditPopup(task) {
      this.editTaskText = task.text;
      this.editTaskId = task.id;
      this.isEditing = true;
    },
    addTask() {
      if (this.newTask.trim()) {
        let bodyVal = {
          text: this.newTask,
          user: this.userInfo.user.id,
        };
        console.log("bodyVal", bodyVal);

        axios
          .post(
            `${process.env.VUE_APP_API}/to-do-create/?Key=${this.userInfo.token_key}`,
            bodyVal
          )
          .then((response) => {
            if (response.data.status == 200) {
              this.success_message = response.data.message;
              setTimeout(() => {
                this.success_message = "";
              }, 900);
              this.newTask = "";
              this.showButton = false;
              this.getTodo();
            }
          })
          .finally(() => {
            this.loaders = false;
          });
      }
    },
    async getTodo() {
      await axios
        .get(
          `${process.env.VUE_APP_API}/to-do-get/${this.userInfo.user.id}/?Key=${this.userInfo.token_key}&search=false`
        )
        .then((response) => {
          if (response.data.data.status == 200) {
            this.todolist = response.data.data.data;
            console.log(" this.todolist", this.todolist);
          }
        });
    },
    updateTask() {
      let formdata = {
        text: this.editTaskText,
      };
      console.log("formdata", formdata);

      axios
        .put(
          `${process.env.VUE_APP_API}/to-do-text-edit/${this.editTaskId}/?Key=${this.userInfo.token_key}`,
          formdata
        )
        .then((response) => {
          if (response.data.status == 200) {
            this.editmessages = response.data.message;

            setTimeout(() => {
              this.editmessages = "";
              this.isEditing = false;
              this.getTodo();
            }, 2000);
          }
        })
        .finally(() => {
          this.loaders = false;
        });
    },
    deleteTask(id) {
      console.log("sdafsdfsdfsd", id);
      axios
        .delete(
          `${process.env.VUE_APP_API}/to-do-update/${id}/?Key=${this.userInfo.token_key}`
        )
        .then((response) => {
          if (response.data.status == 200) {
            this.success_delemessage = response.data.message;
            setTimeout(() => {
              this.success_delemessage = "";
            }, 900);
            this.getTodo();
          }
        });
    },
    completeTask(id) {
      let completedata = {
        completed: true,
      };
      axios
        .put(
          `${process.env.VUE_APP_API}/to-do-update/${id}/?Key=${this.userInfo.token_key}`,
          completedata
        )
        .then((response) => {
          if (response.data.status == 200) {
             this.success_message = response.data.message;
              setTimeout(() => {
                this.success_message = "";
              }, 900);
            this.getTodo();
          }
        });
    },
  },
};
</script>

<style></style>
