import store from "@/store";

export const adminGuards = (to, from, next) => {
    if (store.getters.isAuthenticated) {
        if(store.getters.userInfo.user.permission == "super_admin"){
            next();
        }
        else{
            next("/");
        }
    } 
    else{
        next("/");
    }
};
export const studentGuards = (to, from, next) => {
    if (store.getters.isAuthenticated) {
        if(store.getters.userInfo.user.permission == "student"){
            next();
        }
        else{
            next("/");
        }
    } 
    else{
        next("/");
    }
};

export const instructorGuards = (to, from, next) => {
    if (store.getters.isAuthenticated) {
        if(store.getters.userInfo.user.permission == "instructor"){
            next();
        }
        else{
            next("/");
        }
    } 
    else{
        next("/");
    }
};

export const loginGuards = (to, from, next) => {
    if (!store.getters.isAuthenticated) {
        next();
    } 
    else {
        if(store.getters.userInfo.user.permission == "super_admin"){
            next("/admin/home");
        }
        if(store.getters.userInfo.user.permission == "student"){
            next("/student/home");
        }
        if(store.getters.userInfo.user.permission == "instructor"){
            next("/instructor/home");
        }
    }
};

