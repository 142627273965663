class FileUpload {
    
    constructor(input) {
      this.input = input;
      this.max_length = 1024 * 1024 * 1024 * 100;
      this.uploadVideoUrl = null;
    }
  
    // create_progress_bar() {
    //   var progress = `<div class="file-icon">
    //                       <i class="fa fa-file-o" aria-hidden="true"></i>
    //                   </div>
    //                   <div class="file-details">
    //                       <p class="filename"></p>
    //                       <small class="textbox"></small>
    //                       <div class="progress">
    //                           <div class="progress-bar bg-success" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%">
    //                           </div>
    //                       </div>
    //                   </div>`;
    //   document.getElementById("uploaded_files").innerHTML = progress;
    // }
  
    upload(append_id) {
      // this.create_progress_bar();
      this.initFileUpload(append_id);
    }
  
    initFileUpload(append_id) {
      this.file = this.input.files[0];
      this.upload_file(0, null, append_id);
    }
  
    //upload file
    upload_file(start, model_id, append_id) {
      var end;
      var self = this;
      var existingPath = model_id;
      var formData = new FormData();
      var nextChunk = start + this.max_length + 1;
      var currentChunk = this.file.slice(start, nextChunk);
      var uploadedChunk = start + currentChunk.size;
      if (uploadedChunk >= this.file.size) {
        end = 1;
      } else {
        end = 0;
      }
  
      formData.append("title", "title");
  
      formData.append("file", currentChunk);
      formData.append("filename", this.file.name);
      $(".filename").text(this.file.name);
      $(".textbox").text("Uploading file");
      formData.append("end", end);
      formData.append("existingPath", existingPath);
      formData.append("nextSlice", nextChunk);
  
      $.ajax({
        xhr: function () {
          var xhr = new XMLHttpRequest();
          xhr.upload.addEventListener("progress", function (e) {
            if (e.lengthComputable) {
              if (self.file.size < self.max_length) {
                var percent = Math.round((e.loaded / e.total) * 100);
              } else {
                var percent = Math.round((uploadedChunk / self.file.size) * 100);
              }
              $(".progress-bar").css("width", percent + "%");
              $(".progress-bar").text(percent + "%");
            }
          });
          return xhr;
        },
  
        url: "https://api.galecta.com/video-uploader/",
        type: "POST",
        dataType: "json",
        cache: false,
        processData: false,
        contentType: false,
        data: formData,
        error: function (xhr) {
          alert(xhr.statusText);
        },
        success: function (res) {
          if (nextChunk < self.file.size) {
            // upload file in chunks
            existingPath = res.existingPath;
            self.upload_file(nextChunk, existingPath);
          } else {
            // upload complete
            $(".textbox").text(res.data);
            $(`#${append_id}`).val(
              "https://api.galecta.com/media/" + res.existingPath
            );
            console.log(res, "resp::");
            this.uploadVideoUrl =  "https://api.galecta.com/media/" + res.existingPath;
            // alert(JSON.parse(res))
          }
        },
      });
    }


    getInputValue() {
      return this.uploadVideoUrl;
    }

  }


class VideoUpload {

    constructor({data}){
        this.VideoUrl = null; 
    }

    render() {
        const form = document.createElement('form');
        
        // form.setAttribute('action', '#'); 
        form.setAttribute('method', 'post');
        form.setAttribute('enctype', 'multipart/form-data');  

        const fileInput = document.createElement('input');
        fileInput.id = 'fileupload';
        fileInput.setAttribute('type', 'file');
        fileInput.setAttribute('name', 'file')

        const submitButton = document.createElement('button');
        submitButton.setAttribute('type', 'submit');
        submitButton.textContent = 'Upload File';

        form.appendChild(fileInput);
        form.appendChild(submitButton);

        form.addEventListener('submit', function(event) {
            event.preventDefault();
            console.log("uploading...");
            var uploader = new FileUpload(document.querySelector("#fileupload"));
            // console.log(document.querySelector("#fileupload"));
            uploader.upload("videoUrl");
            this.VideoUrl = uploader.getInputValue();
        })

        return form;
    }

    save(blockContent) {
        return this.VideoUrl; 
    }

    static get toolbox() {
        return {
          title: 'Upload Video',
          icon: '<img width="15" height="15" src="https://img.icons8.com/ios/50/quiz.png" alt="quiz"/>'
        };
    }

}

export default VideoUpload;