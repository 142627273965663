<template>
  <div>
    <Header />
    <FlashMessage v-if="this.messages.text != ''" :message="this.messages" />

    <div class="py-10">
      <div class="container px-6 mx-auto max-w-[1200px] flex flex-col gap-10">
        <div>
          <form ref="blogRef" class="rounded-3xl p-4 sm:p-6 lg:p-10 heightfix overflow-y-auto shadow-md"
            @submit.prevent="createBlogs">
            <div class="flex flex-col sm:flex-row items-center justify-between">
              <h1 class="py-2 text-xl sm:text-2xl font-bold text-blues text-center sm:text-left">
                Add Blog
              </h1>


            </div>

            <div class="py-6 flex flex-col gap-6">
              <div class="flex flex-col sm:flex-row items-center gap-4 w-full">
                <div class="flex flex-col gap-2 w-full sm:w-1/2">
                  <label for="title" class="text-sm sm:text-base">Title <span class="text-red-400">*</span></label>
                  <input id="title" type="text" required
                    class="bg-[#F9FAFC] rounded-lg text-sm sm:text-base w-full p-3 border-none outline-none" />
                </div>

                <div class="flex flex-col gap-2 w-full sm:w-1/2">
                  <label for="category" class="flex gap-2 items-center text-sm sm:text-base">
                    Category <span class="text-red-400">*</span>
                    <svg @click="is_CategoryPopup = true" xmlns="http://www.w3.org/2000/svg"
                      class="w-5 h-5 cursor-pointer" viewBox="0 0 48 48">
                      <path fill="#4caf50"
                        d="M44,24c0,11.045-8.955,20-20,20S4,35.045,4,24S12.955,4,24,4S44,12.955,44,24z"></path>
                      <path fill="#fff" d="M21,14h6v20h-6V14z"></path>
                      <path fill="#fff" d="M14,21h20v6H14V21z"></path>
                    </svg>
                  </label>
                  <select id="category" required
                    class="bg-[#F9FAFC] rounded-lg text-sm sm:text-base w-full p-3 border-none outline-none">
                    <option v-for="category in categories" :key="category.id" :value="category.id">
                      {{ category.name }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="flex flex-col gap-2 w-full">
                <label for="thumbnail" class="text-sm sm:text-base">Thumbnail <span
                    class="text-red-400">*</span></label>
                <input id="thumbnail" type="file" required
                  class="bg-[#F9FAFC] rounded-lg text-sm sm:text-base w-full p-3 border-none outline-none" />
              </div>

              <div class="flex flex-col gap-2 w-full">
                <label for="description" class="text-sm sm:text-base">Description <span
                    class="text-red-400">*</span></label>
                <QuillEditor class="border rounded-2xl min-h-[250px] sm:min-h-[300px]" theme="snow" toolbar="full"
                  :content="description" contentType="html" />
              </div>

              <div class="flex items-center gap-2">
                <input type="checkbox" id="Published" class="w-4 h-4" />
                <label for="Published" class="text-sm sm:text-base">Published</label>
              </div>

            
              <div class="flex gap-3 flex-wrap">
                <button type="submit"
                  class="text-greens border border-greens rounded-lg text-lg font-semibold px-6 py-1.5">
                  Save
                </button>
                <router-link :to="{ name: 'InstructorHome' }"
                  class="bg-blues text-white rounded-lg text-lg font-semibold px-6 py-1.5">Back</router-link>
              </div>
            </div>
          </form>

        </div>
      </div>
    </div>

    <!-- Category Add in Course  -->
    <div v-if="is_CategoryPopup" class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div class="w-11/12 sm:w-2/3 lg:w-1/3 bg-white rounded-lg shadow-lg py-6 sm:py-10 px-6 sm:px-8 relative">
        <!-- Close Button -->
        <div @click="is_CategoryPopup = false" class="absolute right-4 top-4 cursor-pointer flex items-center">
          <span class="text-gray-700 hover:text-red-500 text-sm sm:text-base"></span>
          <img class="h-8 w-8 sm:h-6 sm:w-6 ml-2" src="@/assets/closebutton.svg" alt="Close" />
        </div>

        <h4 class="text-gray-700 text-center text-lg sm:text-xl font-semibold mt-6">Add Category</h4>

        <form class="w-full mx-auto mt-6" @submit.prevent="categoryAdd">
          <div class="flex flex-col gap-4">
            <div>
              <label for="categorytitle" class="text-sm sm:text-base font-medium">
                Title <span class="text-red-400">*</span>
              </label>
              <input id="categorytitle" type="text" required
                class="w-full p-3 border border-gray-300 rounded-md text-sm sm:text-base focus:outline-none focus:ring-2 focus:ring-blue-500" />
            </div>

            <!-- Submit Button -->
            <div class="flex justify-end">
              <button type="submit" class="text-white bg-blues rounded-lg text-sm sm:text-base px-6 sm:px-10 py-2">
                Add Category
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>

  </div>
</template>

<script>
import Header from "@/components/Instructor/Header.vue";
import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import axios from "axios";
import { mapGetters } from "vuex";
import FlashMessage from "@/components/FlashMessage.vue";

export default {
  components: {
    Header,
    QuillEditor,
    FlashMessage,
  },

  data() {
    return {
      is_CategoryPopup: false,
      categories: null,
      messages: {
        text: "",
        type: "null",
      },
    };
  },

  computed: mapGetters(["userInfo"]),

  async created() {
    this.categoryget();
  },
  methods: {
    // add the new Category in Course
    categoryAdd(el) {
      let bodyVal = {
        name: el.target.elements.categorytitle.value,
      };

      axios
        .post(
          `${process.env.VUE_APP_API}/blog-category/post/?Key=${this.userInfo.token_key}`,
          bodyVal
        )
        .then((response) => {
          console.log(response.data.message);
          if (response.data.status == 200) {
            this.messages = {
              text: "Category Added successfully",
              type: "success",
            };
            setTimeout(() => {
              this.messages = {
                text: "",
                type: "null",
              };
              this.is_CategoryPopup = false;
              this.categoryget();
            }, 1500);
          } else {
            this.messages = {
              text: response.data.message,
              type: "error",
            };
            setTimeout(() => {
              this.messages = {
                text: "",
                type: "null",
              };
            }, 2000);
          }
        });
    },
    categoryget() {
      axios
        .get(`${process.env.VUE_APP_API}/blog-category/list/?Key=${this.userInfo.token_key}`)
        .then((res) => {
          if (res.data.data.data) {
            this.categories = res.data.data.data;
          }
        });
    },
    async createBlogs(e) {
      let token = this.userInfo.token_key;

      this.description = document.querySelector(".ql-editor").innerHTML;
      console.log(this.description);

      let urls = `${process.env.VUE_APP_API}/blog-details/post/?Key=${token}`;
      let HeaderVal = new FormData();

      if (e.target.elements.thumbnail.files.length > 0) {
        var thumbnailVal = e.target.elements.thumbnail.files[0];
        HeaderVal.append("thumbnail", thumbnailVal);
      }

      HeaderVal.append("title", e.target.elements.title.value);

      HeaderVal.append(
        "category_id",
        parseInt(e.target.elements.category.value)
      );

      // HeaderVal.append("description", this.description);
      HeaderVal.append("content", this.description);

      HeaderVal.append("published", e.target.elements.Published.checked);
      HeaderVal.append("bolger_id", this.userInfo.user.id);

      await axios
        .post(urls, HeaderVal, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.log(response);
          if (response.data.status == 200) {
            this.messages = {
              text: "Blog Added successfully",
              type: "success",
            };
            setTimeout(() => {
              this.messages = {
                text: "",
                type: "null",
              };
              this.$refs.blogRef.reset();
              //   this.$router.push({
              //     name: "InstructorCourses"
              //   });
            }, 1500);
          } else {
            this.messages = {
              text: " Event Faild",
              type: "error",
            };
            setTimeout(() => {
              this.messages = {
                text: "",
                type: "null",
              };
            }, 2000);
          }
        });
    },
  },
};
</script>

<style></style>
