<template>
    <!-- <Navigation active="signup" /> -->
    <!-- <FlashMessage v-if="this.messages.text != ''" :message="this.messages" /> -->
    <div class="container flex flex-col-reverse md:flex-row items-center max-h-screen w-full overflow-hidden">
        <!-- Image Section -->
        <div class="w-full lg:w-4/5 h-72 md:h-96 lg:h-full overflow-hidden">
            <img src="@/assets/newd/login.png" alt="Login Image" class="w-[700px] h-[1024px] object-contain">
        </div>

        <!-- Signup Form Section -->
        <div class="lg:w-6/12 md:w-full md:ml-auto w-full mt-2 md:mt-0 mx-auto px-6 py-8 bg-white ">
            <h2 class="text-4xl font-bold text-start mb-6">Sign Up</h2>

            <form @submit.prevent="SignUp" class="flex flex-col gap-4">
                <div class="flex flex-col lg:flex-row gap-4">
                    <div class="w-full">
                        <label for="first_name" class="block text-sm font-medium">First Name *</label>
                        <input id="first_name" name="first_name" type="text" required
                            class="w-full px-4 py-2 border border-gray-300 rounded-lg " placeholder="First Name">
                    </div>
                    <div class="w-full">
                        <label for="last_name" class="block text-sm font-medium">Last Name *</label>
                        <input id="last_name" name="last_name" type="text" required
                            class="w-full px-4 py-2 border border-gray-300 rounded-lg " placeholder="Last Name">
                    </div>
                </div>

                <div>
                    <label for="affiliation" class="block text-sm font-medium">Affiliation</label>
                    <input id="affiliation" name="affiliation" type="text"
                        class="w-full px-4 py-2 border border-gray-300 rounded-lg " placeholder="Affiliation">
                </div>

                <div>
                    <label for="email" class="block text-sm font-medium">Email Address *</label>
                    <input id="email" name="email" type="email" autocomplete="email" required
                        class="w-full px-4 py-2 border border-gray-300 rounded-lg " placeholder="Email address">
                </div>

                <div class="flex flex-col md:flex-row gap-4">
                    <div class="w-full relative">
                        <label for="password" class="block text-sm font-medium">Password *</label>
                        <div class="relative">
                            <input v-model="password_watch" id="password" name="password" type="password" required
                                class="w-full px-4 py-2 border border-gray-300 rounded-lg " placeholder="Password">
                            <img v-if="passwordPrimary == 'password'" src="../assets/eyeslash.svg"
                                @click="hideShow('password')" class="absolute right-3 top-3 cursor-pointer">
                            <img v-else src="../assets/eye.svg" @click="hideShow('password')"
                                class="absolute right-3 top-3 cursor-pointer">
                        </div>
                    </div>
                    <div class="w-full relative">
                        <label for="retype_password" class="block text-sm font-medium">Retype Password *</label>
                        <div class="relative">
                            <input v-model="retype_password_watch" id="retype_password" name="retype_password"
                                type="password" required
                                class="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-700"
                                placeholder="Retype Password">
                            <img v-if="passwordConfirm == 'password'" src="../assets/eyeslash.svg"
                                @click="hideShow('retype_password')" class="absolute right-3 top-3 cursor-pointer">
                            <img v-else src="../assets/eye.svg" @click="hideShow('retype_password')"
                                class="absolute right-3 top-3 cursor-pointer">
                            <img id="exclamation" src="../assets/exclamationMark.svg" @click="clickss = !clickss" alt=""
                                class="absolute -right-5 bottom-2 cursor-pointer inline-block z-30 "
                                style="margin-right: 1px; ">
                        </div>
                    </div>
                </div>

                <button v-if="!loading" type="submit"
                    class="w-full bg-[#0F172A] text-white py-2 rounded-lg">Signup</button>
                <button v-else class="w-full bg-gray-500 text-white py-2 rounded-lg flex justify-center">
                    <img class="h-6" src="../assets/Pulse.gif" alt="Loading">
                </button>

                <p v-if="msg" class="text-center text-red-600 font-semibold">{{ msg }}</p>
            </form>

            <p class="text-sm text-gray-700 mt-4 text-center">Already have an account?
                <router-link to="/login" class="text-blue-500 hover:underline">Login</router-link>
            </p>
        </div>
    </div>

    <div class="fixed top-0 h-screen w-full bg-black bg-opacity-70 flex justify-center items-center z-50"
        v-if="varificationPopup == false" style="z-index:1000">
        <div id="resendMail"
            class="relative w-full px-2 h-1/2 lg:w-1/2 lg:h-1/2 2xl:w-1/3 2xl:h-1/3 bg-white flex flex-col justify-center items-center gap-4 rounded text-base  md:text-xl">
            <p class="text-red-500">This Mail id already Signed up </p>
            <p>Mail still not verified please check your mail and Activate Your Account</p>
            <p>If you lost your Activation mail link please use <span class="text-teal-600">Resend</span> option</p>
            <button class="px-4 py-1 border bg-teal-500 text-white rounded hover:bg-teal-700"
                @click="resendActivationMail">Resend</button>
            <img src="../assets/closebutton.svg" class="w-8 h-8 absolute top-10 right-10 cursor-pointer" alt=""
                @click="varificationPopup = true" />
        </div>
    </div>
</template>

<script>
// import FlashMessage from "@/components/flashMessage.vue";
import axios from "axios";
import {
    mapActions
} from "vuex";
export default {
    components: {
        // FlashMessage,
    },
    data: () => ({
        email: "",
        password: "",
        info: "",
        msg: "",
        retype_password_watch: "",
        password_watch: "",
        loading: false,
        passwordConfirm: "password",
        passwordPrimary: "password",
        varificationPopup: true,
        messages: {
            text: "",
            type: 'null',
        },

    }),

    mounted() {
        this.$refs.input.focus()
        var PWD = document.getElementById("password");

        PWD.onfocus = function () {
            document.getElementById("coloralerts").style.display = "block";
        }

        PWD.onblur = function () {
            document.getElementById("coloralerts").style.display = "none";
        }

        document.getElementById("exclamation").addEventListener("mouseover", function () {
            document.getElementById("keysforpassword").style.display = "block";
        })
        document.getElementById("exclamation").addEventListener("mouseout", function () {
            document.getElementById("keysforpassword").style.display = "none";
        })

    },
    methods: {
        ...mapActions(["loginUser"]),
        msg_change() {
            this.msg = ""
        },
        resendActivationMail() {
            // console.log(document.querySelector("#email-address").value)
            this.varificationPopup = true
            let mailId = {
                email: document.querySelector("#email-address").value
            }
            axios.post(`${process.env.VUE_APP_API}/active_email_send/`, mailId)
                .then(response => {
                    // console.log(response.data.message)
                    if (response.data.message == "Email send") {

                        this.messages = {
                            text: "Mail Send successfully",
                            type: 'success',
                        }
                    } else {
                        this.messages = {
                            text: " Event Faild",
                            type: 'error',
                        }
                    }
                    setTimeout(() => {
                        this.messages = {
                            text: "",
                            type: 'null',
                        }
                    }, 2500)

                })
                .catch(error => {
                    console.log(error)
                })

        },

        colorCode(data, inputclass) {
            document.getElementById(`${inputclass}`).value

            var PWD = document.getElementById(`${inputclass}`);
            var lowerCaseLetters = /[a-z]/g;
            var upperCaseLetters = /[A-Z]/g;
            var numbers = /[0-9]/g;
            document.getElementById(`${data}`).lastChild.childNodes.forEach(el => {
                el.style["border-color"] = 'red'
            })

            if ((PWD.value.match(lowerCaseLetters) || PWD.value.match(upperCaseLetters) || PWD.value.match(numbers) || PWD.value.length >= 8) && PWD.value.length != 0) {
                document.getElementById(`${data}`).lastChild.childNodes[0].style["border-color"] = 'green'

                if ((PWD.value.match(lowerCaseLetters) && PWD.value.match(upperCaseLetters)) ||
                    (PWD.value.match(lowerCaseLetters) && PWD.value.match(numbers)) ||
                    (PWD.value.match(lowerCaseLetters) && PWD.value.length >= 8) ||
                    (PWD.value.match(upperCaseLetters) && PWD.value.match(numbers)) ||
                    (PWD.value.match(upperCaseLetters) && PWD.value.length >= 8) ||
                    (PWD.value.match(numbers) && PWD.value.length >= 8)) {

                    document.getElementById(`${data}`).lastChild.childNodes[1].style["border-color"] = 'green'

                    if ((PWD.value.match(lowerCaseLetters) && PWD.value.match(upperCaseLetters) && PWD.value.match(numbers)) ||
                        (PWD.value.match(lowerCaseLetters) && PWD.value.match(numbers) && PWD.value.length >= 8) ||
                        (PWD.value.match(lowerCaseLetters) && PWD.value.length >= 8 && PWD.value.match(upperCaseLetters)) ||
                        (PWD.value.match(upperCaseLetters) && PWD.value.match(numbers) && PWD.value.length >= 8)

                    ) {
                        document.getElementById(`${data}`).lastChild.childNodes[2].style["border-color"] = 'green'
                        if (PWD.value.match(lowerCaseLetters) && PWD.value.match(upperCaseLetters) && PWD.value.match(numbers) && PWD.value.length >= 8) {
                            document.getElementById(`${data}`).lastChild.childNodes[3].style["border-color"] = 'green'
                        } else {
                            document.getElementById(`${data}`).lastChild.childNodes[3].style["border-color"] = 'red'
                        }
                    } else {
                        document.getElementById(`${data}`).lastChild.childNodes[2].style["border-color"] = 'red'

                    }
                } else {
                    document.getElementById(`${data}`).lastChild.childNodes[1].style["border-color"] = 'red'
                }
            } else {

                document.getElementById(`${data}`).lastChild.childNodes[0].style["border-color"] = 'red'
            }

        },

        hideShow(id) {
            const passwordField = document.querySelector(`#${id}`)
            passwordField.getAttribute('type') === 'password' ? passwordField.setAttribute('type', 'text') : passwordField.setAttribute('type', 'password')
            switch (id) {
                case "password":
                    this.passwordPrimary = passwordField.getAttribute('type')
                    break;
                case "retype_password":
                    this.passwordConfirm = passwordField.getAttribute('type')
                    break;
            }
        },

        SignUp: async function (submitEvent) {

            let is_activation
            var emailID = submitEvent.target.elements.email.value;
            var passWord = submitEvent.target.elements.password.value;
            var passWordRetype = submitEvent.target.elements.retype_password.value;
            var first_name = submitEvent.target.elements.first_name.value;
            var last_name = submitEvent.target.elements.last_name.value;

            let mailId = {
                email: emailID.toLowerCase()
            }
            // console.log(mailId)
            if (passWord == passWordRetype) {
                await axios.post(`${process.env.VUE_APP_API}/email_verification/`, mailId)
                    .then(response => {
                        if (response.data.message == "Email already verified") {
                            is_activation = true
                            // console.log("Email already verified")
                        } else if (response.data.message == "Invalid Email credentials") {
                            // console.log(" invalid Email credentials") 
                            is_activation = true

                        } else {
                            this.varificationPopup = false
                            is_activation = false
                            this.loading = false;
                            //    console.log("Email not verified")

                        }
                    })
                    .catch(error => {
                        console.log(error)
                    })
            } else {
                this.msg = "Password mismatched!!!"
                setTimeout(this.msg_change, 3000)
                this.loading = false

            }
            if (is_activation) {
                this.loading = true

                var dataSignUp = {}
                dataSignUp['email'] = emailID.toLowerCase(),
                    dataSignUp['password'] = passWord,
                    dataSignUp['first_name'] = first_name,
                    dataSignUp['is_active'] = false,
                    dataSignUp['last_name'] = last_name

                if (passWord == passWordRetype) {

                    axios.post(`${process.env.VUE_APP_API}/register`, dataSignUp)
                        .then(async response => {
                            let data = {
                                username: emailID.toLowerCase(),
                                password: passWord,
                            }
                            await this.loginUser(data);
                            this.loading = false
                            setTimeout(() => {
                                this.$router.push({
                                    name: 'Thanks'
                                });
                            }, 1000)

                        })
                        .catch(error => {
                            // console.log(error.response.data.email[0])
                            this.msg = error.response.data.email[0]
                            setTimeout(this.msg_change, 3000)
                            this.loading = false
                        })
                } else {
                    this.msg = "Password mismatched!!!"
                    setTimeout(this.msg_change, 3000)
                    this.loading = false

                }
            } else {
                console.log("MailID have a issue")
            }
        }

    }
}
</script>

<style></style>
