<template>
  <div
    class="fixed bg-primary bg-opacity-30 top-0 left-0 right-0 bottom-0 z-50 flex items-center justify-center"
  >
    <div class="lg:w-[40%] 2xl:w-[30%] bg-white rounded-xl p-10 overflow-y-auto max-h-[600px]">
      <div
        class="w-full flex flex-col relative rounded-2xl"
      >
        <div class="w-full flex flex-col gap-8">
          
          <h1 class="font-bold text-xl text-primary text-center">
            Do you want to logout?
          </h1>

          <div class="flex items-center justify-center gap-5">
            <button @click="$emit('cancel')" class="bg-blues text-white rounded-xl text-sm font-semibold px-6 py-2">
              Cancel
            </button>

            <button @click="$emit('logOut')" class="bg-blues text-white rounded-xl text-sm font-semibold px-6 py-2">
              Logout
            </button>
          </div>      
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LogOut",
};
</script>
