<template>
  <div>
    <Header />

    <FlashMessage v-if="this.messages.text != ''" :message="this.messages" />
    <confirmPopup v-if="popuplessonDelete == true" :title="titlepass" @cancelClick="popuplessonDelete = false"
      @confirmClick="deleteLesson(idpass)" />
    <confirmPopup v-if="popupsectionDelete == true" :title="titlepass" @cancelClick="popupsectionDelete = false"
      @confirmClick="deleteSection(idpass)" />

    <div class="py-10">
      <div class="container px-6 mx-auto max-w-[1200px] flex flex-col gap-10">
        <div>
          <div class="rounded-3xl pt-10 px-10 pb-2 heightfix overflow-y-auto"
            style="box-shadow: 0px 2px 20px 0px #105c8e26">

            <div class="flex  justify-start mb-4">
              <div class="flex items-center gap-4">
                <router-link :to="{
                  name: 'InstructorCoursePreview',
                  params: { courseid: this.course_id },
                }" class="bg-blues text-xs font-bold text-white px-5 py-1.5 rounded-full">Preview</router-link>
                <router-link :to="{ name: 'InstructorCourses' }"
                  class="bg-blues text-xs font-bold text-white px-5 py-1.5 rounded-full">Back</router-link>
              </div>
            </div>

            <div class="flex items-center justify-center">
              <!-- <div class="hidden lg:flex"></div> -->
              <div>
                <h1 class="py-2 text-xl text-center font-bold text-blues">
                  Update Course
                </h1>
              </div>
            </div>

            <div
              class="mt-10 flex items-center justify-center rounded-lg cursor-pointer gap-2 px-4 py-5 border border-blues"
              @click="popUpsection = true">
              <img src="@/assets/newd/icon1.svg" alt />
              <p class="text-blues text-sm">Add Unit</p>

            </div>

            <div class="mt-10 pb-10 flex flex-col gap-10">
              <div v-for="section in sectionInfo.data" class :key="section">
                <div class="flex items-center justify-between border border-blues rounded-lg px-6 py-5">
                  <h1 class="text-blues font-semibold">{{ section.title.slice(0, 20) }}<p
                      v-if="section.title.length > 20" class="text-blues text-sm mt-1 font-semibold">...More</p>
                  </h1>

                  <div class="relative dropdown-container">
                    <div @click="addOperation(section.id)" class="cursor-pointer">
                      <img src="@/assets/newd/colon.png" alt />
                    </div>
                    <div v-if="activeOperationMenu == section.id"
                      class="absolute top-10 w-[150px] z-30 bg-white rounded-xl shadow-lg right-0 py-1"
                      style="box-shadow: 0px 2px 20px 0px #00000040">
                      <div class="flex items-center gap-2 px-4 py-2 cursor-pointer" @click="
                        (popUpLesson = true), (selectUnitId = section.id)
                        ">
                        <img src="@/assets/newd/icon1.svg" class="w-5" alt />
                        <p class="text-blues text-sm">Add Lesson</p>
                      </div>

                      <div class="flex items-center gap-2 px-4 py-2 cursor-pointer" @click="
                        sectionval = section;
                      popUpsectionUpdate = true;
                      ">
                        <img src="@/assets/newd/icon2.svg" class="w-5" alt />
                        <p class="text-greens text-sm">Edit</p>
                      </div>
                      <div class="flex items-center gap-2 px-4 py-2 cursor-pointer" @click="
                        titlepass = section.title;
                      popupsectionDelete = true;
                      idpass = section.id;
                      ">
                        <img src="@/assets/newd/icon3.svg" class="w-5" alt />
                        <p class="text-[#F36540] text-sm">Delete</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div v-if="lessonInfo != null">
                  <div v-for="lesson in lessonInfo.results" :key="lesson">
                    <div v-if="section.id == lesson.section_id"
                      class="bg-blues text-white rounded-lg my-3 justify-between relative dropdown-container">
                      <div @click="addLessonOperation(lesson.id)" class="px-6 py-5 flex items-center justify-between">
                        <p class="text-sm font-semibold">{{ lesson.title }}</p>
                        <img src="@/assets/newd/3dot.png" alt class="px-4 cursor-pointer" />
                      </div>

                      <div v-if="activeLessonOperationMenu == lesson.id"
                        class="absolute top-10 w-[150px] z-30 bg-white rounded-xl shadow-lg right-0 py-1"
                        style="box-shadow: 0px 2px 20px 0px #00000040">
                        <div class="flex items-center gap-2 px-4 py-2 cursor-pointer" @click="
                          lessonVal = lesson;
                        popUp = true;
                        descriptionUpdate = lesson.content;
                        ">
                          <img src="@/assets/newd/icon2.svg" class="w-5" alt />
                          <p class="text-greens text-sm">Edit</p>
                        </div>
                        <div class="flex items-center gap-2 px-4 py-2 cursor-pointer" @click="
                          titlepass = lesson.title;
                        popuplessonDelete = true;
                        idpass = lesson.id;
                        ">
                          <img src="@/assets/newd/icon3.svg" class="w-5" alt />
                          <p class="text-[#F36540] text-sm">Delete</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="popUpsectionUpdate"
      class="fixed bg-primary bg-opacity-30 top-0 left-0 right-0 bottom-0 z-50 flex items-center justify-center p-4">
      <div
        class="w-full max-w-lg sm:max-w-md md:max-w-lg lg:max-w-xl bg-white rounded-xl p-6 sm:p-8 md:p-10 overflow-y-auto max-h-[80vh]">
        <div class="flex items-center justify-between">
          <h3 class="text-lg sm:text-xl font-bold text-blues">Update Unit</h3>
          <img src="@/assets/newd/close.png" class="cursor-pointer w-5 h-5 sm:w-6 sm:h-6" alt
            @click="popUpsectionUpdate = false" />
        </div>

        <div class="w-full mt-4 flex flex-col p-4 sm:p-6 relative border border-gray-100 rounded-2xl">
          <form ref="addSection" @submit.prevent="updatesection(sectionval)"
            class="flex flex-col gap-4 text-xs sm:text-sm md:text-base h-full">
            <div class="flex flex-col gap-2 w-full">
              <label for="title" class="text-left">Title</label>
              <input id="title" v-model="sectionval.title" type="text"
                class="border border-gray-300 rounded text-xs sm:text-sm md:text-base py-2 px-3 focus:outline-none focus:ring-2 focus:ring-blue-500 w-full"
                required />
            </div>
            <div class="flex justify-end pb-4 mt-3">
              <button type="submit" class="text-white border border-blues rounded-lg text-sm px-10 py-2 bg-blues">
                Update
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>


    <div v-if="popUpsection"
      class="fixed bg-primary bg-opacity-30 top-0 left-0 right-0 bottom-0 z-50 flex items-center justify-center px-6 md:px-0">
      <div
        class="w-full sm:w-[90%] md:w-[70%] lg:w-[40%] bg-white rounded-xl p-6 sm:p-8 md:p-10 overflow-y-auto max-h-[600px] ">
        <div class="flex items-center justify-between">
          <h3 class="text-xl font-bold text-blues">Add Unit</h3>
          <img src="@/assets/newd/close.png" class="cursor-pointer" alt @click="popUpsection = false" />
        </div>

        <div class="w-full mt-4 flex flex-col p-4 sm:p-6 relative border border-gray-100 rounded-2xl">
          <div class="w-full">
            <form ref="addSection" @submit.prevent="createNewsection"
              class="flex flex-col gap-4 text-xs sm:text-sm md:text-base h-full">

              <div class="flex flex-col gap-2 w-full">
                <label for="title" class="text-left">Title</label>
                <input id="title" type="text"
                  class="border border-gray-300 rounded text-xs sm:text-sm md:text-base py-2 px-2 focus:outline-none focus:ring-2 focus:ring-blue-400"
                  required />
              </div>

              <div class="flex justify-end pb-4 mt-3">
                <button type="submit"
                  class="text-black border border-black rounded-lg text-sm px-6 sm:px-8 md:px-10 py-2 hover:bg-black hover:text-white transition duration-300">
                  Submit
                </button>
              </div>

            </form>
          </div>
        </div>
      </div>

    </div>

    <div v-if="popUp"
      class="fixed bg-primary bg-opacity-30 top-0 left-0 right-0 bottom-0 z-50 flex items-center justify-center px-6 md:px-0">
      <div class="w-full max-w-4xl bg-white rounded-xl p-6 md:p-10 overflow-y-auto h-[90%]">
        <div class="flex items-center justify-between">
          <h3 class="text-xl font-bold text-blues">Update Lesson</h3>
          <img src="@/assets/newd/close.png" class="cursor-pointer" alt @click="popUp = false" />
        </div>

        <div class="w-full mt-4 flex flex-col p-4 md:p-6 border border-gray-100 rounded-2xl">
          <form ref="lessonaddFn" @submit.prevent="updateLesson">
            <div class="py-6 flex flex-col gap-6">
              <div class="grid grid-cols-1 md:grid-cols-2 gap-5 w-full">
                <div class="flex flex-col gap-3">
                  <label for="title" class="text-sm">Title <span class="text-red-400">*</span></label>
                  <input id="title" type="text" v-model="lessonVal.title"
                    class="bg-gray-100 rounded-lg text-sm w-full p-3 border-none outline-none" />
                </div>
                <div class="flex flex-col gap-3">
                  <label for="duration" class="text-sm">Duration <span class="text-red-400">*</span></label>
                  <input id="duration" type="number" v-model="lessonVal.duration"
                    class="bg-gray-100 rounded-lg text-sm w-full p-3 border-none outline-none" />
                </div>
              </div>

              <div class="flex flex-col gap-3">
                <label for="section" class="text-sm">Unit</label>
                <select name="section" id="section" class="bg-gray-50 border-gray-300 rounded-md p-3 w-full"
                  v-model="lessonVal.section_id">
                  <option v-for="section in sectionInfo.data" :key="section.id" :value="section.id">
                    {{ section.title }}
                  </option>
                </select>
              </div>

              <div class="w-full">
                <form enctype="multipart/form-data" class="flex flex-col gap-4 w-full" method="POST"
                  @submit.prevent="videoUploadURLUpdate">
                  <div class="flex flex-col gap-3">
                    <label class="font-bold text-sm">Select video file to upload.</label>
                    <input type="file"
                      class="form-control bg-gray-100 rounded-lg text-sm w-full p-3 border-none outline-none"
                      id="fileuploadUpdate" accept="video/*" required />
                  </div>
                  <div class="flex justify-end">
                    <input type="submit" value="Upload" id="submit"
                      class="text-white bg-blues rounded-lg text-sm font-bold px-6 py-2" />
                  </div>
                </form>
                <div id="uploaded_files" class="mt-2 text-sm text-gray-600"></div>
              </div>


              <div class="flex flex-col gap-3">
                <label for="getVideoU" class="text-sm">Video URL</label>
                <input type="text" v-model="lessonVal.video_url" @mouseleave="convertToEmbed('getVideoU')"
                  id="getVideoU" class="bg-gray-100 rounded-lg text-sm w-full p-3 border-none outline-none" />
              </div>

              <div class="flex flex-col gap-3">
                <label class="text-sm">Description <span class="text-red-400">*</span></label>
                <QuillEditor theme="snow" class="border rounded-2xl min-h-[200px]" toolbar="full"
                  :content="descriptionUpdate" contentType="html" />
              </div>

              <div class="grid grid-cols-1 md:grid-cols-2 gap-5 w-full">
                <div class="flex flex-col gap-3">
                  <label class="text-sm">Keywords <span class="text-red-400">*</span></label>
                  <input type="text" v-model="lessonVal.keywords"
                    class="bg-gray-100 rounded-lg text-sm w-full p-3 border-none outline-none" />
                </div>
                <div class="flex flex-col gap-3">
                  <label class="text-sm">Order By</label>
                  <input type="number" v-model="lessonVal.orderby"
                    class="bg-gray-100 rounded-lg text-sm w-full p-3 border-none outline-none" />
                </div>
              </div>

              <div class="grid grid-cols-1 md:grid-cols-2 gap-5 w-full">
                <div class="flex flex-col gap-3">
                  <label class="text-sm">PDF File</label>
                  <input id="pdf_files" type="file" accept=".pdf"
                    class="bg-gray-100 rounded-lg text-sm w-full p-3 border-none outline-none" />
                  <a :href="lessonVal.pdf_file" class="text-blues text-xs font-semibold">View PDF</a>
                </div>

                <div class="flex flex-col gap-3">
                  <label class="text-sm">Audio File</label>
                  <input id="audiofile" type="file" accept="audio/*"
                    class="bg-gray-100 rounded-lg text-sm w-full p-3 border-none outline-none" />
                  <audio controls v-if="lessonVal.audio_file" class="rounded-xl">
                    <source :src="lessonVal.audio_file" type="audio/ogg" />
                    <source :src="lessonVal.audio_file" type="audio/mpeg" />
                    Your browser does not support the audio element.
                  </audio>
                </div>
              </div>

              <div class="flex justify-end">
                <button type="submit" class="text-white bg-blues rounded-lg text-lg px-6 py-2">Save</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div v-if="popUpLesson"
      class="fixed bg-primary bg-opacity-30 top-0 left-0 right-0 bottom-0 z-50 flex items-center justify-center px-6 md:px-0">
      <div class="w-full md:w-[80%] lg:w-[60%] bg-white rounded-xl p-6 md:p-10 overflow-y-auto h-[90%]">
        <div class="flex items-center justify-between">
          <div class="flex items-center space-x-4">
            <h3 class="text-sm font-medium px-4 py-1 rounded-xl cursor-pointer" @click="takeTab = 'add_lesson'"
              :class="{ 'bg-blues text-white': takeTab == 'add_lesson', 'text-black': takeTab != 'add_lesson' }">
              Add Lesson
            </h3>
            <h3 class="text-sm font-medium px-4 py-1 rounded-xl cursor-pointer" @click="takeTab = 'add_quiz'"
              :class="{ 'bg-blues text-white': takeTab == 'add_quiz', 'text-black': takeTab != 'add_quiz' }">
              Add Quiz
            </h3>
          </div>
          <img src="@/assets/newd/close.png" class="cursor-pointer" alt="Close" @click="popUpLesson = false" />
        </div>
        <div v-if="takeTab == 'add_lesson'" class="w-full mt-4 p-4 md:p-6 border border-gray-100 rounded-2xl">
          <form ref="lessonaddFn" @submit.prevent="createNewLesson" class="space-y-6">
            <div class="grid grid-cols-1 md:grid-cols-2 gap-5">
              <div class="flex flex-col gap-2">
                <label for="title" class="text-sm">Title <span class="text-red-400">*</span></label>
                <input id="title" type="text" required
                  class="bg-gray-100 rounded-lg text-sm p-3 border-none outline-none" />
              </div>
              <div class="flex flex-col gap-2">
                <label for="duration" class="text-sm">Duration <span class="text-red-400">*</span></label>
                <input id="duration" type="number" required
                  class="bg-gray-100 rounded-lg text-sm p-3 border-none outline-none" />
              </div>
            </div>

            <div class="w-full">
              <form enctype="multipart/form-data" class="flex flex-col gap-4" method="POST"
                @submit.prevent="videoUploadURL">
                <div class="flex flex-col gap-2">
                  <label class="font-bold text-sm">Select video file to upload.</label>
                  <input type="file" class="bg-gray-100 rounded-lg p-3 border-none outline-none" id="fileupload"
                    accept="video/*" required />
                </div>
                <div class="flex justify-end">
                  <input type="submit" value="Upload" id="submit"
                    class="text-white bg-blues rounded-lg text-sm font-bold px-4 py-2 cursor-pointer" />
                </div>
              </form>
              <div id="uploaded_files" class="mt-2"></div>
            </div>


            <div class="flex flex-col gap-2">
              <label for="videoUrl" class="text-sm">Video URL</label>
              <input id="videoUrl" type="text" @mouseleave="convertToEmbed('videoUrl')"
                class="bg-gray-100 rounded-lg p-3 border-none outline-none" />
            </div>

            <div class="flex flex-col gap-2">
              <label class="text-sm">Description <span class="text-red-400">*</span></label>
              <QuillEditor class="border rounded-2xl h-48" toolbar="full" :content="description" contentType="html" />
            </div>

            <div class="grid grid-cols-1 md:grid-cols-2 gap-5">
              <div class="flex flex-col gap-2">
                <label for="keywords" class="text-sm">Keywords <span class="text-red-400">*</span></label>
                <input id="keywords" type="text" required class="bg-gray-100 rounded-lg p-3 border-none outline-none" />
              </div>
              <div class="flex flex-col gap-2">
                <label for="orderby" class="text-sm">Order By</label>
                <input id="orderby" type="number" required
                  class="bg-gray-100 rounded-lg p-3 border-none outline-none" />
              </div>
            </div>

            <div class="grid grid-cols-1 md:grid-cols-2 gap-5">
              <div class="flex flex-col gap-2">
                <label for="pdffile" class="text-sm">PDF File</label>
                <input id="pdffile" type="file" accept=".pdf"
                  class="bg-gray-100 rounded-lg p-3 border-none outline-none" />
              </div>
              <div class="flex flex-col gap-2">
                <label for="audio_file" class="text-sm">Audio File</label>
                <input id="audio_file" type="file" accept="audio/*"
                  class="bg-gray-100 rounded-lg p-3 border-none outline-none" />
              </div>
            </div>

            <div class="flex justify-end">
              <button type="submit" class="text-white bg-blues rounded-lg text-lg px-6 py-2">Save</button>
            </div>
          </form>
        </div>
        <div v-if="takeTab == 'add_quiz'"
          class="w-full mt-4 flex flex-col p-6 relative border border-gray-100 rounded-2xl">
          <form ref="addQuizRefs" @submit.prevent="createQuizOfSection" class="w-full flex flex-col gap-3">
            <div class="flex flex-col gap-3 w-full">
              <label for="title" class="text-sm font-medium">
                Title <span class="text-red-400">*</span>
              </label>
              <input id="title" type="text" required
                class="bg-gray-100 rounded-lg text-sm w-full p-3 border outline-none" />
            </div>

            <div class="flex flex-col gap-3 w-full">
              <label for="content" class="text-sm font-medium">
                Content <span class="text-red-400">*</span>
              </label>
              <input id="content" type="text" required
                class="bg-gray-100 rounded-lg text-sm w-full p-3 border outline-none" />
            </div>

            <div class="flex flex-col gap-3 w-full">
              <label for="order" class="text-sm font-medium">
                Order By <span class="text-red-400">*</span>
              </label>
              <input id="order" type="number" required
                class="bg-gray-100 rounded-lg text-sm w-full p-3 border outline-none" />
            </div>

            <div class="flex items-center justify-end mt-3">
              <button @click="addForm" class="bg-blues text-sm text-white rounded-md px-4 py-2">
                Add Question
              </button>
            </div>

            <div v-for="(form, index) in forms" :key="index" class="w-full mt-3 bg-gray-200 rounded-lg p-4">
              <div class="flex flex-col gap-5">
                <div class="flex items-center justify-between bg-primary px-6 py-3 rounded-t-lg w-full">
                  <label class="text-lg text-white">Question {{ index + 1 }}:</label>
                  <button v-if="index != 0" type="button" class="bg-red-700 text-xs text-white rounded-md px-3 py-1"
                    @click="removeForm(index)">
                    Remove Question
                  </button>
                </div>

                <div class="px-4 sm:px-6 py-2">
                  <textarea v-model="form.question_text" rows="2" required
                    class="p-2 sm:p-4 md:p-4 rounded-md w-full text-sm sm:text-base" placeholder="Enter your question">
                  </textarea>
                </div>

              </div>

              <div class="py-3 flex flex-col gap-2 mt-1 px-6">
                <div v-for="(address, addrIndex) in form.answers" :key="addrIndex"
                  class="flex flex-wrap items-center gap-3 py-2">
                  <label class="text-sm text-gray-700">Option {{ addrIndex + 1 }}:</label>
                  <input type="text" v-model="address['answer_text']"
                    class="flex-1 border rounded-md p-2 text-sm w-[150px]" placeholder="Enter your option" required />
                  <input type="checkbox" v-model="address['is_correct']" class="w-4 h-4" />
                  <label class="text-sm">Is Answer</label>
                </div>
              </div>
            </div>

            <div class="flex justify-end mt-4">
              <button type="submit" class="text-white bg-blues rounded-lg text-lg px-6 py-2">
                Save
              </button>
            </div>
          </form>
        </div>

      </div>
    </div>


  </div>
</template>

<script>
class FileUpload {
  constructor(input) {
    this.input = input;
    this.max_length = 1024 * 1024 * 1024 * 100;
  }

  create_progress_bar() {
    var progress = `<div class="file-icon">
                        <i class="fa fa-file-o" aria-hidden="true"></i>
                    </div>
                    <div class="file-details">
                        <p class="filename"></p>
                        <small class="textbox"></small>
                        <div class="progress">
                            <div class="progress-bar bg-success" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%">
                            </div>
                        </div>
                    </div>`;
    document.getElementById("uploaded_files").innerHTML = progress;
  }

  upload(append_id) {
    this.create_progress_bar();
    this.initFileUpload(append_id);
  }

  initFileUpload(append_id) {
    this.file = this.input.files[0];
    this.upload_file(0, null, append_id);
  }

  //upload file
  upload_file(start, model_id, append_id) {
    var end;
    var self = this;
    var existingPath = model_id;
    var formData = new FormData();
    var nextChunk = start + this.max_length + 1;
    var currentChunk = this.file.slice(start, nextChunk);
    var uploadedChunk = start + currentChunk.size;
    if (uploadedChunk >= this.file.size) {
      end = 1;
    } else {
      end = 0;
    }

    formData.append("title", "title");

    formData.append("file", currentChunk);
    formData.append("filename", this.file.name);
    $(".filename").text(this.file.name);
    $(".textbox").text("Uploading file");
    formData.append("end", end);
    formData.append("existingPath", existingPath);
    formData.append("nextSlice", nextChunk);

    $.ajax({
      xhr: function () {
        var xhr = new XMLHttpRequest();
        xhr.upload.addEventListener("progress", function (e) {
          if (e.lengthComputable) {
            if (self.file.size < self.max_length) {
              var percent = Math.round((e.loaded / e.total) * 100);
            } else {
              var percent = Math.round((uploadedChunk / self.file.size) * 100);
            }
            $(".progress-bar").css("width", percent + "%");
            $(".progress-bar").text(percent + "%");
          }
        });
        return xhr;
      },

      url: "https://api.galecta.com/video-uploader/",
      type: "POST",
      dataType: "json",
      cache: false,
      processData: false,
      contentType: false,
      data: formData,
      error: function (xhr) {
        alert(xhr.statusText);
      },
      success: function (res) {
        if (nextChunk < self.file.size) {
          // upload file in chunks
          existingPath = res.existingPath;
          self.upload_file(nextChunk, existingPath);
        } else {
          // upload complete
          $(".textbox").text(res.data);
          $(`#${append_id}`).val(
            "https://api.galecta.com/media/" + res.existingPath
          );
          console.log(res, "resp::");
          // alert(JSON.parse(res))
        }
      },
    });
  }
}

import Header from "@/components/Instructor/Header.vue";
import axios from "axios";
import { mapGetters } from "vuex";

import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";

import FlashMessage from "@/components/FlashMessage.vue";
import ConfirmPopup from "@/components/ConfirmPopup.vue";

export default {
  components: {
    Header,
    QuillEditor,
    FlashMessage,
    ConfirmPopup,
  },

  data() {
    return {
      contentType: "html",
      markdown: "# Hello",
      lessonInfo: "",
      lessonVal: "",
      TimeTableVal: "",
      TimeTablepopUp: false,
      popUp: false,
      popUpLesson: false,
      sectionInfo: "",
      popUpsection: false,
      sectionval: "",
      popUpsectionUpdate: false,
      messages: {
        text: "",
        type: "null",
      },
      popupsectionDelete: false,
      idpass: "",
      popuplessonDelete: false,
      titlepass: "",
      lesson_id: "",
      popupquiz: false,
      is_documentPopup: false,
      is_tablePopup: false,
      pageX: 0,
      pageY: 0,
      uploadDragoverTracking: false,
      uploadDragoverEvent: false,
      documentList: "",
      timeTable: "",
      timeTable_val: "",
      popupDocumentDelete: false,
      selectUnitId: null,
      activeOperationMenu: null,
      activeLessonOperationMenu: null,
      description: "",
      descriptionUpdate: "",
      takeTab: "add_lesson",

      forms: [
        {
          question_text: "",
          answers: [
            { answer_text: "", is_correct: false },
            { answer_text: "", is_correct: false },
            { answer_text: "", is_correct: false },
            { answer_text: "", is_correct: false },
          ],
        },
      ],
    };
  },

  computed: mapGetters(["userInfo"]),

  props: ["course_id"],

  async created() {
    this.lessoninfoGet();
    this.sectionInfoGet();
  },

  mounted() {
    const script = document.createElement("script");
    script.src =
      "https://ajax.googleapis.com/ajax/libs/jquery/3.3.1/jquery.min.js";
    script.onload = () => {
      console.log("jQuery loaded");
      this.initJQuery();
    };
    document.head.appendChild(script);

    // fetch(videoAPIUrl)
    //   .then(response => response.blob())
    //   .then(blob => {
    //     const videoURL = URL.createObjectURL(blob);
    //     videoPlayer.src = videoURL;
    //   })
    //   .catch(error => {
    //     console.error("Error fetching the video:", error);
    //   });
    document.addEventListener('click', this.handleClickOutside);
  },

  beforeUnmount() {
    document.removeEventListener('click', this.handleClickOutside);
  },

  methods: {
    addForm() {
      this.forms.push({
        question_text: "",
        answers: [
          { answer_text: "", is_correct: false },
          { answer_text: "", is_correct: false },
          { answer_text: "", is_correct: false },
          { answer_text: "", is_correct: false },
        ],
      });
    },

    removeForm(index) {
      if (this.forms.length > 1) {
        this.forms.splice(index, 1);
      } else {
        alert("At least one form must be present!");
      }
    },

    // addAddress(formIndex) {
    //   this.forms[formIndex].options.push({ option1: "", is_answer: false }, { option2: "", is_answer: false }, { option3: "", is_answer: false }, { option4: "", is_answer: false });
    // },
    //
    // removeAddress(formIndex, addressIndex) {
    //   if (this.forms[formIndex].options.length > 1) {
    //     this.forms[formIndex].options.splice(addressIndex, 1);
    //   } else {
    //     alert("At least one address must be present!");
    //   }
    // },

    convertToEmbed(id) {
      // console.log("video embed convert:::");
      let urlsBody = document.getElementById(id);
      let urls = document.getElementById(id).value;

      const videoId = this.extractVideoId(urls);

      if (videoId != null) {
        // this.embedLink = `https://www.youtube.com/embed/${videoId}`;
        // return `https://www.youtube.com/embed/${videoId}`
        urlsBody.value = `${videoId}`;
      } else {
        urlsBody.value = urls;
        // alert('Invalid YouTube URL');
      }
    },

    // convertToEmbedVale(value) {
    //     const videoId = this.extractVideoId(value);

    //     if (videoId != null) {
    //         return videoId;
    //     } else {
    //         return value;
    //     }
    // },

    extractVideoId(url) {
      const regExp =
        /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
      const match = url.match(regExp);

      return match && match[2].length === 11
        ? `https://www.youtube.com/embed/${match[2]}`
        : null;

      // return (match && match[2].length === 11)
      // ? match[2]
      // : null;
    },

    videoUploadURL() {
      var uploader = new FileUpload(document.querySelector("#fileupload"));
      console.log(document.querySelector("#fileupload"));
      uploader.upload("videoUrl");
    },

    videoUploadURLUpdate() {
      // fileuploadUpdate
      var uploader = new FileUpload(
        document.querySelector("#fileuploadUpdate")
      );
      console.log(document.querySelector("#fileuploadUpdate"));
      uploader.upload("getVideoU");
    },

    initJQuery() {
      $(document).ready(() => {
        console.log("jQuery is ready in this component");
      });
    },

    addLessonOperation(id) {
      this.activeOperationMenu = null;
      if (this.activeLessonOperationMenu == id) {
        this.activeLessonOperationMenu = null;
      } else {
        this.activeLessonOperationMenu = id;
      }
    },
    addOperation(id) {
      this.activeLessonOperationMenu = null;
      if (this.activeOperationMenu == id) {
        this.activeOperationMenu = null;
      } else {
        this.activeOperationMenu = id;
      }
    },

    async lessoninfoGet() {
      await axios
        .get(
          `${process.env.VUE_APP_API}/lessons/?course_name=${this.course_id}&Key=${this.userInfo.token_key}`
        )
        .then((response) => {
          if (Object.keys(response.data) == "error") {
            this.lessonInfo = null;
          } else {
            this.lessonInfo = response.data;
            // this.descriptionUpdate = response.data;
          }
        });
    },

    async sectionInfoGet() {
      await axios
        .get(
          `${process.env.VUE_APP_API}/section/?course_name=${this.course_id}`
        )
        .then((response) => {
          this.sectionInfo = response.data;
        });
    },

    async updateLesson(el) {
      let urls = `${process.env.VUE_APP_API}/lesson_file_update/${this.lessonVal.id}/?Key=${this.userInfo.token_key}`;

      this.descriptionUpdate = document.querySelector(".ql-editor").innerHTML;
      console.log(this.descriptionUpdate);

      let HeaderVal = new FormData();
      console.log("HeaderVal::", HeaderVal);

      let audio_val = el.target.elements.audiofile.files;

      let file_val = el.target.elements.pdf_files.files;

      if (file_val.length > 0 || audio_val.length > 0) {
        if (file_val.length > 0) {
          HeaderVal.append("pdf_file", el.target.elements.pdf_files.files[0]);
        }
        if (audio_val.length > 0) {
          HeaderVal.append("audio_file", el.target.elements.audiofile.files[0]);
        }
        await axios
          .put(urls, HeaderVal, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((r) => {
            console.log(r);
            if (r.data.status == 200) {
              this.messages = {
                text: "lesson updated successfully",
                type: "success",
              };
              setTimeout(() => {
                this.messages = {
                  text: "",
                  type: "",
                };
                this.popUp = false;
              }, 2000);
            } else {
              this.messages = {
                text: r.data.message,
                type: "error",
              };
              setTimeout(() => {
                this.messages = {
                  text: "",
                  type: "",
                };
                this.popUp = false;
              }, 2000);
            }
          });
      } else {
        let token_key = this.userInfo.token_key;
        let infoDetails = {
          id: this.lessonVal.id,
          title: this.lessonVal.title,
          duration: this.lessonVal.duration,
          video_url: document.getElementById("getVideoU").value,
          status: this.lessonVal.status,
          created_at: this.lessonVal.created_at,
          updated_at: new Date(),
          content: this.descriptionUpdate,
          keyworeds: this.lessonVal.keyworeds,
          slug: this.lessonVal.slug,
          section_id: this.lessonVal.section_id,
          course_name: this.lessonVal.course_name,
          orderby: this.lessonVal.orderby,
        };
        console.log("HeaderVal::", infoDetails);
        this.popUp = false;
        await axios
          .put(
            `${process.env.VUE_APP_API}/lessonupdate/${this.lessonVal.id}/?Key=${token_key}`,
            infoDetails
          )
          .then((response) => {
            if (response.data.message == "lesson updated successfully") {
              this.messages = {
                text: "lesson updated successfully",
                type: "success",
              };
              setTimeout(() => {
                this.messages = {
                  text: "",
                  type: "",
                };
              }, 2000);
            } else {
              this.messages = {
                text: "Event Faild",
                type: "error",
              };
              setTimeout(() => {
                this.messages = {
                  text: "",
                  type: "",
                };
              }, 2000);
            }
          });
      }

      await this.lessoninfoGet();

      setTimeout(() => {
        this.messages = {
          text: "",
          type: "null",
        };
      }, 3000);
    },
    async createNewLesson(el) {
      this.description = document.querySelector(".ql-editor").innerHTML;
      console.log(this.description);

      let urls = `${process.env.VUE_APP_API}/lessonsnew/?Key=${this.userInfo.token_key}`;
      let HeaderVal = new FormData();
      let couseId = this.course_id;
      let file_val = el.target.elements.pdffile.files;
      let audio_file = el.target.elements.audio_file.files;
      if (file_val.length > 0) {
        HeaderVal.append("pdf_file", el.target.elements.pdffile.files[0]);
      }
      if (audio_file.length > 0) {
        HeaderVal.append("audio_file", el.target.elements.audio_file.files[0]);
      }
      HeaderVal.append("title", el.target.elements.title.value);
      HeaderVal.append("duration", el.target.elements.duration.value);
      HeaderVal.append("video_url", el.target.elements.videoUrl.value);
      HeaderVal.append("status", true);
      HeaderVal.append("content", this.description);
      HeaderVal.append("keyworeds", el.target.elements.keywords.value);
      HeaderVal.append("section_id", this.selectUnitId);
      HeaderVal.append("course_name", couseId);
      HeaderVal.append("orderby", el.target.elements.orderby.value);

      // HeaderVal.append("quizzes", 2);

      await axios
        .post(urls, HeaderVal, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.log(response);
          if (response.data.status == 200) {
            this.messages = {
              text: "Lesson added successfully",
              type: "success",
            };
            this.$refs.lessonaddFn.reset();
            this.popUpLesson = false;
            this.lessoninfoGet();
          } else {
            this.messages = {
              text: response.data.message,
              type: "error",
            };
          }
        });

      setTimeout(() => {
        this.messages = {
          text: "",
          type: "null",
        };
      }, 3000);
    },

    async createQuizOfSection(el) {
      // this.description = document.querySelector(".ql-editor").innerHTML;
      // console.log(this.description);

      let urls = `${process.env.VUE_APP_API}/lessonsnew/?Key=${this.userInfo.token_key}`;

      // let HeaderVal = new FormData();
      let HeaderVal = {};
      let couseId = this.course_id;

      // let file_val = el.target.elements.pdffile.files;
      // let audio_file = el.target.elements.audio_file.files;
      // if (file_val.length > 0) {
      //   HeaderVal.append("pdf_file", el.target.elements.pdffile.files[0]);
      // }
      // if (audio_file.length > 0) {
      //   HeaderVal.append("audio_file", el.target.elements.audio_file.files[0]);
      // }
      // HeaderVal.append("duration", el.target.elements.duration.value);
      // HeaderVal.append("video_url", el.target.elements.videoUrl.value);
      // HeaderVal.append("status", true);
      // HeaderVal.append("keyworeds", el.target.elements.keywords.value);

      HeaderVal["title"] = el.target.elements.title.value;
      HeaderVal["content"] = el.target.elements.content.value;
      HeaderVal["section_id"] = this.selectUnitId;
      HeaderVal["course_name"] = parseInt(couseId);
      HeaderVal["orderby"] = el.target.elements.order.value;

      let quizData = {}
      quizData['user'] = this.userInfo.user.id
      quizData['course'] = parseInt(couseId)
      quizData['name'] = el.target.elements.title.value
      quizData['description'] = "description"

      quizData['total_score'] = 100
      quizData['qualify_certificate'] = true
      quizData['min_score'] = 50
      quizData['pre_quiz'] = false

      quizData['questions'] = this.forms
      HeaderVal["quiz"] = quizData

      await axios
        .post(urls, HeaderVal)
        .then((response) => {
          console.log(response);
          if (response.data.message == "Lesson created successfully!") {
            this.messages = {
              text: "Quiz added successfully",
              type: "success",
            };
            this.$refs.addQuizRefs.reset();
            this.popUpLesson = false;
            this.lessoninfoGet();

            this.forms = [
              {
                question_text: "",
                answers: [
                  { answer_text: "", is_correct: false },
                  { answer_text: "", is_correct: false },
                  { answer_text: "", is_correct: false },
                  { answer_text: "", is_correct: false },
                ],
              },
            ]

            // this.question_text = "",
            // this.answers = [
            //     { answer_text: "", is_correct: false },
            //     { answer_text: "", is_correct: false },
            //     { answer_text: "", is_correct: false },
            //     { answer_text: "", is_correct: false },
            //   ]

          } else {
            this.messages = {
              text: response.data.message,
              type: "error",
            };
          }
        });

      setTimeout(() => {
        this.messages = {
          text: "",
          type: "null",
        };
      }, 3000);
    },

    async createNewsection(el) {
      let newsection = {
        title: el.target.elements.title.value,
        course_id: this.course_id,
      };
      await axios
        .post(
          `${process.env.VUE_APP_API}/sectionnew/?Key=${this.userInfo.token_key}`,
          newsection
        )
        .then((response) => {
          if (response.data.message == "Your section was added successfully") {
            this.messages = {
              text: "Section added successfully",
              type: "success",
            };
            this.$refs.addSection.reset();
            this.popUpsection = false;
            this.sectionInfoGet();
          } else {
            this.messages = {
              text: "Event Faild",
              type: "error",
            };
          }
        });

      setTimeout(() => {
        this.messages = {
          text: "",
          type: "null",
        };
      }, 3000);
      // setTimeout(()=>{this.$router.go(0);},1000)
    },
    updatesection(data) {
      this.popUpsectionUpdate = false;

      let updatedsection = {
        id: data.id,
        title: data.title,
        course_name: data.course_name,
      };

      axios
        .put(
          `${process.env.VUE_APP_API}/Sectionupdate/${data.id}/?Key=${this.userInfo.token_key}`,
          updatedsection
        )
        .then((response) => {
          if (response.data.message == "section updated successfully") {
            this.messages = {
              text: "section updated successfully",
              type: "success",
            };
          } else {
            this.messages = {
              text: "Event Faild",
              type: "error",
            };
          }
        });
      setTimeout(() => {
        this.messages = {
          text: "",
          type: "null",
        };
      }, 3000);
    },
    async deleteLesson(lessonVal) {
      await axios
        .delete(
          `${process.env.VUE_APP_API}/lesson_deleted/${lessonVal}/?Key=${this.userInfo.token_key}`
        )
        .then((response) => {
          //  setTimeout(()=>{this.$router.go(0);},1000)
          if (
            response.data.message == "This Lesson data deleted successfully"
          ) {
            this.messages = {
              text: "lesson Deleted successfully",
              type: "success",
            };
          } else {
            this.messages = {
              text: "Event Faild",
              type: "error",
            };
          }
        });
      setTimeout(() => {
        this.messages = {
          text: "",
          type: "null",
        };
      }, 3000);

      this.popuplessonDelete = false;
      await this.lessoninfoGet();
    },
    async deleteSection(section) {
      await axios
        .delete(
          `${process.env.VUE_APP_API}/section_deleted/${section}/?Key=${this.userInfo.token_key}`
        )
        .then((response) => {
          if (
            response.data.message == "This Section data deleted successfully"
          ) {
            this.messages = {
              text: "Section Deleted successfully",
              type: "success",
            };
          } else {
            this.messages = {
              text: "Event Faild",
              type: "error",
            };
          }
        });
      setTimeout(() => {
        this.messages = {
          text: "",
          type: "null",
        };
      }, 3000);

      this.popupsectionDelete = false;
      await this.sectionInfoGet();
      //  setTimeout(()=>{this.$router.go(0);},1000)
    },
    handleClickOutside(event) {

      if (!event.target.closest(".dropdown-container")) {
        this.activeOperationMenu = null;
        this.activeLessonOperationMenu = null;
      }
    },
  },
};
</script>

<style scoped>
.heightfix {
  height: calc(100vh - 170px) !important;
}
</style>