import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "./index.css";


import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";


import { faEnvelope } from '@fortawesome/free-regular-svg-icons';

library.add(faEnvelope);
  
const app = createApp(App);
document.title ="COMS";

// createApp(App).use(store).use(router).mount('#app')

app.component("font-awesome-icon", FontAwesomeIcon);

app.use(store).use(router).mount("#app");