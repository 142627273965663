<template>

  <div v-if="hide_menu" class="absolute top-[15%] z-[60] bg-blues p-2 lg:hidden rounded-r-xl cursor-pointer h-[40px]" @click="toggleMenu">
    <!-- <div class="pl-2">
    <div class="bg-gray-500 w-8 mt-1.5 h-1 rounded-lg"></div>
    <div class="bg-gray-500 w-8 mt-1.5 h-1 rounded-lg"></div>
    <div class="bg-gray-500 w-8 mt-1.5 h-1 rounded-lg"></div>
    </div> -->
     <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="20"
      height="20"
      viewBox="0 0 50 50"
      fill="#fff"
    >
      <path
        d="M 0 7.5 L 0 12.5 L 50 12.5 L 50 7.5 Z M 0 22.5 L 0 27.5 L 50 27.5 L 50 22.5 Z M 0 37.5 L 0 42.5 L 50 42.5 L 50 37.5 Z"
      ></path>
    </svg>
  </div>

  <div
    class="lg:flex fixed w-[95%] bg-white lg:w-[35%] lg:left-0 xl:w-[30%] 2xl:w-[25%] lg:relative z-30 mt-10 lg:m-10  rounded-3xl heightfix overflow-y-auto" :class="{'hidden': !isMobile}"
    style="box-shadow: 0px 2px 30px 0px #01b0f926"
  >
  <div
      @click="toggleSidebar"
      class="absolute top-2 right-[2px] z-30 block lg:hidden bg-gray-200 rounded-full p-1 cursor-pointer"
    >
      <!-- <span class="text-2xl">⬅️</span> -->
      <img src="@/assets/leftArrow.svg" alt="" srcset="" class="w-5 h-5" />
    </div>
    <div class="flex flex-col gap-5 relative w-full overflow-y-auto  p-10">
      <router-link
        :to="{ name: 'InstructorStudents' }"
        class="flex items-center gap-5 p-4 rounded-3xl"
        :class="{ 'bg-[#0085DB]': $route.name == 'InstructorStudents' }"
      >
        <img
          src="@/assets/newd/icons/Grid.png"
          v-if="$route.name == 'InstructorStudents'"
          alt=""
        />
        <img
          src="@/assets/newd/icons/Grid-1.png"
          v-if="$route.name != 'InstructorStudents'"
          alt=""
        />
        <p
          class="font-bold text-sm"
          :class="{
            'text-white': $route.name == 'InstructorStudents',
            'text-[#333333]': $route.name != 'InstructorStudents',
          }"
        >
          Students
        </p>
      </router-link>

      <router-link
        :to="{ name: 'InstructorMessages' }"
        class="flex items-center gap-5 p-4 rounded-3xl"
        :class="{ 'bg-[#0085DB]': $route.name == 'InstructorMessages' }"
      >
        <img
          src="@/assets/newd/icons/mdi-light_forum-1.png"
          v-if="$route.name == 'InstructorMessages'"
          alt=""
        />
        <img
          src="@/assets/newd/icons/mdi-light_forum.png"
          v-if="$route.name != 'InstructorMessages'"
          alt=""
        />

        <p
          class="font-bold text-sm"
          :class="{
            'text-white': $route.name == 'InstructorMessages',
            'text-[#333333]': $route.name != 'InstructorMessages',
          }"
        >
          Messages
        </p>
      </router-link>

      <router-link
        :to="{ name: 'InstructorAssignments' }"
        class="flex items-center gap-5 p-4 rounded-3xl"
        :class="{ 'bg-[#0085DB]': $route.name == 'InstructorAssignments' }"
      >
        <img
          src="@/assets/newd/icons/gg_notes-1.png"
          v-if="$route.name == 'InstructorAssignments'"
          alt=""
        />
        <img
          src="@/assets/newd/icons/gg_notes.png"
          v-if="$route.name != 'InstructorAssignments'"
          alt=""
        />
        <p
          class="font-bold text-sm"
          :class="{
            'text-white': $route.name == 'InstructorAssignments',
            'text-[#333333]': $route.name != 'InstructorAssignments',
          }"
        >
          Assignments
        </p>
      </router-link>

      <router-link
        :to="{ name: 'InstructorForums' }"
        class="flex items-center gap-5 p-4 rounded-3xl"
        :class="{ 'bg-[#0085DB]': $route.name == 'InstructorForums' }"
      >
        <img
          src="@/assets/newd/icons/Archive-1.png"
          v-if="$route.name == 'InstructorForums'"
          alt=""
        />
        <img
          src="@/assets/newd/icons/Archive.png"
          v-if="$route.name != 'InstructorForums'"
          alt=""
        />

        <p
          class="font-bold text-sm"
          :class="{
            'text-white': $route.name == 'InstructorForums',
            'text-[#333333]': $route.name != 'InstructorForums',
          }"
        >
          Forums
        </p>
      </router-link>

      <router-link
        :to="{ name: 'InstructorClasses' }"
        class="flex items-center gap-5 p-4 rounded-3xl"
        :class="{ 'bg-[#0085DB]': $route.name == 'InstructorClasses' }"
      >
        <img
          src="@/assets/newd/icons/Calendar2Week-1.png"
          v-if="$route.name == 'InstructorClasses'"
          alt=""
        />
        <img
          src="@/assets/newd/icons/Calendar2Week.png"
          v-if="$route.name != 'InstructorClasses'"
          alt=""
        />

        <p
          class="font-bold text-sm"
          :class="{
            'text-white': $route.name == 'InstructorClasses',
            'text-[#333333]': $route.name != 'InstructorClasses',
          }"
        >
          Live Classes
        </p>
      </router-link>

      <router-link
        :to="{ name: 'InstructorQuizs' }"
        class="flex items-center gap-5 p-4 rounded-3xl"
        :class="{ 'bg-[#0085DB]': $route.name == 'InstructorQuizs' }"
      >
        <img
          src="@/assets/newd/icons/BarChartLine-1.png"
          v-if="$route.name == 'InstructorQuizs'"
          alt=""
        />
        <img
          src="@/assets/newd/icons/BarChartLine.png"
          v-if="$route.name != 'InstructorQuizs'"
          alt=""
        />

        <p
          class="font-bold text-sm"
          :class="{
            'text-white': $route.name == 'InstructorQuizs',
            'text-[#333333]': $route.name != 'InstructorQuizs',
          }"
        >
          Assessment
        </p>
      </router-link>
    

      <router-link
        :to="{ name: 'InstructorBlogs' }"
        class="flex items-center gap-5 p-4 rounded-3xl"
        :class="{ 'bg-[#0085DB]': $route.name == 'InstructorBlogs' }"
      >

        <img
          src="@/assets/newd/icons/gg_notes-1.png"
          v-if="$route.name == 'InstructorBlogs'"
          alt=""
        />
        <img
          src="@/assets/newd/icons/gg_notes.png"
          v-if="$route.name != 'InstructorBlogs'"
          alt=""
        />

        <p
          class="font-bold text-sm"
          :class="{
            'text-white': $route.name == 'InstructorBlogs',
            'text-[#333333]': $route.name != 'InstructorBlogs',
          }"
        >
          Projects
        </p>
      </router-link>

      <router-link
        :to="{ name: 'CourseComplete' }"
        class="flex items-center gap-5 p-4 rounded-3xl"
        :class="{ 'bg-[#0085DB]': $route.name == 'CourseComplete' }"
      >

        <img
          src="@/assets/newd/icons/gg_notes-1.png"
          v-if="$route.name == 'CourseComplete'"
          alt=""
        />
        <img
          src="@/assets/newd/icons/gg_notes.png"
          v-if="$route.name != 'CourseComplete'"
          alt=""
        />

        <p
          class="font-bold text-sm"
          :class="{
            'text-white': $route.name == 'CourseComplete',
            'text-[#333333]': $route.name != 'CourseComplete',
          }"
        >
          Grading
        </p>
      </router-link>

      <router-link
        :to="{ name: 'InstructorInviteCourse' }"
        class="flex items-center gap-5 p-4 rounded-3xl"
        :class="{ 'bg-[#0085DB]': $route.name == 'InstructorInviteCourse' }"
      >
        <img
          src="@/assets/newd/icons/Calendar2Week-1.png"
          v-if="$route.name == 'InstructorInviteCourse'"
          alt=""
        />
        <img
          src="@/assets/newd/icons/Calendar2Week.png"
          v-if="$route.name != 'InstructorInviteCourse'"
          alt=""
        />

        <p
          class="font-bold text-sm"
          :class="{
            'text-white': $route.name == 'InstructorInviteCourse',
            'text-[#333333]': $route.name != 'InstructorInviteCourse',
          }"
        >
          Invite
        </p>
      </router-link>
      <router-link
        :to="{ name: 'SkillGapAnalysis' }"
        class="flex items-center gap-5 p-4 rounded-3xl"
        :class="{ 'bg-[#0085DB]': $route.name == 'SkillGapAnalysis' }"
      >

        <img
          src="@/assets/newd/icons/BarChartLine-1.png"
          v-if="$route.name == 'SkillGapAnalysis'"
          alt=""
        />
        <img
          src="@/assets/newd/icons/BarChartLine.png"
          v-if="$route.name != 'SkillGapAnalysis'"
          alt=""
        />

        <p
          class="font-bold text-sm"
          :class="{
            'text-white': $route.name == 'SkillGapAnalysis',
            'text-[#333333]': $route.name != 'SkillGapAnalysis',
          }"
        >
          Skill Gap Analysis
        </p>
      </router-link>

    </div>
  </div>
</template>

<script>
export default {
  data(){
    return {
      isMobile: false,
      hide_menu: true,
    }
  },
  methods:{
     toggleSidebar() {
      this.isMobile = false;
      this.hide_menu = true;
    },
     toggleMenu() {
      this.isMobile = !this.isMobile;
      this.hide_menu = !this.isMobile; // Hide button when menu is open
    },
  }
};
</script>

<style scoped>
.heightfix {
  height: calc(100vh - 170px) !important;
}</style>
