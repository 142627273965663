<template>
  <div>
    <Header />
    <FlashMessage v-if="this.messages.text != ''" :message="this.messages" />

    <div class="py-10 overflow-y-auto">
      <div class="container mx-auto max-w-[1200px] flex flex-col gap-10">
        <div class="flex justify-end px-6">
          <router-link
            :to="{ name: 'InstructorBlogsList' }"
            class="bg-blues text-white rounded-lg text-lg font-semibold px-8 py-1.5"
            >Back</router-link
          >
        </div>
        <div class="grid lg:grid-cols-1 gap-10">
          <div class="rounded-3xl">
            <div class="flex flex-col h-auto gap-5 lg:text-left px-6 py-6">
              <img
                v-if="blogdetail_list.thumbnail"
                class="h-[450px] w-full rounded-lg"
                :src="blogdetail_list.thumbnail"
                alt=""
              />
              <img
                v-else
                class="object-cover object-center h-24 w-52 rounded"
                src="@/assets/coding.svg"
                alt=""
              />

              <h1 class="font-bold text-2xl text-primary">
                {{ blogdetail_list.title }}
              </h1>
              <div class="text-lg">
                {{ formatDate(blogdetail_list.create_date) }}
              </div>
              <p class="text-black" v-html="blogdetail_list.content"></p>
            </div>
          </div>
        </div>

        <div class="px-6">
          <h1 class="text-lg font-bold py-2">Comments</h1>
          <div class="flex items-center mb-2">
            <div
              class="w-8 h-8 rounded-full flex items-center justify-center text-white font-bold"
            >
              <!-- <div v-if="commentList.user_id.profile_image">
                <img
                  :src="commentList.user_id.profile_image"
                  alt=""
                  class="w-8 h-8 rounded-full border-2 border-white"
                />
              </div> -->
              <div>
                <img
                  src="@/assets/new/image.png"
                  alt=""
                  class="w-8 h-8 rounded-full border-2 border-white"
                />
              </div>
            </div>

            <span class="ml-2 font-semibold">{{
              userInfo.user.display_name
            }}</span>
          </div>

          <div class="w-full p-4 border rounded-md shadow-md bg-gray-100">
            <textarea
              v-model="text"
              @input="adjustHeight"
              placeholder="What are you thoughts?"
              class="w-full h-[40px] max-h-[200px] overflow-y-auto resize-none border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
            ></textarea>

            <div v-if="text" class="flex justify-end gap-2 mt-3">
              <button
                @click="clearText"
                class="px-4 py-2 bg-gray-300 text-gray-700 rounded-md hover:bg-gray-400"
              >
                Cancel
              </button>
              <button
                @click="sendText"
                class="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
              >
                Send
              </button>
            </div>
          </div>
        </div>

        <div class="px-6">
          <div
            v-for="comment in this.visibleComments"
            :key="comment.id"
            class="pb-5 flex gap-2"
          >
            <div class="w-[2px] bg-gray-500 min-h-[40px]"></div>
            <div class="w-full" style="box-shadow: 0px 2px 20px 0px #105c8e26">
              <!-- name -->
              <div class="flex  items-center mb-2">
               
                  <div
                    class="w-8 h-8 rounded-full flex items-center justify-center text-white font-bold"
                  >
                    <div v-if="comment.user_id.profile_image">
                      <img
                        :src="comment.user_id.profile_image"
                        alt=""
                        class="w-8 h-8 rounded-full border-2 border-white"
                      />
                    </div>
                    <div v-else>
                      <img
                        src="@/assets/new/image.png"
                        alt=""
                        class="w-8 h-8 rounded-full border-2 border-white"
                      />
                    </div>
                  </div>

                  <span class="ml-2 font-semibold">{{
                    comment.user_id.display_name
                  }}</span>
              
                
              </div>
              <!-- name end -->

              <div
                class="px-6 py-3 flex flex-col gap-5 w-full rounded-lg cursor-pointer border-b"
              >
                <div class=" text-gray-500 text-sm">{{ comment.comment }}</div>
                <div
                  v-if="editCommentId == comment.id"
                  class="w-full p-4 border rounded-md shadow-md bg-gray-100"
                >
                  <textarea
                    v-model="editCommentContent"
                    @input="adjustHeight"
                    placeholder="What are you thoughts?"
                    class="w-full h-[40px] max-h-[200px] overflow-y-auto resize-none border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                  ></textarea>

                  <div
                   
                    class="flex justify-end gap-2 mt-3"
                  >
                    <button
                      @click="editCommentId = null"
                      class="px-4 py-2 bg-gray-300 text-gray-700 rounded-md hover:bg-gray-400"
                    >
                      Cancel
                    </button>
                    <button
                      @click="editCommentApi(comment.id)"
                      class="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
                    >
                      Update
                    </button>
                  </div>
                </div>

                <!-- {{ replycommentList }} -->
                <div class="">
                  <div
                    v-for="replyComment in replycommentList"
                    :key="replyComment"
                  >
                    <div
                      class="flex mb-2"
                      v-if="replyComment.comment_id == comment.id"
                    >
                      <div
                        class="w-8 h-8 rounded-full flex items-center justify-center text-white font-bold"
                      >
                        <div v-if="replyComment.user_id.profile_image">
                          <img
                            :src="replyComment.user_id.profile_image"
                            alt=""
                            class="w-8 h-8 rounded-full border-2 border-white"
                          />
                        </div>
                        <div v-else>
                          <img
                            src="@/assets/new/image.png"
                            alt=""
                            class="w-8 h-8 rounded-full border-2 border-white"
                          />
                        </div>
                      </div>
                      <div class="flex flex-col gap-2 w-full">
                        <span class="ml-2 font-semibold">{{
                          replyComment.user_id.display_name
                        }}</span>

                        <div class="ml-2">{{ replyComment.comment }}</div>
                            <div v-if="editInnerCommentId == replyComment.id" class="w-full p-4 border rounded-md shadow-md bg-gray-100">

                          <textarea
                            v-model="editInnerCommentContent"
                            @input="adjustHeight"
                            placeholder="What are you thoughts?"
                            class="w-full h-[40px] max-h-[200px] overflow-y-auto resize-none border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                          ></textarea>

                          <div class="flex justify-end gap-2 mt-3">
                            <button
                              @click="editInnerCommentId = null"
                              class="px-4 py-2 bg-gray-300 text-gray-700 rounded-md hover:bg-gray-400"
                            >
                              Cancel
                            </button>
                            <button
                              @click="editCommentInnerApi(replyComment.id)"
                              class="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
                            >
                              Save
                            </button>
                          </div>

                        </div>


                        <p v-if="replyComment.user_id.id == this.userInfo.user.id"
                          class="text-sm underline text-gray-500 font-bold ml-2"
                          @click="editInnerReply(replyComment.id, replyComment.comment)"
                        >
                          Edit 
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                    <div class="flex items-center gap-3"> 
                  <p
                    class="text-sm underline text-gray-500 font-bold"
                    @click="toggleReply(comment.id)"
                  >
                    Reply
                  </p>
                  <p v-if="comment.user_id.id == this.userInfo.user.id"
                    class="text-sm underline text-gray-500 font-bold"
                    @click="editReply(comment.id, comment.comment)"
                  >
                    Edit 
                  </p>
                  </div>
                  <!-- reply comment -->
                  <div
                    v-if="isOpen === comment.id"
                    class="w-full mt-2 p-4 border rounded-md shadow-md bg-gray-100"
                  >
                    <textarea
                      v-model="replytext"
                      @input="adjustHeight"
                      class="w-full h-[40px] max-h-[200px] overflow-y-auto resize-none border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                    ></textarea>

                    <div v-if="replytext" class="flex justify-end gap-2 mt-3">
                      <button
                        @click="clearText"
                        class="px-4 py-2 bg-gray-300 text-gray-700 rounded-md hover:bg-gray-400"
                      >
                        Cancel
                      </button>
                      <button
                        @click="textreply(comment.id)"
                        class="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
                      >
                        Send
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

       

        <button
          v-if="visibleComments.length < commentList.length"
          @click="loadMore"
          class="text-center underline text-blues text-sm"
        >
          See More
        </button>

        <button
          v-if="
            visibleComments.length > valueSeeMoreComment &&
            visibleComments.length == commentList.length
          "
           class="text-center underline text-blues text-sm"
          @click="seeLess"
        >
          See Less
        </button>
      </div>
    </div>
    <!-- blogs -->
    <div class="py-10 overflow-y-auto">
      <div class="container mx-auto max-w-[1200px] flex flex-col gap-10">
        <div class="flex justify-between items-center">
          <h1 class="py-2 text-2xl text-center font-medium text-black">
            Recommended from Blogs
          </h1>
          <router-link
            :to="{ name: 'InstructorBlogsList' }"
            class="hover:text-blues"
          >
            View All
          </router-link>
        </div>
        <div class="grid lg:grid-cols-3 gap-10">
          <div v-for="blog in this.bloglistFilter.slice(0, 3)" :key="blog.id">
            <div
              @click="pushRoute(blog.id)"
              class="rounded-3xl cursor-pointer"
              style="box-shadow: 0px 2px 20px 0px #105c8e26"
            >
              <div
                class="flex flex-col min-h-[500px] justify-between gap-2 lg:text-left px-6 py-6"
              >
                <img
                  v-if="blog.thumbnail"
                  class="object-cover object-center h-[250px] w-full rounded-lg"
                  :src="blog.thumbnail"
                  alt=""
                />
                <img
                  v-else
                  class="object-cover object-center h-24 w-52 rounded"
                  src="@/assets/coding.svg"
                  alt=""
                />

                <h1 class="font-bold text-lg text-primary leading-6">
                  {{ blog.title }}
                </h1>
                <p class="text-blues text-sm font-bold">
                  {{ blog.bolger_id.display_name }}
                </p>

                <div class="text-sm text-gray-500 font-medium">
                  {{ formatDate(blog.create_date) }}
                </div>

                <p
                  class="text-black line-clamp-3 text-sm"
                  v-html="blog.content"
                ></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Instructor/Header.vue";

import axios from "axios";
import { mapGetters } from "vuex";
import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import FlashMessage from "@/components/FlashMessage.vue";

export default {
  components: {
    Header,
    QuillEditor,
    FlashMessage,
  },
  props: ["blog_id"],

  // beforeRouteUpdate(to, from, next) {
  //     this.blogsdetails();
  //     next();
  // },

  data() {
    return {
      blogdetail_list: [],
      commentList: [],
      messages: {
        text: "",
        type: "null",
      },
      text: "",
      replytext: "",
      isOpen: null,
      editcomment: null,
      replycommentList: [],
      bloglist: [],
      bloglistFilter: [],
      // isExpanded: false,
      valueSeeMoreComment: 2,
      visibleComments: [],
      activeOperationMenu: null,
      editCommentId: null,
      editCommentContent: "",
      editInnerCommentId: null,
      editInnerCommentContent: ""
    };
  },

  computed: mapGetters(["userInfo"]),

  async created() {
    this.blogsdetails();
    this.commentget();
    this.replycommentget();
    this.blogsget();
  },

  mounted() {
    // window.location.reload();
    document.addEventListener("click", this.handleClickOutside);
  },
  beforeUnmount() {
    document.removeEventListener("click", this.handleClickOutside);
  },

  methods: {
    MenuToggle(id) {
      this.activeOperationMenu = this.activeOperationMenu === id ? null : id;
    },
    handleClickOutside(event) {
      if (!event.target.closest(".dropdown-container")) {
        this.activeOperationMenu = null;
      }
    },
    toggleeditReply(id) {
      this.activeOperationMenu = null;
      this.editcomment = this.editcomment === id ? null : id;
      this.textcontent = false;
    },
    toggleReply(commentId) {
      this.isOpen = this.isOpen === commentId ? null : commentId;
      this.replytext = "";
    },
     editReply(commentId, commentValue) {
      this.editCommentId = this.editCommentId === commentId ? null : commentId;
      this.editCommentContent = commentValue;
    },
    editInnerReply(commentId, commentValue){
      this.editInnerCommentId = this.editInnerCommentId === commentId ? null : commentId;
      this.editInnerCommentContent = commentValue;
    },
     editCommentInnerApi(id){
      axios.put(`${process.env.VUE_APP_API}/blog-replay-comment/deleted/${id}/?Key=${this.userInfo.token_key}`, {
          comment: this.editInnerCommentContent
        })
          .then((response)=>{
            if(response.data.status == 200){
              this.commentget();
              this.replycommentget();
              setTimeout(()=>{
                this.editInnerCommentId = null;
              },300)
              this.messages = {
                text: response.data.message,
                type: "success",
              };
              setTimeout(() => {
                this.messages = {
                  text: "",
                  type: "null",
                };
              }, 2000);

            }
            else{
              console.log("something went wrong.");
            }
          }
          )
          .catch(err=>{
            console.log(err);
          })
    },

    editCommentApi(id){
        axios.put(`${process.env.VUE_APP_API}/blog-comment/deleted/${id}/?Key=${this.userInfo.token_key}`, {
          comment: this.editCommentContent
        })
          .then((response)=>{
            if(response.data.status == 200){
              this.commentget();
              this.replycommentget();
              setTimeout(()=>{
                this.editCommentId = null;
              },300)

              this.messages = {
                text: response.data.message,
                type: "success",
              };
              setTimeout(() => {
                this.messages = {
                  text: "",
                  type: "null",
                };
              }, 2000);

            }
            else{
              console.log("something went wrong.");
            }
          }
          )
          .catch(err=>{
            console.log(err);
          })
    },

    loadMore() {
      const nextIndex = this.visibleComments.length;
      const nextComments = this.commentList.slice(
        nextIndex,
        nextIndex + this.valueSeeMoreComment
      );
      this.visibleComments.push(...nextComments);
    },

    seeLess() {
      this.visibleComments = this.commentList.slice(
        0,
        this.valueSeeMoreComment
      );
    },

    pushRoute(b_id) {
      this.$router.push({
        name: "InstructorBlogDetails",
        params: { blog_id: b_id },
      });
      setTimeout(() => {
        window.location.reload();
      }, 10);
    },

    adjustHeight(event) {
      const textarea = event.target;
      textarea.style.height = "30px";
      if (textarea.scrollHeight >= 100) {
        textarea.style.height = `${textarea.scrollHeight}px`;
      } else {
        textarea.style.height = "100px";
      }
    },
    clearText() {
      this.text = "";
      this.isOpen = null;
      this.$nextTick(() => {
        const textarea = document.querySelector("textarea");
        if (textarea) {
          textarea.style.height = "40px";
        }
      });
    },
    async sendText() {
      let HeaderVal = new FormData();
      HeaderVal.append("comment", this.text);
      HeaderVal.append("user_id", this.userInfo.user.id);
      HeaderVal.append("bolg_id", this.blog_id);
      await axios
        .post(
          `${process.env.VUE_APP_API}/blog-comment/post/?Key=${this.userInfo.token_key}`,
          HeaderVal
        )
        .then((response) => {
          if (response.data.status == 200) {
            this.messages = {
              text: response.data.message,
              type: "success",
            };
            setTimeout(() => {
              this.messages = {
                text: "",
                type: "null",
              };
              this.clearText();
              this.commentget();
              this.description = "";
            }, 500);
          }
        });
    },
    async textreply(id) {
      let HeaderVal = new FormData();
      HeaderVal.append("comment", this.replytext);
      HeaderVal.append("user_id", this.userInfo.user.id);
      HeaderVal.append("comment_id", id);
      await axios
        .post(
          `${process.env.VUE_APP_API}/blog-replay-comment/post/?Key=${this.userInfo.token_key}`,
          HeaderVal
        )
        .then((response) => {
          if (response.data.status == 200) {
            this.messages = {
              text: response.data.message,
              type: "success",
            };
            this.isOpen = null;
            setTimeout(() => {
              this.messages = {
                text: "",
                type: "null",
              };
              this.clearText();
              this.replycommentget();
              this.replytext = "";
            }, 500);
          }
        });
    },

    blogsdetails() {
      axios
        .get(
          `${process.env.VUE_APP_API}/blog-details/list/?id=${this.blog_id}&Key=${this.userInfo.token_key}`
        )
        .then((res) => {
          console.log("res", res);
          if (res.data.data.data) {
            this.blogdetail_list = res.data.data.data[0];
          }
        });
    },
    formatDate(date) {
      return new Date(date).toLocaleDateString("en-IN", {
        day: "numeric",
        month: "long",
        year: "numeric",
      });
    },

    commentget() {
      axios
        .get(
          `${process.env.VUE_APP_API}/blog-comments/list/?bolg_id__id=${this.blog_id}&Key=${this.userInfo.token_key}`
        )
        .then((res) => {
          console.log("res", res);
          if (res.data.data.data) {
            this.commentList = res.data.data.data;
            this.visibleComments = this.commentList.slice(
              0,
              this.valueSeeMoreComment
            );
            // this.commentList = this.commentList.slice(0, this.valueSeeMoreComment);
          }
        });
    },
    replycommentget() {
      axios
        .get(
          `${process.env.VUE_APP_API}/blog-replay-comment/list/?Key=${this.userInfo.token_key}`
        )
        .then((res) => {
          console.log("res", res);
          if (res.data.data.data) {
            this.replycommentList = res.data.data.data;
            console.log(" this.commentList", this.comment_id);
          }
        });
    },
    blogsget() {
      axios
        .get(
          `${process.env.VUE_APP_API}/blog-details/list/?Key=${this.userInfo.token_key}`
        )
        .then((res) => {
          console.log("res", res);
          if (res.data.data.data) {
            this.bloglist = res.data.data.data;
            this.bloglistFilter = this.bloglist.filter(
              (blog) => blog.id != this.blog_id
            );
          }
        });
    },
  },
};
</script>

<style></style>
